import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from "react-router-dom"
import useMousetrap from "react-hook-mousetrap"

import {
    Grid,
    TextField,
    makeStyles,
    Breadcrumbs,
    Typography,
    IconButton,
    Button, Tooltip, withStyles, Checkbox
} from '@material-ui/core'
import {blue, red} from "@material-ui/core/colors"
import {
    SelectionState, PagingState, CustomPaging, IntegratedSelection
} from '@devexpress/dx-react-grid'
import {
    DragDropProvider,
    Table,
    TableSelection,
    TableHeaderRow,
    TableColumnResizing,
    PagingPanel,
    TableColumnReordering,
    TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui'

import {FileActions} from "../actions/file"
import {ItemActions} from "../actions/item"
import {ItemActions as StandardActions} from "../../Item/actions/item"
import {useDebouncedCallback} from 'use-debounce'
import {StickyTable} from "../../App/components/Table/StikyTable"
import {Grid as GridTable} from "../../App/components/Table/Grid"
import {Pager} from "../../App/components/Table/Paging/Pager"
import {Home, NavigateNext, HighlightOff, Check} from "@material-ui/icons"
import Alert from "@material-ui/lab/Alert"
import {DeleteForm} from "./DeleteForm"
import {endings} from "../../App/helpers/endings"
import {CategoryForm} from "./CategoryForm"
import * as Yup from "yup"
import {Field, Form, Formik} from "formik"
import {TextField as MUITextField} from "formik-material-ui"
import {FieldCategory} from "../../Category/components/Attribute/FieldCategory"
import {SystemActions} from "../../App/actions/system"
import {Standard} from "./Standard"
import {ItemForm} from "../../Item/components/ItemForm"
import {UnitActions} from "../../Dictionary/actions/unit"
import {TableHeaderContent} from "./Table/TableHeaderContent"
import {ColumnChooser} from "../../Item/components/Table/ColumnChooser"

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    data: {
        'height': 'calc(100% - 72px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '52px'
    },
    active: {
        'height': '41px',
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    confirmation: {
        'height': '41px',
        'background-color': red[50],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    body: {
        'height': '100%'
    },
    tooltip: {
        "background-color": "#000",
        "font-size": "15px",
        "color": "#fff",
        "padding": "15px 15px",
    }
}))

const StyledAlert = withStyles((theme) => ({
    message: {
        'padding': '0'
    },
}))(Alert)

export const Items = (props) => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const classes = useStyles()
    const {account, items, filter, file, categories} = useSelector(state => {return {...state.account, ...state.system, ...state.markup_file, ...state.markup_item}} )
    const [search, setSearch] = useState('')
    const [item, setItem] = useState(null)
    const [standard, setStandard] = useState(null)
    const [tableRef, setTableRef] = useState(null)

    const debounced = useDebouncedCallback(
        (value) => {
            setLoading(value)
        }, 700
    )

    const columns = [
        { name: 'index', title: '№'},
        { name: 'keys', title: 'Идентификаторы'},
        { name: 'categories', title: 'Категории'},
        { name: 'subcategories', title: 'Подкатегории'},
        { name: 'value', title: 'Наименование товара/услуги' },
        { name: 'status', title: 'Статус' },
        { name: 'standard', title: 'Эталон'},
        { name: 'category', title: 'Категория эталона'},
        { name: 'variation', title: 'Не использовать для обучения'},
        { name: 'description', title: 'Описание'},
        { name: 'user', title: 'Пользователь'}
    ]

    const [hiddenColumnNames, setHiddenColumnNames] = useState(['categories', 'subcategories', 'description', 'user'])

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [loading, setLoading] = useState(false)
    const [initialize, setInitialize] = useState(false)
    const [selection, setSelection] = useState([])
    const [select, setSelect] = useState(null)
    const [submit, setSubmit] = useState(false)
    const [change, setChange] = useState(false)
    const [deleted, setDeleted] = useState(false)
    const [category, setCategory] = useState(false)
    const [selectionPage, setSelectionPage] = useState({})
    const [columnWidths, setColumnWidths] = useState([
        { columnName: 'index', width: 50 },
        { columnName: 'keys', width: 150 },
        { columnName: 'value', width: 250 },
        { columnName: 'categories', width: 250 },
        { columnName: 'subcategories', width: 250 },
        { columnName: 'status', width: 150 },
        { columnName: 'standard', width: 450 },
        { columnName: 'category', width: 350 },
        { columnName: 'variation', width: 250 },
        { columnName: 'description', width: 250 },
        { columnName: 'user', width: 200 }
    ])
    const [columnOrder, setColumnOrder] = useState(['index', 'keys', 'value', 'categories', 'subcategories', 'status', 'standard', 'category', 'variation', 'description', 'user'])

    const getStatus = item => {
        if (item.item.standard && item.item.standard.confirmed) {
            return <StyledAlert icon={false} color="success">Сопоставлено</StyledAlert>
        } else if (item.item.category) {
            return <StyledAlert icon={false} color="warning">Категория</StyledAlert>
        }
        return <StyledAlert icon={false} color="error">Не проверено</StyledAlert>
    }

    const getStandard = item => {
        if (item.item.match && !item.item.matching) {
            return <Grid container direction="column" justify="center" alignItems="stretch" className={ classes.fullWidth }>
                <Grid item>
                    <Typography variant="body2" color="initial" style={{ whiteSpace: 'break-spaces' }} noWrap>
                        {item.item.match.name}
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container direction="row" justify="flex-start" alignItems="center" className={ classes.fullWidth }>
                        <Grid item>
                            <Button
                                onClick={() => {
                                    handleStandard(file.id, item.id, {standard: item.item.match.id})
                                }}
                                color="primary"
                                type="button"
                            >
                                <Typography variant="body2" noWrap>
                                    Подтвердить
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={() => {
                                    handleStandard(file.id, item.id, {standard: null})
                                }}
                                color="secondary"
                                type="button"
                            >
                                <Typography variant="body2" noWrap>
                                    Отклонить
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        }

        if (select === item.id) {
            if (!change && item.item.standard && !item.item.standard.confirmed && ((item.item.standard.creator && (item.item.standard.creator.id === account.id)) || !item.item.standard.creator) && ((item.item.standard.deleted && item.item.standard.log && (item.item.standard.log.type !== 'duplicate')) || !item.item.standard.deleted)) {
                return <React.Fragment>
                    <Button
                        onClick={() => {
                            setStandard(item.item.standard)
                            setItem(item)
                        }}
                        color="primary"
                        type="button"
                    >
                        <Typography variant="body2" noWrap>
                            Редактировать предложение
                        </Typography>
                    </Button>
                    <Button
                        onClick={() => {
                            setChange(true)
                        }}
                        color="primary"
                        type="button"
                    >
                        <Typography variant="body2" noWrap>
                            Выбрать эталон
                        </Typography>
                    </Button>
                </React.Fragment>
            }

            if (item.item.standard && item.item.standard.deleted && item.item.standard.log && (item.item.standard.log.type === 'duplicate')) {
                return <Grid container direction="column" justify="center" alignItems="stretch" className={ classes.fullWidth }>
                    <Grid item>
                        <Typography variant="body2" color="initial" style={{ whiteSpace: 'break-spaces' }} noWrap>
                            {item.item.standard.log.description.standard.name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="flex-start" alignItems="center" className={ classes.fullWidth }>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        handleStandard(file.id, item.id, {standard: item.item.standard.log.description.standard.id})
                                    }}
                                    color="primary"
                                    type="button"
                                >
                                    <Typography variant="body2" noWrap>
                                        Подтвердить
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        handleStandard(file.id, item.id, {standard: null})
                                    }}
                                    color="secondary"
                                    type="button"
                                >
                                    <Typography variant="body2" noWrap>
                                        Отклонить
                                    </Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            return (
                <Formik
                    enableReinitialize={ true }
                    initialValues={{
                        standard: item.item.standard ? item.item.standard : null
                    }}
                    validationSchema={Yup.object().shape({
                        standard: Yup.mixed()
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        handleStandard(file.id, item.id, {standard: (values.standard && values.standard.hasOwnProperty('id')) ? values.standard.id : null}).then(
                            () => {
                                setSubmitting(false)
                            },
                            () => {
                                setSubmitting(false)
                            }
                        )
                    }}
                >
                    {params => (
                        <Form>
                            <Standard {...params} item={item} setItem={setItem} />
                        </Form>
                    )}
                </Formik>
            )
        }

        return item.item.standard ?
            item.item.standard.deleted ? (
                <Grid container direction="row" justify="flex-start" alignItems="center" className={ classes.fullWidth } spacing={2}>
                    <Grid item style={{width: '40px'}}>
                        <Tooltip
                            title={!!item.item.standard.log ? ((item.item.standard.log.type === 'duplicate') ? `Эталон отклонен, уже имеется подходящий: "${item.item.standard.log.description.standard.name}".` : (item.item.standard.log.description ? `Эталон отклонен, "${item.item.standard.log.description.comment}"` : 'Эталон отклонен, "Некорректное торговое наименование"')) : ''}
                            placement="right"
                            classes={{tooltip: classes.tooltip}}
                        >
                            <IconButton
                                style={{
                                    "width": "24px",
                                    "height": "24px",
                                    "padding": "0"
                                }}
                            >
                                <HighlightOff color="error" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item style={{width: 'calc(100% - 40px)'}}>
                        <Typography variant="body2" color={(!!item.item.standard.log && (item.item.standard.log.type === 'duplicate')) ? 'textSecondary' : 'initial'} style={{ whiteSpace: 'break-spaces' }} noWrap>
                            {item.item.standard.name}
                        </Typography>
                    </Grid>
                </Grid>
            ) : <Typography variant="body2" style={{ whiteSpace: 'break-spaces' }}>{item.item.standard.name}</Typography>
            : null
    }

    const getDescription = item => {
        if (select === item.id) {
            return (
                <Formik
                    enableReinitialize={ true }
                    initialValues={{
                        description: item.description ?? ''
                    }}
                    validationSchema={Yup.object().shape({
                        description: Yup.string()
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)

                        handleDescription(file.id, item.id, {description: values.description}).then(
                            () => {
                                setSubmitting(false)
                            },
                            () => {
                                setSubmitting(false)
                            }
                        )
                    }}
                >
                    {({
                          handleSubmit
                      }) => (
                        <Form>
                            <Field
                                fullWidth
                                name="description"
                                type="text"
                                component={ MUITextField }
                                onBlur={() => {
                                    handleSubmit()
                                }}
                            />
                        </Form>
                    )}
                </Formik>
            )
        }

        return item.description
    }

    const getCategory = item => {
        if (select === item.id) {
            return (
                <Formik
                    enableReinitialize={ true }
                    initialValues={{
                        category: item.item.category ? categories.find(category => (category.id === item.item.category.id)) : {}
                    }}
                    validationSchema={Yup.object().shape({
                        category: Yup.mixed()
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        handleCategory(file.id, {category: values.category.hasOwnProperty('id') ? values.category.id : null, ids: [item.id]}).then(
                            () => {
                                setSubmitting(false)
                            },
                            () => {
                                setSubmitting(false)
                            }
                        )
                    }}
                >
                    {({
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) => (
                        <Form>
                            <FieldCategory
                                label={null}
                                disabled={isSubmitting || !!item.item.standard}
                                isSubmitting={isSubmitting}
                                onChange={(e, value) => {
                                    setFieldValue(`category`, value)
                                    handleSubmit()
                                }}
                            />
                        </Form>
                    )}
                </Formik>
            )
        }

        return item.item.category ? item.item.category.name : null
    }

    useMousetrap(["up", 'down'], (event) => {
        if (select) {
            const index = items.data.findIndex(item => (item.id === select))

            // eslint-disable-next-line default-case
            switch (event.code) {
                case 'ArrowUp':
                    if (index > 0) {
                        setSelect(items.data[index - 1].id)
                        setChange(false)
                    }
                    break
                case 'ArrowDown':
                    if (index + 1 < items.data.length) {
                        setSelect(items.data[index + 1].id)
                        setChange(false)
                    }
                    break
            }
        }
    }, 'keydown')

    useEffect(() => {
        const getData = async () => {
            dispatch(UnitActions.units())
            dispatch(SystemActions.categories())
            return await dispatch(FileActions.file(id))
        }

        if (!initialize && id) {
            getData().then(() => {
                setInitialize(true)
            })
        }

        if (initialize) {
            return () => {
                dispatch({type: 'MARKUP_FILE_CLEAR'})
                dispatch({type: 'MARKUP_FILE_ITEMS_CLEAR'})
                dispatch({type: 'MARKUP_FILE_ITEMS_FILTER', payload: {sort: {name: null, direction: null}, status: [], value: [], categories: [], subcategories: [], standard: [], category: [], user: []}})
            }
        }
    }, [initialize, id])

    useEffect(() => {
        const getData = async () => {
            return await dispatch(ItemActions.items(file.id, {
                page: page,
                limit: rowsPerPage,
                ...((filter.sort.name && filter.sort.direction) ? {order: [filter.sort.name, filter.sort.direction]} : []),
                ...(search ? {search: search} : {}),
                ...(!!filter.status.length ? {status: filter.status} : []),
                ...(!!filter.value.length ? {values: filter.value} : []),
                ...(!!filter.categories.length ? {categories: filter.categories} : []),
                ...(!!filter.subcategories.length ? {subcategories: filter.subcategories} : []),
                ...(!!filter.standard.length ? {standard: filter.standard} : []),
                ...(!!filter.category.length ? {category: filter.category} : []),
                ...(!!filter.user.length ? {users: filter.user} : []),
            }))
        }

        if (file && !loading) {
            getData().then(items => {
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setLoading(true)

                let numbers = []

                if (selectionPage.hasOwnProperty(page)) {
                    items.forEach((item, index) => {
                        if (selectionPage[page].find(id => (id === item.id))) {
                            numbers.push(index)
                        }
                    })
                }

                setSelection(numbers)
            })
        }
    }, [file, loading, page, rowsPerPage, search])

    useEffect(() => {
        setPage(1)
        setLoading(false)
    }, [filter])

    const handleDelete = (id, params) => {
        return dispatch(ItemActions.remove(id, params)).then(
            () => {
                setSelectionPage({})
                setSelection([])
                setLoading(false)
            }
        )
    }

    const handleCategory = (id, values) => {
        return dispatch(ItemActions.category(id, values)).then(
            () => {
                setSelectionPage({})
                setSelection([])
            }
        )
    }

    const handleStandard = (id, item, values) => {
        return dispatch(ItemActions.standard(id, item, values)).then(
            () => {
                setItem(null)
                setStandard(null)
            }
        )
    }

    const handleDescription = (id, item, values) => {
        return dispatch(ItemActions.description(id, item, values)).then(
            () => {
                setItem(null)
                setStandard(null)
            }
        )
    }

    const handleVariation = (id, item, values) => {
        return dispatch(ItemActions.variation(id, item, values)).then(
            () => {
                setSubmit(false)
            }
        )
    }

    const handleOffer = (values, id = null) => {
        return dispatch(id ? StandardActions.save(id, values) : StandardActions.add({...values, ...{confirmed: false}})).then(
            standard => {
                handleStandard(file.id, item.id, {standard: standard.id})
            }
        )
    }

    const handleDeleteOffer = () => {
        return handleStandard(file.id, item.id, {standard: null})
    }

    return file ? (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={ classes.body } spacing={2} onClick={() => {setSelect(null); setChange(false)}}>
            <Grid item className={classes.element}>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Grid item sm={3}>
                        <TextField
                            fullWidth
                            id="category"
                            label='Поиск'
                            value={search}
                            onChange={event => {
                                setPage(1)
                                setSearch(event.target.value)
                                debounced.callback(false)
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.breadcrumb}>
                <Grid container direction="row" justify="space-between" alignItems="center" style={{ height: '38px' }}>
                    <Grid item>
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
                            <IconButton
                                type="button"
                                size="small"
                                component={Link}
                                to="/markup"
                            >
                                <Home />
                            </IconButton>
                            <Typography variant="subtitle2" color="textPrimary">{file.name}</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item>
                                <Button
                                    disabled={ !Object.keys(selectionPage).length }
                                    onClick={() => {
                                        setSelect(null)
                                        setChange(false)
                                        setCategory(true)
                                    }}
                                    color="primary"
                                    type="button"
                                >
                                    Назначить категорию
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={ !Object.keys(selectionPage).length }
                                    onClick={() => {
                                        setSelect(null)
                                        setChange(false)
                                        setDeleted(true)
                                    }}
                                    color="secondary"
                                    type="button"
                                >
                                    Удалить
                                </Button>
                            </Grid>
                            <Grid item>
                                <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.data}>
                <GridTable
                    rows={items.data.map(item => ({
                        'id': item.id,
                        'index': item.index,
                        'keys': item.keys.join(', '),
                        'categories': item.categories.join(', '),
                        'subcategories': item.subcategories.join(', '),
                        'value': <Typography variant="body2" style={{ whiteSpace: 'break-spaces' }}>{item.value}</Typography>,
                        'status': getStatus(item),
                        'standard': getStandard(item),
                        'category': getCategory(item),
                        'variation': (select === item.id) ? <Checkbox
                            color="primary"
                            checked={!item.variation}
                            onClick={e => {
                                setSubmit(true)
                                handleVariation(file.id, item.id, {variation: e.target.checked})
                            }}
                            disabled={submit}
                        /> : (!item.variation ? <Check /> : null),
                        'user': item.item.user ? item.item.user.login : null,
                        'description': getDescription(item),
                        'item': item
                    }))}
                    columns={columns}
                >
                    <PagingState
                        currentPage={page}
                        onCurrentPageChange={newPage => {
                            setPage(newPage)
                            setLoading(false)
                        }}
                        pageSize={rowsPerPage}
                        onPageSizeChange={newRowsPerPage => {
                            setPage(1)
                            setRowsPerPage(newRowsPerPage)
                            setLoading(false)
                        }}
                    />
                    <CustomPaging
                        totalCount={items.data.length ? items.meta.total : 0}
                    />
                    <SelectionState
                        selection={selection}
                        onSelectionChange={(numbers) => {
                            setSelection(numbers)
                            const data = items.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined)).map(item => item.id)
                            setSelectionPage(!!data.length ? {...selectionPage, [page]: data} : Object.fromEntries(
                                Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                            ))
                        }}
                    />
                    <IntegratedSelection />
                    <DragDropProvider />
                    <Table
                        noDataCellComponent={props => {
                            return null
                        }}
                        tableComponent={props => <StickyTable onClick={e => e.stopPropagation()} {...props} setTableRef={setTableRef} />}
                        rowComponent={({ row, tableRow, children }) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.default}
                                row={row}
                            />
                        )}
                    />
                    <TableColumnReordering
                        order={columnOrder}
                        onOrderChange={setColumnOrder}
                    />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                    />
                    <TableHeaderRow
                        contentComponent={TableHeaderContent}
                    />
                    <TableColumnVisibility
                        hiddenColumnNames={hiddenColumnNames}
                        onHiddenColumnNamesChange={setHiddenColumnNames}
                    />
                    <TableSelection
                        selectByRowClick
                        showSelectAll
                        rowComponent={(props) => {
                            const { tableRow, children, highlighted } = props
                            const { row } = tableRow
                            return (
                                <Table.Row
                                    tableRow={tableRow}
                                    children={children}
                                    onClick={() => {
                                        if (select !== row.id) {
                                            setChange(false)
                                        }
                                        setSelect(row.id)
                                    }}
                                    className={(highlighted || (select === row.id)) ? classes.active : ((row.item.item.standard && !row.item.item.standard.confirmed && !row.item.item.standard.deleted) ? classes.confirmation : classes.default)}
                                    row={row}
                                />
                            )
                        }}
                    />
                    <PagingPanel
                        containerComponent={Pager}
                        messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from}-{to} из {count}'}}
                        pageSizes={[50, 100, 200]}
                    />
                </GridTable>
            </Grid>
            {category &&
                (
                    function () {
                        const items = Object.entries(selectionPage).map(([key, value]) => value).flat()

                        return <CategoryForm
                            open={category}
                            items={items}
                            handleCategory={(category) => {
                                return handleCategory(file.id, {category: category.hasOwnProperty('id') ? category.id : null, ids: items})
                            }}
                            handleClose={() => {setCategory(false)}}
                        />
                    }()
                )
            }
            {deleted &&
                (
                    function () {
                        const items = Object.entries(selectionPage).map(([key, value]) => value).flat()

                        return <DeleteForm
                            label="Удаление позиций"
                            text={`Вы действительно хотите удалить ${endings(items.length, ['позицию', 'позиции', 'позиций'])}? `}
                            open={deleted}
                            handleDelete={() => {
                                return handleDelete(file.id, {ids: items.join(',')})
                            }}
                            handleClose={() => {setDeleted(false)}}
                        />
                    }()
                )
            }
            {item ? <ItemForm item={standard} category={standard ? categories.find(category => category.id === standard.category.id) : null} categories={ categories } open={item} offer={true} handleClose={() => { setItem(null) }} handleDelete={handleDeleteOffer} handleSave={handleOffer} /> : null}
        </Grid>
    ) : null
}
