import React, {useEffect, useState} from 'react'

import {
    Grid,
    TextField,
    makeStyles, Select, MenuItem, InputLabel, FormControl,
} from '@material-ui/core'
import {blue} from "@material-ui/core/colors"

import {useDebouncedCallback} from 'use-debounce'
import {Attributes} from "./Attributes";
import {useParams} from "react-router-dom";
import {history} from "../../../App/helpers/history";
import {Terms} from "./Terms";
import {Categories} from "./Categories";
import {Units} from "./Units";
import {Exceptions} from "./Exceptions";
import {useDispatch} from "react-redux";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    data: {
        'height': 'calc(100% - 20px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '52px'
    },
    active: {
        'height': '41px',
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    body: {
        'height': '100%'
    }
}))

export const Index = (props) => {
    const dispatch = useDispatch()
    const params = useParams()
    const classes = useStyles()

    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)

    const debounced = useDebouncedCallback(
        (value) => {
            setLoading(value)
        }, 700
    )

    const [dictionary, setDictionary] = useState(params.dictionary)

    useEffect(() => {
        dispatch({type: 'SYNONYM_FILTER', payload: {sort: {name: null, direction: null}}})
        setDictionary(params.dictionary)
        setPage(1)
        setSearch('')
        setLoading(false)
    }, [params.dictionary])

    const getData = () => {
        switch (dictionary) {
            case 'attributes':
                return <Attributes {...props} loading={loading} setLoading={setLoading} page={page} setPage={setPage} search={search} />
            case 'terms':
                return <Terms {...props} loading={loading} setLoading={setLoading} page={page} setPage={setPage} search={search} />
            case 'categories':
                return <Categories {...props} loading={loading} setLoading={setLoading} page={page} setPage={setPage} search={search} />
            case 'exceptions':
                return <Exceptions {...props} loading={loading} setLoading={setLoading} page={page} setPage={setPage} search={search} />
            case 'units':
                return <Units {...props} loading={loading} setLoading={setLoading} page={page} setPage={setPage} search={search} />
        }
    }

    return (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={ classes.body } spacing={2}>
            <Grid item className={classes.element}>
                <Grid container direction="row" justify="space-between" alignItems="flex-end">
                    <Grid item sm={2}>
                        <FormControl fullWidth>
                            <InputLabel>Справочник</InputLabel>
                            <Select
                                value={dictionary}
                                onChange={(e) => {
                                    history.push(`/dictionary/synonym/${e.target.value}`)
                                }}
                            >
                                <MenuItem value="attributes">По атрибуту</MenuItem>
                                <MenuItem value="terms">По части атрибута</MenuItem>
                                <MenuItem value="categories">Вариации эталонов</MenuItem>
                                <MenuItem value="exceptions">Слова исключения</MenuItem>
                                <MenuItem value="units">Единицы измерения</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            fullWidth
                            id="category"
                            label='Поиск'
                            value={search}
                            onChange={event => {
                                setPage(1)
                                setSearch(event.target.value)
                                debounced.callback(false)
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {getData()}
        </Grid>
    )
}
