import React, {useEffect, useState} from "react"
import { Redirect } from 'react-router'
import { Route } from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"

import {Grid} from '@material-ui/core'

import { AuthorizationService } from './services/authorization'
import { App } from '../App/App'
import { history } from "../App/helpers/history"
import { AccountActions } from '../Account/actions/account'
import * as AppStorage from "../App/helpers/storage";

export const Authorization = ({Component, ...props}) => {
    const { Roles = [], Permissions = [], require = false, base = true } = props

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(null)
    const {account} = useSelector(state => state)
    const {location, path} = props

    useEffect(() => {
        const token = AppStorage.get('token')

        const getAccount = async () => {
            return dispatch(AccountActions.account()).catch(e => {
                history.push("/logout")
            })
        }

        if (token && (((account.account && !account.load) || !account.account) && !account.loading) && path !== '/logout') {
            getAccount().then(account => {
                if (account && !AuthorizationService.access(account, Roles, Permissions, require)) {
                    setAlert('Доступ запрещён.')
                }
                setLoading(true)
            })
        } else {
            setLoading(true)
        }
    }, [dispatch, account.account, account.load, Roles, Permissions, require, path])

    useEffect(() => {
        if (alert) {
            dispatch({
                type: "ALERT_ERROR",
                payload: {
                    message: alert,
                    type: 'error'
                }
            })
        }
    }, [dispatch, alert])

    if (loading) {
        if (account.account) {
            if (AuthorizationService.access(account.account, Roles, Permissions, require)) {
                if (base) {
                    return (
                        <Route
                            path={path}
                            render={(props) =>
                                <Grid item style={{height: '100%'}}>
                                    <App Content={Component} {...props}/>
                                </Grid>
                            }
                        />
                    )
                } else {
                    return <Route path={path} render={(props) => <Component {...props} />}/>
                }
            } else {
                if (`${location.pathname}${location.search}` === '/items') {
                    if (AuthorizationService.access(account.account, [],['category'])) {
                        return <Redirect to='/categories'/>
                    } else if (AuthorizationService.access(account.account, [],['data_markup'])) {
                        return <Redirect to='/markup'/>
                    } else if (AuthorizationService.access(account.account, [],['user'])) {
                        return <Redirect to='/access/users'/>
                    } else if (AuthorizationService.access(account.account, [],['role'])) {
                        return <Redirect to='/access/roles'/>
                    }
                }
            }
        } else {
            if (!Roles.length && !Permissions.length) {
                return <Route path={path} render={(props) => <Component {...props} />}/>
            } else {
                if (`${location.pathname}${location.search}` !== '/logout') {
                    AppStorage.set('redirect', `${location.pathname}${location.search}`)
                }

                return <Redirect to='/login'/>
            }
        }
    }

    return null
}
