import { HttpService } from '../../App/services/http'

export const DownloadService = {
    files,
    create
}

function files (params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/downloads`, options, true)
        .then(response => {
            return response
        })
}

function create (values) {
    const options = {
        method: 'POST',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/download/file`, options, true)
        .then(response => {
            return response
        })
}
