import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { Redirect } from 'react-router'
import { Router, Route, Switch } from 'react-router-dom'

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'

import './index.css'

import {Authorization} from "./Auth/Authorization"
import AppReducers from './App/reducers/index'
import { history } from './App/helpers/history'
import { NotFound } from './App/Status'
import { Loading } from './App/Loading'
import { Alert } from './App/Alert'
import { Login } from './Auth/Login'
import { Logout } from './Auth/Logout'
import { Items } from "./Item/Items"
import { Files as MarkupFiles } from "./Markup/Files"
import { Items as MarkupItems } from "./Markup/components/Items"
import { Category } from "./Category/Category"
import { Index as Dictionary } from "./Dictionary/components/Dictionaries/Index"
import { Values as DictionaryValues } from "./Dictionary/components/Dictionaries/Values"
import { Units as DictionaryUnits } from "./Dictionary/components/Dictionaries/Units"
import { Index as Synonym } from "./Dictionary/components/Synonym/Index"
import { Values as SynonymAttributeValues } from "./Dictionary/components/Synonym/Attribute/Values"
import { Values as SynonymCategoryValues } from "./Dictionary/components/Synonym/Category/Values"
import { Values as SynonymTermValues } from "./Dictionary/components/Synonym/Term/Values"
import { Values as SynonymUnitValues } from "./Dictionary/components/Synonym/Unit/Values"
import { Index as Converter } from "./Converter/Index"
import { Role } from "./Access/Role"
import { User } from "./Access/User"
import { Notification } from "./Notification/Notification"
import {Message} from "./App/Message"
import {Union} from "./Item/Union";
import { Files as Downloads } from "./Download/Files";

const store = createStore(
    AppReducers,
    applyMiddleware(thunk, logger)
)

const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                "padding": "4px"
            }
        },
        MuiTableRow: {
            root: {
                "cursor": "pointer"
            }
        },
        MuiTableBody: {
            root: {
                '& .MuiTableCell-root': {
                    "white-space": "nowrap",
                    "max-width": "100px",
                    "overflow": "hidden",
                    "text-overflow": "ellipsis"
                }
            }
        },
        MuiButton:{
            root: {
                'border-radius': '0'
            }
        }
    }
})

render(
    <MuiThemeProvider theme={ theme }>
        <Provider store={ store }>
            <Loading />
            <Grid container direction='column' justify='center' alignItems='stretch' style={{ height: '100%' }}>
                <Alert />
                <Message />
                <Router history={ history } basename={ process.env.REACT_APP_BASE_NAME }>
                    <Switch>
                        <Authorization exact Component={Items} Permissions={['standard_view']} path='/items' />
                        <Authorization exact Component={Union} Permissions={['standard_view']} path='/items/union' />
                        <Authorization exact Component={MarkupFiles} Permissions={['data_markup']} path='/markup' />
                        <Authorization exact Component={MarkupItems} Permissions={['data_markup']} path='/markup/file/:id' />
                        <Authorization exact Component={Category} Permissions={['category']} path='/categories' />
                        <Authorization exact Component={Dictionary} Permissions={['dictionary']} path='/dictionaries' />
                        <Authorization exact Component={DictionaryUnits} Permissions={['dictionary']} path='/dictionary/units/values' />
                        <Authorization exact Component={DictionaryValues} Permissions={['dictionary']} path='/dictionary/:id/values' />
                        <Authorization exact Component={Synonym} Permissions={['dictionary']} path='/dictionary/synonym/:dictionary' />
                        <Authorization exact Component={SynonymAttributeValues} Permissions={['dictionary']} path='/dictionary/synonym/attribute/:id/values' />
                        <Authorization exact Component={SynonymCategoryValues} Permissions={['dictionary']} path='/dictionary/synonym/category/:id/values' />
                        <Authorization exact Component={SynonymTermValues} Permissions={['dictionary']} path='/dictionary/synonym/term/:id/values' />
                        <Authorization exact Component={SynonymUnitValues} Permissions={['dictionary']} path='/dictionary/synonym/unit/:id/values' />
                        <Authorization exact Component={Converter} Roles={['user']} path='/converter' />
                        <Authorization exact Component={Downloads} Roles={['user']} path='/downloads' />
                        <Authorization exact Component={Role} Permissions={['role']} path='/access/roles' />
                        <Authorization exact Component={User} Permissions={['user']} path='/access/users' />
                        <Authorization exact Component={Notification} Permissions={['user']} path='/notifications' />
                        <Authorization exact Component={Login} path='/login' />
                        <Authorization exact Component={Logout} Roles={['user']} path='/logout' />
                        <Redirect from='/' to='/items' />
                        <Route component={ NotFound } />
                    </Switch>
                </Router>
            </Grid>
        </Provider>
    </MuiThemeProvider>,
    document.getElementById('app')
)
