export default function unit (state = {
    types: {data: [], meta: {}, limit: 50, page: 1},
    groups: {data: [], meta: {}, limit: 50, page: 1},
    units: {data: [], meta: {}, limit: 50, page: 1},
    filter: {sort: {name: null, direction: null}}
}, action) {
    switch (action.type) {
        case 'UNITS_REQUEST':
            return state
        case 'UNITS_SUCCESS':
            return {
                ...state,
                ...{units: action.payload}
            }
        case 'UNITS_FAILURE':
            return {
                ...state,
                ...{units: {data: [], meta: {}, limit: 50, page: 1}}
            }
        case 'UNITS_CLEAR':
            return {
                ...state,
                ...{units: {data: [], meta: {}, limit: 50, page: 1}}
            }
        case 'UNIT_TYPES_REQUEST':
            return state
        case 'UNIT_TYPES_SUCCESS':
            return {
                ...state,
                ...{types: action.payload}
            }
        case 'UNIT_TYPES_FAILURE':
            return {
                ...state,
                ...{types: {data: [], meta: {}, limit: 50, page: 1}}
            }
        case 'UNIT_TYPES_CLEAR':
            return {
                ...state,
                ...{types: {data: [], meta: {}, limit: 50, page: 1}}
            }
        case 'UNIT_GROUPS_REQUEST':
            return state
        case 'UNIT_GROUPS_SUCCESS':
            return {
                ...state,
                ...{groups: action.payload}
            }
        case 'UNIT_GROUPS_FAILURE':
            return {
                ...state,
                ...{groups: {data: [], meta: {}, limit: 50, page: 1}}
            }
        case 'UNIT_GROUPS_CLEAR':
            return {
                ...state,
                ...{groups: {data: [], meta: {}, limit: 50, page: 1}}
            }
        case 'UNIT_FILTER':
            return {
                ...state,
                ...{filter: {
                    ...state.filter,
                    ...{sort: action.payload.sort},
                    ...action.payload
                }}
            }
        default:
            return state
    }
}
