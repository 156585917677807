import { DownloadService } from '../services/download'

export const DownloadActions = {
    files,
    create
}

function files (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'DOWNLOAD_FILES_REQUEST' })

        DownloadService.files(params)
            .then(
                response => {
                    dispatch({ type: 'DOWNLOAD_FILES_SUCCESS', payload: { data: response.data, meta: response.meta, limit: params.limit ? params.limit : 10, page: params.page ? params.page : 1 } })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(response.data)
                },
                error => {
                    dispatch({ type: 'DOWNLOAD_FILES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function create (values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'DOWNLOAD_FILE_CREATE_REQUEST' })

        DownloadService.create(values)
            .then(
                () => {
                    dispatch({ type: 'DOWNLOAD_FILE_CREATE_SUCCESS' })
                    dispatch({ type: 'ALERT_SUCCESS', payload: 'Файл добавлен в диспетчер загрузок' })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'DOWNLOAD_FILE_CREATE_FAILURE' })
                    if (!error.type) {
                        dispatch({type: 'ALERT_ERROR', payload: error.message})
                    }
                    dispatch({ type: 'FILLING', payload: false })
                    reject(error)
                }
            )
    })
}
