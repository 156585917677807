export default function synonym (state = {
    attributes: { data: [], meta: {}, limit: 100, page: 1 },
    attribute: null, values: { data: [], meta: {}, limit: 100, page: 1 },
    categories: { data: [], meta: {}, limit: 100, page: 1 },
    category: null, units: { data: [], meta: {}, limit: 100, page: 1 },
    unit: null,
    filter: {sort: {name: null, direction: null}}
}, action) {
    switch (action.type) {
        case 'SYNONYM_ATTRIBUTES_REQUEST':
            return state
        case 'SYNONYM_ATTRIBUTES_SUCCESS':
            return  {
                ...state,
                ...{attributes: action.payload}
            }
        case 'SYNONYM_ATTRIBUTES_FAILURE':
            return {
                ...state,
                ...{attributes: { data: [], meta: {}, limit: state.attributes.limit, page: state.attributes.page }}
            }
        case 'SYNONYM_ATTRIBUTES_CLEAR':
            return {
                ...state,
                ...{attributes: { data: [], meta: {}, limit: 100, page: 1 }}
            }
        case 'SYNONYM_CATEGORIES_REQUEST':
            return state
        case 'SYNONYM_CATEGORIES_SUCCESS':
            return  {
                ...state,
                ...{categories: action.payload}
            }
        case 'SYNONYM_CATEGORIES_FAILURE':
            return {
                ...state,
                ...{categories: { data: [], meta: {}, limit: state.categories.limit, page: state.categories.page }}
            }
        case 'SYNONYM_CATEGORIES_CLEAR':
            return {
                ...state,
                ...{categories: { data: [], meta: {}, limit: 100, page: 1 }}
            }
        case 'SYNONYM_CATEGORY_REQUEST':
            return state
        case 'SYNONYM_CATEGORY_SUCCESS':
            return  {
                ...state,
                ...{category: action.payload}
            }
        case 'SYNONYM_CATEGORY_FAILURE':
            return {
                ...state,
                ...{category: null}
            }
        case 'SYNONYM_CATEGORY_CLEAR':
            return {
                ...state,
                ...{category: null}
            }
        case 'SYNONYM_CATEGORY_VALUES_REQUEST':
            return state
        case 'SYNONYM_CATEGORY_VALUES_SUCCESS':
            return  {
                ...state,
                ...{values: action.payload}
            }
        case 'SYNONYM_CATEGORY_VALUES_FAILURE':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: state.values.limit, page: state.values.page }}
            }
        case 'SYNONYM_CATEGORY_VALUES_CLEAR':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: 100, page: 1 }}
            }
        case 'SYNONYM_ATTRIBUTE_REQUEST':
            return state
        case 'SYNONYM_ATTRIBUTE_SUCCESS':
            return  {
                ...state,
                ...{attribute: action.payload}
            }
        case 'SYNONYM_ATTRIBUTE_FAILURE':
            return {
                ...state,
                ...{attribute: null}
            }
        case 'SYNONYM_ATTRIBUTE_CLEAR':
            return {
                ...state,
                ...{attribute: null}
            }
        case 'SYNONYM_ATTRIBUTE_VALUES_REQUEST':
            return state
        case 'SYNONYM_ATTRIBUTE_VALUES_SUCCESS':
            return  {
                ...state,
                ...{values: action.payload}
            }
        case 'SYNONYM_ATTRIBUTE_VALUES_FAILURE':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: state.values.limit, page: state.values.page }}
            }
        case 'SYNONYM_ATTRIBUTE_VALUES_CLEAR':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: 100, page: 1 }}
            }
        case 'SYNONYM_SAVE_ATTRIBUTE_VALUES_REQUEST':
            return state
        case 'SYNONYM_SAVE_ATTRIBUTE_VALUES_SUCCESS':
            return state
        case 'SYNONYM_SAVE_ATTRIBUTE_VALUES_FAILURE':
            return state
        case 'SYNONYM_DELETE_ATTRIBUTE_VALUE_REQUEST':
            return state
        case 'SYNONYM_DELETE_ATTRIBUTE_VALUE_SUCCESS':
            return state
        case 'SYNONYM_DELETE_ATTRIBUTE_VALUE_FAILURE':
            return state
        case 'SYNONYM_EXCEPTION_VALUES_REQUEST':
            return state
        case 'SYNONYM_EXCEPTION_VALUES_SUCCESS':
            return  {
                ...state,
                ...{values: action.payload}
            }
        case 'SYNONYM_EXCEPTION_VALUES_FAILURE':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: state.values.limit, page: state.values.page }}
            }
        case 'SYNONYM_EXCEPTION_VALUES_CLEAR':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: 100, page: 1 }}
            }
        case 'SYNONYM_SAVE_EXCEPTION_VALUES_REQUEST':
            return state
        case 'SYNONYM_SAVE_EXCEPTION_VALUES_SUCCESS':
            return state
        case 'SYNONYM_SAVE_EXCEPTION_VALUES_FAILURE':
            return state
        case 'SYNONYM_DELETE_EXCEPTION_VALUE_REQUEST':
            return state
        case 'SYNONYM_DELETE_EXCEPTION_VALUE_SUCCESS':
            return state
        case 'SYNONYM_DELETE_EXCEPTION_VALUE_FAILURE':
            return state
        case 'SYNONYM_UNITS_REQUEST':
            return state
        case 'SYNONYM_UNITS_SUCCESS':
            return  {
                ...state,
                ...{units: action.payload}
            }
        case 'SYNONYM_UNITS_FAILURE':
            return {
                ...state,
                ...{units: { data: [], meta: {}, limit: state.units.limit, page: state.units.page }}
            }
        case 'SYNONYM_UNITS_CLEAR':
            return {
                ...state,
                ...{units: { data: [], meta: {}, limit: 100, page: 1 }}
            }
        case 'SYNONYM_UNIT_REQUEST':
            return state
        case 'SYNONYM_UNIT_SUCCESS':
            return  {
                ...state,
                ...{unit: action.payload}
            }
        case 'SYNONYM_UNIT_FAILURE':
            return {
                ...state,
                ...{unit: null}
            }
        case 'SYNONYM_UNIT_CLEAR':
            return {
                ...state,
                ...{unit: null}
            }
        case 'SYNONYM_UNIT_VALUES_REQUEST':
            return state
        case 'SYNONYM_UNIT_VALUES_SUCCESS':
            return  {
                ...state,
                ...{values: action.payload}
            }
        case 'SYNONYM_UNIT_VALUES_FAILURE':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: state.values.limit, page: state.values.page }}
            }
        case 'SYNONYM_UNIT_VALUES_CLEAR':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: 100, page: 1 }}
            }
        case 'SYNONYM_FILTER':
            return {
                ...state,
                ...{filter: {
                    ...state.filter,
                    ...{sort: action.payload.sort},
                    ...action.payload
                }}
            }
        default:
            return state
    }
}
