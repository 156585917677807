import React, {useState} from 'react'
import {useDispatch} from "react-redux"
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup"

import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    makeStyles
} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {FileUpload} from "../../App/components/Input/FileUpload"
import {ItemActions} from "../actions/item";

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    }
}))

export const Upload = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const {category} = props

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>
            <Button
                disabled={ !category || category.category }
                onClick={() => {
                    setOpen(true)
                }}
                color="primary"
                type="button"
            >
                Загрузить
            </Button>
            {open ? (
                <Formik
                    initialValues={{
                        file: null
                    }}
                    validationSchema={Yup.object().shape({
                        file: Yup.mixed().test('fileFormat', 'Файл должен быть одного из следующих типов: csv.', (value) => {
                            return ((value && ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(value.type)) || (value && !value.type && value.name))
                        })
                    })}
                    onSubmit={(values, {setSubmitting, setErrors}) => {
                        const formData = new FormData()

                        for (const name in values) {
                            if (values.hasOwnProperty(name)) {
                                switch (name) {
                                    case 'file':
                                        formData.append(`${name}`, values[name])
                                        break
                                }
                            }
                        }

                        dispatch(ItemActions.upload(category.id, formData)).then(
                            () => {
                                setSubmitting(false)
                                dispatch(handleClose)
                            },
                            errors => {
                                setErrors(errors)
                                setSubmitting(false)
                            }
                        )
                    }}
                >
                    {({
                          values,
                          errors,
                          isSubmitting,
                          handleSubmit,
                          setFieldValue,
                          setFieldTouched
                      }) => (
                        <Form>
                            <Dialog
                                fullWidth={true}
                                maxWidth="sm"
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="Загрузка файла"
                                classes={{
                                    paper: classes.dialog
                                }}
                            >
                                <DialogTitle>
                                    <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                        <Grid item>
                                            Загрузить файл
                                        </Grid>
                                        <Grid item>
                                            <IconButton
                                                aria-label="Закрыть"
                                                onClick={() => handleClose()}
                                            >
                                                <Close/>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                        <Grid item sm={10} className={classes.fullWidth}>
                                            <Field
                                                fullWidth
                                                size="small"
                                                component={(props) => (
                                                    <FileUpload {...props} endIcon={"Выбрать файл"}/>
                                                )}
                                                name="file"
                                                InputLabelProps={{
                                                    name: values.file ? values.file.name : null
                                                }}
                                                InputProps={{
                                                    onChange: (event) => {
                                                        setFieldValue(`file`, event.currentTarget.files[0])
                                                        setFieldTouched(`file`, true, false)
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        disabled={isSubmitting}
                                        onClick={handleSubmit}
                                        color="primary"
                                        type="submit"
                                    >
                                        {isSubmitting ? <CircularProgress size={24}/> : 'Загрузить'}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Form>
                    )}
                </Formik>
            ) : null}
        </React.Fragment>
    )
}
