import React, {useEffect, useState} from "react"
import { Field } from "formik"
import {TextField} from "formik-material-ui"
import {
    Grid,
    Tooltip,
    IconButton,
    InputAdornment,
    makeStyles,
    Typography,
    DialogTitle,
    DialogContent, Dialog
} from '@material-ui/core'
import Autocomplete from "@material-ui/lab/Autocomplete"
import {useDispatch, useSelector} from "react-redux"
import {Close, Help, MoreHoriz} from "@material-ui/icons";
import {ValueForm} from "../../../Dictionary/components/Dictionaries/Values/ValueForm";
import {DictionaryActions} from "../../../Dictionary/actions/dictionary";
import {Values as DictionaryValues} from "../../../Dictionary/components/Dictionaries/Values";

const useStyles = makeStyles(theme => ({
    default: {
        'width': '100%',
        'padding': '6px 24px 6px 16px',
    },
    dictionary: {
        'width': 'calc(100% - 46px)'
    },
    option: {
        'padding': '0 !important'
    },
    string: {
        'width': '100%'
    },
    tooltip: {
        "background-color": "#000",
        "font-size": "15px",
        "color": "#fff",
        "padding": "15px 15px",
    }
}))

export const FieldString = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { id, label, values, attribute, attributes, addition, setFieldValue, setFieldTouched, setSearch, isSubmitting } = props
    const [name] = useState(props.hasOwnProperty('name') ? props.name : `attributes.${id}`)

    const { items } = useSelector(state => { return {items: (attribute.dictionary ? state.dictionary.attribute.items.data : state.attribute.items)} })
    const [value, setValue] = useState({ value: props.value })
    const [listeners] = useState((!!attribute.dictionary && attribute.dictionary.hasOwnProperty('listeners') && !!attribute.dictionary.listeners.length) ? attribute.dictionary.listeners : [])
    const [disabled, setDisabled] = useState(!!(isSubmitting || props.disabled || !!listeners.find(
        listener => !!attributes.find(attr => (attr.dictionary && ((listener.id === attr.dictionary.id) && ((listener.relation === 'one') || !values[attr.id]))))
    )))
    const [dialog, setDialog] = useState(false)
    const [dictionaries, setDictionaries] = useState(false)

    useEffect(() => {
        setDisabled(!!(isSubmitting || props.disabled || !!listeners.find(
            listener => !!attributes.find(attr => (attr.dictionary && ((listener.id === attr.dictionary.id) && ((listener.relation === 'one') || !values[attr.id]))))
        )))
    }, [values, isSubmitting])

    useEffect(() => {
        setValue({ value: props.value ?? (attribute.multiple ? [] : '') })
    }, [props.value])

    const dictionary = (
        <Grid container justify='space-between' alignItems="center" style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setDialog(true) }}>
            <Grid item><Typography>Добавить значение</Typography></Grid>
            <Grid item>
                <Tooltip
                    title="Если значение отсутствует в списке, вы можете его добавить"
                    placement="right"
                    classes={{tooltip: classes.tooltip}}
                >
                    <IconButton
                        style={{
                            "width": "24px",
                            "height": "24px",
                            "padding": "0"
                        }}
                    >
                        <Help style={{"color": "#485868"}}/>
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )

    return (
        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
            <Grid item className={attribute.dictionary ? classes.dictionary : classes.string}>
                <Autocomplete
                    multiple={attribute.multiple}
                    freeSolo={!attribute.dictionary}
                    filterOptions={options => options}
                    options={attribute.dictionary ? (!!items.length ? items : (!!listeners.length ? [] : ['dictionary'])) : items}
                    disabled={disabled}
                    getOptionLabel={option => option ? ((option instanceof Object) ? (option.value ?? '') : option) : ''}
                    name={`${name}`}
                    defaultValue={attribute.multiple ? value.value : value}
                    value={attribute.multiple ? value.value : value}
                    noOptionsText='Данные не найдены'
                    {...((attribute.dictionary && !listeners.length) ? {
                        groupBy: () => {
                            return dictionary
                        },
                        classes: {
                            option: classes.option
                        }
                    } : {})}
                    onChange={(e, value) => {
                        if (attribute.dictionary && attribute.dictionary.hasOwnProperty('attributes') && !!attribute.dictionary.attributes.length) {
                            const attr = attributes.find(attr => attr.dictionary && !!attribute.dictionary.attributes.find(item => (item.type === 'one') && item.dictionary && (item.dictionary.id === attr.dictionary.id)))
                            if (attr) {
                                const item = value?.items.find(item => item.attribute.dictionary.id === attr.dictionary.id)
                                setFieldValue(`attributes.${attr.id}`, (item ? item.value : null), true)
                            }
                        }

                        setFieldValue(`${name}`, attribute.multiple ? [...new Map(value.map(el => el.hasOwnProperty('value') ? [el.value, el] : [el, el])).values()].map(el => el.hasOwnProperty('value') ? el.value : el) : ((value && value.hasOwnProperty('value')) ? value.value : value))
                    }}
                    onBlur={() => {
                        setValue({ value: props.value })
                        setFieldTouched(`${name}`, true)
                        setSearch({search: null, attribute: attribute.id, type: (attribute.dictionary ? attribute.dictionary.id : null), listeners: {}})
                    }}
                    renderOption={option => {
                        if (option instanceof Object) {
                            return <Typography className={classes.default}>{[option.value, ...(option.items ?? []).filter(item => item.attribute.primary).map(item => item.value)].join(' ')}</Typography>
                        }
                        return null
                    }}
                    renderInput={params => (
                        <Field
                            component={TextField}
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                ...((!attribute.multiple && addition && addition.prefix.value) ? {startAdornment: <InputAdornment position="start">{addition.prefix.value}</InputAdornment>} : {}),
                                ...((!attribute.multiple && addition && addition.postfix.value) ? {endAdornment: <InputAdornment position="end">{addition.postfix.value}</InputAdornment>} : {}),
                                onChange: (e) => {
                                    if (attribute.dictionary) {
                                        if (!attribute.multiple) {
                                            setValue(e.target.value)
                                        }
                                    } else {
                                        setFieldValue(`${name}`, e.target.value)
                                    }

                                    setSearch({search: e.target.value, attribute: attribute.id, type: (attribute.dictionary ? attribute.dictionary.id : null), listeners: listeners.reduce((obj, listener) => {
                                        obj[listener.id] = values[attributes.find(attr => (attr.dictionary && (listener.id === attr.dictionary.id)))?.id]
                                        return obj
                                    }, {})})
                                }
                            }}
                            name={`${name}`}
                            label={label}
                            fullWidth
                        />
                    )}
                />
            </Grid>
            {attribute.dictionary &&
                <Grid item>
                    <Tooltip title="Справочник" placement="bottom-end">
                        <div>
                            <IconButton
                                type="button"
                                size="small"
                                disabled={isSubmitting || disabled}
                                onClick={() => {
                                    setDictionaries(true)
                                }}
                            >
                                <MoreHoriz />
                            </IconButton>
                        </div>
                    </Tooltip>
                </Grid>
            }
            {(attribute.dictionary && dictionaries) &&
                <Dialog
                    fullScreen
                    fullWidth={true}
                    open={dictionaries}
                    onClose={() => setDictionaries(false)}
                >
                    <DialogTitle>
                        <Grid container direction='row' justify='flex-end' alignItems='center' spacing={2}>
                            <Grid item>
                                <IconButton
                                    aria-label="Закрыть"
                                    onClick={() => setDictionaries(false)}
                                >
                                    <Close/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <DictionaryValues
                            type='dialog'
                            id={attribute.dictionary.id}
                            callback={obj => {
                                if (attribute.dictionary && attribute.dictionary.hasOwnProperty('attributes') && !!attribute.dictionary.attributes.length) {
                                    const attr = attributes.find(attr => attr.dictionary && !!attribute.dictionary.attributes.find(item => (item.type === 'one') && item.dictionary && (item.dictionary.id === attr.dictionary.id)))
                                    if (attr) {
                                        const item = obj?.items.find(item => item.attribute.dictionary.id === attr.dictionary.id)
                                        setFieldValue(`attributes.${attr.id}`, (item ? item.value : null), true)
                                    }
                                }

                                setFieldValue(`${name}`, attribute.multiple ? [...value.value.filter(el => el !== obj.value).map(el => el), obj.value] : ((obj && obj.hasOwnProperty('value')) ? obj.value : obj), true)
                                setValue({value: attribute.multiple ? [...value.value.filter(el => el !== obj.value).map(el => el), obj.value] : obj.value})
                                setSearch({search: null, attribute: attribute.id, type: (attribute.dictionary ? attribute.dictionary.id : null), listeners: {}})
                                setDictionaries(false)
                            }}
                        />
                    </DialogContent>
                </Dialog>
            }
            {(attribute.dictionary && dialog) &&
                <ValueForm
                    open={dialog}
                    handleClose={() => {
                        setDialog(false)
                    }}
                    handleSave={values => {
                        return new Promise((resolve, reject) => {
                            dispatch(DictionaryActions.addItem(attribute.dictionary.id, values)).then(
                                (item) => {
                                    resolve(item)
                                },
                                errors => {
                                    reject(errors)
                                }
                            )
                        })
                    }}
                    dictionary={attribute.dictionary}
                    callback={obj => {
                        if (attribute.dictionary && attribute.dictionary.hasOwnProperty('attributes') && !!attribute.dictionary.attributes.length) {
                            const attr = attributes.find(attr => attr.dictionary && !!attribute.dictionary.attributes.find(item => (item.type === 'one') && item.dictionary && (item.dictionary.id === attr.dictionary.id)))
                            if (attr) {
                                const item = obj?.items.find(item => item.attribute.dictionary.id === attr.dictionary.id)
                                setFieldValue(`attributes.${attr.id}`, (item ? item.value : null), true)
                            }
                        }

                        setFieldValue(`${name}`, attribute.multiple ? [...value.value.filter(el => el !== obj.value).map(el => el), obj.value] : ((obj && value.hasOwnProperty('value')) ? obj.value : obj), true)
                        setValue({value: attribute.multiple ? [...value.value.filter(el => el !== obj.value).map(el => el), obj.value] : obj.value})
                        setSearch({search: null, attribute: attribute.id, type: (attribute.dictionary ? attribute.dictionary.id : null), listeners: {}})
                    }}
                />
            }
        </Grid>
    )
}
