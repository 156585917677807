import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useDebouncedCallback} from 'use-debounce'

import {
    Grid,
    Typography,
    TextField,
    Table as TableMUI,
    TableRow as TableRowMUI,
    TableCell as TableCellMUI,
    Checkbox,
    TableBody,
    TableContainer,
    IconButton,
    makeStyles,
    Popover, Divider, Button, Box, Tooltip, Fade, InputLabel, Select, MenuItem, FormControl
} from '@material-ui/core'
import {blue, red} from "@material-ui/core/colors"
import {FilterList, Sort} from '@material-ui/icons'

import { stringFormat } from '../../App/helpers/string'
import {AttributeActions} from "../actions/attribute"
import {Skeleton} from "@material-ui/lab"
import {AutoSizer, Table, InfiniteLoader} from "react-virtualized"
import {ItemActions} from "../actions/item";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    fullHeight: {
        'height': '100%'
    },
    popover: {
        'width': '300px'
    },
    filter: {
        'width': '100%'
    },
    filterItems: {
        'width': '100%',
        'height': 'calc(100% - 158px)',
        'overflow': 'auto'
    },
    filterItem: {
        'white-space': 'normal !important'
    },
    field: {
        'height': '100%'
    },
    data: {
        'height': 'calc(100% - 60px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '40px'
    },
    table: {
        'height': '100%'
    },
    fab: {
        'margin': '0',
        'top': 'auto',
        'right': '90px',
        'bottom': '25px',
        'left': 'auto',
        'position': 'fixed'
    },
    activeCategory: {
        'background-color': red[100],
        '&:hover': {
            'background-color': red[50] + '!important',
        }
    },
    defaultCategory: {
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    active: {
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    confirmation: {
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    default: {
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    visuallyHidden: {
        "border": "0",
        "clip": "rect(0 0 0 0)",
        "height": "1",
        "margin": "-1",
        "overflow": "hidden",
        "padding": "0",
        "position": "absolute",
        "top": "20",
        "width": "1",
    },
    attributes: {
        '& .MuiSelect-selectMenu': {
            'padding-top': '9px'
        }
    },
    body: {
        'height': '100%'
    },
    item: {
        'height': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    },
    sortLabelRoot: {
        maxWidth: '100%',
    },
    sortLabelRight: {
        flexDirection: 'row-reverse',
    },
    sortLabelActive: {
        color: 'inherit',
    },
    toolbar: {
        'padding-left': '0 !important'
    },
    actions: {
        'margin-left': '0 !important'
    },
    filterContent: {
        width: '100%',
        padding: theme.spacing(2)
    },
    divider: {
        width: '100%'
    }
}))

export const Filter = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {filter} = useSelector(state => { return {filter: state.item.filter} })

    const {attribute = null, name} = props
    const [loading, setLoading] = useState(false)
    const [additive, setAdditive] = useState(false)
    const [initialize, setInitialize] = useState(false)
    const [values, setValues] = useState(false)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [selected, setSelected] = useState((!!attribute && filter.selected.hasOwnProperty(attribute.id)) ? filter.selected[attribute.id] : [])
    const [status, setStatus] = useState((name === 'status') ? filter.status : [])
    const [source, setSource] = useState((name === 'source') ? filter.source : [])
    const [company, setCompany] = useState((name === 'company') ? filter.company : [])
    const [module, setModule] = useState((name === 'module') ? filter.module : [])
    const [standard, setStandard] = useState((name === 'value.keyword') ? filter.standard : [])
    const [sort, setSort] = useState({name: name, direction: (filter.sort.name === name) ? filter.sort.direction : null})
    const [anchorEl, setAnchorEl] = useState(null)
    const [search, setSearch] = useState((!!attribute && filter.conditions.hasOwnProperty(attribute.id) && filter.conditions[attribute.id].expression) ? filter.conditions[attribute.id].expression : '')
    const [condition, setCondition] = useState((!!attribute && filter.conditions.hasOwnProperty(attribute.id) && filter.conditions[attribute.id].condition) ? filter.conditions[attribute.id].condition : '')
    const [value, setValue] = useState((!!attribute && filter.attributes.hasOwnProperty(attribute.id)) ? filter.attributes[attribute.id] : '')
    const open = Boolean(anchorEl)

    const debounced = useDebouncedCallback(
        (value) => {
            setLoading(value)
        }, 700
    )

    const getData = async (page = 1) => {
        let params = {
            filter: '',
            group: 0
        }

        if (!!filter.order.length) {
            let index = filter.order.indexOf(attribute.id)

            if (index > 0) {
                filter.order.slice(0, index).forEach(id => {
                    params.group += 1
                    params.filter += params.filter.length ? `,${filter.selected[id].join(',')}` : filter.selected[id].join(',')
                })
            } else if (index < 0) {
                filter.order.forEach(id => {
                    params.group += 1
                    params.filter += params.filter.length ? `,${filter.selected[id].join(',')}` : filter.selected[id].join(',')
                })
            }
        }

        setPage(page)

        return await dispatch(AttributeActions.values(attribute.id, {
            page: page,
            limit: 20,
            category: filter.category,
            ...(search ? {search: search} : {}),
            ...(condition ? {condition: condition} : {}),
            ...(!!params.filter.length ? params : {}),
            ...(!!filter.status.length ? {status: filter.status} : {})
        }))
    }

    const getSources = async (page = 1) => {
        setPage(page)

        return await dispatch(ItemActions.sources({
            page: page,
            limit: 20,
            ...(search ? {search: search} : {})
        }))
    }

    const getStandard = async (page = 1) => {
        setPage(page)

        return await dispatch(ItemActions.search({
            page: page,
            limit: 20,
            type: 'standard',
            ...(filter.category ? {category: filter.category} : {}),
            ...(search ? {search: search} : {}),
            ...(condition ? {condition: condition} : {})
        }))
    }

    const getConditions = (type) => {
        let conditions = [
            {value: 'equal', title: 'Равно'},
            {value: 'not_equal', title: 'Не равно'},
            {value: 'more', title: 'Больше'},
            {value: 'more_or_equal', title: 'Больше или равно'},
            {value: 'less', title: 'Меньше'},
            {value: 'less_or_equal', title: 'Меньше или равно'}
        ]

        switch (type) {
            case 'dictionary':
            case 'string':
                conditions = [
                    ...conditions,
                    ...[
                        {value: 'contain', title: 'Содержит'},
                        {value: 'not_contain', title: 'Не содержит'},
                        {value: 'begin_with', title: 'Начинается с'},
                        {value: 'ends_with', title: 'Заканчивается на'},
                        {value: 'match_expression', title: 'Соответствует маске'},
                        {value: 'not_match_expression', title: 'Не соответствует маске'},
                    ]
                ]
        }

        return conditions
    }

    useEffect(() => {
        if (open) {
            if (attribute) {
                getData().then(response => {
                    setValues(response.data)
                    setTotal(response.meta.total)
                    setLoading(true)
                    setInitialize(true)
                })
            }

            if (name === 'value.keyword') {
                getStandard().then(response => {
                    setValues(response.data)
                    setTotal(response.meta.total)
                    setLoading(true)
                    setInitialize(true)
                })
            }

            if (name === 'company') {
                getSources().then(response => {
                    setValues(response.data)
                    setTotal(response.meta.total)
                    setLoading(true)
                    setInitialize(true)
                })
            }

            return () => {
                setInitialize(false)
                setLoading(false)
                setSearch(null)
                setSelected([])
                setSort({name: null, direction: null})
                setStatus([])
                setSource([])
                setCompany([])
                setModule([])
                setStandard([])
                dispatch({type: 'ATTRIBUTE_VALUES_CLEAR'})
            }
        }
    }, [open, attribute])

    useEffect(() => {
        if (!loading && initialize) {
            if (attribute) {
                getData(1).then(response => {
                    setValues(response.data)
                    setTotal(response.meta.total)
                    setLoading(true)
                })
            }

            if (name === 'value.keyword') {
                getStandard(1).then(response => {
                    setValues(response.data)
                    setTotal(response.meta.total)
                    setLoading(true)
                })
            }

            if (name === 'company') {
                getSources(1).then(response => {
                    setValues(response.data)
                    setTotal(response.meta.total)
                    setLoading(true)
                })
            }
        }
    }, [dispatch, loading, search, attribute, initialize])

    useEffect(() => {
        if (value === 'empty') {
            setSearch(null)
            setCondition('')
            setSelected([])
        }
    }, [value])

    return (
        <React.Fragment>
            <IconButton
                size="small"
                onClick={(event) => {
                    setSelected((!!attribute && filter.selected.hasOwnProperty(attribute.id)) ? filter.selected[attribute.id] : [])
                    setSort({name: name, direction: (filter.sort.name === name) ? filter.sort.direction : null})
                    setStatus((name === 'status') ? filter.status : [])
                    setSource((name === 'source') ? filter.source : [])
                    setCompany((name === 'company') ? filter.company : [])
                    setModule((name === 'module') ? filter.module : [])
                    setStandard((name === 'value.keyword') ? filter.standard : [])
                    setAnchorEl(event.currentTarget)
                }}
                color={(
                    (
                        filter.sort.name === name) ||
                        (!!attribute && (
                            (filter.order.indexOf(attribute.id) >= 0) ||
                            (filter.selected.hasOwnProperty(attribute.id) && !!filter.selected[attribute.id].length) ||
                            (filter.conditions.hasOwnProperty(attribute.id) && (filter.conditions[attribute.id].condition || filter.conditions[attribute.id].expression)) ||
                            (filter.attributes.hasOwnProperty(attribute.id) && filter.attributes[attribute.id])
                        )) ||
                        ((name === 'status') && !!filter.status.length) ||
                        ((name === 'source') && !!filter.source.length) ||
                        ((name === 'company') && !!filter.company.length) ||
                        ((name === 'module') && !!filter.module.length) ||
                        ((name === 'value.keyword') && !!filter.standard.length)
                    ) ? 'secondary' : 'default'}
            >
                <FilterList />
            </IconButton>
            {open ? <Popover
                classes={{
                    paper: classes.popover
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setAnchorEl(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.filterContent}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item>
                                <Typography variant="caption">
                                    Сортировка
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Button
                                            color="default"
                                            onClick={() => {
                                                const direction = ((sort.direction === 'asc') ? null : 'asc')
                                                setSort({...sort, ...{direction: direction}})
                                                dispatch({type: 'ITEM_FILTER', payload: {...filter, ...{sort: {...sort, ...{name: direction ? sort.name : null, direction: direction}}}}})
                                            }}
                                            startIcon={<Sort color={(sort.direction === 'asc') ? 'secondary' : 'action'} style={{transform: 'rotatex(180deg)'}} />}
                                        >
                                            <Typography component="span" variant="body2" style={{textTransform: "none"}}>по возрастанию (А-Я)</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="default"
                                            onClick={() => {
                                                const direction = ((sort.direction === 'desc') ? null : 'desc')
                                                setSort({...sort, ...{direction: direction}})
                                                dispatch({type: 'ITEM_FILTER', payload: {...filter, ...{sort: {...sort, ...{name: direction ? sort.name : null, direction: direction}}}}})
                                            }}
                                            startIcon={<Sort color={(sort.direction === 'desc') ? 'secondary' : 'action'} />}
                                        >
                                            <Typography component="span" variant="body2" style={{textTransform: "none"}}>по убыванию (Я-А)</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {(name === 'value.keyword') && <React.Fragment>
                        <Divider className={classes.divider} />
                        <Grid item className={classes.filterContent}>
                            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                                <Grid item>
                                    <Typography variant="caption">
                                        Фильтр
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.fullWidth}>
                                    <FormControl fullWidth>
                                        <InputLabel>Условие</InputLabel>
                                        <Select
                                            value={condition}
                                            disabled={(value === 'empty')}
                                            onChange={(e) => {
                                                setCondition(e.target.value)
                                                if (search) {
                                                    setLoading(false)
                                                }
                                            }}
                                        >
                                            {getConditions('string').map(function (value, index) {
                                                return <MenuItem key={index} value={value.value}>{value.title}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.fullWidth}>
                                    <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                disabled={(value === 'empty')}
                                                label='Поиск'
                                                value={search ?? ''}
                                                onChange={(event) => {
                                                    const value = event.target.value

                                                    setSearch(!!value.length ? value : null)
                                                    debounced.callback(false)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.fullWidth}>
                                    <TableContainer style={{ height: '250px' }}>
                                        {loading ?
                                            <InfiniteLoader
                                                isRowLoaded={index => !!values[index]}
                                                loadMoreRows={(start, stop) => {
                                                    if (initialize && !additive && (start.stopIndex >= values.length)) {
                                                        setAdditive(true)
                                                        getStandard(page + 1).then(response => {
                                                            setAdditive(false)
                                                            setValues([...values, ...response.data])
                                                            setTotal(response.meta.total)
                                                        })
                                                    }
                                                }}
                                                rowCount={total}
                                                threshold={10}
                                            >
                                                {({ onRowsRendered, registerChild }) => (
                                                    <AutoSizer disableHeight>
                                                        {({width}) =>
                                                            <Table
                                                                ref={registerChild}
                                                                rowHeight={42}
                                                                rowCount={values.length}
                                                                width={width}
                                                                height={250}
                                                                headerHeight={0}
                                                                rowGetter={({ index }) => values[index]}
                                                                onRowsRendered={onRowsRendered}
                                                                rowRenderer={({rowData, style}) => {
                                                                    const isItemStandard = (standard.indexOf(rowData.id) !== -1)

                                                                    let title = stringFormat(rowData.name, false)

                                                                    return (
                                                                        <Tooltip key={rowData.id} TransitionComponent={Fade} title={title} placement="bottom-end">
                                                                            <Grid
                                                                                container
                                                                                direction="row"
                                                                                justify="flex-start"
                                                                                alignItems="center"
                                                                                spacing={2}
                                                                                style={{...style, ...{cursor: 'pointer'}}}
                                                                                onClick={(event) => {
                                                                                    const standardIndex = standard.indexOf(rowData.id)

                                                                                    let select = []

                                                                                    if (standardIndex === -1) {
                                                                                        select = select.concat(standard, rowData.id)
                                                                                    } else if (standardIndex === 0) {
                                                                                        select = select.concat(standard.slice(1))
                                                                                    } else if (standardIndex === standard.length - 1) {
                                                                                        select = select.concat(standard.slice(0, -1))
                                                                                    } else if (standardIndex > 0) {
                                                                                        select = select.concat(
                                                                                            standard.slice(0, standardIndex),
                                                                                            standard.slice(standardIndex + 1),
                                                                                        )
                                                                                    }

                                                                                    setStandard(select)
                                                                                }}
                                                                            >
                                                                                <Grid item xs={2}>
                                                                                    <Checkbox color="primary" checked={isItemStandard}/>
                                                                                </Grid>
                                                                                <Grid item xs={10}>
                                                                                    <Typography color={"inherit"} variant="body2" noWrap>{title}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Tooltip>
                                                                    )
                                                                }}
                                                            />
                                                        }
                                                    </AutoSizer>
                                                )}
                                            </InfiniteLoader> : <Box>
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                            </Box>}
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>}
                    {!!attribute && <React.Fragment>
                        <Divider className={classes.divider} />
                        <Grid item className={classes.filterContent}>
                            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                                <Grid item>
                                    <Typography variant="caption">
                                        Фильтр
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.fullWidth}>
                                    <FormControl fullWidth>
                                        <InputLabel>Значение</InputLabel>
                                        <Select
                                            value={value}
                                            onChange={(e) => {
                                                setValue(e.target.value)
                                            }}
                                        >
                                            <MenuItem value="empty">Пусто</MenuItem>
                                            <MenuItem value="present">Не пусто</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.fullWidth}>
                                    <FormControl fullWidth>
                                        <InputLabel>Условие</InputLabel>
                                        <Select
                                            value={condition}
                                            disabled={(value === 'empty')}
                                            onChange={(e) => {
                                                setCondition(e.target.value)
                                                if (search) {
                                                    setLoading(false)
                                                }
                                            }}
                                        >
                                            {getConditions(attribute.type).map(function (value, index) {
                                                return <MenuItem key={index} value={value.value}>{value.title}</MenuItem>
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item className={classes.fullWidth}>
                                    <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                disabled={(value === 'empty')}
                                                label='Поиск'
                                                value={search ?? ''}
                                                onChange={(event) => {
                                                    const value = event.target.value

                                                    setSearch(!!value.length ? value : null)
                                                    debounced.callback(false)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.fullWidth}>
                                    <TableContainer style={{ height: '250px' }}>
                                        {loading ?
                                            <InfiniteLoader
                                                isRowLoaded={index => !!values[index]}
                                                loadMoreRows={(start, stop) => {
                                                    if (initialize && !additive && (start.stopIndex >= values.length)) {
                                                        setAdditive(true)
                                                        getData(page + 1).then(response => {
                                                            setAdditive(false)
                                                            setValues([...values, ...response.data])
                                                            setTotal(response.meta.total)
                                                        })
                                                    }
                                                }}
                                                rowCount={total}
                                                threshold={10}
                                            >
                                                {({ onRowsRendered, registerChild }) => (
                                                    <AutoSizer disableHeight>
                                                        {({width}) =>
                                                            <Table
                                                                ref={registerChild}
                                                                rowHeight={42}
                                                                rowCount={values.length}
                                                                width={width}
                                                                height={250}
                                                                headerHeight={0}
                                                                rowGetter={({ index }) => values[index]}
                                                                onRowsRendered={onRowsRendered}
                                                                rowRenderer={({rowData, style}) => {
                                                                    const isItemSelected = (selected.indexOf(rowData.id) !== -1)

                                                                    let title = stringFormat(`${(rowData.attribute.addition && rowData.attribute.addition.prefix.value) ? `${rowData.attribute.addition.prefix.value}${rowData.attribute.addition.prefix.space ? ' ' : ''}` : ''}${rowData.value}${(rowData.attribute.addition && rowData.attribute.addition.postfix.value) ? `${rowData.attribute.addition.postfix.space ? ' ' : ''}${rowData.attribute.addition.postfix.value}` : ''}`, false)

                                                                    return (
                                                                        <Tooltip key={rowData.id} TransitionComponent={Fade} title={title} placement="bottom-end">
                                                                            <Grid
                                                                                container
                                                                                direction="row"
                                                                                justify="flex-start"
                                                                                alignItems="center"
                                                                                spacing={2}
                                                                                style={{...style, ...{cursor: 'pointer'}}}
                                                                                onClick={(event) => {
                                                                                    if (value !== 'empty') {
                                                                                        const selectedIndex = selected.indexOf(rowData.id)

                                                                                        let select = []

                                                                                        if (selectedIndex === -1) {
                                                                                            select = select.concat(selected, rowData.id)
                                                                                        } else if (selectedIndex === 0) {
                                                                                            select = select.concat(selected.slice(1))
                                                                                        } else if (selectedIndex === selected.length - 1) {
                                                                                            select = select.concat(selected.slice(0, -1))
                                                                                        } else if (selectedIndex > 0) {
                                                                                            select = select.concat(
                                                                                                selected.slice(0, selectedIndex),
                                                                                                selected.slice(selectedIndex + 1),
                                                                                            )
                                                                                        }

                                                                                        setSelected(select)
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <Grid item xs={2}>
                                                                                    <Checkbox color="primary" checked={isItemSelected} disabled={(!rowData.active || (value === 'empty'))}/>
                                                                                </Grid>
                                                                                <Grid item xs={10}>
                                                                                    <Typography color={(rowData.active && (value !== 'empty')) ? "inherit" : "textSecondary"} variant="body2" noWrap>{title}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Tooltip>
                                                                    )
                                                                }}
                                                            />
                                                        }
                                                    </AutoSizer>
                                                )}
                                            </InfiniteLoader> : <Box>
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                            </Box>}
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>}
                    {(name === 'company') && <React.Fragment>
                        <Divider className={classes.divider} />
                        <Grid item className={classes.filterContent}>
                            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                                <Grid item>
                                    <Typography variant="caption">
                                        Фильтр
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.fullWidth}>
                                    <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                disabled={(value === 'empty')}
                                                label='Поиск'
                                                value={search ?? ''}
                                                onChange={(event) => {
                                                    const value = event.target.value

                                                    setSearch(!!value.length ? value : null)
                                                    debounced.callback(false)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.fullWidth}>
                                    <TableContainer style={{ height: '250px' }}>
                                        {loading ?
                                            <InfiniteLoader
                                                isRowLoaded={index => !!values[index]}
                                                loadMoreRows={(start, stop) => {
                                                    if (initialize && !additive && (start.stopIndex >= values.length)) {
                                                        setAdditive(true)
                                                        getSources(page + 1).then(response => {
                                                            setAdditive(false)
                                                            setValues([...values, ...response.data])
                                                            setTotal(response.meta.total)
                                                        })
                                                    }
                                                }}
                                                rowCount={total}
                                                threshold={10}
                                            >
                                                {({ onRowsRendered, registerChild }) => (
                                                    <AutoSizer disableHeight>
                                                        {({width}) =>
                                                            <Table
                                                                ref={registerChild}
                                                                rowHeight={42}
                                                                rowCount={values.length}
                                                                width={width}
                                                                height={250}
                                                                headerHeight={0}
                                                                rowGetter={({ index }) => values[index]}
                                                                onRowsRendered={onRowsRendered}
                                                                rowRenderer={({rowData, style}) => {
                                                                    const isItemSelected = (company.indexOf(rowData.id) !== -1)

                                                                    return (
                                                                        <Tooltip key={rowData.id} TransitionComponent={Fade} title={rowData.company} placement="bottom-end">
                                                                            <Grid
                                                                                container
                                                                                direction="row"
                                                                                justify="flex-start"
                                                                                alignItems="center"
                                                                                spacing={2}
                                                                                style={{...style, ...{cursor: 'pointer'}}}
                                                                                onClick={(event) => {
                                                                                    const selectedIndex = company.indexOf(rowData.id)

                                                                                    let select = []

                                                                                    if (selectedIndex === -1) {
                                                                                        select = select.concat(company, rowData.id)
                                                                                    } else if (selectedIndex === 0) {
                                                                                        select = select.concat(company.slice(1))
                                                                                    } else if (selectedIndex === company.length - 1) {
                                                                                        select = select.concat(company.slice(0, -1))
                                                                                    } else if (selectedIndex > 0) {
                                                                                        select = select.concat(
                                                                                            company.slice(0, selectedIndex),
                                                                                            company.slice(selectedIndex + 1),
                                                                                        )
                                                                                    }

                                                                                    setCompany(select)
                                                                                }}
                                                                            >
                                                                                <Grid item xs={2}>
                                                                                    <Checkbox color="primary" checked={isItemSelected}/>
                                                                                </Grid>
                                                                                <Grid item xs={10}>
                                                                                    <Typography color="inherit" variant="body2" noWrap>{rowData.company}</Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Tooltip>
                                                                    )
                                                                }}
                                                            />
                                                        }
                                                    </AutoSizer>
                                                )}
                                            </InfiniteLoader> : <Box>
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                                <Skeleton height={42} animation="wave" />
                                            </Box>}
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>}
                    {((name === 'status') || (name === 'source') || (name === 'module')) &&
                        <React.Fragment>
                            <Divider className={classes.divider} />
                            <Grid item className={classes.filterContent}>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid item>
                                        <Typography variant="caption">
                                            Фильтр
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.fullWidth}>
                                        <TableContainer style={{ height: '129px' }}>
                                            <TableMUI stickyHeader>
                                                <TableBody>
                                                    {[
                                                        ...((name === 'status') ? [{name: 'Активный', key: 'active'}, {name: 'Неактивный', key: 'inactive'}, {name: 'Не проверен', key: 'confirmed'}] : []),
                                                        ...((name === 'source') ? [{name: 'Система', key: 'system'}, {name: 'Заказчик', key: 'customer'}, {name: 'Поставщик', key: 'supplier'}] : []),
                                                        ...((name === 'module') ? [{name: 'Система', key: 'system'}, {name: 'Ордер', key: 'order'}, {name: 'Справочник эталонов', key: 'standard'}, {name: 'ЦЗ', key: 'request'}, {name: 'Планирование', key: 'planning'}] : [])
                                                    ].map(item => {
                                                        let obj = []

                                                        switch (name) {
                                                            case 'status':
                                                                obj = status
                                                                break;
                                                            case 'source':
                                                                obj = source
                                                                break;
                                                            case 'module':
                                                                obj = module
                                                                break;
                                                        }

                                                        const isItemSelected = (obj.indexOf(item.key) !== -1)

                                                        return (
                                                            <TableRowMUI
                                                                key={item.key}
                                                                onClick={(event) => {
                                                                    const selectedIndex = obj.indexOf(item.key)

                                                                    let select = []

                                                                    if (selectedIndex === -1) {
                                                                        select = select.concat(obj, item.key)
                                                                    } else if (selectedIndex === 0) {
                                                                        select = select.concat(obj.slice(1))
                                                                    } else if (selectedIndex === obj.length - 1) {
                                                                        select = select.concat(obj.slice(0, -1))
                                                                    } else if (selectedIndex > 0) {
                                                                        select = select.concat(
                                                                            obj.slice(0, selectedIndex),
                                                                            obj.slice(selectedIndex + 1),
                                                                        )
                                                                    }

                                                                    switch (name) {
                                                                        case 'status':
                                                                            setStatus(select)
                                                                            break;
                                                                        case 'source':
                                                                            setSource(select)
                                                                            break;
                                                                        case 'module':
                                                                            setModule(select)
                                                                            break;
                                                                    }
                                                                }}
                                                                role="checkbox"
                                                                selected={isItemSelected}
                                                            >
                                                                <TableCellMUI padding="checkbox">
                                                                    <Checkbox checked={isItemSelected}/>
                                                                </TableCellMUI>
                                                                <TableCellMUI align="left">
                                                                    <Typography variant="body2" noWrap className={classes.filterItem}>{item.name}</Typography>
                                                                </TableCellMUI>
                                                            </TableRowMUI>
                                                        )
                                                    })}
                                                </TableBody>
                                            </TableMUI>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                    <Grid item className={classes.filterContent}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction="row" justify="space-between" alignItems="flex-start">
                                    <Grid item>
                                        <Button
                                            size="small"
                                            color="secondary"
                                            type="button"
                                            onClick={() => {
                                                setSort({...sort, ...{direction: null}})
                                                setSelected([])
                                                setStatus([])
                                                setSource([])
                                                setCompany([])
                                                setModule([])
                                                dispatch({
                                                    type: 'ITEM_FILTER',
                                                    payload: {
                                                        order: filter.order.filter(item => (attribute && (item !== attribute.id))),
                                                        selected: {...filter.selected, ...(attribute ? {[attribute.id]: []} : {})},
                                                        conditions: {...filter.conditions, ...(attribute ? {[attribute.id]: {condition: null, expression: null}} : {})},
                                                        attributes: {...filter.attributes, ...(attribute ? {[attribute.id]: null} : {})},
                                                        sort: ((filter.sort.name === name) ? {name: null, direction: null} : filter.sort),
                                                        status: (name === 'status') ? [] : filter.status,
                                                        source: (name === 'source') ? [] : filter.source,
                                                        company: (name === 'company') ? [] : filter.company,
                                                        module: (name === 'module') ? [] : filter.module,
                                                        standard: (name === 'value.keyword') ? [] : filter.standard,
                                                    }
                                                })
                                            }}
                                        >
                                            Сбросить
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            size="small"
                                            color="primary"
                                            type="button"
                                            onClick={() => {
                                                dispatch({
                                                    type: 'ITEM_FILTER',
                                                    payload: {
                                                        order: [...filter.order, ...((!!selected.length && !filter.order.find(item => (attribute && (item === attribute.id)))) ? (attribute ? [attribute.id] : []) : [])],
                                                        selected: {...filter.selected, ...(!!selected.length ? (attribute ? {[attribute.id]: selected} : {}) : {})},
                                                        conditions: {...filter.conditions, ...((attribute && condition) ? {[attribute.id]: {condition: condition, expression: search}} : {})},
                                                        attributes: {...filter.attributes, ...((attribute && value) ? {[attribute.id]: value} : {})},
                                                        sort: !!sort.direction ? sort : filter.sort,
                                                        status: (name === 'status') ? status : filter.status,
                                                        source: (name === 'source') ? source : filter.source,
                                                        company: (name === 'company') ? company : filter.company,
                                                        module: (name === 'module') ? module : filter.module,
                                                        standard: (name === 'value.keyword') ? standard : filter.standard
                                                    }
                                                })
                                                setAnchorEl(null)
                                            }}
                                        >
                                            Применить
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover> : null}
        </React.Fragment>
    )
}
