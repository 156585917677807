import { HttpService } from '../../App/services/http'

export const DictionaryService = {
    items,
    sources,
    dictionary,
    dictionaries,
    description,
    categories,
    filter,
    addItem,
    editItem,
    rejectItem,
    deleteItem
}

function items (id, params) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/dictionary/${id}/items`, options, true)
        .then(response => {
            return response
        })
}

function sources (id, params) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/dictionary/sources/items`, options, true)
        .then(response => {
            return response
        })
}

function dictionary (id) {
    const options = {
        method: 'GET'
    }

    return HttpService.http(`/dictionary/${id}`, options, true)
        .then(response => {
            return response.dictionary
        })
}

function dictionaries (params) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/dictionaries`, options, true)
        .then(response => {
            return response
        })
}

function description (id, values) {
    const options = {
        method: 'PUT',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/dictionary/${id}/description`, options, true)
        .then(response => {
            return response
        })
}

function categories () {
    const options = {
        method: 'GET'
    }

    return HttpService.http(`/dictionary/categories`, options, true)
        .then(response => {
            return response.categories
        })
}

function filter (type, params = {}) {
    const options = {
        method: 'GET',
        params: params.params
    }

    let path = `/dictionary/filter`

    // eslint-disable-next-line default-case
    switch (type) {
        case 'items':
            // eslint-disable-next-line no-unused-vars
            path = `/dictionary/${params.id}/items/filter`
            break
    }

    return HttpService.http(path, options, true)
        .then(response => {
            return response
        })
}

function addItem (dictionary, values) {
    const options = {
        method: 'POST',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/dictionary/${dictionary}/item`, options, true)
        .then(response => {
            return response.item
        })
}

function editItem (dictionary, id, values) {
    const options = {
        method: 'PUT',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/dictionary/${dictionary}/item/${id}`, options, true)
        .then(response => {
            return response
        })
}

function rejectItem (dictionary, id, values) {
    const options = {
        method: 'PUT',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/dictionary/${dictionary}/item/${id}/reject`, options, true)
        .then(response => {
            return response
        })
}

function deleteItem (dictionary, id) {
    const options = {
        method: 'DELETE'
    }

    return HttpService.http(`/dictionary/${dictionary}/item/${id}`, options, true)
        .then(response => {
            return response
        })
}
