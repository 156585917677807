import { SynonymService } from '../../services/exception/synonym'

export const SynonymActions = {
    values,
    remove,
    save,
    filter
}

function values (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'SYNONYM_EXCEPTION_VALUES_REQUEST' })

        SynonymService.values(params)
            .then(
                values => {
                    dispatch({ type: 'SYNONYM_EXCEPTION_VALUES_SUCCESS', payload: values })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(values.data)
                },
                error => {
                    dispatch({ type: 'SYNONYM_EXCEPTION_VALUES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function remove (params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'SYNONYM_DELETE_EXCEPTION_VALUE_REQUEST' })

        SynonymService.remove(params)
            .then(
                () => {
                    dispatch({ type: 'SYNONYM_DELETE_EXCEPTION_VALUE_SUCCESS' })
                    resolve()
                },
                error => {
                    dispatch({ type: 'SYNONYM_DELETE_EXCEPTION_VALUE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function save (params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'SYNONYM_SAVE_EXCEPTION_VALUES_REQUEST' })

        SynonymService.save(params)
            .then(
                () => {
                    dispatch({ type: 'SYNONYM_SAVE_EXCEPTION_VALUES_SUCCESS' })
                    resolve()
                },
                error => {
                    dispatch({ type: 'SYNONYM_SAVE_EXCEPTION_VALUES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function filter (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        SynonymService.filter(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}
