import {ChangeService} from "../services/history";

export const ChangeActions = {
    filter
}

function filter (id, type, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        ChangeService.filter(id, type, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}
