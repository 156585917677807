export default function notification (state = { events: { data: [], meta: {}, search: null, limit: 10, page: 1 }, count: 0 }, action) {
    switch (action.type) {
        case 'NOTIFICATION_EVENTS_REQUEST':
            return {
                ...state,
                events: state.events
            }
        case 'NOTIFICATION_EVENTS_SUCCESS':
            return {
                ...state,
                events: action.payload
            }
        case 'NOTIFICATION_EVENTS_FAILURE':
            return {
                ...state,
                events: { data: [], meta: {}, search: state.events.search, limit: state.events.limit, page: state.events.page }
            }
        case 'NOTIFICATION_EVENTS_CLEAR':
            return {
                ...state,
                events: { data: [], meta: {}, search: null, limit: 10, page: 1 }
            }
        case 'NOTIFICATION_READ_SUCCESS':
            return {
                ...state,
                count: (state.count > 0) ? (state.count - 1) : 0
            }
        case 'NOTIFICATION_COUNT_REQUEST':
            return {
                ...state,
                count: state.count
            }
        case 'NOTIFICATION_COUNT_SUCCESS':
            return {
                ...state,
                count: action.payload
            }
        case 'NOTIFICATION_COUNT_FAILURE':
            return {
                ...state,
                count: state.count
            }
        case 'NOTIFICATION_COUNT_CLEAR':
            return {
                ...state,
                count: 0
            }
        default:
            return state
    }
}
