import React, {useState} from "react"
import {Field, FieldArray} from "formik"

import {Card, CardContent, Grid, IconButton, makeStyles, Typography} from "@material-ui/core"
import { TextField } from "formik-material-ui"
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete'
import {Add, Clear} from "@material-ui/icons";
import {Hidden} from "./Hidden";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    card: {
        'width': '100%',
        'border-radius': 'unset'
    },
    label: {
        'color': 'rgba(0, 0, 0, 0.54)',
        'padding': '0',
        'font-size': '0.75rem',
        'font-weight': '400',
        'line-height': '1',
        'letter-spacing': '0.00938em'
    }
}))

export const FieldCompositeSize = (props) => {
    const classes = useStyles()

    const { id, attribute, label, items, setFieldValue, isSubmitting, disabled } = props
    const [name] = useState(props.hasOwnProperty('name') ? props.name : `attributes.${id}`)
    const [value, setValue] = useState(props.value)

    return (
        <Grid item className={classes.fullWidth}>
            <Card className={classes.card}>
                <CardContent>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                    <Grid item sm={9} className={classes.fullWidth}>
                                        <Typography variant="caption" className={classes.label}>{label}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                {Object.entries(attribute.params).reduce((result, [key, index]) => {
                                    if (index) {
                                        result[index] = key
                                    }

                                    return result
                                }, []).map((param, index) => {
                                    let component

                                    switch (param) {
                                        case 'prefix':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['prefix'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item xs={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.prefix`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Префикс"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'postfix':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['postfix'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.postfix`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Постфикс"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'amount':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['amount'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item xs={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.amount`}
                                                            type="number"
                                                            disabled={isSubmitting || disabled}
                                                            label="Количество"
                                                            inputProps={{
                                                                step: 0.0001,
                                                                onWheel: e => {
                                                                    e.target.blur()
                                                                    e.stopPropagation()
                                                                    setTimeout(() => {
                                                                        e.target.focus()
                                                                    }, 0)
                                                                }
                                                            }}
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'height':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['height'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.height`}
                                                            type="number"
                                                            disabled={isSubmitting || disabled}
                                                            label="Высота"
                                                            inputProps={{
                                                                step: 0.0001,
                                                                onWheel: e => {
                                                                    e.target.blur()
                                                                    e.stopPropagation()
                                                                    setTimeout(() => {
                                                                        e.target.focus()
                                                                    }, 0)
                                                                }
                                                            }}
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'space':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['space'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.space`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Пробел"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'metric':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['metric'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.metric`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Метрик"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'thickness':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['thickness'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.thickness`}
                                                            type="number"
                                                            disabled={isSubmitting || disabled}
                                                            label="Толщина"
                                                            inputProps={{
                                                                step: 0.0001,
                                                                onWheel: e => {
                                                                    e.target.blur()
                                                                    e.stopPropagation()
                                                                    setTimeout(() => {
                                                                        e.target.focus()
                                                                    }, 0)
                                                                }
                                                            }}
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'unit':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['unit'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Autocomplete
                                                            filterOptions={createFilterOptions({
                                                                matchFrom: 'start',
                                                                stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                            })}
                                                            options={items}
                                                            getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                                            disabled={isSubmitting || disabled}
                                                            onChange={(e, value) => {
                                                                setFieldValue(`${name}.unit`, ((value instanceof Object) ? value.short : value))
                                                            }}
                                                            defaultValue={value.unit}
                                                            renderInput={params => (
                                                                <Field
                                                                    fullWidth
                                                                    {...params}
                                                                    name={`${name}.unit`}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        onChange: (e) => {
                                                                            setValue({
                                                                                ...value,
                                                                                ...{
                                                                                    unit: e.target.value
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                    type="text"
                                                                    label="Общая Ед.изм."
                                                                    component={TextField}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'gauge':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['gauge'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.gauge`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Размер Gauge"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'size.charrier':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['size.charrier'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item xs={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.size.charrier`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Размеры по Шарьеру"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'size.range':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['size.range'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.size.range`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Размерный ряд"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'diameter.outer':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['diameter.outer'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={6} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.diameter.outer.value`}
                                                            type="number"
                                                            disabled={isSubmitting || disabled}
                                                            label="Диаметр"
                                                            inputProps={{
                                                                step: 0.0001,
                                                                onWheel: e => {
                                                                    e.target.blur()
                                                                    e.stopPropagation()
                                                                    setTimeout(() => {
                                                                        e.target.focus()
                                                                    }, 0)
                                                                }
                                                            }}
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6} className={classes.fullWidth}>
                                                        <Autocomplete
                                                            filterOptions={createFilterOptions({
                                                                matchFrom: 'start',
                                                                stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                            })}
                                                            options={items}
                                                            getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                                            disabled={isSubmitting || disabled}
                                                            onChange={(e, value) => {
                                                                setFieldValue(`${name}.diameter.outer.unit`, ((value instanceof Object) ? value.short : value))
                                                            }}
                                                            defaultValue={value.diameter.outer.unit}
                                                            renderInput={params => (
                                                                <Field
                                                                    fullWidth
                                                                    {...params}
                                                                    name={`${name}.diameter.outer.unit`}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        onChange: (e) => {
                                                                            setValue({
                                                                                ...value,
                                                                                ...{
                                                                                    diameter: {
                                                                                        ...value.diameter,
                                                                                        ...{
                                                                                            outer: {
                                                                                                ...value.diameter.outer,
                                                                                                ...{
                                                                                                    unit: e.target.value
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                    type="text"
                                                                    label="Ед. изм."
                                                                    component={TextField}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={6} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.diameter.outer.delimiter`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Разделитель"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                            break
                                        case 'diameter.inner':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['diameter.inner'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={12} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.diameter.inner.value`}
                                                            type="number"
                                                            disabled={isSubmitting || disabled}
                                                            label="Внутренний диаметр"
                                                            inputProps={{
                                                                step: 0.0001,
                                                                onWheel: e => {
                                                                    e.target.blur()
                                                                    e.stopPropagation()
                                                                    setTimeout(() => {
                                                                        e.target.focus()
                                                                    }, 0)
                                                                }
                                                            }}
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                    <Hidden key={index} condition={false}>
                                                        <Grid item sm={6} className={classes.fullWidth}>
                                                            <Autocomplete
                                                                filterOptions={createFilterOptions({
                                                                    matchFrom: 'start',
                                                                    stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                                })}
                                                                options={items}
                                                                getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                                                disabled={isSubmitting || disabled}
                                                                onChange={(e, value) => {
                                                                    setFieldValue(`${name}.diameter.inner.unit`, ((value instanceof Object) ? value.short : value))
                                                                }}
                                                                defaultValue={value.diameter.inner.unit}
                                                                renderInput={params => (
                                                                    <Field
                                                                        fullWidth
                                                                        {...params}
                                                                        name={`${name}.diameter.inner.unit`}
                                                                        InputProps={{
                                                                            ...params.InputProps,
                                                                            onChange: (e) => {
                                                                                setValue({
                                                                                    ...value,
                                                                                    ...{
                                                                                        diameter: {
                                                                                            ...value.diameter,
                                                                                            ...{
                                                                                                inner: {
                                                                                                    ...value.diameter.inner,
                                                                                                    ...{
                                                                                                        unit: e.target.value
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }
                                                                        }}
                                                                        type="text"
                                                                        label="Ед. изм."
                                                                        component={TextField}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Hidden>
                                                </Grid>
                                                <Hidden key={index} condition={false}>
                                                    <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                        <Grid item sm={6} className={classes.fullWidth}>
                                                            <Field
                                                                fullWidth
                                                                name={`${name}.diameter.inner.delimiter`}
                                                                disabled={isSubmitting || disabled}
                                                                label="Разделитель"
                                                                component={TextField}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Hidden>
                                            </Hidden>
                                            break
                                        case 'width':
                                            component = <Hidden key={index} condition={(attribute.params && attribute.params['width'])}>
                                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={6} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.width.value`}
                                                            type="number"
                                                            disabled={isSubmitting || disabled}
                                                            label="Ширина"
                                                            inputProps={{
                                                                step: 0.0001,
                                                                onWheel: e => {
                                                                    e.target.blur()
                                                                    e.stopPropagation()
                                                                    setTimeout(() => {
                                                                        e.target.focus()
                                                                    }, 0)
                                                                }
                                                            }}
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                    <Grid item sm={6} className={classes.fullWidth}>
                                                        <Autocomplete
                                                            filterOptions={createFilterOptions({
                                                                matchFrom: 'start',
                                                                stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                            })}
                                                            options={items}
                                                            getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                                            disabled={isSubmitting || disabled}
                                                            onChange={(e, value) => {
                                                                setFieldValue(`${name}.width.unit`, ((value instanceof Object) ? value.short : value))
                                                            }}
                                                            defaultValue={value.width.unit}
                                                            renderInput={params => (
                                                                <Field
                                                                    fullWidth
                                                                    {...params}
                                                                    name={`${name}.width.unit`}
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        onChange: (e) => {
                                                                            setValue({
                                                                                ...value,
                                                                                ...{
                                                                                    width: {
                                                                                        ...value.width,
                                                                                        ...{
                                                                                            unit: e.target.value
                                                                                        }
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    }}
                                                                    type="text"
                                                                    label="Ед. изм."
                                                                    component={TextField}
                                                                />
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid key={index} container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                    <Grid item sm={6} className={classes.fullWidth}>
                                                        <Field
                                                            fullWidth
                                                            name={`${name}.width.delimiter`}
                                                            disabled={isSubmitting || disabled}
                                                            label="Разделитель"
                                                            component={TextField}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Hidden>
                                        case 'lengths':
                                            component = <React.Fragment key={index}>
                                                {component}
                                                <Hidden condition={(attribute.params && attribute.params['lengths'])}>
                                                    <FieldArray
                                                        name={`${name}.lengths`}
                                                        render={arrayOptions => (
                                                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                                                {props.value.lengths.map((option, key) => {
                                                                    return (((param === 'lengths') && !key) || ((param === 'width') && !!key)) ? <Grid item key={key} className={classes.fullWidth}>
                                                                        <Grid container direction='column' justify='center' alignItems='flex-start' spacing={2}>
                                                                            <Grid item className={classes.fullWidth}>
                                                                                <Grid container direction='row' justify='flex-start' alignItems='flex-end' spacing={2}>
                                                                                    <Grid item xs={6} className={classes.fullWidth}>
                                                                                        <Field
                                                                                            fullWidth
                                                                                            name={`${name}.lengths[${key}].value`}
                                                                                            type="number"
                                                                                            disabled={isSubmitting || disabled}
                                                                                            label="Длина"
                                                                                            inputProps={{
                                                                                                step: 0.0001,
                                                                                                onWheel: e => {
                                                                                                    e.target.blur()
                                                                                                    e.stopPropagation()
                                                                                                    setTimeout(() => {
                                                                                                        e.target.focus()
                                                                                                    }, 0)
                                                                                                }
                                                                                            }}
                                                                                            component={TextField}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item sm={4} className={classes.fullWidth}>
                                                                                        <Autocomplete
                                                                                            filterOptions={createFilterOptions({
                                                                                                matchFrom: 'start',
                                                                                                stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                                                            })}
                                                                                            options={items}
                                                                                            getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                                                                            disabled={isSubmitting || disabled}
                                                                                            onChange={(e, value) => {
                                                                                                setFieldValue(`${name}.lengths[${key}].unit`, ((value instanceof Object) ? value.short : value))
                                                                                            }}
                                                                                            defaultValue={option.unit}
                                                                                            renderInput={params => (
                                                                                                <Field
                                                                                                    fullWidth
                                                                                                    {...params}
                                                                                                    name={`${name}.lengths[${key}].unit`}
                                                                                                    InputProps={{
                                                                                                        ...params.InputProps,
                                                                                                        onChange: (e) => {
                                                                                                            setValue({
                                                                                                                ...value,
                                                                                                                ...{
                                                                                                                    lengths: value.lengths.map((length, number) => ({
                                                                                                                        ...length,
                                                                                                                        ...{
                                                                                                                            unit: (number === key) ? e.target.value : length.unit
                                                                                                                        }
                                                                                                                    }))
                                                                                                                }
                                                                                                            })
                                                                                                        }
                                                                                                    }}
                                                                                                    type="text"
                                                                                                    label="Ед. изм."
                                                                                                    component={TextField}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <IconButton
                                                                                            onClick={() => arrayOptions.remove(key)}
                                                                                            color="primary"
                                                                                            aria-label="Удалить"
                                                                                            component="span"
                                                                                        >
                                                                                            <Clear/>
                                                                                        </IconButton>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item xs={6} className={classes.fullWidth}>
                                                                                <Field
                                                                                    fullWidth
                                                                                    name={`${name}.lengths[${key}].delimiter`}
                                                                                    disabled={isSubmitting || disabled}
                                                                                    label="Разделитель"
                                                                                    component={TextField}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid> : null
                                                                })}
                                                                {(((param === 'lengths') && (props.value.lengths.length < 2)) || ((param === 'width') && (props.value.lengths.length > 1))) &&
                                                                    <Grid item className={classes.fullWidth}>
                                                                        <IconButton
                                                                            onClick={() => arrayOptions.push({
                                                                                value: '',
                                                                                unit: '',
                                                                                delimiter: ''
                                                                            })}
                                                                            color={'primary'}
                                                                            aria-label="Добавить"
                                                                            component="span"
                                                                        >
                                                                            <Add/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        )}
                                                    />
                                                </Hidden>
                                            </React.Fragment>
                                            break
                                        default:
                                            component = null
                                    }

                                    return component
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}
