export default function attribute (state = { values: { data: [], meta: {}, limit: 10, page: 1}, terms: { data: [], meta: {}, limit: 10, page: 1}, items: [] }, action) {
    switch (action.type) {
        case 'ATTRIBUTE_VALUES_REQUEST':
            return state
        case 'ATTRIBUTE_VALUES_SUCCESS':
            return {
                ...state,
                ...{values: action.payload}
            }
        case 'ATTRIBUTE_VALUES_FAILURE':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: state.values.limit, page: state.values.page}}
            }
        case 'ATTRIBUTE_VALUES_CLEAR':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: state.values.limit, page: state.values.page}}
            }
        case 'ATTRIBUTE_TERMS_REQUEST':
            return state
        case 'ATTRIBUTE_TERMS_SUCCESS':
            return {
                ...state,
                ...{terms: action.payload}
            }
        case 'ATTRIBUTE_TERMS_FAILURE':
            return {
                ...state,
                ...{terms: { data: [], meta: {}, limit: state.terms.limit, page: state.terms.page}}
            }
        case 'ATTRIBUTE_TERMS_CLEAR':
            return {
                ...state,
                ...{terms: { data: [], meta: {}, limit: state.terms.limit, page: state.terms.page}}
            }
        case 'ATTRIBUTE_ITEMS_REQUEST':
            return state
        case 'ATTRIBUTE_ITEMS_SUCCESS':
            return {
                ...state,
                ...{items: action.payload}
            }
        case 'ATTRIBUTE_ITEMS_FAILURE':
            return {
                ...state,
                ...{items: []}
            }
        case 'ATTRIBUTE_ITEMS_CLEAR':
            return {
                ...state,
                ...{items: []}
            }
        default:
            return state
    }
}
