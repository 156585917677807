export default function item (state = { items: { data: [], meta: {}, limit: 100, page: 1 }, values: { data: [], meta: {}, limit: 100, page: 1 }, filter: {sort: {name: null, direction: null}, status: [], value: [], categories: [], subcategories: [], standard: [], category: [], user: []} }, action) {
    switch (action.type) {
        case 'MARKUP_FILE_ITEMS_REQUEST':
            return state
        case 'MARKUP_FILE_ITEMS_SUCCESS':
            return {
                ...state,
                ...{items: action.payload}
            }
        case 'MARKUP_FILE_ITEMS_FAILURE':
            return {
                ...state,
                ...{items: { data: [], meta: {}, limit: state.items.limit, page: state.items.page }}
            }
        case 'MARKUP_FILE_ITEMS_CLEAR':
            return {
                ...state,
                ...{items: { data: [], meta: {}, limit: 10, page: 1 }}
            }
        case 'MARKUP_FILE_ITEMS_VALUES_SUCCESS':
            return {
                ...state,
                ...{values: action.payload}
            }
        case 'MARKUP_FILE_ITEMS_VALUES_FAILURE':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: state.values.limit, page: state.values.page }}
            }
        case 'MARKUP_FILE_ITEMS_VALUES_CLEAR':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: 10, page: 1 }}
            }
        case 'MARKUP_FILE_ITEMS_FILTER':
            return {
                ...state,
                ...{filter: {
                    ...state.filter,
                    ...{sort: action.payload.sort},
                    ...{status: action.payload.status},
                    ...{value: action.payload.value},
                    ...{categories: action.payload.categories},
                    ...{subcategories: action.payload.subcategories},
                    ...{standard: action.payload.standard},
                    ...{category: action.payload.category},
                    ...{user: action.payload.user},
                }}
            }
        case 'MARKUP_CATEGORY_FILE_ITEMS_REQUEST':
            return state
        case 'MARKUP_CATEGORY_FILE_ITEMS_SUCCESS':
            return {
                ...state,
                items: {
                    ...state.items,
                    ...{
                        data: state.items.data.map(item => {
                            const index = action.payload.findIndex(el => el.id === item.id)

                            return (index >= 0) ? action.payload[index] : item
                        })
                    },
                }
            }
        case 'MARKUP_CATEGORY_FILE_ITEMS_FAILURE':
            return state
        case 'MARKUP_STANDARD_FILE_ITEM_REQUEST':
            return state
        case 'MARKUP_STANDARD_FILE_ITEM_SUCCESS':
            return {
                ...state,
                ...{
                    items: {
                        ...state.items,
                        ...{
                            data: state.items.data.map(item => {
                                return (action.payload.id === item.id) ? action.payload : item
                            })
                        },
                    }
                }
            }
        case 'MARKUP_STANDARD_FILE_ITEM_FAILURE':
            return state
        case 'MARKUP_DESCRIPTION_FILE_ITEM_REQUEST':
            return state
        case 'MARKUP_DESCRIPTION_FILE_ITEM_SUCCESS':
            return {
                ...state,
                ...{
                    items: {
                        ...state.items,
                        ...{
                            data: state.items.data.map(item => {
                                return (action.payload.id === item.id) ? {
                                    ...item,
                                    ...action.payload.values,
                                } : item
                            })
                        },
                    }
                }
            }
        case 'MARKUP_DESCRIPTION_FILE_ITEM_FAILURE':
            return state
        case 'MARKUP_VARIATION_FILE_ITEM_REQUEST':
            return state
        case 'MARKUP_VARIATION_FILE_ITEM_SUCCESS':
            return {
                ...state,
                ...{
                    items: {
                        ...state.items,
                        ...{
                            data: state.items.data.map(item => {
                                return (action.payload.id === item.id) ? action.payload : item
                            })
                        },
                    }
                }
            }
        case 'MARKUP_VARIATION_FILE_ITEM_FAILURE':
            return state
        default:
            return state
    }
}
