import React, {useState} from "react"
import { Field } from "formik"
import { TextField } from "formik-material-ui"
import {IconButton, ListItemIcon, ListItemText, makeStyles, MenuItem, Typography} from "@material-ui/core"
import {ExpandLess, ExpandMore} from "@material-ui/icons"
import {useDispatch, useSelector} from "react-redux"

const useStyles = makeStyles(theme => ({
    disabled: {
        'color': 'grey'
    },
    default: {
        'color': 'inherit'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    }
}))

export const FieldCategory = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { isSubmitting, disabled, label, onChange } = props

    const {categories} = useSelector(state => state.system)
    const [openCategory, setOpenCategory] = useState({})

    const assembly = (options, parent = 0, level = 0) => {
        let result = []

        if (!parent) {
            result.push(
                <MenuItem key={0} value={{}} style={{ paddingLeft: `${16 * (level + 1)}px`, minHeight: '44px'}}>
                    <ListItemText className={ classes.listItemText } />
                </MenuItem>
            )
        }

        if (options.hasOwnProperty(parent)) {
            options[parent].sort((first, second) => {
                return (first.name > second.name) ? 1 : (second.name > first.name) ? -1 : 0
            }).forEach(category => {
                result.push(
                    <MenuItem key={ category.id } value={ category } style={{ paddingLeft: `${16 * (level + 1)}px`}}>
                        {options.hasOwnProperty(category.id) &&
                        <ListItemIcon className={ classes.listItemIcon }>
                            <IconButton
                                size="small"
                                onClick={e => {
                                    e.stopPropagation()
                                    if (options.hasOwnProperty(category.id)) {
                                        setOpenCategory({...openCategory, ...{[category.id]: openCategory.hasOwnProperty(category.id) ? !openCategory[category.id] : true}})
                                    }
                                }}
                            >
                                {(openCategory.hasOwnProperty(category.id) && openCategory[category.id]) ? <ExpandLess/> : <ExpandMore/>}
                            </IconButton>
                        </ListItemIcon>
                        }
                        <ListItemText className={ options.hasOwnProperty(category.id) ? classes.listItemTextWithIcon : classes.listItemText } primary={ !level ? <Typography variant="body1" className={classes.default}>{category.name}</Typography> : <Typography variant="body2" className={classes.default}>{category.name}</Typography> } />
                    </MenuItem>
                )

                const childes = assembly(options, category.id, level + 1)

                if (!!childes.length && openCategory.hasOwnProperty(category.id) && openCategory[category.id]) {
                    result = result.concat([
                        childes.map(row => row)
                    ])
                }
            })
        }

        return result
    }

    const getCategoriesTree = options => {
        let tmp = {}
        options.forEach(category => {
            if (!tmp.hasOwnProperty((category.category !== null) ? category.category.id : 0)) {
                tmp[(category.category !== null) ? category.category.id : 0] = []
            }

            tmp[(category.category !== null) ? category.category.id : 0].push(category)
        })

        return assembly(tmp)
    }

    return (
        <Field
            fullWidth
            type="text"
            name="category"
            label={label}
            select
            variant="standard"
            disabled={ isSubmitting || disabled }
            component={ TextField }
            InputLabelProps={{
                shrink: true
            }}
            inputProps={{
                renderValue: value => value.name,
                ...(onChange ? {onChange: onChange} : {})
            }}
        >
            {getCategoriesTree(categories)}
        </Field>
    )
}
