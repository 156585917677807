export const MessageActions = {
  success,
  info,
  error,
  clear
}

function success(title, message) {
  return { type: 'MESSAGE_SUCCESS', payload: {title: title, message: message} }
}

function info(title, message) {
  return { type: 'MESSAGE_INFO', payload: {title: title, message: message} }
}

function error(title, message) {
  return { type: 'MESSAGE_ERROR', payload: {title: title, message: message} }
}

function clear() {
  return { type: 'MESSAGE_CLEAR' }
}
