import { SynonymService } from '../../services/unit/synonym'

export const SynonymActions = {
    units,
    unit,
    values,
    remove,
    save,
    filter
}

function units (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'SYNONYM_UNITS_REQUEST' })

        SynonymService.units(params)
            .then(
                units => {
                    dispatch({ type: 'SYNONYM_UNITS_SUCCESS', payload: units })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(units)
                },
                error => {
                    dispatch({ type: 'SYNONYM_UNITS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function unit (id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'SYNONYM_UNIT_REQUEST' })

        SynonymService.unit(id)
            .then(
                unit => {
                    dispatch({ type: 'SYNONYM_UNIT_SUCCESS', payload: unit })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(unit)
                },
                error => {
                    dispatch({ type: 'SYNONYM_UNIT_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function values (id, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'SYNONYM_UNIT_VALUES_REQUEST' })

        SynonymService.values(id, params)
            .then(
                values => {
                    dispatch({ type: 'SYNONYM_UNIT_VALUES_SUCCESS', payload: values })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(values.data)
                },
                error => {
                    dispatch({ type: 'SYNONYM_UNIT_VALUES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function remove (id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'SYNONYM_DELETE_UNIT_VALUE_REQUEST' })

        SynonymService.remove(id, params)
            .then(
                () => {
                    dispatch({ type: 'SYNONYM_DELETE_UNIT_VALUE_SUCCESS' })
                    resolve()
                },
                error => {
                    dispatch({ type: 'SYNONYM_DELETE_UNIT_VALUE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function save (id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'SYNONYM_SAVE_UNIT_VALUES_REQUEST' })

        SynonymService.save(id, params)
            .then(
                () => {
                    dispatch({ type: 'SYNONYM_SAVE_UNIT_VALUES_SUCCESS' })
                    resolve()
                },
                error => {
                    dispatch({ type: 'SYNONYM_SAVE_UNIT_VALUES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function filter (id = null, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        SynonymService.filter(id, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}
