import { HttpService } from '../../App/services/http'

export const ItemService = {
    items,
    analysis,
    count,
    offers,
    add,
    save,
    union,
    matching,
    cache,
    remove,
    sources,
    changes,
    upload
}

function items (params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/items`, options, true)
        .then(response => {
            return response
        })
}

function analysis (params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/analysis`, options, true)
        .then(response => {
            return response
        })
}

function count (params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/items/count`, options, true)
        .then(response => {
            return response
        })
}

function offers (params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/items/offers`, options, true)
        .then(response => {
            return response
        })
}

function add (values) {
    const options = {
        method: 'POST',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/item`, options, true)
        .then(response => {
            return response.data
        })
}

function save (id, values) {
    const options = {
        method: 'PUT',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/item/${id}`, options, true)
        .then(response => {
            return response.data
        })
}

function union (values) {
    const options = {
        method: 'PUT',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/items/union`, options, true)
        .then(response => {
            return response
        })
}

function matching (id) {
    const options = {
        method: 'GET'
    }

    return HttpService.http(`/item/${id}/matching`, options, true)
        .then(response => {
            return response
        })
}

function cache (values) {
    const options = {
        method: 'POST',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/matching/cache`, options, true)
        .then(response => {
            return response
        })
}

function remove (id, params) {
    const options = {
        method: 'DELETE',
        body: JSON.stringify(params)
    }

    return HttpService.http(`/item/${id}`, options, true)
        .then(response => {
            return response
        })
}

function sources (params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/items/sources`, options, true)
        .then(response => {
            return response
        })
}

function changes (id, params = null) {
    const options= {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/item/${id}/changes`, options, true)
        .then(response => {
            return response
        })
}

function upload(id, formData) {
    const options = {
        method: "POST",
        body: formData
    }

    return HttpService.http(`/category/${id}/items`, options, true, true)
        .then(response => {
            return response.file
        })
}
