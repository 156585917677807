import {useEffect} from "react"
import {useDispatch} from "react-redux"

import {AuthActions} from "./actions/authentication"

export const Logout = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(AuthActions.logout())
    })

    return null
}
