import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Formik, Field, Form, FieldArray, useFormikContext} from 'formik'
import * as Yup from "yup"

import {
    Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Grid, IconButton,
    makeStyles, Typography, MenuItem, FormControlLabel
} from '@material-ui/core'
import {Add, Clear, Close} from '@material-ui/icons'
import {
    Switch,
    TextField
} from 'formik-material-ui'
import {DictionaryActions} from "../../../actions/dictionary";
import {useDebounce} from "use-debounce";
import {FieldDosage} from "../../../../Category/components/Attribute/FieldDosage";
import {FieldSize} from "../../../../Category/components/Attribute/FieldSize";
import {FieldCompositeSize} from "../../../../Category/components/Attribute/FieldCompositeSize";
import {FieldVolume} from "../../../../Category/components/Attribute/FieldVolume";
import {FieldCompositeVolume} from "../../../../Category/components/Attribute/FieldCompositeVolume";
import {FieldNumberDefinitions} from "../../../../Category/components/Attribute/FieldNumberDefinitions";
import {FieldNeedle} from "../../../../Category/components/Attribute/FieldNeedle";
import {FieldString} from "../../../../Category/components/Attribute/FieldString";
import {AttributeActions} from "../../../../Item/actions/attribute";
import {DictionaryAttributeActions} from "../../../actions/attribute";
import {getValue} from "../../../../Category/components/Attribute/helpers/value";

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    }
}))

export const ValueForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {categories, units} = useSelector(state => {
        const { categories } = state.dictionary
        const { units } = state.unit

        return {
            categories, units: units.data
        }
    })

    const {open, handleClose, handleSave, handleReject, dictionary, value, callback} = props
    const [items, setItems] = useState([])
    const [dictionaries, setDictionaries] = useState([])
    const [combine, setCombine] = useState(null)
    const [reject, setReject] = useState(false)
    const [search, setSearch] = useState({search: null, type: null, attribute: null, item: null, listeners: {}})
    const [searchRequest] = useDebounce(search, 700)
    const [loading, setLoading] = useState(false)

    const getDictionaries = async (category) => {
        return await dispatch(DictionaryActions.dictionaries({
            ...(category ? {category: category} : {})
        }))
    }

    const composite = (type) => {
        switch (type) {
            case 'volume':
            case 'composite_volume':
            case 'size':
            case 'composite_size':
            case 'dosage':
            case 'number_definitions':
            case 'needle':
                return true
            default:
                return false
        }
    }

    const getAttribute = (attribute, items= [], value= [], errors= [], setFieldValue, setFieldTouched, setSearch = () => {}, isSubmitting= false, disabled = false) => {
        switch (attribute.type.key) {
            case 'volume':
                return <FieldVolume
                    name="params"
                    id={ attribute.id }
                    label={ attribute.name }
                    items={ items }
                    value={ value }
                    errors={ errors }
                    setFieldValue={ setFieldValue }
                    setFieldTouched={ setFieldTouched }
                    setSearch={ setSearch }
                    isSubmitting={ isSubmitting }
                    disabled={disabled}
                />
            case 'composite_volume':
                return <FieldCompositeVolume
                    name="params"
                    id={ attribute.id }
                    attribute={ attribute }
                    label={ attribute.name }
                    items={ items }
                    value={ value }
                    errors={ errors }
                    setFieldValue={ setFieldValue }
                    setFieldTouched={ setFieldTouched }
                    setSearch={ setSearch }
                    isSubmitting={ isSubmitting }
                    disabled={disabled}
                />
            case 'size':
                return <FieldSize
                    name="params"
                    id={ attribute.id }
                    label={ attribute.name }
                    items={ items }
                    value={ value }
                    errors={ errors }
                    setFieldValue={ setFieldValue }
                    setFieldTouched={ setFieldTouched }
                    setSearch={ setSearch }
                    isSubmitting={ isSubmitting }
                    disabled={disabled}
                />
            case 'composite_size':
                return <FieldCompositeSize
                    name="params"
                    id={ attribute.id }
                    attribute={ attribute }
                    label={ attribute.name }
                    items={ items }
                    value={ value }
                    errors={ errors }
                    setFieldValue={ setFieldValue }
                    setFieldTouched={ setFieldTouched }
                    setSearch={ setSearch }
                    isSubmitting={ isSubmitting }
                    disabled={disabled}
                />
            case 'dosage':
                return <FieldDosage
                    name="params"
                    id={ attribute.id }
                    label={ attribute.name }
                    items={ items }
                    value={ value }
                    errors={ errors }
                    setFieldValue={ setFieldValue }
                    setFieldTouched={ setFieldTouched }
                    setSearch={ setSearch }
                    isSubmitting={ isSubmitting }
                    disabled={disabled}
                />
            case 'number_definitions':
                return <FieldNumberDefinitions
                    name="params"
                    id={ attribute.id }
                    label={ attribute.name }
                    items={ items }
                    value={ value }
                    errors={ errors }
                    setFieldValue={ setFieldValue }
                    setFieldTouched={ setFieldTouched }
                    setSearch={ setSearch }
                    isSubmitting={ isSubmitting }
                    disabled={disabled}
                />
            case 'needle':
                return <FieldNeedle
                    name="params"
                    id={ attribute.id }
                    label={ attribute.name }
                    items={ items }
                    value={ value }
                    errors={ errors }
                    setFieldValue={ setFieldValue }
                    setFieldTouched={ setFieldTouched }
                    setSearch={ setSearch }
                    isSubmitting={ isSubmitting }
                    disabled={disabled}
                />
            default:
                return null
        }
    }

    const getParams = (type) => {
        switch (type) {
            case 'volume':
                return {
                    value: '',
                    volume: '',
                    weight: '',
                    dose: {
                        value: '',
                        max: ''
                    },
                    unit: '',
                    delimiter: '',
                    symbol: ''
                }
            case 'composite_volume':
                return {
                    value: '',
                    prefix: '',
                    values: [
                        {
                            amount: '',
                            delimiter: '',
                            volume: '',
                            weight: '',
                            dose: '',
                            unit: '',
                            space: ''
                        }
                    ],
                    postfix: ''
                }
            case 'size':
                return {
                    value: '',
                    width: '',
                    height: '',
                    depth: {
                        value: '',
                        max: ''
                    },
                    unit: '',
                    delimiter: '',
                    symbol: ''
                }
            case 'composite_size':
                return {
                    value: '',
                    prefix: '',
                    amount: '',
                    size: {
                        range: '',
                        charrier: ''
                    },
                    space: '',
                    diameter: {
                        outer: {
                            value: '',
                            unit: '',
                            delimiter: ''
                        },
                        inner: {
                            value: '',
                            unit: '',
                            delimiter: ''
                        }
                    },
                    lengths: [
                        {
                            value: '',
                            unit: '',
                            delimiter: ''
                        }
                    ],
                    width: {
                        value: '',
                        unit: '',
                        delimiter: ''
                    },
                    metric: '',
                    height: '',
                    thickness: '',
                    unit: '',
                    gauge: '',
                    postfix: ''
                }
            case 'dosage':
                return {
                    value: '',
                    prefix: '',
                    values: [
                        {
                            amount: '',
                            unit: '',
                            delimiter: ''
                        }
                    ],
                    volume: {
                        value: '',
                        unit: '',
                        delimiter: ''
                    },
                    description: '',
                    initial: ''
                }
            case 'number_definitions':
                return {
                    value: '',
                    values: [
                        {
                            amount: '',
                            definitions: '',
                            unit: '',
                            delimiter: ''
                        }
                    ]
                }
            case 'needle':
                return {
                    value: '',
                    information: '',
                    amount: '',
                    gauge: {
                        value: '',
                        additional: ''
                    },
                    diameter: {
                        value: '',
                        delimiter: ''
                    },
                    length: {
                        value: '',
                        unit: '',
                        delimiter: ''
                    },
                    bend: '',
                    description: ''
                }
            default:
                return {}
        }
    }

    useEffect(() => {
        if (open) {
            const getSources = async () => {
                return await dispatch(DictionaryActions.sources())
            }

            if (dictionary) {
                switch (dictionary.key) {
                    case 'sources':
                        setLoading(true)
                        break
                    case 'black_lists':
                        if (value && value.dictionary.attribute) {
                            getDictionaries(value.dictionary.attribute.category.id).then(items => {
                                setDictionaries(items)
                            })
                        }
                    // eslint-disable-next-line no-fallthrough
                    default:
                        getSources().then(items => {
                            setItems(items)
                            setLoading(true)
                        })
                }
            }
        }
    }, [open])

    useEffect(() => {
        if (search.type) {
            if (search.search) {
                dispatch(DictionaryAttributeActions.items(search.type, {...{search: search.search, status: ['active']}, ...{limit: 20}}))
            }
        } else if (search.attribute) {
            if (search.search) {
                dispatch(AttributeActions.items(search.attribute, {...{search: search.search}, ...{limit: 20}}))
            }
        }
    }, [searchRequest])

    useEffect(() => {
        if (search.type) {
            if (!search.search) {
                dispatch({type: 'DICTIONARY_ATTRIBUTE_ITEMS_CLEAR'})
            }
        } else if (search.attribute) {
            if (!search.search) {
                dispatch({type: 'ATTRIBUTE_ITEMS_CLEAR'})
            }
        }
    }, [search])

    const Listener = () => {
        const { values, dirty, setFieldValue } = useFormikContext()

        useEffect(async () => {
            if (dirty && composite(dictionary?.attribute?.type.key)) {
                setFieldValue('value', getValue(dictionary?.attribute, values.params))
            }
        }, [values])

        return null
    }

    return (
        <Formik
            initialValues={{
                ...((dictionary.key === 'black_lists') ? {
                    category: (value && value.dictionary.attribute) ? value.dictionary.attribute.category.id : '',
                    dictionary: (value && value.dictionary.attribute) ? value.dictionary.id : ''
                } : {}),
                value: value ? value.value : '',
                ...(composite(dictionary?.attribute?.type.key) ? {params: value?.params ? JSON.parse(value.params.replace(new RegExp('"null"|null', 'g'), '""')) : getParams(dictionary?.attribute?.type.key)} : {}),
                active: value && value.confirmed ? value.active : true,
                ...((dictionary.key !== 'sources') ? {pipeline: (value && value.pipeline) ? value.pipeline.id : ''} : {}),
                ...((dictionary.hasOwnProperty('attributes') && dictionary.attributes.length) ? {attributes: dictionary.attributes.reduce((result, attribute) => {
                    switch (attribute.type) {
                        case 'one':
                            result[attribute.id] = value && value.items.find(item => { return item.attribute.id === attribute.id }) ? value.items.find(item => { return item.attribute.id === attribute.id }).value : ''
                            break
                        case 'many':
                            result[attribute.id] = value ? value.items.filter(item => { return item.attribute.id === attribute.id }) : []
                            break
                    }
                    return result
                }, {})} : {}),
                block: false
            }}
            validationSchema={Yup.object().shape({
                ...((dictionary.key === 'black_lists') ? {
                    category: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    dictionary: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                } : {}),
                value: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                active: Yup.boolean(),
                ...(((dictionary.key !== 'sources') && !reject) ? {pipeline: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")} : {}),
                ...((dictionary.hasOwnProperty('attributes') && dictionary.attributes.length && !reject) ? {attributes: Yup.object().shape(dictionary.attributes.reduce((result, attribute) => {
                    switch (attribute.type) {
                        case 'one':
                            result[attribute.id] = attribute.required ? Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!") : Yup.string()
                            break
                        case 'many':
                            result[attribute.id] = attribute.required ? Yup.array().of(Yup.object().shape({
                                value: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!")
                            })) : Yup.array()
                            break
                    }
                    return result
                }, {}))} : {}),
                block: Yup.boolean()
            })}
            onSubmit={(values, {setSubmitting, setErrors}) => {
                if (combine) {
                    values.combine = true
                }

                if (reject) {
                    handleReject(values, value.id).then(
                        () => {
                            setSubmitting(false)
                            dispatch(handleClose)
                        },
                        error => {
                            setErrors(error)
                            setSubmitting(false)
                        }
                    )
                } else {
                    handleSave(values, value ? value.id : null).then(
                        (item) => {
                            setSubmitting(false)
                            if (callback) {
                                callback(item)
                            }
                            dispatch(handleClose)
                        },
                        error => {
                            setErrors(error)
                            if (error.hasOwnProperty('type')) {
                                switch (error.type) {
                                    case 'combine':
                                        setCombine({message: error.message})
                                        break;
                                }
                            }
                            setSubmitting(false)
                        }
                    )
                }
            }}
        >
            {({
                  values,
                  errors,
                  handleSubmit,
                  isSubmitting,
                  setSubmitting,
                  setValues,
                  setTouched,
                  setFieldValue,
                  setFieldTouched
              }) => (
                <Form>
                    <Dialog
                        fullWidth={true}
                        maxWidth="md"
                        open={loading}
                        onClose={handleClose}
                        classes={{
                            paper: classes.dialog
                        }}
                    >
                        <DialogTitle>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item>
                                    {(value ? (reject ? 'Отклонить' : (!!combine ? 'Объединить' : (value.confirmed ? 'Редактировать' : 'Подтвердить'))) : 'Добавить')}
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label="Закрыть"
                                        onClick={() => handleClose()}
                                    >
                                        <Close/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            {reject ?
                                <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                    <Grid item sm={10} className={classes.fullWidth}>
                                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                            <Grid item sm={12} className={classes.fullWidth}>
                                                <Typography>Вы действительно хотите отклонить значение?</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={10} className={classes.fullWidth}>
                                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                            <Grid item sm={6} className={classes.fullWidth}>
                                                <Field
                                                    fullWidth
                                                    name="value"
                                                    type="text"
                                                    label={dictionary.value ?? (dictionary.attribute ? dictionary.attribute.name : 'Значение')}
                                                    required
                                                    disabled
                                                    component={TextField}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={10} className={classes.fullWidth}>
                                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                            <Grid item sm={6} className={classes.fullWidth}>
                                                <FormControlLabel
                                                    control={
                                                        <Field
                                                            name="block"
                                                            label="Добавить в чёрный список"
                                                            component={ Switch }
                                                        />
                                                    }
                                                    label="Добавить в чёрный список"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid> : (combine ?
                                <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                    <Grid item sm={10} className={classes.fullWidth}>
                                        <Typography>{combine.message}</Typography>
                                    </Grid>
                                </Grid> :
                                <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                    {(dictionary.key === 'black_lists') &&
                                        <Grid item sm={10} className={classes.fullWidth}>
                                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                                <Grid item sm={6} className={classes.fullWidth}>
                                                    <Field
                                                        fullWidth
                                                        type="text"
                                                        name="category"
                                                        label="Категория"
                                                        select
                                                        required
                                                        variant="standard"
                                                        disabled={ isSubmitting }
                                                        component={ TextField }
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        InputProps={{
                                                            onChange: (e) => {
                                                                const category = e.target.value;
                                                                getDictionaries(category).then(values => {
                                                                    setFieldValue('category', category)
                                                                    setDictionaries(values)
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        {categories.map(category => (
                                                            <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                                        ))}
                                                    </Field>
                                                </Grid>
                                                <Grid item sm={6} className={classes.fullWidth}>
                                                    <Field
                                                        fullWidth
                                                        type="text"
                                                        name="dictionary"
                                                        label="Атрибут"
                                                        select
                                                        required
                                                        variant="standard"
                                                        disabled={ isSubmitting || !values.category }
                                                        component={ TextField }
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                    >
                                                        {dictionaries.map(dictionary => (
                                                            <MenuItem key={dictionary.id} value={dictionary.id}>{dictionary.attribute.name}</MenuItem>
                                                        ))}
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid item sm={10} className={classes.fullWidth}>
                                        <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                            <Grid item sm={8} className={classes.fullWidth}>
                                                {composite(dictionary?.attribute?.type.key) ?
                                                    <Grid container direction='column' justify='flex-start' alignItems='center' spacing={2}>
                                                        <Listener />
                                                        <Grid item className={classes.fullWidth}>
                                                            <Field
                                                                fullWidth
                                                                name="value"
                                                                type="text"
                                                                label={dictionary.value ?? (dictionary.attribute ? dictionary.attribute.name : 'Значение')}
                                                                required
                                                                disabled
                                                                component={TextField}
                                                            />
                                                        </Grid>
                                                        <Grid item className={classes.fullWidth}>
                                                            {getAttribute(
                                                                dictionary?.attribute,
                                                                units,
                                                                values.params,
                                                                errors,
                                                                setFieldValue,
                                                                setFieldTouched,
                                                                setSearch,
                                                                isSubmitting
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    : <Field
                                                        fullWidth
                                                        name="value"
                                                        type="text"
                                                        label={dictionary.value ?? (dictionary.attribute ? dictionary.attribute.name : 'Значение')}
                                                        required
                                                        disabled={isSubmitting}
                                                        component={TextField}
                                                    />
                                                }
                                            </Grid>
                                            {(dictionary.key !== 'sources') &&
                                                <Grid item sm={4} className={classes.fullWidth}>
                                                    <Field
                                                        fullWidth
                                                        type="text"
                                                        name="pipeline"
                                                        label="Источник"
                                                        select
                                                        required
                                                        variant="standard"
                                                        disabled={ isSubmitting }
                                                        component={ TextField }
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                    >
                                                        {items.map(item => (
                                                            <MenuItem key={item.id} value={item.id}>{item.value}</MenuItem>
                                                        ))}
                                                    </Field>
                                                </Grid>
                                            }
                                            {dictionary.hasOwnProperty('attributes') && dictionary.attributes.map(attribute => {
                                                switch (attribute.type) {
                                                    case 'one':
                                                        return <Grid key={attribute.id} item sm={8} className={classes.fullWidth}>
                                                            <FieldString
                                                                name={`attributes[${attribute.id}]`}
                                                                id={ attribute.id }
                                                                label={ attribute.name }
                                                                attribute={ attribute }
                                                                value={ values.attributes[attribute.id] }
                                                                values={ values.attributes }
                                                                setFieldValue={ setFieldValue }
                                                                setFieldTouched={ setFieldTouched }
                                                                setSearch={ setSearch }
                                                                isSubmitting={ isSubmitting }
                                                            />
                                                        </Grid>
                                                    case 'many':
                                                        return <Grid key={attribute.id} item sm={12} className={classes.fullWidth}>
                                                            <FieldArray
                                                                name={`attributes[${attribute.id}]`}
                                                                render={ arrayOptions => (
                                                                    <Grid container direction='column' justify='space-between' alignItems='center' spacing={2}>
                                                                        {values.attributes[attribute.id].map((option, key) => (
                                                                            <Grid item key={key} className={classes.fullWidth}>
                                                                                <Grid container direction='row' justify='flex-start' alignItems='center' spacing={2}>
                                                                                    <Grid item sm={7} className={classes.fullWidth}>
                                                                                        <FieldString
                                                                                            name={`attributes[${attribute.id}][${key}].value`}
                                                                                            id={ attribute.id }
                                                                                            label={ attribute.name }
                                                                                            attribute={ attribute }
                                                                                            value={ values.attributes[attribute.id][key].value }
                                                                                            values={ values.attributes }
                                                                                            setFieldValue={ setFieldValue }
                                                                                            setFieldTouched={ setFieldTouched }
                                                                                            setSearch={ setSearch }
                                                                                            isSubmitting={ isSubmitting }
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item sm={1} className={classes.fullWidth}>
                                                                                        <IconButton
                                                                                            onClick={() => arrayOptions.remove(key)}
                                                                                            color="primary"
                                                                                            aria-label="Удалить"
                                                                                            component="span"
                                                                                        >
                                                                                            <Clear/>
                                                                                        </IconButton>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        ))}
                                                                        <Grid item className={classes.fullWidth}>
                                                                            <IconButton
                                                                                onClick={() => arrayOptions.push({value: ''})}
                                                                                color={'primary'}
                                                                                aria-label="Добавить"
                                                                                component="span"
                                                                            >
                                                                                <Add />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                            />
                                                        </Grid>
                                                }
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={10} className={classes.fullWidth}>
                                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                            <Grid item sm={6} className={classes.fullWidth}>
                                                <FormControlLabel
                                                    control={
                                                        <Field
                                                            name="active"
                                                            label="Активный"
                                                            component={ Switch }
                                                        />
                                                    }
                                                    label="Активный"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>)
                            }
                        </DialogContent>
                        <DialogActions>
                            {(value && ((!value.confirmed && (dictionary.key !== 'black_lists')) || combine)) &&
                                <Button
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        if (combine) {
                                            setCombine(null)
                                        } else {
                                            setFieldValue('value', value ? value.value : '')
                                            setReject(!reject)
                                        }
                                    }}
                                    color="secondary"
                                    type="button"
                                >
                                    {(isSubmitting ? <CircularProgress size={24}/> : ((combine || reject) ? 'Отмена' : 'Отклонить'))}
                                </Button>
                            }
                            <Button
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                                color={(!value || value.confirmed || (dictionary.key === 'black_lists')) ? 'primary' : 'default'}
                                type="submit"
                            >
                                {(isSubmitting ? <CircularProgress size={24}/> : (value ? (reject ? 'Отклонить' : (combine ? 'Объединить' : ((value.confirmed || (dictionary.key === 'black_lists')) ? 'Сохранить' : 'Подтвердить'))) : 'Добавить'))}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    )
}
