import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
    Grid,
    Typography,
    Checkbox,
    TableContainer,
    IconButton,
    makeStyles,
    Popover, Divider, Button, Tooltip, Fade, Box, TextField
} from '@material-ui/core'
import {blue, red} from "@material-ui/core/colors"
import {FilterList, Sort} from '@material-ui/icons'

import {AutoSizer, InfiniteLoader, Table} from "react-virtualized"
import {Skeleton} from "@material-ui/lab"
import {SynonymActions} from "../../../../actions/category/synonym";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    fullHeight: {
        'height': '100%'
    },
    popover: {
        'width': '300px'
    },
    filter: {
        'width': '100%'
    },
    filterItems: {
        'width': '100%',
        'height': 'calc(100% - 158px)',
        'overflow': 'auto'
    },
    filterItem: {
        'white-space': 'normal !important'
    },
    field: {
        'height': '100%'
    },
    data: {
        'height': 'calc(100% - 60px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '40px'
    },
    table: {
        'height': '100%'
    },
    fab: {
        'margin': '0',
        'top': 'auto',
        'right': '90px',
        'bottom': '25px',
        'left': 'auto',
        'position': 'fixed'
    },
    activeCategory: {
        'background-color': red[100],
        '&:hover': {
            'background-color': red[50] + '!important',
        }
    },
    defaultCategory: {
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    active: {
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    confirmation: {
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    default: {
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    visuallyHidden: {
        "border": "0",
        "clip": "rect(0 0 0 0)",
        "height": "1",
        "margin": "-1",
        "overflow": "hidden",
        "padding": "0",
        "position": "absolute",
        "top": "20",
        "width": "1",
    },
    attributes: {
        '& .MuiSelect-selectMenu': {
            'padding-top': '9px'
        }
    },
    body: {
        'height': '100%'
    },
    item: {
        'height': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    },
    sortLabelRoot: {
        maxWidth: '100%',
    },
    sortLabelRight: {
        flexDirection: 'row-reverse',
    },
    sortLabelActive: {
        color: 'inherit',
    },
    toolbar: {
        'padding-left': '0 !important'
    },
    actions: {
        'margin-left': '0 !important'
    },
    filterContent: {
        width: '100%',
        padding: theme.spacing(2)
    },
    divider: {
        width: '100%'
    }
}))

export const Filter = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {filter} = useSelector(state => { return {filter: state.synonym.filter} })

    const {name, category} = props
    const [loading, setLoading] = useState(false)
    const [initialize, setInitialize] = useState(false)
    const [additive, setAdditive] = useState(false)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [values, setValues] = useState([])
    const [selected, setSelected] = useState(filter.hasOwnProperty(name) ? filter[name] : [])
    const [search, setSearch] = useState(null)
    const [sort, setSort] = useState({name: name, direction: (filter.sort.name === name) ? filter.sort.direction : null})
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const getValues = async (name, page = 1) => {
        setPage(page)

        return await dispatch(SynonymActions.filter(category.id, {
            name: name,
            page: page,
            limit: 20,
            ...(search ? {search: search} : {})
        }))
    }

    useEffect(() => {
        if (open) {
            switch (name) {
                case 'value':
                case 'option':
                case 'source':
                    getValues(name).then(response => {
                        setValues(response.data)
                        setTotal(response.meta.total)
                        setLoading(true)
                        setInitialize(true)
                    })
                    break
                case 'active':
                    setValues([
                        {id: 'active', value: 'Активный'},
                        {id: 'inactive', value: 'Неактивный'}
                    ])
                    setTotal(2)
                    setLoading(true)
                    setInitialize(true)
                    break
                default:
                    setLoading(true)
                    setInitialize(true)
            }

            return () => {
                setInitialize(false)
                setLoading(false)
                setSearch(null)
                setSelected([])
                setSort({name: null, direction: null})
            }
        }
    }, [open, name])

    useEffect(() => {
        if (!loading && initialize) {
            switch (name) {
                case 'value':
                case 'option':
                case 'source':
                    getValues(name, 1).then(response => {
                        setValues(response.data)
                        setTotal(response.meta.total)
                        setLoading(true)
                    })
                    break
                default:
                    setLoading(true)
            }
        }
    }, [dispatch, loading, name, initialize])

    const hasFilter = (name) => {
        switch (name) {
            case 'value':
            case 'option':
            case 'active':
            case 'source':
                return true
            default:
                return false
        }
    }

    return (
        <React.Fragment>
            <IconButton
                size="small"
                onClick={(event) => {
                    setSort({name: name, direction: (filter.sort.name === name) ? filter.sort.direction : null})
                    setSelected(filter.hasOwnProperty(name) ? filter[name] : [])
                    setAnchorEl(event.currentTarget)
                }}
                color={((filter.sort.name === name) || (filter.hasOwnProperty(name) && !!filter[name].length)) ? 'secondary' : 'default'}
            >
                <FilterList />
            </IconButton>
            {open ? <Popover
                classes={{
                    paper: classes.popover
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setAnchorEl(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.filterContent}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item>
                                <Typography variant="caption">
                                    Сортировка
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                                    <Grid item>
                                        <Button
                                            color="default"
                                            onClick={() => {
                                                const direction = ((sort.direction === 'asc') ? null : 'asc')
                                                setSort({...sort, ...{direction: direction}})
                                                dispatch({type: 'SYNONYM_FILTER', payload: {...filter, ...{sort: {...sort, ...{name: direction ? sort.name : null, direction: direction}}}}})
                                            }}
                                            startIcon={<Sort color={(sort.direction === 'asc') ? 'secondary' : 'action'} style={{transform: 'rotatex(180deg)'}} />}
                                        >
                                            <Typography component="span" variant="body2" style={{textTransform: "none"}}>по возрастанию (А-Я)</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="default"
                                            onClick={() => {
                                                const direction = ((sort.direction === 'desc') ? null : 'desc')
                                                setSort({...sort, ...{direction: direction}})
                                                dispatch({type: 'SYNONYM_FILTER', payload: {...filter, ...{sort: {...sort, ...{name: direction ? sort.name : null, direction: direction}}}}})
                                            }}
                                            startIcon={<Sort color={(sort.direction === 'desc') ? 'secondary' : 'action'} />}
                                        >
                                            <Typography component="span" variant="body2" style={{textTransform: "none"}}>по убыванию (Я-А)</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {hasFilter(name) &&
                        <React.Fragment>
                            <Divider className={classes.divider}/>
                            <Grid item className={classes.filterContent}>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid item>
                                        <Typography variant="caption">
                                            Фильтр
                                        </Typography>
                                    </Grid>
                                    {(name !== 'active') &&
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container direction="column" justify="flex-start" alignItems="stretch">
                                                <Grid item>
                                                    <TextField
                                                        fullWidth
                                                        label='Поиск'
                                                        value={search ?? ''}
                                                        onChange={(event) => {
                                                            const value = event.target.value

                                                            setSearch(!!value.length ? value : null)
                                                            setLoading(false)
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid item className={classes.fullWidth}>
                                        <TableContainer style={{height: '250px'}}>
                                            {loading ?
                                                <InfiniteLoader
                                                    isRowLoaded={index => !!values[index]}
                                                    loadMoreRows={(start, stop) => {
                                                        if (!additive && (start.stopIndex >= values.length)) {
                                                            setAdditive(true)
                                                            switch (name) {
                                                                case 'value':
                                                                case 'option':
                                                                case 'source':
                                                                    getValues(name, page + 1).then(response => {
                                                                        setAdditive(false)
                                                                        setValues([...values, ...response.data])
                                                                        setTotal(response.meta.total)
                                                                    })
                                                                    break
                                                                default:
                                                            }
                                                        }
                                                    }}
                                                    rowCount={total}
                                                    threshold={10}
                                                >
                                                    {({ onRowsRendered, registerChild }) => (
                                                        <AutoSizer disableHeight>
                                                            {({width}) =>
                                                                <Table
                                                                    ref={registerChild}
                                                                    rowHeight={42}
                                                                    rowCount={values.length}
                                                                    width={width}
                                                                    height={250}
                                                                    headerHeight={0}
                                                                    rowGetter={({ index }) => values[index]}
                                                                    onRowsRendered={onRowsRendered}
                                                                    rowRenderer={({rowData, style}) => {
                                                                        const isItemSelected = (selected.indexOf(rowData.id) !== -1)

                                                                        let value = rowData.value

                                                                        return (
                                                                            <Tooltip key={rowData.id} TransitionComponent={Fade} title={value} placement="bottom-end">
                                                                                <Grid
                                                                                    container
                                                                                    direction="row"
                                                                                    justify="flex-start"
                                                                                    alignItems="center"
                                                                                    spacing={2}
                                                                                    style={{...style, ...{cursor: 'pointer'}}}
                                                                                    onClick={(event) => {
                                                                                        const selectedIndex = selected.indexOf(rowData.id)

                                                                                        let select = []

                                                                                        if (selectedIndex === -1) {
                                                                                            select = select.concat(selected, rowData.id)
                                                                                        } else if (selectedIndex === 0) {
                                                                                            select = select.concat(selected.slice(1))
                                                                                        } else if (selectedIndex === selected.length - 1) {
                                                                                            select = select.concat(selected.slice(0, -1))
                                                                                        } else if (selectedIndex > 0) {
                                                                                            select = select.concat(
                                                                                                selected.slice(0, selectedIndex),
                                                                                                selected.slice(selectedIndex + 1),
                                                                                            )
                                                                                        }

                                                                                        setSelected(select)
                                                                                    }}
                                                                                >
                                                                                    <Grid item xs={2}>
                                                                                        <Checkbox color="primary" checked={isItemSelected}/>
                                                                                    </Grid>
                                                                                    <Grid item xs={10}>
                                                                                        <Typography color="inherit" variant="body2" noWrap>{value}</Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Tooltip>
                                                                        )
                                                                    }}
                                                                />
                                                            }
                                                        </AutoSizer>
                                                    )}
                                                </InfiniteLoader> : <Box>
                                                    <Skeleton height={42} animation="wave" />
                                                    <Skeleton height={42} animation="wave" />
                                                    <Skeleton height={42} animation="wave" />
                                                    <Skeleton height={42} animation="wave" />
                                                    <Skeleton height={42} animation="wave" />
                                                    <Skeleton height={42} animation="wave" />
                                                </Box>
                                            }
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.filterContent}>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                                    <Grid item className={classes.fullWidth}>
                                        <Grid container direction="row" justify="space-between" alignItems="flex-start">
                                            <Grid item>
                                                <Button
                                                    size="small"
                                                    color="secondary"
                                                    type="button"
                                                    onClick={() => {
                                                        setSort({...sort, ...{direction: null}})
                                                        setSelected([])
                                                        dispatch({type: 'SYNONYM_FILTER', payload: {...filter, ...{sort: (filter.sort.name === name) ? {name: null, direction: null} : filter.sort, [name]: []}}})
                                                    }}
                                                >
                                                    Сбросить
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    size="small"
                                                    color="primary"
                                                    type="button"
                                                    onClick={() => {
                                                        dispatch({type: 'SYNONYM_FILTER', payload: {...filter, ...{[name]: selected}}})
                                                        setAnchorEl(null)
                                                    }}
                                                >
                                                    Применить
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </Popover> : null}
        </React.Fragment>
    )
}
