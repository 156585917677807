import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"

import {
    Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Fab, makeStyles
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { RoleActions } from "./actions/role"
import { RoleForm } from "./components/RoleForm"
import { PermissionActions } from "./actions/permission"

const useStyles = makeStyles(theme => ({
    field: {
        'height': '100%'
    },
    data: {
        'height': 'calc(100% - 52px)',
        'width': '100%'
    },
    item: {
        'width': '100%'
    },
    table: {
        'height': '100%'
    },
    fab: {
        'margin': '0',
        'top': 'auto',
        'right': '90px',
        'bottom': '25px',
        'left': 'auto',
        'position': 'fixed'
    }
}))

const columns = [
    {
        id: 'name',
        label: 'Наименование',
        align: 'left',
        format: value => value.toLocaleString()
    },
    {
        id: 'key',
        label: 'Уникальный ключ',
        align: 'left',
        format: value => value.toLocaleString()
    }
]

export const Role = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {roles} = useSelector(state => state.role)
    const [role, setRole] = useState(null)
    const [dialog, setDialog] = useState(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [loading, setLoading] = useState(false)
    const [initialize, setInitialize] = useState(false)

    useEffect(() => {
        const getRoles = async () => {
            if (!initialize) {
                await dispatch(PermissionActions.permissions())
            }
            return await dispatch(RoleActions.roles({page: page + 1, limit: rowsPerPage}))
        }

        if (!loading) {
            getRoles().then(_ => {
                setLoading(true)
                setInitialize(true)
            })
        }
    }, [dispatch, initialize, loading, page, rowsPerPage])

    const handleDelete = (id) => {
        return dispatch(RoleActions.remove(id)).then(
            () => {
                setLoading(false)
            }
        )
    }

    const handleSave = (values, id = null) => {
        if (id) {
            return dispatch(RoleActions.save(id, values))
        } else {
            return dispatch(RoleActions.add(values)).then(
                () => {
                    setLoading(false)
                }
            )
        }
    }

    const handleClose = () => {
        setRole(null)
        setDialog(false)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        setLoading(false)
    }

    const handleChangeRowsPerPage = (event) => {
        setPage(0)
        setRowsPerPage(+event.target.value)
        setLoading(false)
    }

    return (
        <Grid container direction="column" justify="space-between" alignItems="center" className={classes.field}>
            <Grid item className={classes.data}>
                <TableContainer className={classes.table}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map(column => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {roles.data.map(item => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={item.id}
                                        onClick={() => {
                                            setRole(item)
                                            setDialog(true)
                                        }}
                                    >
                                        <TableCell align="left">
                                            { item.description }
                                        </TableCell>
                                        <TableCell align="left">
                                            { item.name }
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item className={classes.item}>
                <TablePagination
                    rowsPerPageOptions={ [10, 25, 100] }
                    component="div"
                    count={ roles.data.length ? roles.meta.total : 0 }
                    rowsPerPage={ rowsPerPage }
                    page={ page }
                    onChangePage={ handleChangePage }
                    onChangeRowsPerPage={ handleChangeRowsPerPage }
                />
            </Grid>
            <Fab size="medium" color="primary" aria-label="Добавить" className={ classes.fab } onClick={() => { setDialog(true) }}>
                <AddIcon />
            </Fab>
            { dialog && <RoleForm role={ role } open={ dialog } handleClose={ handleClose } handleDelete={ handleDelete } handleSave={ handleSave } /> }
        </Grid>
    )
}
