import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
    Grid,
    ListItemIcon,
    ListItemText,
    Typography,
    Select,
    MenuItem,
    TextField,
    Badge,
    Breadcrumbs,
    IconButton,
    makeStyles,
    withStyles
} from '@material-ui/core'
import {blue, red} from "@material-ui/core/colors"
import {ExpandLess, ExpandMore, GetApp} from '@material-ui/icons'
import {
    PagingState, CustomPaging
} from '@devexpress/dx-react-grid'
import {
    DragDropProvider, Table, TableHeaderRow , TableColumnResizing, PagingPanel,
    TableColumnReordering, TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui'

import {SystemActions} from "../App/actions/system"
import {ItemActions} from "./actions/item"
import {useDebouncedCallback} from 'use-debounce'
import {StickyTable} from "../App/components/Table/StikyTable"
import {Grid as GridTable} from "../App/components/Table/Grid"
import {Pager} from "../App/components/Table/Paging/Pager"
import {standard} from "../App/helpers/standard"
import {TableHeaderContent} from "./components/Table/TableHeaderContent"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import {DownloadActions} from "../Download/actions/download";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    fullHeight: {
        'height': '100%'
    },
    filter: {
        'width': '100%'
    },
    filterItems: {
        'width': '100%',
        'height': 'calc(100% - 158px)',
        'overflow': 'auto'
    },
    filterItem: {
        'white-space': 'normal !important'
    },
    field: {
        'height': '100%'
    },
    data: {
        'height': 'calc(100% - 72px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '52px'
    },
    table: {
        'height': '100%'
    },
    fab: {
        'margin': '0',
        'top': 'auto',
        'right': '90px',
        'bottom': '25px',
        'left': 'auto',
        'position': 'fixed'
    },
    activeCategory: {
        'background-color': red[50],
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    defaultCategory: {
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    active: {
        'height': '41px',
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    inactive: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    confirmation: {
        'height': '41px',
        'background-color': red[50],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    visuallyHidden: {
        "border": "0",
        "clip": "rect(0 0 0 0)",
        "height": "1",
        "margin": "-1",
        "overflow": "hidden",
        "padding": "0",
        "position": "absolute",
        "top": "20",
        "width": "1",
    },
    attributes: {
        '& .MuiSelect-selectMenu': {
            'padding-top': '9px'
        }
    },
    body: {
        'height': '100%'
    },
    item: {
        'height': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    },
    sortLabelRoot: {
        maxWidth: '100%',
    },
    sortLabelRight: {
        flexDirection: 'row-reverse',
    },
    sortLabelActive: {
        color: 'inherit',
    },
    toolbar: {
        'padding-left': '0 !important'
    },
    actions: {
        'margin-left': '0 !important'
    },
    filterContent: {
        width: '100%',
        padding: theme.spacing(2)
    },
    divider: {
        width: '100%'
    }
}))

const StyledBadge = withStyles((theme) => ({
    badge: {
        'transform': 'none',
        'top': 4
    }
}))(Badge)

export const Union = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {categories} = useSelector(state => state.system)
    const {items, offers, filter} = useSelector(state => state.item)
    const [search, setSearch] = useState('')
    const [tableRef, setTableRef] = useState(null)

    const debounced = useDebouncedCallback(
        (value) => {
            setLoading(value)
        }, 700
    )

    const [page, setPage] = useState(1)
    const [open, setOpen] = useState({})
    const [category, setCategory] = useState({})
    const [rows, setRows] = useState([])
    const [columns] = useState([
        { name: 'value.keyword', title: 'Исходный эталон' },
        { name: 'general.keyword', title: 'Объединенный эталон' },
        { name: 'comment.keyword', title: 'Комментарий' },
        { name: 'editor', title: 'Пользователь' },
        { name: 'updated', title: 'Дата изменения' }
    ])
    const [hiddenColumnNames, setHiddenColumnNames] = useState([])

    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [order, setOrder] = useState({columnName: 'confirmed', direction: 'asc'})
    const [loading, setLoading] = useState(false)
    const [initialize, setInitialize] = useState(false)
    const [columnWidths, setColumnWidths] = useState([
        { columnName: 'value.keyword', width: 500 },
        { columnName: 'general.keyword', width: 500 },
        { columnName: 'comment.keyword', width: 250 },
        { columnName: 'editor', width: 250 },
        { columnName: 'updated', width: 200 }
    ])
    const [columnOrder, setColumnOrder] = useState(['value.keyword', 'general.keyword', 'comment.keyword', 'editor', 'updated'])

    const getRow = (item, categories) => {
        return {
            'value.keyword': standard(categories.find(cat => item.category.id === cat.id), categories, item.values),
            'general.keyword': standard(categories.find(cat => item.item.category.id === cat.id), categories, item.item.values),
            'comment.keyword': item.comment,
            'editor': item.editor ? item.editor.login : null,
            'updated': format(new Date(item.updated), 'H:mm PP',  {locale: ru})
        }
    }

    useEffect(() => {
        const getData = async () => {
            let data = {items, categories}

            if (!initialize) {
                data.categories = await dispatch(SystemActions.categories())
            }

            data.items = await dispatch(ItemActions.items({
                page: page,
                limit: rowsPerPage,
                order: (filter.sort.name && filter.sort.direction) ? [filter.sort.name, filter.sort.direction] : [order.columnName, order.direction],
                type: 'union',
                ...(!!Object.keys(category).length ? {category: category.id} : {}),
                ...(search ? {search: search} : {})
            }))

            return data
        }

        if (!loading) {
            getData().then(props => {
                const {items, categories} = props

                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setRows(items.map(item => getRow(item, categories)))

                setLoading(true)
                setInitialize(true)
            })
        }
    }, [loading, page, rowsPerPage, category, search])

    useEffect(() => {
        setPage(1)
        setLoading(false)
    }, [filter])

    const assembly = (categories, parent = 0, level = 0) => {
        let result = !!parent ? {offer: false, elements: [], offers: 0} : []
        if (categories.hasOwnProperty(parent)) {
            let global = 0
            categories[parent].sort((first, second) => {
                return (first.name > second.name) ? 1 : (second.name > first.name) ? -1 : 0
            }).forEach(category => {
                const children = assembly(categories, category.id, level + 1)

                if (parent) {
                    result.offer = result.offer || (children.offer || offers.offers.categories.hasOwnProperty(category.id))
                    result.offers += (offers.offers.categories.hasOwnProperty(category.id) ? offers.offers.categories[category.id] : 0)
                    global += children.offers
                }

                const element = (
                    <MenuItem key={ category.id } value={ category } style={{ paddingLeft: `${16 * (level + 1)}px`}} className={ (children.offer || offers.offers.categories.hasOwnProperty(category.id)) ? classes.activeCategory : classes.defaultCategory }>
                        {categories.hasOwnProperty(category.id) &&
                            <ListItemIcon className={ classes.listItemIcon }>
                                <IconButton
                                    size="small"
                                    onClick={e => {
                                        e.stopPropagation()
                                        if (categories.hasOwnProperty(category.id)) {
                                            setOpen({...open, ...{[category.id]: open.hasOwnProperty(category.id) ? !open[category.id] : true}})
                                        }
                                    }}
                                >
                                    {(open.hasOwnProperty(category.id) && open[category.id]) ? <ExpandLess/> : <ExpandMore/>}
                                </IconButton>
                            </ListItemIcon>
                        }
                        <StyledBadge badgeContent={ children.offers + (offers.offers.categories.hasOwnProperty(category.id) ? offers.offers.categories[category.id] : 0) } style={{ width: categories.hasOwnProperty(category.id) ? 'calc(100% - 39px)' : '100%' }} color="secondary">
                            <ListItemText className={ categories.hasOwnProperty(category.id) ? classes.listItemTextWithIcon : classes.listItemText } primary={ !level ? <Typography variant="body1">{category.name}</Typography> : <Typography variant="body2">{category.name}</Typography> } />
                        </StyledBadge>
                    </MenuItem>
                )

                !!parent ? result.elements.push(element) : result.push(element)

                if (!!children.elements.length && open.hasOwnProperty(category.id) && open[category.id]) {
                    result = !!parent ? {...result, ...{elements: result.elements.concat([
                                children.elements.map(row => row)
                            ])}} : result.concat([
                        children.elements.map(row => row)
                    ])
                }
            })
            result.offers += global
        }
        return result
    }

    const getCategoriesTree = categories => {
        let tmp = {}

        categories.forEach(category => {
            if (!tmp.hasOwnProperty((category.category !== null) ? category.category.id : 0)) {
                tmp[(category.category !== null) ? category.category.id : 0] = []
            }

            tmp[(category.category !== null) ? category.category.id : 0].push(category)
        })

        return assembly(tmp)
    }

    const getParent = (breadcrumb, category) => {
        if (category.category) {
            breadcrumb = getParent(breadcrumb, category.category)
        }

        breadcrumb.push(<Typography key={breadcrumb.length} variant="subtitle2">{categories.find(cat => cat.id === category.id).name}</Typography>)

        return breadcrumb
    }

    const getBreadcrumb = () => {
        let breadcrumb = []

        if (Object.keys(category).length) {
            if (category.category) {
                breadcrumb = getParent(breadcrumb, categories.find(cat => cat.id === category.category.id))
            }

            breadcrumb.push(<Typography key={breadcrumb.length} variant="subtitle2" color="textPrimary">{category.name}</Typography>)
        } else {
            breadcrumb.push(<Typography key={breadcrumb.length} variant="subtitle2" color="textPrimary">Все категории</Typography>)
        }

        return breadcrumb
    }

    const handleChange = event => {
        const category = event.target.value

        dispatch({type: 'ITEM_FILTER_CATEGORY', payload: category.id})

        setCategory(category)
        setPage(1)
        setRows([])
        setSearch('')
        setOrder({columnName: 'confirmed', direction: 'asc'})

        setLoading(false)
    }

    return (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={ classes.body } spacing={2}>
            <Grid item className={classes.element}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item sm={3}>
                        <Badge color="secondary" className={ classes.fullWidth }>
                            <Select
                                fullWidth
                                id="category"
                                value={ !!Object.keys(category).length ? category : '' }
                                onChange={ handleChange }
                                renderValue={(value) => value.hasOwnProperty('name') ? value.name : null}
                            >
                                {getCategoriesTree(categories)}
                            </Select>
                        </Badge>
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            fullWidth
                            id="category"
                            label='Поиск'
                            value={search}
                            onChange={event => {
                                setPage(1)
                                setSearch(event.target.value)
                                debounced.callback(false)
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.breadcrumb}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                        <Breadcrumbs>
                            {getBreadcrumb()}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <IconButton
                            type="button"
                            size="small"
                            onClick={() => {
                                dispatch(DownloadActions.create({
                                    type: 'union_standards',
                                    columns: columnOrder.filter(name => (hiddenColumnNames.indexOf(name) < 0)),
                                    params: {
                                        order: (filter.sort.name && filter.sort.direction) ? `${filter.sort.name}, ${filter.sort.direction}` : `${order.columnName}, ${order.direction}`,
                                        type: 'union',
                                        ...(!!Object.keys(category).length ? {category: category.id} : {}),
                                        ...(search ? {search: search} : {})
                                    }
                                }))
                            }}
                        >
                            <GetApp />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.data}>
                <GridTable
                    rows={rows}
                    columns={columns}
                >
                    <PagingState
                        currentPage={page}
                        onCurrentPageChange={newPage => {
                            setPage(newPage)
                            setLoading(false)
                        }}
                        pageSize={rowsPerPage}
                        onPageSizeChange={newRowsPerPage => {
                            setPage(1)
                            setRowsPerPage(newRowsPerPage)
                            setLoading(false)
                        }}
                    />
                    <CustomPaging
                        totalCount={items.data.length ? items.meta.total : 0}
                    />
                    <DragDropProvider />
                    <Table
                        noDataCellComponent={props => {
                            return null
                        }}
                        tableComponent={props => <StickyTable {...props} setTableRef={setTableRef} />}
                        rowComponent={({ row, tableRow, children }) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.default}
                                row={row}
                            />
                        )}
                    />
                    <TableColumnReordering
                        order={columnOrder}
                        onOrderChange={setColumnOrder}
                    />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                    />
                    <TableHeaderRow
                        contentComponent={TableHeaderContent}
                    />
                    <TableColumnVisibility
                        hiddenColumnNames={hiddenColumnNames}
                        onHiddenColumnNamesChange={setHiddenColumnNames}
                    />
                    <PagingPanel
                        containerComponent={Pager}
                        messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from}-{to} из {count}'}}
                        pageSizes={[50, 100, 200]}
                    />
                </GridTable>
            </Grid>
        </Grid>
    )
}
