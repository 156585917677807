import { ItemService } from '../services/item'

export const ItemActions = {
    items,
    values,
    categories,
    remove,
    category,
    standard,
    description,
    variation
}

function items (id, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'MARKUP_FILE_ITEMS_REQUEST' })

        ItemService.items(id, params)
            .then(
                response => {
                    dispatch({ type: 'MARKUP_FILE_ITEMS_SUCCESS', payload: { data: response.data, meta: response.meta, limit: params.limit ? params.limit : 10, page: params.page ? params.page : 1 } })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(response.data)
                },
                error => {
                    dispatch({ type: 'MARKUP_FILE_ITEMS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function values (id, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        ItemService.values(id, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function categories (id, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        ItemService.categories(id, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function remove (id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'MARKUP_DELETE_FILE_ITEMS_REQUEST' })

        ItemService.remove(id, params)
            .then(
                () => {
                    dispatch({ type: 'MARKUP_DELETE_FILE_ITEMS_SUCCESS', payload: params })
                    resolve()
                },
                error => {
                    dispatch({ type: 'MARKUP_DELETE_FILE_ITEMS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function category (id, values) {
    return dispatch => new Promise((resolve, reject) => {
        ItemService.category(id, values)
            .then(
                items => {
                    dispatch({ type: 'MARKUP_CATEGORY_FILE_ITEMS_SUCCESS', payload: items })
                    resolve()
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function standard (id, item, values) {
    return dispatch => new Promise((resolve, reject) => {
        ItemService.standard(id, item, values)
            .then(
                item => {
                    dispatch({ type: 'MARKUP_STANDARD_FILE_ITEM_SUCCESS', payload: item})
                    resolve()
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function description (id, item, values) {
    return dispatch => new Promise((resolve, reject) => {
        ItemService.description(id, item, values)
            .then(
                () => {
                    dispatch({ type: 'MARKUP_DESCRIPTION_FILE_ITEM_SUCCESS', payload: {id: item, values: values} })
                    resolve()
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function variation (id, item, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'MARKUP_VARIATION_FILE_ITEM_REQUEST' })

        ItemService.variation(id, item, values)
            .then(
                item => {
                    dispatch({ type: 'MARKUP_VARIATION_FILE_ITEM_SUCCESS', payload: item})
                    resolve()
                },
                error => {
                    dispatch({ type: 'MARKUP_VARIATION_FILE_ITEM_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}
