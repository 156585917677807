import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {
    Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell,
    Fab, Typography, Collapse, IconButton, makeStyles
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { CategoryActions } from "./actions/category"
import { CategoryForm } from "./components/CategoryForm"
import {TypeActions} from "./actions/Attribute/type"
import {ItemActions} from "../Item/actions/item"

const useStyles = makeStyles(theme => ({
    field: {
        'height': '100%'
    },
    data: {
        'height': 'calc(100% - 52px)',
        'width': '100%'
    },
    table: {
        'height': '100%'
    },
    cell: {
        'height': '38px'
    },
    fab: {
        'margin': '0',
        'top': 'auto',
        'right': '90px',
        'bottom': '25px',
        'left': 'auto',
        'position': 'fixed'
    },
    root: {
        '& > *': {
            borderBottom: 'unset'
        }
    }
}))

const columns = [
    {
        id: 'expand',
        label: '',
        align: 'center'
    },
    {
        id: 'name',
        label: 'Наименование',
        align: 'center',
        format: value => value.toLocaleString()
    }
]

export const Category = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {categories, options} = useSelector(state => {
        const { categories } = state.category

        return {
            categories, options: state.system.categories
        }
    })

    const [category, setCategory] = useState(null)
    const [dialog, setDialog] = useState(false)
    const [open, setOpen] = useState({})
    const [loading, setLoading] = useState(false)
    const [initialize, setInitialize] = useState(false)

    useEffect(() => {
        const getCategories = async () => {
            if (!initialize) {
                dispatch(TypeActions.types())
            }

            return await dispatch(CategoryActions.categories())
        }

        if (!loading) {
            getCategories().then(_ => {
                setLoading(true)
                setInitialize(true)
            })
        }
    }, [dispatch, initialize, loading, categories.length])

    const handleDelete = (id, params = null) => {
        return new Promise((resolve, reject) => {
            if (params) {
                return dispatch(CategoryActions.remove(id, params)).then(
                    () => {
                        setLoading(true)
                        resolve()
                    }
                )
            } else {
                return dispatch(ItemActions.count({category: id}))
                    .then(
                        count => {
                            if (count > 0) {
                                resolve(count)
                            } else {
                                return dispatch(CategoryActions.remove(id)).then(
                                    () => {
                                        setLoading(true)
                                        resolve()
                                    }
                                )
                            }
                        },
                        error => {
                            reject()
                        }
                    )
            }
        })
    }

    const handleSave = (values, id = null) => {
        if (id) {
            return dispatch(CategoryActions.save(id, values)).then(
                () => {
                    setLoading(false)
                }
            )
        } else {
            return dispatch(CategoryActions.add(values)).then(
                () => {
                    setLoading(false)
                }
            )
        }
    }

    const assembly = (categories, parent = 0, level = 0) => {
        let result = []

        if (categories.hasOwnProperty(parent)) {
            categories[parent].sort((first, second) => {
                return (first.name > second.name) ? 1 : (second.name > first.name) ? -1 : 0
            }).forEach(category => {
                result.push(
                    <TableRow
                        key={category.id}
                        hover
                        role="checkbox"
                        className={classes.cell}
                        tabIndex={-1}
                        onClick={() => {
                            setCategory(category)
                            setDialog(true)
                        }}
                    >
                        <TableCell style={{ width: '25px', paddingLeft: `${16 * (level + 1)}px`}}  align="center">
                            {categories.hasOwnProperty(category.id) &&
                                <IconButton
                                    className={classes.margin}
                                    size="small"
                                    onClick={e => {
                                        e.stopPropagation()
                                        if (categories.hasOwnProperty(category.id)) {
                                            setOpen({...open, ...{[category.id]: open.hasOwnProperty(category.id) ? !open[category.id] : true}})
                                        }
                                    }}
                                >
                                    {(open.hasOwnProperty(category.id) && open[category.id]) ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            }
                        </TableCell>
                        <TableCell align="left">
                            { !level ? <Typography variant="body1">{category.name}</Typography> : <Typography variant="body2">{category.name}</Typography> }
                        </TableCell>
                    </TableRow>
                )

                const childes = assembly(categories, category.id, level + 1)

                if (!!childes.length && open.hasOwnProperty(category.id) && open[category.id]) {
                    result = result.concat([
                        <TableRow key={`collapse_${category.id}`} className={classes.root}>
                            <TableCell style={{ padding: 0 }} colSpan={2}>
                                <Collapse in={open.hasOwnProperty(category.id) && open[category.id]} timeout="auto" unmountOnExit>
                                    <Table stickyHeader>
                                        <TableBody>
                                            {childes.map(row => row)}
                                        </TableBody>
                                    </Table>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    ])
                }
            })
        }

        return result
    }

    const getCategoriesTree = categories => {
        let tmp = {}
        categories.forEach(category => {
            if (!tmp.hasOwnProperty((category.category !== null) ? category.category.id : 0)) {
                tmp[(category.category !== null) ? category.category.id : 0] = []
            }

            tmp[(category.category !== null) ? category.category.id : 0].push(category)
        })

        return assembly(tmp)
    }

    return (
        <Grid container direction="column" justify="space-between" alignItems="center" className={classes.field}>
            <Grid item className={classes.data}>
                <TableContainer className={classes.table}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map(column => (
                                    <TableCell
                                        key={column.id}
                                        align="left"
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getCategoriesTree(categories.data)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Fab size="medium" color="primary" aria-label="Добавить" className={ classes.fab } onClick={() => { setDialog(true) }}>
                <AddIcon />
            </Fab>
            { dialog && <CategoryForm category={ category } categories={ options } open={ dialog } handleClose={() => { setCategory(null); setDialog(false) }} handleDelete={ handleDelete } handleSave={ handleSave } /> }
        </Grid>
    )
}
