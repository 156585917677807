import { HttpService } from '../../App/services/http'
import * as AppStorage from "../../App/helpers/storage";

export const AccountService = {
  account
}

function account() {
  const options = {
      method: 'GET'
  }

  return HttpService.http('/account?include=roles.permissions', options, true)
    .then(response => {
      if (response.account) {
          AppStorage.set('account', JSON.stringify(response.account))
      }

      return response.account
    })
}
