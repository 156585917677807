import { TermService } from '../services/term'
import {SynonymService} from "../services/attribute/synonym";

export const TermActions = {
    values,
    remove,
    save,
    filter
}

function values (id, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'SYNONYM_ATTRIBUTE_TERM_VALUES_REQUEST' })

        TermService.values(id, params)
            .then(
                values => {
                    dispatch({ type: 'SYNONYM_ATTRIBUTE_TERM_VALUES_SUCCESS', payload: values })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(values.data)
                },
                error => {
                    dispatch({ type: 'SYNONYM_ATTRIBUTE_TERM_VALUES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function remove (id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'SYNONYM_DELETE_ATTRIBUTE_TERM_VALUE_REQUEST' })

        TermService.remove(id, params)
            .then(
                () => {
                    dispatch({ type: 'SYNONYM_DELETE_ATTRIBUTE_TERM_VALUE_SUCCESS' })
                    resolve()
                },
                error => {
                    dispatch({ type: 'SYNONYM_DELETE_ATTRIBUTE_TERM_VALUE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function save (id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'SYNONYM_SAVE_ATTRIBUTE_TERM_VALUES_REQUEST' })

        TermService.save(id, params)
            .then(
                () => {
                    dispatch({ type: 'SYNONYM_SAVE_ATTRIBUTE_TERM_VALUES_SUCCESS' })
                    resolve()
                },
                error => {
                    dispatch({ type: 'SYNONYM_SAVE_ATTRIBUTE_TERM_VALUES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function filter (id, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        TermService.filter(id, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}
