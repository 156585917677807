import React, {useState} from 'react'
import {
    Grid,
    makeStyles,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Link
} from '@material-ui/core'
import {GetApp} from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    accordion: {
        'box-shadow': 'none !important',
        'border-radius': '0 !important',
    },
    accordionSummary: {
        '& .MuiAccordionSummary-content' : {
            'max-width': 'calc(100% - 32px)'
        }
    }
}))

export const Images = (props) => {
    const classes = useStyles()

    const { images } = props

    const [expanded, setExpanded] = useState(false)

    return (
        <Accordion expanded={expanded} className={classes.accordion} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon/>}>
                <Grid container direction="row" justify="center" alignItems="center">
                    <Grid item className={classes.fullWidth}>
                        <Typography>Изображения</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction='row' justify='flex-starts' alignItems='center' spacing={2}>
                    {images.map((image, index) => (
                        <Grid item xs={6} key={index} className={classes.fullWidth}>
                            <Link href={(process.env.REACT_APP_ENV !== 'production') ? (process.env.REACT_APP_HOST_API.replace('/api', `${process.env.REACT_APP_BASE_NAME}/storage/images`) + image.path) : image.path} target="_blank">
                                <Grid container direction='row' justify='flex-starts' alignItems='center' spacing={2}>
                                    <Grid item>
                                        Пример изображения
                                    </Grid>
                                    <Grid item>
                                        <GetApp />
                                    </Grid>
                                </Grid>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}
