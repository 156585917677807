import React from 'react'
import {useDispatch, useSelector} from "react-redux"

import { Snackbar, Fade, Typography, makeStyles } from '@material-ui/core'

import { AlertActions } from './actions/alert'

const useStyles = makeStyles(theme => ({
    alert: {
        'border-radius': 0,
        'background': 'rgba(0, 0, 0, 0.75)',
        'color': '#FFFFFF',
        'max-width': '500px'
    },
    text: {
        'margin-bottom': 0
    }
}))

export const Alert = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const alert = useSelector(state => state.alert)

    const close = () => {
        dispatch(AlertActions.clear())
    }

    if (alert.message) {
        return (
            <Snackbar
                ContentProps={{
                    classes: {
                        root: classes.alert
                    }
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={ true }
                TransitionComponent={ Fade }
                onClose={ close }
                message={ <Typography className={ classes.text } gutterBottom color='inherit'>{ alert.message }</Typography> }
            />
        )
    } else {
      return null
    }
}
