import React, {useEffect} from 'react'
import classNames from "clsx"
import {Table, makeStyles} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    table: {
        tableLayout: "fixed",
        borderCollapse: "separate",
        "& thead th": {
            position: "sticky",
            zIndex: 600,
            fallbacks: {
                position: "-webkit-sticky"
            }
        }
    },
    stickyTable: {
        position: "sticky",
        zIndex: 500,
        overflow: "visible",
        background: theme.palette.background.paper,
        fallbacks: {
            position: "-webkit-sticky"
        }
    },
    headTable: {
        top: 0
    },
    footTable: {
        bottom: 0
    }
}))

export const StickyTable = ({children, className, use, tableRef, setTableRef, onClick, ...restProps}) => {
    const classes = useStyles()

    useEffect(() => {
        if (setTableRef) {
            setTableRef(tableRef)
        }
    }, [tableRef])

    return (
        <Table
            ref={tableRef}
            onClick={onClick}
            stickyHeader
            aria-label="sticky table"
            className={classNames(
                {
                    [classes.table]: true,
                    [classes.stickyTable]: !!use,
                    [classes.headTable]: use === "head",
                    [classes.footTable]: use === "foot"
                },
                className
            )}
            {...restProps}
        >
            {children}
        </Table>
    )
}
