import { UnitService } from '../services/unit'

export const UnitActions = {
    units,
    types,
    groups,
    filter,
    add,
    edit,
    remove
}

function units (params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'UNITS_REQUEST' })

        UnitService.units(params)
            .then(
                units => {
                    dispatch({ type: 'UNITS_SUCCESS', payload: units })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'UNITS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function types (params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'UNIT_TYPES_REQUEST' })

        UnitService.types(params)
            .then(
                types => {
                    dispatch({ type: 'UNIT_TYPES_SUCCESS', payload: types })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'UNIT_TYPES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function groups (params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'UNIT_GROUPS_REQUEST' })

        UnitService.groups(params)
            .then(
                groups => {
                    dispatch({ type: 'UNIT_GROUPS_SUCCESS', payload: groups })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'UNIT_GROUPS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function filter (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        UnitService.filter(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}


function add (values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'UNIT_ADD_REQUEST' })

        UnitService.add(values)
            .then(
                () => {
                    dispatch({ type: 'UNIT_ADD_SUCCESS' })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'UNIT_ADD_FAILURE' })
                    if (!error.type) {
                        dispatch({type: 'ALERT_ERROR', payload: error.message})
                    }
                    dispatch({ type: 'FILLING', payload: false })
                    reject(error)
                }
            )
    })
}

function edit (id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'UNIT_EDIT_REQUEST' })

        UnitService.edit(id, values)
            .then(
                () => {
                    dispatch({ type: 'UNIT_EDIT_SUCCESS' })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'UNIT_EDIT_FAILURE' })
                    if (!error.type) {
                        dispatch({type: 'ALERT_ERROR', payload: error.message})
                    }
                    dispatch({ type: 'FILLING', payload: false })
                    reject(error)
                }
            )
    })
}

function remove (id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'UNIT_DELETE_REQUEST' })

        UnitService.remove(id)
            .then(
                () => {
                    dispatch({ type: 'UNIT_DELETE_SUCCESS' })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'UNIT_DELETE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}
