export default function history (state = {
    filter: {sort: {name: null, direction: null}}
}, action) {
    switch (action.type) {
        case 'ITEM_CHANGES_FILTER':
            return {
                ...state,
                ...{filter: {
                    ...state.filter,
                    ...{sort: action.payload.sort},
                    ...action.payload
                }}
            }
        default:
            return state
    }
}
