import { HttpService } from '../../App/services/http'

export const ChangeService = {
    filter
}

function filter (id, type, params = {}) {
    const options = {
        method: 'GET',
        params: params.params
    }

    return HttpService.http(`/item/${id}/changes/${type}/filter`, options, true)
        .then(response => {
            return response
        })
}
