import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup"

import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    makeStyles, Typography, MenuItem, FormControlLabel
} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {
    Switch,
    TextField
} from 'formik-material-ui'

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    }
}))

export const UnitForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {types, groups} = useSelector(state => {
        const {types, groups} = state.unit

        return {
            types: types.data,
            groups: groups.data,
        }
    })

    const {open, handleClose, handleSave, handleReject, unit} = props
    const [combine, setCombine] = useState(null)
    const [reject, setReject] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (open) {
            setLoading(true)
        }
    }, [open])

    return (
        <Formik
            initialValues={{
                group: (unit && unit.group) ? unit.group.id : '',
                type: (unit && unit.type) ? unit.type.id : '',
                name: unit ? unit.name : '',
                short: (unit && unit.short) ? unit.short : '',
                code: (unit && unit.code) ? unit.code : '',
                active: (unit && unit.confirmed) ? unit.active : true
            }}
            validationSchema={Yup.object().shape({
                group: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                type: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                name: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!"),
                short: Yup.string(),
                code: Yup.string(),
                active: Yup.boolean(),
            })}
            validate = {values => {
                const errors = {}

                if (!reject && values.active && !values.short.length) {
                    errors.short = 'Поле не может быть пустым или состоять из одних пробелов!'
                }

                return errors
            }}
            onSubmit={(values, {setSubmitting, setErrors}) => {
                if (combine) {
                    values.combine = true
                }

                if (reject) {
                    handleReject(unit.id).then(
                        () => {
                            setSubmitting(false)
                            dispatch(handleClose)
                        },
                        error => {
                            setErrors(error)
                            setSubmitting(false)
                        }
                    )
                } else {
                    handleSave(values, unit ? unit.id : null).then(
                        () => {
                            setSubmitting(false)
                            dispatch(handleClose)
                        },
                        error => {
                            setErrors(error)
                            if (error.hasOwnProperty('type')) {
                                switch (error.type) {
                                    case 'combine':
                                        setCombine({message: error.message})
                                        break;
                                }
                            }
                            setSubmitting(false)
                        }
                    )
                }
            }}
        >
            {({
                  values,
                  errors,
                  handleSubmit,
                  isSubmitting,
                  setSubmitting,
                  setValues,
                  setFieldValue,
                  setFieldTouched
              }) => (
                <Form>
                    <Dialog
                        fullWidth={true}
                        maxWidth="md"
                        open={loading}
                        onClose={handleClose}
                        classes={{
                            paper: classes.dialog
                        }}
                    >
                        <DialogTitle>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item>
                                    {(unit ? (reject ? 'Отклонить' : (!!combine ? 'Объединить' : (unit.confirmed ? 'Редактировать' : 'Подтвердить'))) : 'Добавить')}
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label="Закрыть"
                                        onClick={() => handleClose()}
                                    >
                                        <Close/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            {reject ?
                                <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                    <Grid item sm={10} className={classes.fullWidth}>
                                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                            <Grid item sm={12} className={classes.fullWidth}>
                                                <Typography>Вы действительно хотите отклонить значение?</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={10} className={classes.fullWidth}>
                                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                            <Grid item sm={6} className={classes.fullWidth}>
                                                <Field
                                                    fullWidth
                                                    name="name"
                                                    type="text"
                                                    label="Наименование"
                                                    required
                                                    disabled
                                                    component={TextField}
                                                />
                                            </Grid>
                                            {!!values.short.length &&
                                                <Grid item sm={6} className={classes.fullWidth}>
                                                    <Field
                                                        fullWidth
                                                        name="short"
                                                        type="text"
                                                        label="Краткое наименование"
                                                        required={values.active}
                                                        disabled
                                                        component={TextField}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid> : (combine ?
                                <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                    <Grid item sm={10} className={classes.fullWidth}>
                                        <Typography>{combine.message}</Typography>
                                    </Grid>
                                </Grid> :
                                <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                    <Grid item sm={10} className={classes.fullWidth}>
                                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                            <Grid item sm={6} className={classes.fullWidth}>
                                                <Field
                                                    fullWidth
                                                    type="text"
                                                    name="group"
                                                    label="Группа"
                                                    select
                                                    required
                                                    variant="standard"
                                                    disabled={ isSubmitting }
                                                    component={ TextField }
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                >
                                                    {groups.map(item => (
                                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </Field>
                                            </Grid>
                                            <Grid item sm={6} className={classes.fullWidth}>
                                                <Field
                                                    fullWidth
                                                    type="text"
                                                    name="type"
                                                    label="Тип"
                                                    select
                                                    required
                                                    variant="standard"
                                                    disabled={ isSubmitting }
                                                    component={ TextField }
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                >
                                                    {types.map(item => (
                                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                    ))}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={10} className={classes.fullWidth}>
                                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                            <Grid item sm={6} className={classes.fullWidth}>
                                                <Field
                                                    fullWidth
                                                    name="name"
                                                    type="text"
                                                    label="Наименование"
                                                    required
                                                    disabled={isSubmitting}
                                                    component={TextField}
                                                />
                                            </Grid>
                                            <Grid item sm={6} className={classes.fullWidth}>
                                                <Field
                                                    fullWidth
                                                    name="short"
                                                    type="text"
                                                    label="Краткое наименование"
                                                    required={values.active}
                                                    disabled={isSubmitting}
                                                    component={TextField}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={10} className={classes.fullWidth}>
                                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                            <Grid item sm={6} className={classes.fullWidth}>
                                                <FormControlLabel
                                                    control={
                                                        <Field
                                                            name="active"
                                                            label="Активный"
                                                            component={ Switch }
                                                        />
                                                    }
                                                    label="Активный"
                                                />
                                            </Grid>
                                            <Grid item sm={6} className={classes.fullWidth}>
                                                <Field
                                                    fullWidth
                                                    name="code"
                                                    type="text"
                                                    label="Код"
                                                    disabled={isSubmitting}
                                                    component={TextField}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>)
                            }
                        </DialogContent>
                        <DialogActions>
                            {(unit && (!unit.confirmed || combine)) &&
                                <Button
                                    disabled={isSubmitting}
                                    onClick={() => {
                                        if (combine) {
                                            setCombine(null)
                                        } else {
                                            setReject(!reject)
                                        }
                                    }}
                                    color="secondary"
                                    type="button"
                                >
                                    {(isSubmitting ? <CircularProgress size={24}/> : ((combine || reject) ? 'Отмена' : 'Отклонить'))}
                                </Button>
                            }
                            <Button
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                                color={(!unit || unit.confirmed) ? 'primary' : 'default'}
                                type="submit"
                            >
                                {(isSubmitting ? <CircularProgress size={24}/> : (unit ? (reject ? 'Отклонить' : (combine ? 'Объединить' : (unit.confirmed ? 'Сохранить' : 'Подтвердить'))) : 'Добавить'))}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    )
}
