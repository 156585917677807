export default function term (state = { values: { data: [], meta: {}, limit: 100, page: 1 } }, action) {
    switch (action.type) {
        case 'SYNONYM_ATTRIBUTE_TERM_VALUES_REQUEST':
            return state
        case 'SYNONYM_ATTRIBUTE_TERM_VALUES_SUCCESS':
            return  {
                ...state,
                ...{values: action.payload}
            }
        case 'SYNONYM_ATTRIBUTE_TERM_VALUES_FAILURE':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: state.values.limit, page: state.values.page }}
            }
        case 'SYNONYM_ATTRIBUTE_TERM_VALUES_CLEAR':
            return {
                ...state,
                ...{values: { data: [], meta: {}, limit: 100, page: 1 }}
            }
        case 'SYNONYM_SAVE_ATTRIBUTE_TERM_VALUES_REQUEST':
            return state
        case 'SYNONYM_SAVE_ATTRIBUTE_TERM_VALUES_SUCCESS':
            return state
        case 'SYNONYM_SAVE_ATTRIBUTE_TERM_VALUES_FAILURE':
            return state
        case 'SYNONYM_DELETE_ATTRIBUTE_TERM_VALUE_REQUEST':
            return state
        case 'SYNONYM_DELETE_ATTRIBUTE_TERM_VALUE_SUCCESS':
            return state
        case 'SYNONYM_DELETE_ATTRIBUTE_TERM_VALUE_FAILURE':
            return state
        default:
            return state
    }
}
