import React, {useState} from "react"
import {Field, FieldArray} from "formik"

import {Card, CardContent, Grid, IconButton, makeStyles, Typography} from "@material-ui/core"
import { TextField } from "formik-material-ui"
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete'
import {Add, Clear} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    card: {
        'width': '100%',
        'border-radius': 'unset'
    },
    label: {
        'color': 'rgba(0, 0, 0, 0.54)',
        'padding': '0',
        'font-size': '0.75rem',
        'font-weight': '400',
        'line-height': '1',
        'letter-spacing': '0.00938em'
    }
}))

export const FieldDosage = (props) => {
    const classes = useStyles()

    const { id, label, items, setFieldValue, isSubmitting, disabled } = props
    const [name] = useState(props.hasOwnProperty('name') ? props.name : `attributes.${id}`)
    const [value, setValue] = useState(props.value)

    return (
        <Grid item className={classes.fullWidth}>
            <Card className={classes.card}>
                <CardContent>
                    <Grid item className={classes.fullWidth}>
                        <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                    <Grid item sm={9} className={classes.fullWidth}>
                                        <Typography variant="caption" className={classes.label}>{label}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`${name}.prefix`}
                                            disabled={ isSubmitting || disabled }
                                            label="Префикс"
                                            component={ TextField }
                                        />
                                    </Grid>
                                </Grid>
                                <FieldArray
                                    name={`${name}.values`}
                                    render={ arrayOptions => (
                                        <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                            {props.value.values.map((option, key) => (
                                                <Grid item key={key} className={classes.fullWidth}>
                                                    <Grid container direction='column' justify='center' alignItems='flex-start' spacing={2}>
                                                        <Grid item className={classes.fullWidth}>
                                                            <Grid container direction='row' justify='space-between' alignItems='flex-end' spacing={2}>
                                                                <Grid item xs={6} className={classes.fullWidth}>
                                                                    <Field
                                                                        fullWidth
                                                                        name={`${name}.values[${key}].amount`}
                                                                        type="number"
                                                                        disabled={ isSubmitting || disabled }
                                                                        label="Количество"
                                                                        inputProps={{
                                                                            step: 0.0001,
                                                                            onWheel: e => {
                                                                                e.target.blur()
                                                                                e.stopPropagation()
                                                                                setTimeout(() => {
                                                                                    e.target.focus()
                                                                                }, 0)
                                                                            }
                                                                        }}
                                                                        component={ TextField }
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={4} className={classes.fullWidth}>
                                                                    <Autocomplete
                                                                        filterOptions={createFilterOptions({
                                                                            matchFrom: 'start',
                                                                            stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                                        })}
                                                                        options={ items }
                                                                        name={`${name}.values[${key}].unit`}
                                                                        getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                                                        disabled={ isSubmitting || disabled }
                                                                        onChange={(e, value) => {
                                                                            setFieldValue(`${name}.values[${key}].unit`, ((value instanceof Object) ? value.short : value))
                                                                        }}
                                                                        defaultValue={option.unit}
                                                                        renderInput={params => (
                                                                            <Field
                                                                                fullWidth
                                                                                {...params}
                                                                                name={`${name}.values[${key}].unit`}
                                                                                InputProps={{
                                                                                    ...params.InputProps,
                                                                                    onChange: (e) => {
                                                                                        setValue({
                                                                                            ...value,
                                                                                            ...{
                                                                                                values: value.values.map((val, number) => ({
                                                                                                    ...val,
                                                                                                    ...{
                                                                                                        unit: (number === key) ? e.target.value : val.unit
                                                                                                    }
                                                                                                }))
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                }}
                                                                                type="text"
                                                                                label="Ед. изм."
                                                                                component={ TextField }
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item>
                                                                    <IconButton
                                                                        onClick={() => arrayOptions.remove(key)}
                                                                        color="primary"
                                                                        aria-label="Удалить"
                                                                        component="span"
                                                                    >
                                                                        <Clear/>
                                                                    </IconButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item className={classes.fullWidth}>
                                                            <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                                                <Grid item sm={6} className={classes.fullWidth}>
                                                                    <Field
                                                                        fullWidth
                                                                        name={`${name}.values[${key}].delimiter`}
                                                                        disabled={ isSubmitting || disabled }
                                                                        label="Разделитель"
                                                                        component={ TextField }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Grid item className={classes.fullWidth}>
                                                <IconButton
                                                    onClick={() => arrayOptions.push({value: '', unit: '', delimiter: ''})}
                                                    color={'primary'}
                                                    aria-label="Добавить"
                                                    component="span"
                                                >
                                                    <Add />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )}
                                />
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`${name}.volume.value`}
                                            type="number"
                                            disabled={ isSubmitting || disabled }
                                            label="Объём/вес"
                                            inputProps={{
                                                step: 0.0001,
                                                onWheel: e => {
                                                    e.target.blur()
                                                    e.stopPropagation()
                                                    setTimeout(() => {
                                                        e.target.focus()
                                                    }, 0)
                                                }
                                            }}
                                            component={ TextField }
                                        />
                                    </Grid>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Autocomplete
                                            filterOptions={createFilterOptions({
                                                matchFrom: 'start',
                                                stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                            })}
                                            options={ items }
                                            name={`${name}.volume.unit`}
                                            getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                                            disabled={ isSubmitting || disabled }
                                            onChange={(e, value) => {
                                                setFieldValue(`${name}.volume.unit`, ((value instanceof Object) ? value.short : value))
                                            }}
                                            defaultValue={value.volume.unit}
                                            renderInput={params => (
                                                <Field
                                                    fullWidth
                                                    {...params}
                                                    name={`${name}.volume.unit`}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        onChange: (e) => {
                                                            setValue({
                                                                ...value,
                                                                ...{
                                                                    volume: {
                                                                        ...value.volume,
                                                                        ...{
                                                                            unit: e.target.value
                                                                        }
                                                                    }
                                                                }
                                                            })
                                                        }
                                                    }}
                                                    type="text"
                                                    label="Ед. изм."
                                                    component={ TextField }
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item sm={6} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`${name}.volume.delimiter`}
                                            disabled={ isSubmitting || disabled }
                                            label="Разделитель"
                                            component={ TextField }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item sm={12} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`${name}.description`}
                                            disabled={ isSubmitting || disabled }
                                            label="Текст"
                                            component={ TextField }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
                                    <Grid item sm={12} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            name={`${name}.initial`}
                                            disabled={ isSubmitting || disabled }
                                            label="Начальное значение"
                                            component={ TextField }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    )
}
