import React from "react"
import {stringFormat} from "./string"
import {getValue} from "../../Category/components/Attribute/helpers/value";

const standard = (category, categories, values) => {

    let name = ''

    let attributes = []

    if (category) {
        if (category.category) {
            let parent = categories.find(cat => (cat.id === category.category.id))

            while (parent) {
                parent.attributes.forEach(attribute => {
                    attributes.push(attribute)
                })

                parent = parent.category ? categories.find(cat => (cat.id === parent.category.id)) : null
            }
        }

        category.attributes.forEach((attribute) => {
            attributes.push(attribute)
        })
    }

    attributes.filter(attribute => (attribute.index && attribute.standard)).sort((first, second) => {
        return (first.index > second.index) ? 1 : ((first.index < second.index) ? -1 : 0)
    }).map((attribute) => {
        const value = values.find(el => el.attribute.id === attribute.id)
        if (value) {
            let val = getValue(value.attribute, value, attribute.dictionary, true)

            // eslint-disable-next-line default-case
            switch (attribute.name) {
                case 'Дозировка':
                case 'Объем, вес и дозы':
                case 'Вес, объем':
                case 'Фасовка':
                case 'Количество':
                case 'Фасовка/Количество':
                case 'Концентрация':
                case 'Игла':
                case 'Плотность/жесткость':
                    val = stringFormat(String(val), false)
            }

            val = val?.trim()

            if (val?.length) {
                name = `${name} ${val}`
            }
        }
    })

    return name.trim()
}

export { standard }
