import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
    Button,
    Grid, makeStyles
} from '@material-ui/core'
import {blue, green} from "@material-ui/core/colors"
import {
    PagingState, CustomPaging
} from '@devexpress/dx-react-grid'
import {
    DragDropProvider, Table, TableHeaderRow , TableColumnResizing, PagingPanel, TableColumnReordering
} from '@devexpress/dx-react-grid-material-ui'

import {StickyTable} from "../App/components/Table/StikyTable"
import {Grid as GridTable} from "../App/components/Table/Grid"
import {Pager} from "../App/components/Table/Paging/Pager"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import {DownloadActions} from "./actions/download";
import * as AppStorage from "../App/helpers/storage";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    data: {
        'height': 'calc(100% - -44px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '52px'
    },
    active: {
        'height': '45.5px',
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    completed: {
        'height': '45.5px',
        'background-color': green[100],
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '45.5px',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '45.5px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    body: {
        'height': '100%'
    }
}))

export const Files = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {files} = useSelector(state => state.download)
    const [tableRef, setTableRef] = useState(null)

    const columns = [
        { name: 'name', title: 'Наименование' },
        { name: 'status', title: 'Статус' },
        { name: 'created', title: 'Дата создания' },
        { name: 'updated', title: 'Дата обновления' },
        { name: 'download', title: ' ' }
    ]

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [loading, setLoading] = useState(false)
    const [columnWidths, setColumnWidths] = useState([
        { columnName: 'name', width: 500 },
        { columnName: 'status', width: 250 },
        { columnName: 'created', width: 250 },
        { columnName: 'updated', width: 250 },
        { columnName: 'download', width: 250 }
    ])
    const [columnOrder, setColumnOrder] = useState(['name', 'status', 'created', 'updated', 'download'])

    useEffect(() => {
        const getData = async () => {
            return await dispatch(DownloadActions.files({
                page: page,
                limit: rowsPerPage
            }))
        }

        if (!loading) {
            getData().then(props => {
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setLoading(true)
            })
        }
    }, [loading, page, rowsPerPage])

    return (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={ classes.body } spacing={2}>
            <Grid item className={classes.data}>
                <GridTable
                    rows={files.data.map(file => ({
                        name: file.name,
                        status: file.status.name,
                        created: format(new Date(file.created), 'H:mm PP',  {locale: ru}),
                        updated: format(new Date(file.updated), 'H:mm PP',  {locale: ru}),
                        download: file.path ? <Button
                            color="primary"
                            type="button"
                            href={process.env.REACT_APP_HOST_API + `/download/file/${file.id}?token=${AppStorage.get('token').replace('Bearer ', '')}`}
                            download
                        >
                            Скачать
                        </Button> : null
                    }))}
                    columns={columns}
                >
                    <PagingState
                        currentPage={page}
                        onCurrentPageChange={newPage => {
                            setPage(newPage)
                            setLoading(false)
                        }}
                        pageSize={rowsPerPage}
                        onPageSizeChange={newRowsPerPage => {
                            setPage(1)
                            setRowsPerPage(newRowsPerPage)
                            setLoading(false)
                        }}
                    />
                    <CustomPaging
                        totalCount={files.data.length ? files.meta.total : 0}
                    />
                    <DragDropProvider />
                    <Table
                        noDataCellComponent={props => {
                            return null
                        }}
                        tableComponent={props => <StickyTable {...props} setTableRef={setTableRef} />}
                        rowComponent={({ row, tableRow, children }) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.default}
                                row={row}
                            />
                        )}
                    />
                    <TableColumnReordering
                        order={columnOrder}
                        onOrderChange={setColumnOrder}
                    />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                    />
                    <TableHeaderRow />
                    <PagingPanel
                        containerComponent={Pager}
                        messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from}-{to} из {count}'}}
                        pageSizes={[50, 100, 200]}
                    />
                </GridTable>
            </Grid>
        </Grid>
    )
}
