import { SynonymService } from '../../services/attribute/synonym'

export const SynonymActions = {
    attributes,
    attribute,
    values,
    remove,
    save,
    filter
}

function attributes (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'SYNONYM_ATTRIBUTES_REQUEST' })

        SynonymService.attributes(params)
            .then(
                attributes => {
                    dispatch({ type: 'SYNONYM_ATTRIBUTES_SUCCESS', payload: attributes })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(attributes)
                },
                error => {
                    dispatch({ type: 'SYNONYM_ATTRIBUTES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function attribute (id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'SYNONYM_ATTRIBUTE_REQUEST' })

        SynonymService.attribute(id)
            .then(
                attribute => {
                    dispatch({ type: 'SYNONYM_ATTRIBUTE_SUCCESS', payload: attribute })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(attribute)
                },
                error => {
                    dispatch({ type: 'SYNONYM_ATTRIBUTE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function values (id, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'SYNONYM_ATTRIBUTE_VALUES_REQUEST' })

        SynonymService.values(id, params)
            .then(
                values => {
                    dispatch({ type: 'SYNONYM_ATTRIBUTE_VALUES_SUCCESS', payload: values })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(values.data)
                },
                error => {
                    dispatch({ type: 'SYNONYM_ATTRIBUTE_VALUES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function remove (id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'SYNONYM_DELETE_ATTRIBUTE_VALUE_REQUEST' })

        SynonymService.remove(id, params)
            .then(
                () => {
                    dispatch({ type: 'SYNONYM_DELETE_ATTRIBUTE_VALUE_SUCCESS' })
                    resolve()
                },
                error => {
                    dispatch({ type: 'SYNONYM_DELETE_ATTRIBUTE_VALUE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function save (id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'SYNONYM_SAVE_ATTRIBUTE_VALUES_REQUEST' })

        SynonymService.save(id, params)
            .then(
                () => {
                    dispatch({ type: 'SYNONYM_SAVE_ATTRIBUTE_VALUES_SUCCESS' })
                    resolve()
                },
                error => {
                    dispatch({ type: 'SYNONYM_SAVE_ATTRIBUTE_VALUES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function filter (id = null, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        SynonymService.filter(id, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}
