import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux"
import { Field, FieldArray } from 'formik'

import {
    Button,
    MenuItem,
    Grid,
    FormControlLabel,
    IconButton,
    Card,
    CardContent,
    makeStyles
} from '@material-ui/core'
import {DeleteSweep, Add, Clear, Settings} from '@material-ui/icons'
import {
    TextField, Switch, CheckboxWithLabel
} from 'formik-material-ui'
import Autocomplete from "@material-ui/lab/Autocomplete"

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    }
}))

const dictionaries = [
    {
        key: 'generics',
        name: 'Международное непатентованное наименование',
    },
    {
        key: 'active_substances',
        name: 'Действующее вещество'
    },
    {
        key: 'trade_marks',
        name: 'Торговая марка'
    },
    {
        key: 'release_forms',
        name: 'Форма выпуска'
    },
    {
        key: 'dosage_forms',
        name: 'Лекарственная форма'
    },
    {
        key: 'analyzers',
        name: 'Анализатор'
    }
]

const composite = {
    'composite_size': {
        'prefix': {
            name: 'Префикс',
            active: true,
            index: 1
        },
        'amount': {
            name: 'Количество',
            active: true,
            index: 2
        },
        'size.range': {
            name: 'Размерный ряд',
            active: true,
            index: 3
        },
        'size.charrier': {
            name: 'Размеры по Шарьеру',
            active: true,
            index: 4
        },
        'space': {
            name: 'Пробел/Разделитель',
            active: true,
            index: 5
        },
        'diameter.outer': {
            name: 'Диаметр',
            active: true,
            index: 6
        },
        'diameter.inner': {
            name: 'Внутренний диаметр',
            active: true,
            index: 7
        },
        'lengths': {
            name: 'Длина',
            active: true,
            index: 8
        },
        'width': {
            name: 'Ширина',
            active: true,
            index: 9
        },
        'height': {
            name: 'Высота',
            active: true,
            index: 10
        },
        'thickness': {
            name: 'Толщина',
            active: true,
            index: 11
        },
        'metric': {
            name: 'Метрик',
            active: true,
            index: 12
        },
        'gauge': {
            name: 'Размер Gauge',
            active: true,
            index: 13
        },
        'unit': {
            name: 'Общая Ед. изм.',
            active: true,
            index: 14
        },
        'postfix': {
            name: 'Постфикс',
            active: true,
            index: 15
        }
    },
    'composite_volume': {
        'prefix': {
            name: 'Префикс',
            active: true,
            index: 1
        },
        'values': {
            name: 'Объём, вес и дозы',
            active: true,
            index: 2
        },
        'postfix': {
            name: 'Постфикс',
            active: true,
            index: 3
        }
    }
}

export const Attribute = (props) => {
    const classes = useStyles()

    const {types} = useSelector(state => state.attribute_type)

    const [state, setState] = useState(null)
    const [options] = useState(types.filter(type => ((type.key === 'select') || (type.key === 'multiselect')) ).map(type => type.id))
    const [dictionary] = useState(types.filter(type => (type.key === 'dictionary')).map(type => type.id))

    const { attribute, index, remove, isSubmitting, setFieldValue, errors } = props

    useEffect(() => {
        if (!attribute.dictionary && !attribute.hasOwnProperty('id')) {
            setFieldValue(`attributes.${index}.multiple`, false)
        }
    }, [attribute.dictionary]);

    const multiple = (attribute) => {
        switch (types.find(item => (item.id === attribute.type.id)).key) {
            case 'dictionary':
                return true;
            case 'string':
                return !!attribute.dictionary;
            default:
                return false
        }
    }

    const directory = (type) => {
        switch (types.find(item => (item.id === type.id)).key) {
            case 'string':
            case 'volume':
            case 'composite_volume':
            case 'size':
            case 'composite_size':
            case 'dosage':
            case 'number_definitions':
            case 'needle':
                return true
            default:
                return false
        }
    }

    const addition = (type) => {
        switch (types.find(item => (item.id === type.id)).key) {
            case 'integer':
            case 'double':
            case 'string':
            case 'number':
                return true
            default:
                return false
        }
    }

    const space = (type) => {
        switch (types.find(item => (item.id === type.id)).key) {
            case 'integer_unit':
            case 'double_unit':
            case 'size':
            case 'volume':
                return true
            default:
                return false
        }
    }

    const params = (type) => {

        switch (type.key) {
            case 'composite_size':
            case 'composite_volume':
                return Object.keys(composite[type.key]).map(key => (
                    <Grid item key={key} className={classes.fullWidth}>
                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                            <Grid item xs={8}>
                                <FormControlLabel
                                    control={
                                        <Field
                                            color="primary"
                                            label={composite[type.key][key].name}
                                            name={`attributes.${index}.params['${key}'].active`}
                                            component={ Switch }
                                            disabled={ attribute.disabled || isSubmitting }
                                            inputProps={{
                                                onChange: event => {
                                                    setFieldValue(`attributes.${index}.params['${key}']`, {
                                                        active: event.target.checked,
                                                        index: ''
                                                    })
                                                }
                                            }}
                                        />
                                    }
                                    label={composite[type.key][key].name}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    name={`attributes.${index}.params['${key}'].index`}
                                    type="number"
                                    step={1}
                                    InputProps={{inputProps: { min: 0 }}}
                                    label="Порядок"
                                    disabled={ attribute.disabled || (attribute.params.hasOwnProperty(key) && !attribute.params[key].active) }
                                    component={ TextField }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                ))
            default:
                return null
        }
    }

    const render = () => {
        switch (state) {
            case 'delete':
                return <Grid item className={classes.fullWidth}>
                    <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
                        <Grid item>
                            <Button
                                disabled={ isSubmitting }
                                onClick={() => {
                                    setState(null)
                                }}
                                color="secondary"
                                type="button"
                            >
                                Отмена
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={ isSubmitting }
                                onClick={() => {
                                    remove(index)
                                    setState(null)
                                }}
                                color="primary"
                                type="button"
                            >
                                Подтвердить
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            case 'params':
                return <Grid item className={classes.fullWidth}>
                    <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                        {params(attribute.type)}
                        <Grid item className={classes.fullWidth}>
                            <Grid container direction='row' justify='flex-end' alignItems='center' spacing={2}>
                                <Grid item>
                                    <Button
                                        disabled={ isSubmitting }
                                        onClick={() => {
                                            setState(null)
                                        }}
                                        color="primary"
                                        type="button"
                                    >
                                        Применить
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            default:
                return <Grid item className={classes.fullWidth}>
                    <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                        <Grid item className={classes.fullWidth}>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item xs={8}>
                                    <FormControlLabel
                                        control={
                                            <Field
                                                color="primary"
                                                label="Эталонный"
                                                name={`attributes.${index}.assembly.standard.active`}
                                                component={ Switch }
                                                disabled={ attribute.disabled || isSubmitting }
                                            />
                                        }
                                        label="Эталонный"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        name={`attributes.${index}.assembly.standard.index`}
                                        type="number"
                                        step={1}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        label="Порядок"
                                        disabled={ attribute.disabled || isSubmitting || !attribute.assembly.standard.active }
                                        component={ TextField }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.fullWidth}>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item xs={8}>
                                    <FormControlLabel
                                        control={
                                            <Field
                                                color="primary"
                                                label="Типизированный"
                                                name={`attributes.${index}.assembly.typed.active`}
                                                component={ Switch }
                                                disabled={ attribute.disabled || isSubmitting }
                                            />
                                        }
                                        label="Типизированный"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        name={`attributes.${index}.assembly.typed.index`}
                                        type="number"
                                        step={1}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        label="Порядок"
                                        disabled={ attribute.disabled || isSubmitting || !attribute.assembly.typed.active }
                                        component={ TextField }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.fullWidth}>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item xs={8}>
                                    <FormControlLabel
                                        control={
                                            <Field
                                                color="primary"
                                                label="Произвольный"
                                                name={`attributes.${index}.assembly.arbitrary.active`}
                                                component={ Switch }
                                                disabled={ attribute.disabled || isSubmitting }
                                            />
                                        }
                                        label="Произвольный"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        name={`attributes.${index}.assembly.arbitrary.index`}
                                        type="number"
                                        step={1}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        label="Порядок"
                                        disabled={ attribute.disabled || isSubmitting || !attribute.assembly.arbitrary.active }
                                        component={ TextField }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.fullWidth}>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <Field
                                        fullWidth
                                        name={`attributes.${index}.name`}
                                        type="text"
                                        label="Наименование"
                                        disabled={ attribute.disabled || isSubmitting }
                                        component={ TextField }
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton
                                        aria-label="Удалить"
                                        disabled={ attribute.disabled || isSubmitting }
                                        onClick={() => {
                                            setState('delete')
                                        }}
                                    >
                                        <DeleteSweep />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.fullWidth}>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item xs={10}>
                                    <Field
                                        fullWidth
                                        type="text"
                                        name={`attributes.${index}.type`}
                                        label="Тип"
                                        select
                                        variant="standard"
                                        disabled={ attribute.hasOwnProperty('id') || attribute.disabled || isSubmitting }
                                        component={ TextField }
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{
                                            renderValue: value => value.name,
                                            onChange:async (event) => {
                                                let type = event.target.value

                                                await setFieldValue(`attributes.${index}.type`, type)

                                                switch (type.key) {
                                                    case 'composite_size':
                                                    case 'composite_volume':
                                                        setFieldValue(`attributes.${index}.params`, Object.entries(composite[type.key]).reduce((result, [key, values]) => {
                                                            result[`${key}`] = {
                                                                active: values.active,
                                                                index: values.index
                                                            }

                                                            return result
                                                        }, {}))
                                                        break
                                                    default:
                                                        setFieldValue(`attributes.${index}.params`, null)
                                                }
                                            }
                                        }}
                                    >
                                        {types.map(option => (
                                            <MenuItem key={option.id} value={option} selected={option.id === attribute.type?.id}>
                                                {`${option.name} ${option.composite ? '(Составной)' : ''}`}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton
                                        aria-label="Настройки"
                                        color={(errors && errors.hasOwnProperty('params') && errors.params) ? 'secondary' : 'default'}
                                        disabled={attribute.disabled || isSubmitting || !attribute.type?.composite}
                                        onClick={() => {
                                            setState('params')
                                        }}
                                    >
                                        <Settings />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        {((attribute.type && addition(attribute.type)) && attribute.addition) &&
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                    <Grid item sm={12} className={classes.fullWidth}>
                                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                            <Grid item sm={3} className={classes.fullWidth}>
                                                <Field
                                                    fullWidth
                                                    name={`attributes.${index}.addition.prefix.value`}
                                                    type="text"
                                                    label="Префикс"
                                                    disabled={ attribute.disabled || isSubmitting }
                                                    component={ TextField }
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <Field
                                                    color="primary"
                                                    label="Пробел"
                                                    name={`attributes.${index}.addition.prefix.space`}
                                                    component={ CheckboxWithLabel }
                                                    disabled={ attribute.disabled || !attribute.addition.prefix.value.length || isSubmitting }
                                                    Label={{ label: 'Пробел' }}
                                                />
                                            </Grid>
                                            <Grid item sm={3} className={classes.fullWidth}>
                                                <Field
                                                    fullWidth
                                                    name={`attributes.${index}.addition.postfix.value`}
                                                    type="text"
                                                    label="Постфикс"
                                                    disabled={ attribute.disabled || isSubmitting }
                                                    component={ TextField }
                                                />
                                            </Grid>
                                            <Grid item sm={3}>
                                                <Field
                                                    color="primary"
                                                    label="Пробел"
                                                    name={`attributes.${index}.addition.postfix.space`}
                                                    component={ CheckboxWithLabel }
                                                    disabled={ attribute.disabled || !attribute.addition.postfix.value.length || isSubmitting }
                                                    Label={{ label: 'Пробел' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {(attribute.type && space(attribute.type)) &&
                            <Grid item className={classes.fullWidth}>
                                <Grid container direction='row' justify='flex-start' alignItems='center' spacing={2}>
                                    <Grid item sm={12} className={classes.fullWidth}>
                                        <Field
                                            color="primary"
                                            label="Пробел между числом и ед.измерения"
                                            name={`attributes.${index}.space`}
                                            component={ CheckboxWithLabel }
                                            disabled={ attribute.disabled || isSubmitting }
                                            Label={{ label: 'Пробел между числом и ед.измерения' }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item className={classes.fullWidth}>
                            {((attribute.hasOwnProperty('type') && !!attribute.type) && options.includes(attribute.type.id)) && (
                                <FieldArray
                                    name={`attributes.${index}.options`}
                                    render={ arrayOptions => (
                                        <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                            {((attribute.hasOwnProperty('type') && !!attribute.type) && options.includes(attribute.type.id) && (attribute.hasOwnProperty('options') && !!attribute.options.length)) && (
                                                attribute.options.map((option, key) => (
                                                    <Grid item key={key} className={classes.fullWidth}>
                                                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                                            <Grid item sm={9} className={classes.fullWidth}>
                                                                <Field
                                                                    fullWidth
                                                                    name={`attributes.${index}.options.${key}.option`}
                                                                    type="text"
                                                                    label="Вариант"
                                                                    disabled={attribute.disabled || isSubmitting}
                                                                    component={ TextField }
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    onClick={() => arrayOptions.remove(key) }
                                                                    color="primary"
                                                                    aria-label="Удалить"
                                                                    disabled={attribute.disabled || isSubmitting}
                                                                    component="span"
                                                                >
                                                                    <Clear />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            )}
                                            <Grid item className={classes.fullWidth}>
                                                <IconButton
                                                    onClick={() => arrayOptions.push({ option: '' })}
                                                    color={(errors && errors.hasOwnProperty('option') && errors.option) ? 'secondary' : 'primary'}
                                                    aria-label="Добавить"
                                                    disabled={attribute.disabled || isSubmitting}
                                                    component="span"
                                                >
                                                    <Add />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )}
                                />
                            )}
                            {(attribute.hasOwnProperty('type') && !!attribute.type) && (dictionary.includes(attribute.type.id)) &&
                                <Field
                                    fullWidth
                                    type="text"
                                    name={`attributes.${index}.value`}
                                    label="Словари"
                                    select
                                    variant="standard"
                                    disabled={attribute.disabled || isSubmitting}
                                    component={ TextField }
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {dictionaries.map(option => (
                                        <MenuItem key={option.key} value={option.key}>
                                            { option.name }
                                        </MenuItem>
                                    ))}
                                </Field>
                            }
                        </Grid>
                        <Grid item className={classes.fullWidth}>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Field
                                                color="primary"
                                                label="Поиск"
                                                name={`attributes.${index}.search`}
                                                component={ Switch }
                                                disabled={ attribute.disabled || isSubmitting }
                                            />
                                        }
                                        label="Поиск"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Field
                                                color="primary"
                                                label="Приоритетный"
                                                name={`attributes.${index}.priority`}
                                                disabled={isSubmitting || !attribute.search || attribute.disabled}
                                                component={ Switch }
                                            />
                                        }
                                        label="Приоритетный"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.fullWidth}>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        multiple
                                        options={[]}
                                        freeSolo
                                        disabled={ isSubmitting || (!attribute.search || !attribute.assembly.standard.active) }
                                        defaultValue={attribute.exceptions}
                                        onChange={(e, value) => {
                                            setFieldValue(`attributes.${index}.exceptions`, value)
                                        }}
                                        renderInput={params => (
                                            <Field
                                                fullWidth
                                                {...params}
                                                name={`attributes.${index}.exceptions`}
                                                type="text"
                                                label="Исключения"
                                                component={ TextField }
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.fullWidth}>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Field
                                                color="primary"
                                                label="Обязательный"
                                                name={`attributes.${index}.required`}
                                                component={ Switch }
                                                disabled={attribute.disabled || isSubmitting}
                                            />
                                        }
                                        label="Обязательный"
                                    />
                                </Grid>
                                {(attribute.type && directory(attribute.type)) ? (
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    color="primary"
                                                    label="Справочник"
                                                    name={`attributes.${index}.dictionary`}
                                                    component={Switch}
                                                    disabled={(attribute.hasOwnProperty('id') && attribute.dictionary && attribute.multiple) || attribute.disabled || isSubmitting}
                                                />
                                            }
                                            label="Справочник"
                                        />
                                    </Grid>
                                ) : null}
                                {attribute.type && multiple(attribute) ? (
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    color="primary"
                                                    label="Мультивыбор"
                                                    name={`attributes.${index}.multiple`}
                                                    component={ Switch }
                                                    disabled={attribute.hasOwnProperty('id') || attribute.disabled || isSubmitting }
                                                />
                                            }
                                            label="Мультивыбор"
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
        }
    }

    return (
        <Grid item key={index} className={classes.fullWidth}>
            <Card className={classes.fullWidth}>
                <CardContent>
                    {render()}
                </CardContent>
            </Card>
        </Grid>
    )
}
