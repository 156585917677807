import React from 'react'
import {useSelector} from "react-redux"

import { Grid, CircularProgress, makeStyles }  from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        'height': '100%',
        'width': '100%',
        'display': 'flex',
        'z-index': 1600,
        'position': 'fixed',
        'opacity': 1,
        'background': '#ffffff'
    }
}))

export const Loading = () => {
    const classes = useStyles()
    const {loading} = useSelector(state => state.application)

    if (loading) {
        return (
            <Grid item className={ classes.root }>
                <Grid container alignItems='center' direction='column' justify='center'>
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    return null
}
