import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
    Grid, makeStyles
} from '@material-ui/core'
import {blue} from "@material-ui/core/colors"
import {
    PagingState, CustomPaging, SelectionState
} from '@devexpress/dx-react-grid'
import {
    DragDropProvider, Table, TableHeaderRow, TableColumnResizing, PagingPanel, TableColumnReordering, TableSelection
} from '@devexpress/dx-react-grid-material-ui'

import {StickyTable} from "../../../App/components/Table/StikyTable"
import {Grid as GridTable} from "../../../App/components/Table/Grid"
import {Pager} from "../../../App/components/Table/Paging/Pager"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import {SynonymActions} from "../../actions/category/synonym"
import {history} from "../../../App/helpers/history"
import useMousetrap from "react-hook-mousetrap";
import {TableHeaderContent} from "./Category/Table/TableHeaderContent";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    data: {
        'height': 'calc(100% - 20px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '52px'
    },
    active: {
        'height': '41px',
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    body: {
        'height': '100%'
    }
}))

export const Categories = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {categories, filter} = useSelector(state => state.synonym)

    const {loading, setLoading, page, setPage, search} = props

    const columns = [
        { name: 'number', title: '№'},
        { name: 'name', title: 'Категория' },
        { name: 'count', title: 'Количество вариаций' },
        { name: 'created', title: 'Дата создания' },
        { name: 'updated', title: 'Дата обновления' }
    ]

    const [tableRef, setTableRef] = useState(null)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [selection, setSelection] = useState([])
    const [columnWidths, setColumnWidths] = useState([
        { columnName: 'number', width: 75 },
        { columnName: 'name', width: 450 },
        { columnName: 'count', width: 175 },
        { columnName: 'created', width: 200 },
        { columnName: 'updated', width: 200 }
    ])
    const [columnOrder, setColumnOrder] = useState(['number', 'name', 'count', 'created', 'updated'])

    useEffect(() => {
        const getData = async () => {
            return await dispatch(SynonymActions.categories({
                page: page,
                limit: rowsPerPage,
                ...(search ? {search: search} : {}),
                ...((filter.sort.name && filter.sort.direction) ? {order: [filter.sort.name, filter.sort.direction]} : []),
                ...((filter.hasOwnProperty('name') && filter.name.length) ? {category: filter.name} : [])
            }))
        }

        if (!loading) {
            getData().then(props => {
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setLoading(true)
            })
        }
    }, [loading, page, rowsPerPage, search])

    useEffect(() => {
        setPage(1)
        setLoading(false)
    }, [filter])

    useMousetrap(["up", 'down'], (event) => {
        if (selection.length) {
            const index = selection[0]

            switch (event.code) {
                case 'ArrowUp':
                    if (index > 0) {
                        setSelection([index - 1])
                    }
                    break
                case 'ArrowDown':
                    if (index + 1 < categories.data.length) {
                        setSelection([index + 1])
                    }
                    break
            }
        }
    }, 'keydown')

    return (
        <Grid item className={classes.data}>
            <GridTable
                rows={categories.data.map((category, index) => ({
                    'number': index + 1,
                    'name': category.name,
                    'count': category.count,
                    'created': category.created ? format(new Date(category.created), 'H:mm PP',  {locale: ru}) : null,
                    'updated': category.updated ? format(new Date(category.updated), 'H:mm PP',  {locale: ru}) : null
                }))}
                columns={columns}
            >
                <PagingState
                    currentPage={page}
                    onCurrentPageChange={newPage => {
                        setPage(newPage)
                        setLoading(false)
                    }}
                    pageSize={rowsPerPage}
                    onPageSizeChange={newRowsPerPage => {
                        setPage(1)
                        setRowsPerPage(newRowsPerPage)
                        setLoading(false)
                    }}
                />
                <CustomPaging
                    totalCount={categories.data.length ? categories.meta.total : 0}
                />
                <SelectionState
                    selection={selection}
                    onSelectionChange={(numbers) => {
                        const current = numbers.filter(x => !selection.includes(x))

                        if (!current.length) {
                            history.push(`/dictionary/synonym/category/${categories.data[selection[0]].id}/values`)
                        } else {
                            setSelection(numbers.filter(x => !selection.includes(x)))
                        }
                    }}
                />
                <DragDropProvider />
                <Table
                    noDataCellComponent={props => {
                        return null
                    }}
                    tableComponent={props => <StickyTable {...props} setTableRef={setTableRef} />}
                    rowComponent={({ row, tableRow, children }) => (
                        <Table.Row
                            tableRow={tableRow}
                            children={children}
                            className={classes.default}
                            row={row}
                        />
                    )}
                />
                <TableColumnReordering
                    order={columnOrder}
                    onOrderChange={setColumnOrder}
                />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                />
                <TableHeaderRow
                    contentComponent={TableHeaderContent}
                />
                <TableSelection
                    selectByRowClick
                    highlightRow
                    showSelectionColumn={false}
                    rowComponent={(props) => {
                        const { tableRow, children, highlighted, onToggle } = props
                        const { row } = tableRow
                        return (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                onClick={onToggle}
                                className={highlighted ? classes.active : classes.default}
                                row={row}
                            />
                        )
                    }}
                />
                <PagingPanel
                    containerComponent={Pager}
                    messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from}-{to} из {count}'}}
                    pageSizes={[50, 100, 200]}
                />
            </GridTable>
        </Grid>
    )
}
