import { HttpService } from '../../App/services/http'

export const ItemService = {
    items,
    values,
    categories,
    remove,
    category,
    standard,
    description,
    variation
}

function items (id, params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/markup/file/${id}/items`, options, true)
        .then(response => {
            return response
        })
}

function values (id, params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/markup/file/${id}/items/values`, options, true)
        .then(response => {
            return response
        })
}

function categories (id, params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/markup/file/${id}/items/categories`, options, true)
        .then(response => {
            return response
        })
}

function remove (id, params) {
    const options = {
        method: 'DELETE',
        body: JSON.stringify(params)
    }

    return HttpService.http(`/markup/file/${id}/items`, options, true)
        .then(response => {
            return response
        })
}

function category (id, values) {
    const options = {
        method: 'PUT',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/markup/file/${id}/category/items`, options, true)
        .then(response => {
            return response.data
        })
}

function standard (id, item, values) {
    const options = {
        method: 'PUT',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/markup/file/${id}/standard/item/${item}`, options, true)
        .then(response => {
            return response.item
        })
}

function description (id, item, values) {
    const options = {
        method: 'PUT',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/markup/file/${id}/description/item/${item}`, options, true)
        .then(response => {
            return response
        })
}

function variation (id, item, values) {
    const options = {
        method: 'PUT',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/markup/file/${id}/variation/item/${item}`, options, true)
        .then(response => {
            return response.item
        })
}
