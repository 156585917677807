import React, {useState} from 'react'

import {
    Grid,
    Typography,
    Table as TableMUI,
    TableRow as TableRowMUI,
    TableCell,
    Checkbox,
    TableBody,
    TableContainer,
    IconButton,
    makeStyles,
    Popover
} from '@material-ui/core'
import {blue, red} from "@material-ui/core/colors"
import {Settings} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    fullHeight: {
        'height': '100%'
    },
    popover: {
        'width': '300px'
    },
    filter: {
        'width': '100%'
    },
    filterItems: {
        'width': '100%',
        'height': 'calc(100% - 158px)',
        'overflow': 'auto'
    },
    filterItem: {
        'white-space': 'normal !important'
    },
    field: {
        'height': '100%'
    },
    data: {
        'height': 'calc(100% - 60px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '40px'
    },
    table: {
        'height': '100%'
    },
    fab: {
        'margin': '0',
        'top': 'auto',
        'right': '90px',
        'bottom': '25px',
        'left': 'auto',
        'position': 'fixed'
    },
    activeCategory: {
        'background-color': red[100],
        '&:hover': {
            'background-color': red[50] + '!important',
        }
    },
    defaultCategory: {
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    active: {
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    confirmation: {
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    default: {
        '&:hover': {
            'background-color': `${blue[50]} !important`
        }
    },
    visuallyHidden: {
        "border": "0",
        "clip": "rect(0 0 0 0)",
        "height": "1",
        "margin": "-1",
        "overflow": "hidden",
        "padding": "0",
        "position": "absolute",
        "top": "20",
        "width": "1",
    },
    attributes: {
        '& .MuiSelect-selectMenu': {
            'padding-top': '9px'
        }
    },
    body: {
        'height': '100%'
    },
    item: {
        'height': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    },
    sortLabelRoot: {
        maxWidth: '100%',
    },
    sortLabelRight: {
        flexDirection: 'row-reverse',
    },
    sortLabelActive: {
        color: 'inherit',
    },
    toolbar: {
        'padding-left': '0 !important'
    },
    actions: {
        'margin-left': '0 !important'
    },
    filterContent: {
        width: '100%',
        padding: theme.spacing(2)
    },
    divider: {
        width: '100%'
    }
}))

export const ColumnChooser = (props) => {
    const classes = useStyles()

    const {columns, hiddenColumnNames, setHiddenColumnNames} = props
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    return (
        <React.Fragment>
            <IconButton
                size="small"
                onClick={(event) => {
                    setAnchorEl(event.currentTarget)
                }}
            >
                <Settings />
            </IconButton>
            {open ? <Popover
                classes={{
                    paper: classes.popover
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => {
                    setAnchorEl(null)
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                    <Grid item className={classes.filterContent}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                            <Grid item className={classes.fullWidth}>
                                <TableContainer>
                                    <TableMUI stickyHeader>
                                        <TableBody>
                                            {columns.map((column, index) => {
                                                const isItemSelected = (hiddenColumnNames.indexOf(column.name) !== -1)

                                                return (
                                                    <TableRowMUI
                                                        key={index}
                                                        onClick={(event) => {
                                                            const selectedIndex = hiddenColumnNames.indexOf(column.name)

                                                            let select = []

                                                            if (selectedIndex === -1) {
                                                                select = select.concat(hiddenColumnNames, column.name)
                                                            } else if (selectedIndex === 0) {
                                                                select = select.concat(hiddenColumnNames.slice(1))
                                                            } else if (selectedIndex === hiddenColumnNames.length - 1) {
                                                                select = select.concat(hiddenColumnNames.slice(0, -1))
                                                            } else if (selectedIndex > 0) {
                                                                select = select.concat(
                                                                    hiddenColumnNames.slice(0, selectedIndex),
                                                                    hiddenColumnNames.slice(selectedIndex + 1),
                                                                )
                                                            }

                                                            setHiddenColumnNames(select)
                                                        }}
                                                        role="checkbox"
                                                        selected={isItemSelected}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox checked={isItemSelected}/>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <Typography variant="body2" noWrap className={classes.filterItem}>{column.title}</Typography>
                                                        </TableCell>
                                                    </TableRowMUI>
                                                )
                                            })}
                                        </TableBody>
                                    </TableMUI>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover> : null}
        </React.Fragment>
    )
}
