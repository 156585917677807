import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Formik, Form, Field} from 'formik'
import * as Yup from "yup"

import {
    Dialog, DialogTitle, DialogContent, DialogActions, Grid, IconButton, makeStyles, CircularProgress, Button
} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {TextField} from "formik-material-ui";
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    }
}))

export const PositionForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {units} = useSelector(state => state.unit)

    const {open, handleClose, handleSubmit, position, callback} = props
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (open) {
            setLoading(true)
        }
    }, [open])

    return (
        <Formik
            initialValues={{
                original: position ? position.original : '',
                desired: position ? position.desired : '',
                expression: position ? position.expression : ''
            }}
            validationSchema={Yup.object().shape({
                original: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                desired: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                expression: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
            })}
            onSubmit={(values, {setSubmitting, setErrors}) => {
                handleSubmit(values, position ? position.id : null).then(
                    () => {
                        setSubmitting(false)
                        if (callback) {
                            callback(values.value)
                        }
                        dispatch(handleClose)
                    },
                    error => {
                        setErrors(error)
                        setSubmitting(false)
                    }
                )
            }}
        >
            {({
                  values,
                  errors,
                  handleSubmit,
                  isSubmitting,
                  setSubmitting,
                  setValues,
                  setFieldValue,
                  setFieldTouched
              }) => (
                <Form>
                    <Dialog
                        fullWidth={true}
                        maxWidth="sm"
                        open={loading}
                        onClose={handleClose}
                        classes={{
                            paper: classes.dialog
                        }}
                    >
                        <DialogTitle>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item>
                                    {(position ? 'Редактировать' : 'Создать')}
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label="Закрыть"
                                        onClick={() => handleClose()}
                                    >
                                        <Close/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                        <Grid item sm={6} className={classes.fullWidth}>
                                            <Autocomplete
                                                filterOptions={createFilterOptions({
                                                    matchFrom: 'start',
                                                    stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                })}
                                                options={ units.data }
                                                name="original"
                                                disabled={ isSubmitting }
                                                defaultValue={values.original}
                                                getOptionLabel={option => (option instanceof Object) ? option.short : option}
                                                onChange={(e, value) => {
                                                    setFieldValue("original", value)
                                                }}
                                                onBlur={(e) => {
                                                    if (units.data.find(item => item.short === e.target.value)) {
                                                        setFieldTouched("original", true)
                                                    } else {
                                                        setFieldValue("original", '')
                                                    }
                                                }}
                                                renderInput={params => (
                                                    <Field
                                                        fullWidth
                                                        {...params}
                                                        name="original"
                                                        type="text"
                                                        label="Начальная ед. изм."
                                                        component={ TextField }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item sm={6} className={classes.fullWidth}>
                                            <Autocomplete
                                                filterOptions={createFilterOptions({
                                                    matchFrom: 'start',
                                                    stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                                                })}
                                                options={ units.data }
                                                name="original"
                                                disabled={ isSubmitting }
                                                defaultValue={values.desired}
                                                getOptionLabel={option => (option instanceof Object) ? option.short : option}
                                                onChange={(e, value) => {
                                                    setFieldValue("desired", value)
                                                }}
                                                onBlur={(e) => {
                                                    if (units.data.find(item => item.short === e.target.value)) {
                                                        setFieldTouched("desired", true)
                                                    } else {
                                                        setFieldValue("desired", '')
                                                    }
                                                }}
                                                renderInput={params => (
                                                    <Field
                                                        fullWidth
                                                        {...params}
                                                        name="desired"
                                                        type="text"
                                                        label="Конечная ед. изм."
                                                        component={ TextField }
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                        <Grid item className={classes.fullWidth}>
                                            <Field
                                                fullWidth
                                                name={`expression`}
                                                type="text"
                                                label="Выражение"
                                                multiline
                                                rows={4}
                                                component={ TextField }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                                color="primary"
                                type="submit"
                            >
                                {(isSubmitting ? <CircularProgress size={24}/> : (position ? 'Сохранить' : 'Создать'))}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    )
}
