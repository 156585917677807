import { HttpService } from '../../App/services/http'

export const TermService = {
    values,
    remove,
    save,
    filter
}

function values (id, params) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/dictionary/synonym/attribute/${id}/term/values`, options, true)
        .then(response => {
            return response
        })
}

function remove (id, params) {
    const options = {
        method: 'DELETE',
        body: JSON.stringify(params)
    }

    return HttpService.http(`/dictionary/synonym/attribute/${id}/term/values`, options, true)
        .then(response => {
            return response
        })
}

function save (id, params) {
    const options = {
        method: 'PUT',
        body: JSON.stringify(params)
    }

    return HttpService.http(`/dictionary/synonym/attribute/${id}/term/values`, options, true)
        .then(response => {
            return response
        })
}

function filter (id, params = {}) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/dictionary/synonym/attribute/${id}/term/values/filter`, options, true)
        .then(response => {
            return response
        })
}
