import { HttpService } from '../../../App/services/http'

export const SynonymService = {
    categories,
    category,
    values,
    remove,
    save,
    filter
}

function categories (params) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/dictionary/synonym/categories`, options, true)
        .then(response => {
            return response
        })
}

function category (id) {
    const options = {
        method: 'GET'
    }

    return HttpService.http(`/dictionary/synonym/category/${id}`, options, true)
        .then(response => {
            return response.category
        })
}

function values (id, params) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/dictionary/synonym/category/${id}/values`, options, true)
        .then(response => {
            return response
        })
}

function remove (id, params) {
    const options = {
        method: 'DELETE',
        body: JSON.stringify(params)
    }

    return HttpService.http(`/dictionary/synonym/category/${id}/values`, options, true)
        .then(response => {
            return response
        })
}

function save (id, params) {
    const options = {
        method: 'PUT',
        body: JSON.stringify(params)
    }

    return HttpService.http(`/dictionary/synonym/category/${id}/values`, options, true)
        .then(response => {
            return response
        })
}

function filter (id = null, params = {}) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(id ? `/dictionary/synonym/category/${id}/values/filter` : `/dictionary/synonym/categories/filter`, options, true)
        .then(response => {
            return response
        })
}

