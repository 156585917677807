import React from 'react'
import {useDispatch} from "react-redux"
import { Formik, Field, Form } from 'formik'
import * as Yup from "yup"

import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    makeStyles
} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {
    TextField
} from 'formik-material-ui'
import {FileUpload} from "../../App/components/Input/FileUpload"

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    }
}))

export const FileForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {handleUnloading, handleClose, open} = props

    return (
        <Formik
            initialValues={{
                file: null,
                separator: {
                    column: '',
                    text: ''
                }
            }}
            validationSchema={Yup.object().shape({
                file: Yup.mixed().test('fileFormat', 'Файл должен быть одного из следующих типов: csv.', (value) => {
                    return ((value && ['text/csv', 'text/plain', 'text/x-csv', 'application/vnd.ms-excel'].includes(value.type)) || (value && !value.type && value.name))
                }),
                separator: Yup.object().shape({
                    column: Yup.string().required("Укажите разделитель столбцов!"),
                    text: Yup.string()
                })
            })}
            onSubmit={(values, {setSubmitting, setErrors}) => {
                const formData = new FormData()

                for (const name in values) {
                    if (values.hasOwnProperty(name)) {
                        switch (name) {
                            case 'separator':
                                Object.keys(values[name]).forEach(key => {
                                    if (!!values[name][key].length) {
                                        formData.append(`${name}[${key}]`, values[name][key])
                                    }
                                })
                                break
                            case 'file':
                                formData.append(`${name}`, values[name])
                                break
                        }
                    }
                }

                handleUnloading(formData).then(
                    () => {
                        setSubmitting(false)
                        dispatch(handleClose)
                    },
                    errors => {
                        setErrors(errors)
                        setSubmitting(false)
                    }
                )
            }}
        >
            {({
                  values,
                  errors,
                  handleSubmit,
                  isSubmitting,
                  setSubmitting,
                  setValues,
                  setFieldValue,
                  setFieldTouched
              }) => (
                <Form>
                    <Dialog
                        fullWidth={true}
                        maxWidth="sm"
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="Загрузка файла"
                        classes={{
                            paper: classes.dialog
                        }}
                    >
                        <DialogTitle>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item>
                                    Загрузить файл
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label="Закрыть"
                                        onClick={() => handleClose()}
                                    >
                                        <Close/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <Grid container direction='row' justify='space-between' alignItems='center'
                                          spacing={2}>
                                        <Grid item sm={6} className={classes.fullWidth}>
                                            <Field
                                                fullWidth
                                                name="separator.column"
                                                type="text"
                                                label="Разделитель столбцов"
                                                disabled={isSubmitting}
                                                component={TextField}
                                            />
                                        </Grid>
                                        <Grid item sm={6} className={classes.fullWidth}>
                                            <Field
                                                fullWidth
                                                name="separator.text"
                                                type="text"
                                                label="Разделитель текста"
                                                disabled={isSubmitting}
                                                component={TextField}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <Field
                                        fullWidth
                                        size="small"
                                        component={(props) => (
                                            <FileUpload {...props} endIcon={"Выбрать файл"}/>
                                        )}
                                        name="file"
                                        InputLabelProps={{
                                            name: values.file ? values.file.name : null
                                        }}
                                        InputProps={{
                                            onChange: (event) => {
                                                setFieldValue(`file`, event.currentTarget.files[0])
                                                setFieldTouched(`file`, true, false)
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                                color="primary"
                                type="submit"
                            >
                                {isSubmitting ? <CircularProgress size={24}/> : 'Загрузить'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    )
}
