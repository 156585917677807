import React from 'react'
import {firstRowOnPage, lastRowOnPage} from '@devexpress/dx-grid-core'

import {makeStyles, IconButton} from "@material-ui/core"
import {ChevronLeft, ChevronRight} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    pagination: {
        margin: 0
    },
    rowsLabel: {
        ...theme.typography.body2,
        paddingRight: theme.spacing(3)
    },
    button: {
        minWidth: theme.spacing(2)
    },
    activeButton: {
        fontWeight: 'bold',
        cursor: 'default'
    },
    arrowButton: {
        display: 'inline-block',
        transform: theme.direction === 'rtl' ? 'rotate(180deg)' : null,
        msTransform: theme.direction === 'rtl' ? 'rotate(180deg)' : null
    },
    prev: {
        marginRight: 0
    },
    next: {
        marginLeft: 0
    },
    '@media(max-width: 768px)': {
        button: {
            display: 'none'
        },
        prev: {
            marginRight: theme.spacing(1)
        },
        next: {
            marginLeft: theme.spacing(1)
        },
        rowsLabel: {
            paddingRight: theme.spacing(2)
        }
    }
}))

export const Pagination = ({
    totalPages,
    totalCount,
    pageSize,
    currentPage,
    onCurrentPageChange,
    getMessage,
}) => {
    const classes = useStyles()

    const from = firstRowOnPage(currentPage - 1, pageSize, totalCount)
    const to = lastRowOnPage(currentPage - 1, pageSize, totalCount)

    return (
        <div className={classes.pagination}>
            <span className={classes.rowsLabel}>
                {getMessage('info', { from, to, count: totalCount })}
            </span>
            <IconButton
                color="inherit"
                disabled={currentPage === 1}
                onClick={() => (currentPage > 1) && onCurrentPageChange(currentPage - 1)}
                aria-label="Previous"
            >
                <ChevronLeft />
            </IconButton>
            <IconButton
                color="inherit"
                disabled={(currentPage === totalPages) || totalCount === 0}
                onClick={() => (currentPage <= (totalPages - 1)) && onCurrentPageChange(currentPage + 1)}
                aria-label="Next"
            >
                <ChevronRight />
            </IconButton>
        </div>
    )
}
