import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
    Button,
    Grid, IconButton, makeStyles, withStyles
} from '@material-ui/core'
import {blue} from "@material-ui/core/colors"
import {
    PagingState, CustomPaging, SelectionState, IntegratedSelection
} from '@devexpress/dx-react-grid'
import {
    DragDropProvider, Table, TableHeaderRow, TableColumnResizing, PagingPanel, TableColumnReordering, TableSelection
} from '@devexpress/dx-react-grid-material-ui'

import {StickyTable} from "../../../App/components/Table/StikyTable"
import {Grid as GridTable} from "../../../App/components/Table/Grid"
import {Pager} from "../../../App/components/Table/Paging/Pager"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import {SynonymActions} from "../../actions/unit/synonym"
import {history} from "../../../App/helpers/history"
import Alert from "@material-ui/lab/Alert";
import {UnitForm} from "./Unit/UnitForm";
import {DeleteForm} from "./Unit/DeleteForm";
import {endings} from "../../../App/helpers/endings";
import useMousetrap from "react-hook-mousetrap";
import {TableHeaderContent} from "./Unit/Table/TableHeaderContent";
import {DownloadActions} from "../../../Download/actions/download";
import {GetApp} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    data: {
        'height': 'calc(100% - 72px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '52px'
    },
    active: {
        'height': '41px',
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    body: {
        'height': '100%'
    }
}))

const StyledAlert = withStyles((theme) => ({
    message: {
        'padding': '0'
    },
}))(Alert)

export const Units = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {units, filter} = useSelector(state => state.synonym)

    const {loading, setLoading, page, setPage, search} = props

    const columns = [
        { name: 'name', title: 'Наименование' },
        { name: 'short', title: 'Краткое наименование' },
        { name: 'active', title: 'Статус' },
        { name: 'count', title: 'Количество записей' },
        { name: 'created', title: 'Дата создания' },
        { name: 'updated', title: 'Дата обновления' }
    ]

    const [tableRef, setTableRef] = useState(null)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [selection, setSelection] = useState([])
    const [select, setSelect] = useState(null)
    const [deleted, setDeleted] = useState(false)
    const [change, setChange] = useState(false)
    const [selectionPage, setSelectionPage] = useState({})
    const [columnWidths, setColumnWidths] = useState([
        { columnName: 'name', width: 450 },
        { columnName: 'short', width: 200 },
        { columnName: 'active', width: 135 },
        { columnName: 'count', width: 175 },
        { columnName: 'created', width: 200 },
        { columnName: 'updated', width: 200 }
    ])
    const [columnOrder, setColumnOrder] = useState(['name', 'short', 'active', 'count', 'created', 'updated'])

    useEffect(() => {
        const getData = async () => {
            return await dispatch(SynonymActions.units({
                page: page,
                limit: rowsPerPage,
                ...(search ? {search: search} : {}),
                ...((filter.sort.name && filter.sort.direction) ? {order: [filter.sort.name, filter.sort.direction]} : []),
                ...((filter.hasOwnProperty('value') && filter.value.length) ? {value: filter.value} : []),
                ...((filter.hasOwnProperty('short') && filter.short.length) ? {short: filter.short} : []),
                ...((filter.hasOwnProperty('active') && filter.active.length) ? {status: filter.active} : {status: ['active', 'inactive'].join(',')})
            }))
        }

        if (!loading) {
            getData().then(props => {
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setLoading(true)
            })
        }
    }, [loading, page, rowsPerPage, search])

    useEffect(() => {
        setPage(1)
        setLoading(false)
    }, [filter])

    const handleDelete = (id, params) => {
        return dispatch(SynonymActions.remove(id, params)).then(
            () => {
                setSelect(null)
                setSelectionPage({})
                setSelection([])
                setLoading(false)
            }
        )
    }

    const handleSave = (id, params) => {
        return dispatch(SynonymActions.save(id, params)).then(
            () => {
                setSelect(null)
                setSelectionPage({})
                setSelection([])
                setLoading(false)
            }
        )
    }

    useMousetrap(["up", 'down'], (event) => {
        if (select) {
            const index = units.data.findIndex(item => (item.id === select))

            switch (event.code) {
                case 'ArrowUp':
                    if (index > 0) {
                        setSelect(units.data[index - 1].id)
                        setChange(false)
                    }
                    break
                case 'ArrowDown':
                    if (index + 1 < units.data.length) {
                        setSelect(units.data[index + 1].id)
                        setChange(false)
                    }
                    break
            }
        }
    }, 'keydown')

    return (
        <React.Fragment>
            <Grid item className={classes.breadcrumb}>
                <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                    <Grid item>
                        <Button
                            disabled={ false }
                            onClick={e => {
                                e.stopPropagation()
                                setSelect(null)
                                setChange(true)
                            }}
                            color="primary"
                            type="button"
                        >
                            Добавить
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={ !select  }
                            onClick={e => {
                                e.stopPropagation()
                                setChange(true)
                            }}
                            color="primary"
                            type="button"
                        >
                            Редактировать
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={ !select  }
                            onClick={e => {
                                e.stopPropagation()
                                history.push(`/dictionary/synonym/unit/${select}/values`)
                            }}
                            color="primary"
                            type="button"
                        >
                            Вариации
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={ !Object.keys(selectionPage).length && !select }
                            onClick={e => {
                                e.stopPropagation()
                                setDeleted(true)
                            }}
                            color="secondary"
                            type="button"
                        >
                            Удалить
                        </Button>
                    </Grid>
                    <Grid item>
                        <IconButton
                            type="button"
                            size="small"
                            onClick={() => {
                                dispatch(DownloadActions.create({
                                    type: 'units',
                                    columns: columnOrder,
                                    params: {
                                        ...((filter.sort.name && filter.sort.direction) ? {order: `${filter.sort.name}, ${filter.sort.direction}`} : []),
                                        ...(search ? {search: search} : {}),
                                        ...((filter.hasOwnProperty('value') && filter.value.length) ? {value: filter.value.join(',')} : []),
                                        ...((filter.hasOwnProperty('short') && filter.short.length) ? {short: filter.short.join(',')} : []),
                                        ...((filter.hasOwnProperty('active') && filter.active.length) ? {status: filter.active.join(',')} : {status: ['active', 'inactive'].join(',')})
                                    }
                                }))
                            }}
                        >
                            <GetApp />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.data}>
                <GridTable
                    rows={units.data.map(unit => ({
                        'id': unit.id,
                        'name': unit.name,
                        'short': unit.short,
                        'active': unit.active ? <StyledAlert icon={false} color="success">Активный</StyledAlert> : <StyledAlert icon={false} color="error">Неактивный</StyledAlert>,
                        'count': unit.count,
                        'created': unit.created ? format(new Date(unit.created), 'H:mm PP',  {locale: ru}) : null,
                        'updated': unit.updated ? format(new Date(unit.updated), 'H:mm PP',  {locale: ru}) : null
                    }))}
                    columns={columns}
                >
                    <PagingState
                        currentPage={page}
                        onCurrentPageChange={newPage => {
                            setPage(newPage)
                            setLoading(false)
                        }}
                        pageSize={rowsPerPage}
                        onPageSizeChange={newRowsPerPage => {
                            setPage(1)
                            setRowsPerPage(newRowsPerPage)
                            setLoading(false)
                        }}
                    />
                    <CustomPaging
                        totalCount={units.data.length ? units.meta.total : 0}
                    />
                    <SelectionState
                        selection={selection}
                        onSelectionChange={(numbers) => {
                            setSelection(numbers)
                            const data = units.data.filter((value, index) => (numbers.find(key => (key === index)) !== undefined)).map(value => value.id)
                            setSelectionPage(!!data.length ? {...selectionPage, [page]: data} : Object.fromEntries(
                                Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                            ))
                        }}
                    />
                    <IntegratedSelection />
                    <DragDropProvider />
                    <Table
                        noDataCellComponent={props => {
                            return null
                        }}
                        tableComponent={props => <StickyTable onClick={e => e.stopPropagation()} {...props} setTableRef={setTableRef} />}
                        rowComponent={({ row, tableRow, children }) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.default}
                                row={row}
                            />
                        )}
                    />
                    <TableColumnReordering
                        order={columnOrder}
                        onOrderChange={setColumnOrder}
                    />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                    />
                    <TableHeaderRow
                        contentComponent={TableHeaderContent}
                    />
                    <TableSelection
                        selectByRowClick
                        showSelectAll
                        rowComponent={(props) => {
                            const { tableRow, children, highlighted } = props
                            const { row } = tableRow
                            return (
                                <Table.Row
                                    tableRow={tableRow}
                                    children={children}
                                    onClick={() => {
                                        if (select === row.id) {
                                            setChange(true)
                                        }
                                        setSelect(row.id)
                                    }}
                                    className={(highlighted || (select === row.id)) ? classes.active : classes.default}
                                    row={row}
                                />
                            )
                        }}
                    />
                    <PagingPanel
                        containerComponent={Pager}
                        messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from}-{to} из {count}'}}
                        pageSizes={[50, 100, 200]}
                    />
                </GridTable>
            </Grid>
            {change && (
                function () {
                    return <UnitForm
                        unit={units.data.find(unit => (unit.id === select))}
                        open={change}
                        handleSave={(id, params) => {
                            return handleSave(id, params)
                        }}
                        handleInitialize={() => {
                            setSelect(null)
                        }}
                        handleClose={() => {
                            setChange(false)
                        }}
                    />
                }()
            )}
            {deleted && (
                function () {
                    const items = Object.entries(selectionPage).map(([key, value]) => value).flat()

                    return <DeleteForm
                        label="Удаление позиций"
                        text={`Вы действительно хотите удалить ${endings(!!items.length ? items.length : 1, ['позицию', 'позиции', 'позиций'])}? `}
                        open={deleted}
                        handleDelete={() => {
                            return handleDelete({ids: !!items.length ? items : [select]})
                        }}
                        handleClose={() => {setDeleted(false)}}
                    />
                }()
            )}
        </React.Fragment>
    )
}
