import React, {useEffect, useState} from 'react'

import {
    Grid,
    TextField,
    makeStyles, withStyles, Breadcrumbs, IconButton, Typography, Button
} from '@material-ui/core'
import {blue, red} from "@material-ui/core/colors"

import {useDebouncedCallback} from 'use-debounce'
import {useDispatch, useSelector} from "react-redux";
import {Grid as GridTable} from "../../../App/components/Table/Grid";
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import {CustomPaging, IntegratedSelection, PagingState, SelectionState} from "@devexpress/dx-react-grid";
import {
    DragDropProvider, PagingPanel,
    Table,
    TableColumnReordering,
    TableColumnResizing, TableColumnVisibility, TableHeaderRow, TableSelection
} from "@devexpress/dx-react-grid-material-ui";
import {StickyTable} from "../../../App/components/Table/StikyTable";
import {TableHeaderContent} from "./Units/Table/TableHeaderContent";
import {Pager} from "../../../App/components/Table/Paging/Pager";
import {ColumnChooser} from "../../../Item/components/Table/ColumnChooser";
import {Link} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import {GetApp, Home, NavigateNext} from "@material-ui/icons";
import {UnitActions} from "../../actions/unit";
import {UnitForm} from "./Units/UnitForm";
import {DownloadActions} from "../../../Download/actions/download";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    data: {
        'height': 'calc(100% - 72px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '52px'
    },
    active: {
        'height': '41px',
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    confirmation: {
        'height': '41px',
        'background-color': red[50],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    body: {
        'height': '100%'
    }
}))


const StyledAlert = withStyles((theme) => ({
    message: {
        'padding': '0'
    },
}))(Alert)

export const Units = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {units, filter} = useSelector(state => state.unit)

    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [initialize, setInitialize] = useState(false)

    const columns = [
        { name: 'name', title: 'Наименование' },
        { name: 'short', title: 'Краткое наименование' },
        { name: 'code', title: 'Код' },
        { name: 'type', title: 'Тип' },
        { name: 'group', title: 'Группа' },
        { name: 'active', title: 'Статус' },
        { name: 'created', title: 'Дата создания' },
        { name: 'updated', title: 'Дата обновления' }
    ]

    const [unit, setUnit] = useState(null)
    const [dialog, setDialog] = useState(false)

    const [tableRef, setTableRef] = useState(null)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [select, setSelect] = useState(null)
    const [selection, setSelection] = useState([])
    const [columnWidths, setColumnWidths] = useState([
        { columnName: 'name', width: 450 },
        { columnName: 'short', width: 250 },
        { columnName: 'code', width: 250 },
        { columnName: 'type', width: 250 },
        { columnName: 'group', width: 250 },
        { columnName: 'active', width: 200 },
        { columnName: 'created', width: 200 },
        { columnName: 'updated', width: 200 }
    ])
    const [columnOrder, setColumnOrder] = useState(['name', 'short', 'code', 'type', 'group', 'active', 'created', 'updated'])
    const [hiddenColumnNames, setHiddenColumnNames] = useState(['type', 'group'])

    const debounced = useDebouncedCallback(
        (value) => {
            setLoading(value)
        }, 700
    )

    useEffect(() => {
        const getData = async () => {
            await dispatch(UnitActions.types())
            await dispatch(UnitActions.groups())
        }

        if (!initialize) {
            getData().then(() => {
                setInitialize(true)
            })
        }

        if (initialize) {
            return () => {
                dispatch({type: 'UNITS_CLEAR'})
                dispatch({type: 'UNIT_FILTER', payload: {sort: {name: null, direction: null}}})
            }
        }
    }, [initialize])

    useEffect(() => {
        const getData = async () => {
            return await dispatch(UnitActions.units({
                page: page,
                limit: rowsPerPage,
                ...(search ? {search: search} : {}),
                ...((filter.sort.name && filter.sort.direction) ? {order: [filter.sort.name, filter.sort.direction]} : []),
                ...((filter.hasOwnProperty('name') && filter.name.length) ? {name: filter.name} : []),
                ...((filter.hasOwnProperty('short') && filter.short.length) ? {short: filter.short} : []),
                ...((filter.hasOwnProperty('code') && filter.code.length) ? {code: filter.code} : []),
                ...((filter.hasOwnProperty('type') && filter.type.length) ? {type: filter.type} : []),
                ...((filter.hasOwnProperty('group') && filter.group.length) ? {group: filter.group} : []),
                ...((filter.hasOwnProperty('active') && filter.active.length) ? {status: filter.active} : {status: ['active', 'inactive', 'confirmed']})
            }))
        }

        if (initialize && !loading) {
            getData().then(props => {
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setLoading(true)
                setSelection([])
            })
        }
    }, [initialize, loading, page, rowsPerPage, search])

    useEffect(() => {
        setPage(1)
        setLoading(false)
    }, [filter])

    const getStatus = item => {
        if (item.active) {
            return <StyledAlert icon={false} color="success">Активный</StyledAlert>
        } else if (item.confirmed) {
            return <StyledAlert icon={false} color="warning">Неактивный</StyledAlert>
        }
        return <StyledAlert icon={false} color="error">Не проверено</StyledAlert>
    }

    const handleSave = (values, id = null) => {
        if (id) {
            return new Promise((resolve, reject) => {
                dispatch(UnitActions.edit(id, values)).then(
                    () => {
                        setLoading(false)
                        resolve()
                    },
                    errors => {
                        reject(errors)
                    }
                )
            })
        } else {
            return new Promise((resolve, reject) => {
                dispatch(UnitActions.add(values)).then(
                    () => {
                        setLoading(false)
                        resolve()
                    },
                    errors => {
                        reject(errors)
                    }
                )
            })
        }
    }

    const handleDelete = (id) => {
        return new Promise((resolve, reject) => {
            dispatch(UnitActions.remove(id)).then(
                () => {
                    setSelect(null)
                    setLoading(false)
                    resolve()
                },
                errors => {
                    reject(errors)
                }
            )
        })
    }

    return initialize ? (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={ classes.body } spacing={2}>
            <Grid item className={classes.element}>
                <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                    <Grid item sm={3}>
                        <TextField
                            fullWidth
                            label='Поиск'
                            value={search}
                            onChange={event => {
                                setPage(1)
                                setSearch(event.target.value)
                                debounced.callback(false)
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.breadcrumb}>
                <Grid container direction="row" justify="space-between" alignItems="center" style={{ height: '38px' }}>
                    <Grid item>
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
                            <IconButton
                                type="button"
                                size="small"
                                component={Link}
                                to="/dictionaries"
                            >
                                <Home />
                            </IconButton>
                            <Typography variant="subtitle2" color="textPrimary">Единицы измерения</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item>
                                <Button
                                    disabled={!select || !!units.data.find(item => (item.id === select)).confirmed}
                                    onClick={() => {
                                        setUnit(units.data.find(item => (item.id === select)))
                                        setDialog(true)
                                    }}
                                    color="primary"
                                    type="button"
                                >
                                    Подтвердить
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        setDialog(true)
                                    }}
                                    color="primary"
                                    type="button"
                                >
                                    Добавить
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={!select || !units.data.find(item => (item.id === select)).confirmed}
                                    onClick={() => {
                                        setUnit(units.data.find(item => (item.id === select)))
                                        setDialog(true)
                                    }}
                                    color="primary"
                                    type="button"
                                >
                                    Редактировать
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={!select || (!units.data.find(item => (item.id === select)).confirmed || !units.data.find(item => (item.id === select)).active)}
                                    onClick={() => {
                                        handleDelete(select).then(() => {
                                            setLoading(false)
                                        })
                                    }}
                                    color="secondary"
                                    type="button"
                                >
                                    Удалить
                                </Button>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    type="button"
                                    size="small"
                                    onClick={() => {
                                        dispatch(DownloadActions.create({
                                            type: 'units',
                                            columns: columnOrder.filter(name => (hiddenColumnNames.indexOf(name) < 0)),
                                            params: {
                                                ...(search ? {search: search} : {}),
                                                ...((filter.sort.name && filter.sort.direction) ? {order: `${filter.sort.name}, ${filter.sort.direction}`} : []),
                                                ...((filter.hasOwnProperty('name') && filter.name.length) ? {name: filter.name.join(',')} : []),
                                                ...((filter.hasOwnProperty('short') && filter.short.length) ? {short: filter.short.join(',')} : []),
                                                ...((filter.hasOwnProperty('code') && filter.code.length) ? {code: filter.code.join(',')} : []),
                                                ...((filter.hasOwnProperty('type') && filter.type.length) ? {type: filter.type.join(',')} : []),
                                                ...((filter.hasOwnProperty('group') && filter.group.length) ? {group: filter.group.join(',')} : []),
                                                ...((filter.hasOwnProperty('active') && filter.active.length) ? {status: filter.active.join(',')} : {status: ['active', 'inactive', 'confirmed'].join(',')})
                                            }
                                        }))
                                    }}
                                >
                                    <GetApp />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.data}>
                <GridTable
                    rows={units.data.map(item => ({
                        'id': item.id,
                        'name': item.name,
                        'short': item.short,
                        'code': item.code,
                        'type': item.type ? item.type.name : null,
                        'group': item.group ? item.group.name : null,
                        'confirmed': item.confirmed,
                        'active': getStatus(item),
                        'created': item.created ? format(new Date(item.created), 'H:mm PP',  {locale: ru}) : null,
                        'updated': item.updated ? format(new Date(item.updated), 'H:mm PP',  {locale: ru}) : null
                    }))}
                    columns={columns}
                >
                    <PagingState
                        currentPage={page}
                        onCurrentPageChange={newPage => {
                            setPage(newPage)
                            setLoading(false)
                        }}
                        pageSize={rowsPerPage}
                        onPageSizeChange={newRowsPerPage => {
                            setPage(1)
                            setRowsPerPage(newRowsPerPage)
                            setLoading(false)
                        }}
                    />
                    <CustomPaging
                        totalCount={units.data.length ? units.meta.total : 0}
                    />
                    <SelectionState
                        selection={selection}
                        onSelectionChange={(numbers) => {
                            const current = numbers.filter(x => !selection.includes(x))

                            if (!current.length) {
                                setUnit(units.data[selection[0]])
                                setDialog(true)
                            } else {
                                setSelection(numbers.filter(x => !selection.includes(x)))
                            }
                        }}
                    />
                    <IntegratedSelection />
                    <DragDropProvider />
                    <Table
                        noDataCellComponent={props => {
                            return null
                        }}
                        tableComponent={props => <StickyTable onClick={e => e.stopPropagation()} {...props} setTableRef={setTableRef} />}
                        rowComponent={({ row, tableRow, children }) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.default}
                                row={row}
                            />
                        )}
                    />
                    <TableColumnReordering
                        order={columnOrder}
                        onOrderChange={setColumnOrder}
                    />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                    />
                    <TableHeaderRow
                        contentComponent={(props) => <TableHeaderContent {...props} />}
                    />
                    <TableColumnVisibility
                        hiddenColumnNames={hiddenColumnNames}
                        onHiddenColumnNamesChange={setHiddenColumnNames}
                    />
                    <TableSelection
                        selectByRowClick
                        highlightRow
                        showSelectionColumn={false}
                        rowComponent={(props) => {
                            const { tableRow, children, highlighted, onToggle } = props
                            const { row } = tableRow
                            return (
                                <Table.Row
                                    tableRow={tableRow}
                                    children={children}
                                    onClick={() => {
                                        setSelect(row.id)
                                        onToggle()
                                    }}
                                    className={(highlighted || (select === row.id)) ? classes.active : (row.confirmed ? classes.default : classes.confirmation)}
                                    row={row}
                                />
                            )
                        }}
                    />
                    <PagingPanel
                        containerComponent={Pager}
                        messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from}-{to} из {count}'}}
                        pageSizes={[50, 100, 200]}
                    />
                </GridTable>
            </Grid>
            {dialog &&
                <UnitForm
                    open={dialog}
                    unit={unit}
                    handleClose={() => {
                        setSelect(null)
                        setSelection([])
                        setUnit(null)
                        setDialog(false)
                    }}
                    handleSave={handleSave}
                    handleReject={handleDelete}
                />
            }
        </Grid>
    ) : null
}
