import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
    Grid,
    ListItemIcon,
    ListItemText,
    Typography,
    Select,
    MenuItem,
    TextField,
    Badge,
    Breadcrumbs,
    IconButton,
    makeStyles,
    Button, withStyles
} from '@material-ui/core'
import {blue, red} from "@material-ui/core/colors"
import {Check, ExpandLess, ExpandMore, GetApp} from '@material-ui/icons'
import Alert from '@material-ui/lab/Alert'
import {
    SelectionState, PagingState, CustomPaging, IntegratedSelection
} from '@devexpress/dx-react-grid'
import {
    DragDropProvider, Table, TableSelection, TableHeaderRow , TableColumnResizing, PagingPanel,
    TableColumnReordering, TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui'

import {SystemActions} from "../App/actions/system"
import {UnitActions} from "../Dictionary/actions/unit"
import {ItemActions} from "./actions/item"
import {ItemForm} from "./components/ItemForm"
import {useDebouncedCallback} from 'use-debounce'
import { stringFormat } from '../App/helpers/string'
import {StickyTable} from "../App/components/Table/StikyTable"
import {Grid as GridTable} from "../App/components/Table/Grid"
import {Pager} from "../App/components/Table/Paging/Pager"
import {standard} from "../App/helpers/standard"
import {TableHeaderContent} from "./components/Table/TableHeaderContent"
import {ColumnChooser} from "./components/Table/ColumnChooser"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import {MessageActions} from "../App/actions/message"
import {AuthorizationService} from "../Auth/services/authorization";
import {UnionForm} from "./components/UnionForm";
import {DownloadActions} from "../Download/actions/download";
import {SettingsActions} from "../Settings/actions/settings";
import {getValue} from "../Category/components/Attribute/helpers/value";
import {Upload} from "./components/Upload";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    fullHeight: {
        'height': '100%'
    },
    filter: {
        'width': '100%'
    },
    filterItems: {
        'width': '100%',
        'height': 'calc(100% - 158px)',
        'overflow': 'auto'
    },
    filterItem: {
        'white-space': 'normal !important'
    },
    field: {
        'height': '100%'
    },
    data: {
        'height': 'calc(100% - 72px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '52px'
    },
    table: {
        'height': '100%'
    },
    fab: {
        'margin': '0',
        'top': 'auto',
        'right': '90px',
        'bottom': '25px',
        'left': 'auto',
        'position': 'fixed'
    },
    activeCategory: {
        'background-color': red[50],
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    defaultCategory: {
        '&:hover': {
            'background-color': 'rgba(0, 0, 0, 0.04) !important',
        }
    },
    active: {
        'height': '41px',
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    inactive: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    confirmation: {
        'height': '41px',
        'background-color': red[50],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    visuallyHidden: {
        "border": "0",
        "clip": "rect(0 0 0 0)",
        "height": "1",
        "margin": "-1",
        "overflow": "hidden",
        "padding": "0",
        "position": "absolute",
        "top": "20",
        "width": "1",
    },
    attributes: {
        '& .MuiSelect-selectMenu': {
            'padding-top': '9px'
        }
    },
    body: {
        'height': '100%'
    },
    item: {
        'height': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    },
    sortLabelRoot: {
        maxWidth: '100%',
    },
    sortLabelRight: {
        flexDirection: 'row-reverse',
    },
    sortLabelActive: {
        color: 'inherit',
    },
    toolbar: {
        'padding-left': '0 !important'
    },
    actions: {
        'margin-left': '0 !important'
    },
    filterContent: {
        width: '100%',
        padding: theme.spacing(2)
    },
    divider: {
        width: '100%'
    }
}))

const StyledBadge = withStyles((theme) => ({
    badge: {
        'transform': 'none',
        'top': 4
    }
}))(Badge)

const StyledAlert = withStyles((theme) => ({
    message: {
        'padding': '0'
    },
}))(Alert)

export const Items = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {categories} = useSelector(state => state.system)
    const {items, offers, filter} = useSelector(state => state.item)
    const {account} = useSelector(state => state.account)
    const [search, setSearch] = useState('')
    const [tableRef, setTableRef] = useState(null)

    const debounced = useDebouncedCallback(
        (value) => {
            setLoading(value)
        }, 700
    )

    const [page, setPage] = useState(1)
    const [open, setOpen] = useState({})
    const [item, setItem] = useState(null)
    const [category, setCategory] = useState(undefined)
    const [dialog, setDialog] = useState(false)
    const [union, setUnion] = useState(false)
    const [unionItems, setUnionItems] = useState([])
    const [agreement, setAgreement] = useState(false)
    const [copy, setCopy] = useState(false)
    const [rows, setRows] = useState([])
    const [columns, setColumns] = useState([
        { name: 'id', title: 'Id'},
        { name: 'value.keyword', title: 'Эталон' },
        { name: 'value.typed', title: 'Типизированный эталон' },
        { name: 'value.arbitrary', title: 'Произвольный эталон' },
        { name: 'status', title: 'Статус' },
        { name: 'category', title: 'Категория'},
        { name: 'source.type', title: 'Источник'},
        { name: 'source.module', title: 'Модуль'},
        { name: 'source.company', title: 'Наименование компании'},
        { name: 'source.user', title: 'ФИО пользователя'},
        { name: 'source.login', title: 'Login пользователя'},
        { name: 'source.contacts', title: 'Контактная информация'},
        { name: 'created', title: 'Дата создания' },
        { name: 'updated', title: 'Дата изменения' },
    ])
    const [hiddenColumnNames, setHiddenColumnNames] = useState(['value.typed', 'value.arbitrary', 'source.module', 'source.company', 'source.user', 'source.login', 'source.contacts'])

    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [selection, setSelection] = useState([])
    const [select, setSelect] = useState(null)
    const [selectionPage, setSelectionPage] = useState({})
    const [order, setOrder] = useState({columnName: 'confirmed', direction: 'asc'})
    const [categoryColumns, setCategoryColumns] = useState([])
    const [loading, setLoading] = useState(false)
    const [scroll, setScroll] = useState(true)
    const [initialize, setInitialize] = useState(false)
    const [reload, setReload] = useState(false)
    const [columnWidths, setColumnWidths] = useState([
        { columnName: 'id', width: 100 },
        { columnName: 'status', width: 135 },
        { columnName: 'value.keyword', width: 500 },
        { columnName: 'value.typed', width: 500 },
        { columnName: 'value.arbitrary', width: 500 },
        { columnName: 'category', width: 500 },
        { columnName: 'source.type', width: 200 },
        { columnName: 'source.module', width: 250 },
        { columnName: 'source.company', width: 250 },
        { columnName: 'source.user', width: 250 },
        { columnName: 'source.login', width: 250 },
        { columnName: 'source.contacts', width: 250 },
        { columnName: 'created', width: 200 },
        { columnName: 'updated', width: 200 },
    ])
    const [columnOrder, setColumnOrder] = useState(['id', 'status', 'value.keyword', 'value.typed', 'value.arbitrary', 'category', 'source.type', 'source.module', 'source.company', 'source.user', 'source.login', 'source.contacts', 'created', 'updated'])

    const getRow = (item, categories) => {
        const struct = getCategory(item, categories);

        let obj = {
            id: item.id,
            confirmed: item.confirmed,
            active: item.active,
            status: getStatus(item),
            ...(!category ? {
                category: struct.map((name, index) => {
                    return <Typography key={index} component="span" variant={(getCategory(item, categories).length === (index + 1)) ? "body2" : "caption"}>{`${(!!index ? ' / ' : '')}${name}`}</Typography>
                })
            } : {
                category: struct.shift(),
                section: struct.shift(),
                group: struct.shift()
            }),
            'value.keyword': standard(categories.find(cat => item.category.id === cat.id), categories, item.values),
            'value.typed': function () {
                let type = item.assembly.find(item => {
                    return (item.type.key === 'typed')
                })

                return type ? type.value : null
            }(),
            'value.arbitrary': function () {
                let type = item.assembly.find(item => {
                    return (item.type.key === 'arbitrary')
                })

                return type ? type.value : null
            }(),
            'source.type': item.source ? function () {
                switch (item.source.type) {
                    case 'customer':
                        return 'Заказчик'
                    case 'supplier':
                        return 'Поставщик'
                    default:
                        return 'Система'
                }
            }() : 'Система',
            'source.module': item.source ? item.source.module : null,
            'source.company': item.source ? item.source.company : null,
            'source.user': item.source ? item.source.user : null,
            'source.login': item.source ? item.source.login : null,
            'source.contacts': item.source ? function () {
                if (item.source.contacts) {
                    return Object.entries(item.source.contacts).reduce((contacts, [type, contact]) => {
                        if (contact) {
                            switch (type) {
                                case 'phones':
                                    contacts = `${contacts.length ? `${contacts}; ` : ''}тел.: ${contact.map(phone => { return `${phone.number} ${phone.extra ? `доб. ${phone.extra}` : ''}` }).join(', ')}`
                                    break
                                case 'emails':
                                    contacts = `${contacts.length ? `${contacts}; ` : ''}эл. почта: ${contact.join(', ')}`
                                    break
                            }
                        }

                        return contacts
                    }, '')
                }

                return null
            }() : null,
            created: format(new Date(item.created), 'H:mm PP',  {locale: ru}),
            updated: format(new Date(item.updated ?? item.created), 'H:mm PP',  {locale: ru}),
        }

        categoryColumns.forEach(column => {
            const value = item.values.find((value) => { return (value.attribute.name === column.label) ? value : null })

            let val = null

            if (value) {
                switch (value.attribute.type.key) {
                    case 'boolean':
                        val = <Check />
                        break
                    default:
                        val = getValue(value.attribute, value, value.attribute.dictionary, true)
                }

                switch (column.label) {
                    case 'Дозировка':
                    case 'Объем, вес и дозы':
                    case 'Вес, объем':
                    case 'Фасовка':
                    case 'Количество':
                    case 'Фасовка/Количество':
                    case 'Концентрация':
                    case 'Игла':
                    case 'Плотность/жесткость':
                        return obj[`attribute_${column.id}${((column.type !== 'integer') && (column.type !== 'double')) ? '.keyword' : ''}`] = stringFormat(val)
                    default:
                        return obj[`attribute_${column.id}${((column.type !== 'integer') && (column.type !== 'double')) ? '.keyword' : ''}`] = val
                }
            }

            return val
        })

        return obj
    }

    useEffect(() => {
        if (initialize && reload) {
            dispatch(ItemActions.offers()).then(offers => {
                if (!!offers.fresh.count) {
                    let categoriesString = ''

                    const keys = Object.keys(offers.fresh.categories)

                    keys.forEach(id => {
                        categoriesString = `${categoriesString.length ? `${categoriesString}, ` : ''}${categories.find(cat => cat.id === parseInt(id)).name}`
                    })

                    dispatch(MessageActions.info('Новое предложение', `В ${(keys.length > 1) ? 'категории' : 'категорию'} "${categoriesString}" ${(offers.fresh.count > 1) ? 'поступили новые предложения эталонов' : 'поступило новое предложения эталона'}. Для просмотра обновите страницу.`))
                }
                setReload(false)
            })
        }
    }, [initialize, reload])

    useEffect(() => {
        let interval = setInterval(() => setReload(true), 60000)

        return () => clearInterval(interval)
    })

    useEffect(() => {
        if (loading && initialize) {
            dispatch(SettingsActions.update({
                name: category?.hasOwnProperty('id') ? `category_${category.id}_standards` : 'standards',
                hidden: hiddenColumnNames,
                order: columnOrder
            }))
        }
        // eslint-disable-next-line
    }, [hiddenColumnNames, columnOrder])

    useEffect(() => {
        const getData = async () => {
            let data = {items, categories, offers}

            if (!initialize) {
                dispatch(UnitActions.units())
                dispatch(SettingsActions.table('standards')).then((settings) => {
                    if (settings) {
                        setHiddenColumnNames(settings.hidden)
                        setColumnOrder(settings.order)
                    }
                })
                data.offers = await dispatch(ItemActions.offers())
                data.categories = await dispatch(SystemActions.categories())
            }

            let sort = Object.entries(filter.selected).reduce((result, [key, values]) => {
                if (values.length) {
                    result += result.length ? `,${values.join(',')}` : values.join(',')
                }

                return result
            }, '')

            let conditions = Object.entries(filter.conditions).reduce((result, [id, values]) => {
                Object.keys(values).map(key => {
                    if (values[key]) result[`conditions[${id}][${key}]`] = values[key]
                })

                return result
            }, {})

            let attributes = Object.entries(filter.attributes).reduce((result, [id, values]) => {
                result[`attributes[${id}]`] = values

                return result
            }, {})

            data.items = await dispatch(ItemActions.items({
                page: page,
                limit: rowsPerPage,
                order: (filter.sort.name && filter.sort.direction) ? [filter.sort.name, filter.sort.direction] : [order.columnName, order.direction],
                type: 'standard',
                ...(!!category ? {category: category.id} : {}),
                ...(search ? {search: search} : {}),
                ...(!!sort.length ? {sort: sort} : {}),
                ...(!!Object.keys(conditions).length ? conditions : {}),
                ...(!!Object.keys(attributes).length ? attributes : {}),
                ...(!!filter.status.length ? {status: filter.status} : []),
                ...(!!filter.source.length ? {source: filter.source} : []),
                ...(!!filter.company.length ? {company: filter.company} : []),
                ...(!!filter.module.length ? {module: filter.module} : []),
                ...(!!filter.standard.length ? {id: filter.standard} : []),
            }))

            return data
        }

        if (!loading) {
            getData().then(props => {
                const {items, categories, offers} = props

                items.filter(item => !item.viewed).map(item => {
                    offers.fresh = {
                        categories: {
                            ...offers.fresh.categories,
                            ...{[item.category.id]: offers.fresh.categories.hasOwnProperty(item.category.id) ? offers.fresh.categories[item.category.id] - 1 : 0}
                        },
                        count: offers.fresh.count - 1
                    }
                })

                dispatch({type: 'ITEM_OFFERS_SUCCESS', payload: offers})

                if (scroll) tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setRows(items.map(item => getRow(item, categories)))

                let numbers = []

                if (selectionPage.hasOwnProperty(page)) {
                    items.forEach((item, index) => {
                        if (selectionPage[page].find(id => (id === item.id))) {
                            numbers.push(index)
                        }
                    })
                }

                setSelection(numbers)

                setScroll(true)
                setLoading(true)
                setInitialize(true)
            })
        }
    }, [loading, page, rowsPerPage, category, search])

    useEffect(() => {
        if (!dialog) {
            setAgreement(false)
            setCopy(false)
            setItem(null)
            setSelect(null)
            setRows(items.data.map(item => getRow(item, categories)))
        }
    }, [dialog])

    useEffect(() => {
        setPage(1)
        setLoading(false)
    }, [filter])

    const handleDelete = (item, params) => {
        return dispatch(ItemActions.remove(item.id, params)).then(
            () => {
                if (!item.confirmed) {
                    let categories = offers.offers.categories
                    if (categories.hasOwnProperty(item.category.id)) {
                        if (categories[item.category.id] > 1) {
                            --categories[item.category.id]
                        } else {
                            delete categories[item.category.id]
                        }
                    }

                    dispatch({ type: 'ITEM_OFFERS_SUCCESS', payload: {offers: { count: offers.offers.count - 1, categories: categories }, fresh: offers.fresh} })
                }

                setLoading(false)
            }
        )
    }

    const handleSave = (values, id = null) => {
        if (id) {
            return dispatch(ItemActions.save(id, values)).then(
                () => {
                    if (values.hasOwnProperty('confirmed') && values.confirmed) {
                        let categories = offers.offers.categories
                        if (categories.hasOwnProperty(values.category)) {
                            if (categories[values.category.id] > 1) {
                                --categories[values.category.id]
                            } else {
                                delete categories[values.category.id]
                            }
                        }

                        dispatch({ type: 'ITEM_OFFERS_SUCCESS', payload: {offers: { count: offers.offers.count - 1, categories: categories }, fresh: offers.fresh} })
                    }
                    setScroll(false)
                    setLoading(false)
                }
            )
        } else {
            return dispatch(ItemActions.add(values)).then(
                () => {
                    setLoading(false)
                }
            )
        }
    }

    const handleChange = event => {
        const category = event.target.value

        dispatch({type: 'ATTRIBUTE_VALUES_CLEAR'})
        dispatch({type: 'ITEM_FILTER_CATEGORY', payload: category?.id})
        dispatch({type: 'ITEM_FILTER', payload: {category: null, order: [], selected: {}, conditions: {}, attributes: {}, sort: {name: null, direction: null}, status: [], source: [], company: [], module: [], standard: []}})

        setCategory(category ?? undefined)
        setPage(1)
        setRows([])
        setSearch('')
        setOrder({columnName: 'confirmed', direction: 'asc'})

        if (!category) {
            setColumns([
                { name: 'id', title: 'Id'},
                { name: 'value.keyword', title: 'Эталон' },
                { name: 'value.typed', title: 'Типизированный эталон' },
                { name: 'value.arbitrary', title: 'Произвольный эталон' },
                { name: 'status', title: 'Статус' },
                { name: 'category', title: 'Категория'},
                { name: 'source.type', title: 'Источник'},
                { name: 'source.module', title: 'Модуль'},
                { name: 'source.company', title: 'Наименование компании'},
                { name: 'source.user', title: 'ФИО пользователя'},
                { name: 'source.login', title: 'Login пользователя'},
                { name: 'source.contacts', title: 'Контактная информация'},
                { name: 'created', title: 'Дата создания' },
                { name: 'updated', title: 'Дата изменения' }
            ])
            setCategoryColumns([])
            setColumnWidths([
                { columnName: 'id', width: 100 },
                { columnName: 'status', width: 135 },
                { columnName: 'value.keyword', width: 500 },
                { columnName: 'value.typed', width: 500 },
                { columnName: 'value.arbitrary', width: 500 },
                { columnName: 'category', width: 500 },
                { columnName: 'source.type', width: 200 },
                { columnName: 'source.module', width: 250 },
                { columnName: 'source.company', width: 250 },
                { columnName: 'source.user', width: 250 },
                { columnName: 'source.login', width: 250 },
                { columnName: 'source.contacts', width: 250 },
                { columnName: 'created', width: 200 },
                { columnName: 'updated', width: 200 },
            ])
            dispatch(SettingsActions.table('standards')).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                } else {
                    setColumnOrder(['id', 'status', 'value.keyword', 'value.typed', 'value.arbitrary', 'category', 'source.type', 'source.module', 'source.company', 'source.user', 'source.login', 'source.contacts', 'created', 'updated'])
                }
            })
        } else if (category.hasOwnProperty('attributes')) {
            let columnsData = []

            let parent = category.category ? categories.find(cat => (cat.id === category.category.id)) : null

            while (parent) {
                let tmp = []

                parent.attributes.forEach((attribute) => {
                    tmp.push({
                        id: attribute.id,
                        index: attribute.index,
                        label: attribute.name,
                        type: attribute.type.key,
                        align: 'center',
                        format: value => value.toLocaleString()
                    })
                })

                columnsData = tmp.concat(columnsData)

                parent = parent.category ? categories.find(cat => (cat.id === parent.category.id)) : null
            }

            category.attributes.forEach((attribute) => {
                columnsData.push({
                    id: attribute.id,
                    index: attribute.index,
                    label: attribute.name,
                    type: attribute.type.key,
                    align: 'center',
                    format: value => value.toLocaleString()
                })
            })
            setColumns([
                { name: 'id', title: 'Id'},
                { name: 'value.keyword', title: 'Эталон' },
                { name: 'status', title: 'Статус' },
                { name: 'section', title: 'Подкатегория' },
                { name: 'group', title: 'Группа' },
                { name: 'value.typed', title: 'Типизированный эталон' },
                { name: 'value.arbitrary', title: 'Произвольный эталон' },
                ...(columnsData.sort((first, second) => {
                    return (!first.index || (second.index && (first.index > second.index))) ? 1 : ((!second.index || first.index < second.index) ? -1 : 0)
                }).map(column => {
                    return { name: `attribute_${column.id}${((column.type !== 'integer') && (column.type !== 'double')) ? '.keyword' : ''}`, title: column.label, attribute: column}
                })),
                { name: 'source.type', title: 'Источник'},
                { name: 'source.module', title: 'Модуль'},
                { name: 'source.company', title: 'Наименование компании'},
                { name: 'source.user', title: 'ФИО пользователя'},
                { name: 'source.login', title: 'Имя пользователя'},
                { name: 'source.contacts', title: 'Контактная информация'},
                { name: 'created', title: 'Дата создания' },
                { name: 'updated', title: 'Дата изменения' },
            ])
            setCategoryColumns(columnsData)
            setColumnWidths([
                { columnName: 'id', width: columnWidths.find(item => (item.columnName === 'id')) ? columnWidths.find(item => (item.columnName === 'id')).width : 100 },
                { columnName: 'status', width: columnWidths.find(item => (item.columnName === 'status')) ? columnWidths.find(item => (item.columnName === 'status')).width : 135 },
                { columnName: 'section', width: columnWidths.find(item => (item.columnName === 'section')) ? columnWidths.find(item => (item.columnName === 'section')).width : 300 },
                { columnName: 'group', width: columnWidths.find(item => (item.columnName === 'group')) ? columnWidths.find(item => (item.columnName === 'group')).width : 300 },
                { columnName: 'value.keyword', width: columnWidths.find(item => (item.columnName === 'value.keyword')) ? columnWidths.find(item => (item.columnName === 'value.keyword')).width : 500 },
                { columnName: 'value.typed', width: columnWidths.find(item => (item.columnName === 'value.typed')) ? columnWidths.find(item => (item.columnName === 'value.typed')).width : 500 },
                { columnName: 'value.arbitrary', width: columnWidths.find(item => (item.columnName === 'value.arbitrary')) ? columnWidths.find(item => (item.columnName === 'value.arbitrary')).width : 500 },
                ...(columnsData.sort((first, second) => {
                    return (!first.index || (second.index && (first.index > second.index))) ? 1 : ((!second.index || first.index < second.index) ? -1 : 0)
                }).map(column => {
                    return { columnName: `attribute_${column.id}${((column.type !== 'integer') && (column.type !== 'double')) ? '.keyword' : ''}`, width: columnWidths.find(item => (item.columnName === `attribute_${column.id}${((column.type !== 'integer') && (column.type !== 'double')) ? '.keyword' : ''}`)) ? columnWidths.find(item => (item.columnName === `attribute_${column.id}${((column.type !== 'integer') && (column.type !== 'double')) ? '.keyword' : ''}`)).width : 180 }
                })),
                { columnName: 'source.type', width: columnWidths.find(item => (item.columnName === 'source.type')) ? columnWidths.find(item => (item.columnName === 'source.type')).width : 250 },
                { columnName: 'source.module', width: columnWidths.find(item => (item.columnName === 'source.module')) ? columnWidths.find(item => (item.columnName === 'source.module')).width : 250 },
                { columnName: 'source.company', width: columnWidths.find(item => (item.columnName === 'source.company')) ? columnWidths.find(item => (item.columnName === 'source.company')).width : 250 },
                { columnName: 'source.user', width: columnWidths.find(item => (item.columnName === 'source.user')) ? columnWidths.find(item => (item.columnName === 'source.user')).width : 250 },
                { columnName: 'source.login', width: columnWidths.find(item => (item.columnName === 'source.login')) ? columnWidths.find(item => (item.columnName === 'source.login')).width : 250 },
                { columnName: 'source.contacts', width: columnWidths.find(item => (item.columnName === 'source.contacts')) ? columnWidths.find(item => (item.columnName === 'source.contacts')).width : 250 },
                { columnName: 'created', width: columnWidths.find(item => (item.columnName === 'created')) ? columnWidths.find(item => (item.columnName === 'created')).width : 200 },
                { columnName: 'updated', width: columnWidths.find(item => (item.columnName === 'updated')) ? columnWidths.find(item => (item.columnName === 'updated')).width : 200 },
            ])

            dispatch(SettingsActions.table(`category_${category.id}_standards`)).then((settings) => {
                if (settings) {
                    setHiddenColumnNames(settings.hidden)
                    setColumnOrder(settings.order)
                } else {
                    setColumnOrder(['id', 'status', 'section', 'group', 'value.keyword', 'value.typed', 'value.arbitrary', ...columnsData.map(column => `attribute_${column.id}${((column.type !== 'integer') && (column.type !== 'double')) ? '.keyword' : ''}`), 'source.type', 'source.module', 'source.company', 'source.user', 'source.login', 'source.contacts', 'created', 'updated'])
                }
            })
        }

        setLoading(false)
    }

    const assembly = (categories, parent = 0, level = 0) => {
        let result = !!parent ? {offer: false, elements: [], offers: 0} : []
        if (categories.hasOwnProperty(parent)) {
            let global = 0
            categories[parent].sort((first, second) => {
                return (first.name > second.name) ? 1 : (second.name > first.name) ? -1 : 0
            }).forEach(category => {
                const children = assembly(categories, category.id, level + 1)

                if (parent) {
                    result.offer = result.offer || (children.offer || offers.offers.categories.hasOwnProperty(category.id))
                    result.offers += (offers.offers.categories.hasOwnProperty(category.id) ? offers.offers.categories[category.id] : 0)
                    global += children.offers
                }

                const element = (
                    <MenuItem key={ category.id } value={ category } style={{ paddingLeft: `${16 * (level + 1)}px`}} className={ (children.offer || offers.offers.categories.hasOwnProperty(category.id)) ? classes.activeCategory : classes.defaultCategory }>
                        {categories.hasOwnProperty(category.id) &&
                        <ListItemIcon className={ classes.listItemIcon }>
                            <IconButton
                                size="small"
                                onClick={e => {
                                    e.stopPropagation()
                                    if (categories.hasOwnProperty(category.id)) {
                                        setOpen({...open, ...{[category.id]: open.hasOwnProperty(category.id) ? !open[category.id] : true}})
                                    }
                                }}
                            >
                                {(open.hasOwnProperty(category.id) && open[category.id]) ? <ExpandLess/> : <ExpandMore/>}
                            </IconButton>
                        </ListItemIcon>
                        }
                        <StyledBadge badgeContent={ children.offers + (offers.offers.categories.hasOwnProperty(category.id) ? offers.offers.categories[category.id] : 0) } style={{ width: categories.hasOwnProperty(category.id) ? 'calc(100% - 39px)' : '100%' }} color="secondary">
                            <ListItemText className={ categories.hasOwnProperty(category.id) ? classes.listItemTextWithIcon : classes.listItemText } primary={ !level ? <Typography variant="body1">{category.name}</Typography> : <Typography variant="body2">{category.name}</Typography> } />
                        </StyledBadge>
                    </MenuItem>
                )

                !!parent ? result.elements.push(element) : result.push(element)

                if (!!children.elements.length && open.hasOwnProperty(category.id) && open[category.id]) {
                    result = !!parent ? {...result, ...{elements: result.elements.concat([
                        children.elements.map(row => row)
                    ])}} : result.concat([
                        children.elements.map(row => row)
                    ])
                }
            })
            result.offers += global
        }
        return result
    }

    const getCategoriesTree = categories => {
        let tmp = {}

        categories.forEach(category => {
            if (!tmp.hasOwnProperty((category.category !== null) ? category.category.id : 0)) {
                tmp[(category.category !== null) ? category.category.id : 0] = []
            }

            tmp[(category.category !== null) ? category.category.id : 0].push(category)
        })

        return assembly(tmp)
    }

    const getParent = (breadcrumb, category) => {
        if (category.category) {
            breadcrumb = getParent(breadcrumb, category.category)
        }

        breadcrumb.push(<Typography key={breadcrumb.length} variant="subtitle2">{categories.find(cat => cat.id === category.id).name}</Typography>)

        return breadcrumb
    }

    const getBreadcrumb = () => {
        let breadcrumb = []

        if (category) {
            if (category.category) {
                breadcrumb = getParent(breadcrumb, categories.find(cat => cat.id === category.category.id))
            }

            breadcrumb.push(<Typography key={breadcrumb.length} variant="subtitle2" color="textPrimary">{category.name}</Typography>)
        } else {
            breadcrumb.push(<Typography key={breadcrumb.length} variant="subtitle2" color="textPrimary">Все категории</Typography>)
        }

        return breadcrumb
    }

    const getParentCategory = (breadcrumb, category, categories) => {
        if (category.category) {
            breadcrumb = getParentCategory(breadcrumb, category.category, categories)
        }

        breadcrumb.push(categories.find(cat => cat.id === category.id).name)

        return breadcrumb
    }

    const getCategory = (item, categories) => {
        let breadcrumb = []

        const category = categories.find(cat => cat.id === item.category.id)

        if (category) {
            if (category.category) {
                breadcrumb = getParentCategory(breadcrumb, categories.find(cat => cat.id === category.category.id), categories)
            }

            breadcrumb.push(category.name)
        }

        return breadcrumb
    }

    const getStatus = (item) => {
        if (!item.active) {
            if (item.confirmed) {
                return <StyledAlert icon={false} style={{ backgroundColor: 'rgba(244, 244, 244, 1)', color: 'rgba(0, 0, 0, 0.87)' }}>Неактивный</StyledAlert>
            } else {
                return <StyledAlert icon={false} color="error">Не проверен</StyledAlert>
            }
        }

        return <StyledAlert icon={false} color="success">Активный</StyledAlert>
    }

    const checkAttribute = (item, attribute = null) => {
        if (attribute) {
            return item[attribute]
        }

        return item
    }

    const getChildrenOffers = (id) => {
        let general = 0

        categories.filter(cat => (cat.category && (cat.category.id === id))).forEach(cat => {
            general += (offers.offers.categories.hasOwnProperty(cat.id) ? offers.offers.categories[cat.id] : 0) + getChildrenOffers(cat.id)
        })

        return general
    }

    const getOffers = () => {
        if (offers) {
            if (!!category) {
                return (offers.offers.categories.hasOwnProperty(category.id) ? offers.offers.categories[category.id] : 0) + getChildrenOffers(category.id)
            } else {
                return offers.offers.count
            }
        }

        return 0
    }

    useEffect(() => {
        if (!unionItems.length) {
            setUnion(false)
        }
    }, [unionItems])

    return (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={ classes.body } spacing={2}>
            <Grid item className={classes.element}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item sm={3}>
                        <Badge badgeContent={ getOffers() } color="secondary" className={ classes.fullWidth }>
                            <Select
                                fullWidth
                                id="category"
                                value={ category ?? '' }
                                onChange={ handleChange }
                                renderValue={(value) => value.hasOwnProperty('name') ? value.name : null}
                            >
                                {[<MenuItem key={0} value="">{'\u00A0\u00A0\u00A0\u00A0'}</MenuItem>].concat(getCategoriesTree(categories))}
                            </Select>
                        </Badge>
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            fullWidth
                            id="category"
                            label='Поиск'
                            value={search}
                            onChange={event => {
                                setPage(1)
                                setSearch(event.target.value)
                                debounced.callback(false)
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.breadcrumb}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                        <Breadcrumbs>
                            {getBreadcrumb()}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                            {AuthorizationService.permissions(account, 'standard_control') &&
                                <React.Fragment>
                                    <Grid item>
                                        <Button
                                            onClick={() => {
                                                setSelect(null)
                                                setDialog(true)
                                            }}
                                            color="primary"
                                            type="button"
                                        >
                                            Добавить
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Badge badgeContent={unionItems.length} color="primary">
                                            <Button
                                                onClick={() => {
                                                    setUnion(true)
                                                }}
                                                disabled={ (unionItems.length < 2) || !!unionItems.filter(item => (!item.active || !item.confirmed)).length || !unionItems.reduce((cat, item) => { return (((cat && (cat === item.category.index)) || (cat === null)) ? item.category.index : false) }, null) }
                                                color="primary"
                                                type="button"
                                            >
                                                Объединить
                                            </Button>
                                        </Badge>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={ !select || (!!checkAttribute(select, 'active') || !checkAttribute(select, 'confirmed') || !!select.item) }
                                            onClick={() => {
                                                setAgreement(true)
                                                setItem(select)
                                                setDialog(true)
                                            }}
                                            color="secondary"
                                            type="button"
                                        >
                                            Удалить
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={ !select }
                                            onClick={() => {
                                                setItem(select)
                                                setDialog(true)
                                            }}
                                            color="primary"
                                            type="button"
                                        >
                                            Редактировать
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Upload category={category} />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disabled={ !select || !checkAttribute(select, 'confirmed') }
                                            onClick={() => {
                                                setCopy(true)
                                                setItem(select)
                                                setDialog(true)
                                            }}
                                            color="primary"
                                            type="button"
                                        >
                                            Копировать
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                            }
                            <Grid item>
                                <IconButton
                                    type="button"
                                    size="small"
                                    onClick={() => {

                                        let sort = Object.entries(filter.selected).reduce((result, [key, values]) => {
                                            if (values.length) {
                                                result += result.length ? `,${values.join(',')}` : values.join(',')
                                            }

                                            return result
                                        }, '')

                                        let conditions = Object.entries(filter.conditions).reduce((result, [id, values]) => {
                                            if (!result.hasOwnProperty('conditions')) result = {conditions: {}}

                                            Object.keys(values).map(key => {
                                                (result.conditions.hasOwnProperty(id)) ? result.conditions[id][key] = values[key] : result.conditions[id] = {
                                                    [key]: values[key]
                                                }
                                            })

                                            return result
                                        }, {})

                                        let attributes = Object.entries(filter.attributes).reduce((result, [id, values]) => {
                                            if (!result.hasOwnProperty('attributes')) result = {attributes: {}}

                                            result.attributes[id] = values

                                            return result
                                        }, {})

                                        dispatch(DownloadActions.create({
                                            type: 'standards',
                                            columns: columns.filter(column => (hiddenColumnNames.indexOf(column.name) < 0)).map(column => column.name),
                                            params: {
                                                order: (filter.sort.name && filter.sort.direction) ? `${filter.sort.name}, ${filter.sort.direction}` : `${order.columnName}, ${order.direction}`,
                                                type: 'standard',
                                                ...(!!category ? {category: category.id} : {}),
                                                ...(search ? {search: search} : {}),
                                                ...(!!sort.length ? {sort: sort} : {}),
                                                ...(!!Object.keys(conditions).length ? conditions : {}),
                                                ...(!!Object.keys(attributes).length ? attributes : {}),
                                                ...(!!filter.status.length ? {status: filter.status.join(',')} : []),
                                                ...(!!filter.source.length ? {source: filter.source.join(',')} : []),
                                                ...(!!filter.company.length ? {company: filter.company.join(',')} : []),
                                                ...(!!filter.module.length ? {module: filter.module.join(',')} : [])
                                            }
                                        }))
                                    }}
                                >
                                    <GetApp />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.data}>
                <GridTable
                    rows={rows}
                    columns={columns}
                >
                    <PagingState
                        currentPage={page}
                        onCurrentPageChange={newPage => {
                            setPage(newPage)
                            setLoading(false)
                        }}
                        pageSize={rowsPerPage}
                        onPageSizeChange={newRowsPerPage => {
                            setPage(1)
                            setRowsPerPage(newRowsPerPage)
                            setLoading(false)
                        }}
                    />
                    <CustomPaging
                        totalCount={items.data.length ? items.meta.total : 0}
                    />
                    <SelectionState
                        selection={selection}
                        onSelectionChange={(numbers) => {
                            setSelection(numbers)
                            const data = items.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined)).map(item => item.id)
                            const oldUnitItems = unionItems.filter(item => !items.data.find(el => (el.id === item.id)))
                            setUnionItems([...oldUnitItems, ...items.data.filter((item, index) => (numbers.find(key => (key === index)) !== undefined) && !oldUnitItems.find(el => (el.id === item.id)))])
                            setSelectionPage(!!data.length ? {...selectionPage, [page]: data} : Object.fromEntries(
                                Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                            ))
                        }}
                    />
                    <IntegratedSelection />
                    <DragDropProvider />
                    <Table
                        noDataCellComponent={props => {
                            return null
                        }}
                        tableComponent={props => <StickyTable {...props} setTableRef={setTableRef} />}
                        rowComponent={({ row, tableRow, children }) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={!row.confirmed ? classes.active : classes.default}
                                row={row}
                            />
                        )}
                    />
                    <TableColumnReordering
                        order={columnOrder}
                        onOrderChange={setColumnOrder}
                    />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                    />
                    <TableHeaderRow
                        contentComponent={TableHeaderContent}
                    />
                    <TableColumnVisibility
                        hiddenColumnNames={hiddenColumnNames}
                        onHiddenColumnNamesChange={setHiddenColumnNames}
                    />
                    <TableSelection
                        selectByRowClick
                        showSelectAll
                        rowComponent={(props) => {
                            const { tableRow, children } = props
                            const { row } = tableRow
                            return (
                                <Table.Row
                                    tableRow={tableRow}
                                    children={children}
                                    onClick={() => {
                                        if (select && (select.id === row.id)) {
                                            if (AuthorizationService.permissions(account, 'standard_control')) {
                                                setDialog(true)
                                                setItem(select)
                                            }
                                        } else {
                                            setSelect(items.data.find(item => (item.id === row.id)))
                                        }
                                    }}
                                    className={(select && (select.id === row.id)) ? classes.active : ((row.confirmed && !row.active) ? classes.inactive : (!row.confirmed ? classes.confirmation : classes.default))}
                                    row={row}
                                />
                            )
                        }}
                    />
                    <PagingPanel
                        containerComponent={Pager}
                        messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from}-{to} из {count}'}}
                        pageSizes={[50, 100, 200]}
                    />
                </GridTable>
            </Grid>
            {dialog &&
                <ItemForm
                    item = { item }
                    category = { item ? categories.find(cat => cat.id === item.category.id) : category }
                    categories={ categories }
                    open = { dialog }
                    agreement = { agreement }
                    copy={ copy }
                    handleClose = {() => { setDialog(false); setItem(null) }}
                    handleDelete = { handleDelete }
                    handleSave = { handleSave }
                />
            }
            {union &&
                <UnionForm
                    items = { unionItems }
                    categories = { categories }
                    open = { union }
                    handleClose = {(reload = false) => {
                        setUnion(false)
                        if (reload) {
                            setUnion(false)
                            setSelection([])
                            setSelectionPage({})
                            setUnionItems([])
                            setLoading(false)
                        }
                    }}
                    handleDelete = {id => {
                        setUnionItems(unionItems.filter(item => item.id !== id))
                        setSelection(selection.filter(index => items.data[index].id !== id))
                        setSelectionPage(Object.entries(selectionPage).reduce((result, [key, values]) => {
                            const filteredValues = values.filter((itemId) => (itemId !== id))

                            if (filteredValues.length) {
                                result[key] = filteredValues
                            }

                            return result
                        }, {}))
                    }}
                />
            }
        </Grid>
    )
}
