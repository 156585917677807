import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
    Grid, makeStyles, Button
} from '@material-ui/core'
import {blue, green} from "@material-ui/core/colors"
import {
    SelectionState, PagingState, CustomPaging
} from '@devexpress/dx-react-grid'
import {
    DragDropProvider, Table, TableSelection, TableHeaderRow , TableColumnResizing, PagingPanel, TableColumnReordering
} from '@devexpress/dx-react-grid-material-ui'

import {StickyTable} from "../App/components/Table/StikyTable"
import {Grid as GridTable} from "../App/components/Table/Grid"
import {Pager} from "../App/components/Table/Paging/Pager"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import {ConverterActions} from "./actions/converter";
import {PositionForm} from "./components/PositionForm";
import {UnitActions} from "../Dictionary/actions/unit";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    data: {
        'height': 'calc(100% - 8px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '52px'
    },
    active: {
        'height': '41px',
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    completed: {
        'height': '41px',
        'background-color': green[100],
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    body: {
        'height': '100%'
    }
}))

export const Index = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {positions} = useSelector(state => state.converter)
    const [tableRef, setTableRef] = useState(null)

    const columns = [
        { name: 'original', title: 'Начальная ед. изм.' },
        { name: 'desired', title: 'Конечная ед. изм.' },
        { name: 'created', title: 'Дата создания' },
        { name: 'updated', title: 'Дата обновления' }
    ]

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [loading, setLoading] = useState(false)
    const [selection, setSelection] = useState([])
    const [select, setSelect] = useState(null)
    const [dialog, setDialog] = useState(false)
    const [columnWidths, setColumnWidths] = useState([
        { columnName: 'original', width: 250 },
        { columnName: 'desired', width: 250 },
        { columnName: 'created', width: 175 },
        { columnName: 'updated', width: 175 }
    ])
    const [columnOrder, setColumnOrder] = useState(['original', 'desired', 'created', 'updated'])

    useEffect(() => {
        const getData = async () => {
            dispatch(UnitActions.units())
            return await dispatch(ConverterActions.positions({
                page: page,
                limit: rowsPerPage
            }))
        }

        if (!loading) {
            getData().then(props => {
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setSelect(null)
                setSelection([])
                setLoading(true)
            })
        }
    }, [loading, page, rowsPerPage])

    const handleSave = (values, id = null) => {
        if (id) {
            return new Promise((resolve, reject) => {
                dispatch(ConverterActions.edit(id, values)).then(
                    () => {
                        setLoading(false)
                        resolve()
                    },
                    errors => {
                        reject(errors)
                    }
                )
            })
        } else {
            return new Promise((resolve, reject) => {
                dispatch(ConverterActions.add(values)).then(
                    () => {
                        setLoading(false)
                        resolve()
                    },
                    errors => {
                        reject(errors)
                    }
                )
            })
        }
    }

    return (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={ classes.body } spacing={2}>
            <Grid item className={classes.breadcrumb}>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Grid item>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        setDialog(true)
                                    }}
                                    color="primary"
                                    type="button"
                                >
                                    Добавить
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={ !selection.length }
                                    onClick={() => {
                                        setDialog(true)
                                    }}
                                    color="primary"
                                    type="button"
                                >
                                    Редкатировать
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={ !select }
                                    onClick={() => {
                                        dispatch(ConverterActions.remove(select.id)).then(
                                            () => {
                                                setSelect(null)
                                                setLoading(false)
                                            }
                                        )
                                    }}
                                    color="secondary"
                                    type="button"
                                >
                                    Удалить
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.data}>
                <GridTable
                    rows={positions.data.map(position => ({
                        id: position.id,
                        original: position.original.name,
                        desired: position.desired.name,
                        expression: position.expression,
                        created: format(new Date(position.created), 'H:mm PP',  {locale: ru}),
                        updated: format(new Date(position.updated), 'H:mm PP',  {locale: ru}),
                        position: position
                    }))}
                    columns={columns}
                >
                    <PagingState
                        currentPage={page}
                        onCurrentPageChange={newPage => {
                            setPage(newPage)
                            setLoading(false)
                        }}
                        pageSize={rowsPerPage}
                        onPageSizeChange={newRowsPerPage => {
                            setPage(1)
                            setRowsPerPage(newRowsPerPage)
                            setLoading(false)
                        }}
                    />
                    <CustomPaging
                        totalCount={positions.data.length ? positions.meta.total : 0}
                    />
                    <SelectionState
                        selection={selection}
                        onSelectionChange={(numbers) => {
                            const current = numbers.filter(x => !selection.includes(x))

                            if (!current.length) {
                                setDialog(true)
                            } else {
                                setSelection(numbers.filter(x => !selection.includes(x)))
                            }
                        }}
                    />
                    <DragDropProvider />
                    <Table
                        noDataCellComponent={props => {
                            return null
                        }}
                        tableComponent={props => <StickyTable {...props} setTableRef={setTableRef} />}
                        rowComponent={({ row, tableRow, children }) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.default}
                                row={row}
                            />
                        )}
                    />
                    <TableColumnReordering
                        order={columnOrder}
                        onOrderChange={setColumnOrder}
                    />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                    />
                    <TableHeaderRow />
                    <TableSelection
                        selectByRowClick
                        highlightRow
                        showSelectionColumn={false}
                        rowComponent={(props) => {
                            const { tableRow, children, highlighted, onToggle } = props
                            const { row } = tableRow

                            return (
                                <Table.Row
                                    tableRow={tableRow}
                                    children={children}
                                    onClick={() => {
                                        setSelect(row.position)
                                        onToggle()
                                    }}
                                    className={highlighted ? classes.active : classes.default}
                                    row={row}
                                />
                            )
                        }}
                    />
                    <PagingPanel
                        containerComponent={Pager}
                        messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from}-{to} из {count}'}}
                        pageSizes={[50, 100, 200]}
                    />
                </GridTable>
            </Grid>
            {dialog &&
                <PositionForm
                    open={dialog}
                    position={select}
                    handleClose={() => {
                        setSelect(null)
                        setSelection([])
                        setDialog(false)
                    }}
                    handleSubmit={handleSave}
                />
            }
        </Grid>
    )
}
