import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import { Formik, Field, Form, FieldArray } from 'formik'

import {
    Button, CircularProgress, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Grid, FormControlLabel,
    IconButton, Select, FormHelperText, FormControl, ListItemIcon, ListItemText, Typography, makeStyles
} from '@material-ui/core'
import {PlaylistAdd, Close, ExpandLess, ExpandMore} from '@material-ui/icons'
import {
    TextField, Switch
} from 'formik-material-ui'
import {Attribute} from "./Attribute";

const useStyles = makeStyles(() => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    }
}))

export const CategoryForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {types} = useSelector(state => state.attribute_type)

    const [deleteCategory, setDeleteCategory] = useState(false)
    const [collapse, setCollapse] = useState({})
    const [confirmation, setConfirmation] = useState(false)
    const [category, setCategory] = useState(props.category)
    const [options] = useState(types.filter(type => ((type.key === 'select') || (type.key === 'multiselect')) ).map(type => type.id))
    const [dictionary] = useState(types.filter(type => (type.key === 'dictionary')).map(type => type.id))

    const { handleClose, handleDelete, handleSave, open, categories } = props

    const getAttributes = (category) => {
        let attributes = []

        category.attributes.forEach(attribute => {
            attributes.push({...attribute, ...{type: attribute.type, disabled: true}})
        })

        if (category.category) {
            let parent = categories.find(cat => (cat.id === category.category.id))

            while (parent) {
                parent.attributes.forEach(attribute => {
                    attributes.push({...attribute, ...{type: attribute.type, disabled: true}})
                })

                parent = parent.category ? categories.find(cat => (cat.id === parent.category.id)) : null
            }
        }

        return attributes
    }

    const attributes = () => {
        let attributes = []

        if (category && category.category) {
            let parent = categories.find(cat => (cat.id === category.category.id))

            while (parent) {
                if (parent.attributes.length) {
                    parent.attributes.forEach(attribute => {
                        attributes.push({...attribute, ...{type: attribute.type, disabled: true}})
                    })
                }

                parent = parent.category ? categories.find(cat => (cat.id === parent.category.id)) : null
            }
        }

        category.attributes.forEach(attribute => {
            attributes.push({...attribute, ...{type: attribute.type, disabled: false}})
        })

        return attributes
    }

    const assembly = (items, parent = 0, level = 0, disabled = false) => {
        let result = []

        if (items.hasOwnProperty(parent)) {
            items[parent].sort((first, second) => {
                return (first.name > second.name) ? 1 : (second.name > first.name) ? -1 : 0
            }).forEach(option => {
                result.push(
                    <MenuItem key={ option.id } value={ option.id } style={{ paddingLeft: `${16 * (level + 1)}px`}} disabled={ disabled || (category ? (option.id === category.id) : false) }>
                        {items.hasOwnProperty(option.id) &&
                            <ListItemIcon className={ classes.listItemIcon }>
                                <IconButton
                                    size="small"
                                    onClick={e => {
                                        e.stopPropagation()
                                        if (items.hasOwnProperty(option.id)) {
                                            setCollapse({...collapse, ...{[option.id]: collapse.hasOwnProperty(option.id) ? !collapse[option.id] : true}})
                                        }
                                    }}
                                >
                                    {(collapse.hasOwnProperty(option.id) && collapse[option.id]) ? <ExpandLess/> : <ExpandMore/>}
                                </IconButton>
                            </ListItemIcon>
                        }
                        <ListItemText className={ items.hasOwnProperty(option.id) ? classes.listItemTextWithIcon : classes.listItemText } primary={ !level ? <Typography variant="body1">{option.name}</Typography> : <Typography variant="body2">{option.name}</Typography> } />
                    </MenuItem>
                )

                const childes = assembly(items, option.id, level + 1)

                if (!!childes.length && collapse.hasOwnProperty(option.id) && collapse[option.id]) {
                    result = result.concat([
                        childes.map(row => row)
                    ])
                }
            })
        }

        return result
    }

    const getCategoriesTree = (items, full = true) => {
        let tmp = {}

        items.forEach(option => {
            if (!tmp.hasOwnProperty((option.category !== null) ? option.category.id : 0)) {
                tmp[(option.category !== null) ? option.category.id : 0] = []
            }

            tmp[(option.category !== null) ? option.category.id : 0].push(option)
        })

        if (full) {
            return [<MenuItem key={0} value="">{'\u00A0\u00A0\u00A0\u00A0'}</MenuItem>].concat(assembly(tmp))
        } else {
            return assembly(tmp)
        }
    }

    return (
        <Formik
            initialValues = {{
                name: category ? category.name : '',
                active: category ? ((category.category && !category.category.active) ? false : category.active) : false,
                attributes: category
                    ? attributes().map((attribute) => {
                        return {
                            id: attribute.id,
                            name: attribute.name,
                            type: attribute.type,
                            standard: attribute.standard,
                            index: attribute.index ?? '',
                            assembly: {
                                standard: {active: !!attribute.assembly.standard, index: attribute.assembly.standard ?? ''},
                                typed: {active: !!attribute.assembly.typed, index: attribute.assembly.typed ?? ''},
                                arbitrary: {active: !!attribute.assembly.arbitrary, index: attribute.assembly.arbitrary ?? ''}
                            },
                            addition: attribute.addition
                                ? {
                                    prefix: {value: attribute.addition.prefix.value ?? '', space: Boolean(attribute.addition.prefix.space)},
                                    postfix: {value: attribute.addition.postfix.value ?? '', space: Boolean(attribute.addition.postfix.space)}
                                }
                                : {
                                    prefix: {value: '', space: false},
                                    postfix: {value: '', space: false}
                                },
                            search: !!attribute.search,
                            priority: !!attribute.priority,
                            exceptions: attribute.exceptions,
                            required: !!attribute.required,
                            dictionary: !!attribute.dictionary,
                            multiple: !!attribute.multiple,
                            options: attribute.options,
                            value: attribute.value,
                            disabled: !!attribute.disabled,
                            space: !!attribute.space,
                            params: attribute.params ? Object.entries(attribute.params).reduce((result, [key, value]) => {
                                result[`${key}`] = {
                                    active: !!value,
                                    index: value ?? ''
                                }

                                return result
                            }, {}) : null
                        }
                    })
                    : [],
                category: category ? (category.category ? category.category : '') : ''
            }}
            validate = {values => {
                const errors = {}

                if (!values.name) {
                    errors.name = 'Введите наименование'
                }

                errors.attributes = {}

                values.attributes.forEach((item, key) => {
                    let error = {}

                    if (!item.name) {
                        error.name = 'Введите наименование'
                    }

                    if (!item.type) {
                        error.type = 'Выберите тип'
                    }

                    if (!item.search) {
                        item.priority = false
                    }

                    // eslint-disable-next-line array-callback-return
                    Object.keys(item.assembly).map(name => {
                        if (item.assembly[name].active && !item.assembly[name].index) {
                            if (!error.hasOwnProperty('assembly')) {
                                error.assembly = {}
                            }

                            if (!error.assembly.hasOwnProperty(name)) {
                                error.assembly[name] = {
                                    index: 'Укажите порядок'
                                }
                            }
                        }
                    })

                    if (item.hasOwnProperty('params') && item.params) {
                        Object.keys(item.params).forEach(index => {
                            if (item.params[index].active && !item.params[index].index) {
                                if (!error.hasOwnProperty('params')) {
                                    error.params = {}
                                }

                                if (!error.params.hasOwnProperty(index)) {
                                    error.params[index] = {}
                                }

                                error.params[index] = {
                                    index: 'Укажите порядок'
                                }
                            }
                        })
                    }

                    if (options.includes(item.type)) {
                        if (!item.hasOwnProperty('options') || (item.options.length === 0)) {
                            error.option = true
                        } else {
                            item.options.forEach((val, index) => {
                                if (!val.option.length) {
                                    if (!error.hasOwnProperty('options')) {
                                        error.options = {}
                                    }

                                    if (!error.options.hasOwnProperty(index)) {
                                        error.options[index] = {}
                                    }

                                    error.options[index].option = `Введите наименование`
                                }
                            })
                        }
                    }

                    if (dictionary.includes(item.type)) {
                        if (!item.hasOwnProperty('value') || (item.value.length === 0)) {
                            error.value = `Выберите словарь`
                        }
                    }

                    if (!!Object.keys(error).length) {
                        errors.attributes[key] = error
                    }
                })

                if (!Object.keys(errors.attributes).length) {
                    delete errors.attributes
                }

                return errors
            }}
            onSubmit = {(values, { setSubmitting }) => {
                handleSave(
                    {
                        ...values,
                        ...{attributes: values.attributes.filter(attribute => !attribute.disabled).map(attribute => {
                            return {
                                ...attribute,
                                ...{params: attribute.params ? Object.entries(attribute.params).reduce((result, [key, values]) => {
                                    result[`${key}`] = values.index ?? null

                                    return result
                                }, {}) : null}
                            }
                        })}},
                    category ? category.id : null
                ).then(
                    () => {
                        setSubmitting(false)
                        dispatch(handleClose)
                    },
                    () => {
                        setSubmitting(false)
                    }
                )
            }}
        >
            {({
                  values,
                  errors,
                  handleSubmit,
                  validateForm,
                  isSubmitting,
                  setSubmitting,
                  setValues,
                  setFieldValue
              }) => (
                <Form>
                    <Dialog
                        fullWidth={ true }
                        maxWidth="sm"
                        open={ open }
                        onClose={ handleClose }
                        aria-labelledby="Категория"
                        classes={{
                            paper: classes.dialog
                        }}
                    >
                        <DialogTitle>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item>
                                    { category ? (deleteCategory ? 'Удалить' : 'Редактировать') : 'Добавить' }
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label="Закрыть"
                                        onClick={() => handleClose()}
                                    >
                                        <Close />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <Field
                                        fullWidth
                                        name="name"
                                        type="text"
                                        label="Наименование"
                                        disabled={(isSubmitting || deleteCategory)}
                                        component={ TextField }
                                    />
                                </Grid>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <Field
                                        fullWidth
                                        type="text"
                                        name="category"
                                        label="Родительская категория"
                                        select
                                        variant="standard"
                                        component={ TextField }
                                        disabled={(isSubmitting || deleteCategory)}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        InputProps={{
                                            onChange: (event) => {
                                                const category = categories.find(cat => (cat.id === event.target.value))

                                                setValues({
                                                    ...values,
                                                    ...(category ? {
                                                        category: category,
                                                        attributes: getAttributes(category).map((attribute) => {
                                                            return {
                                                                id: attribute.id,
                                                                name: attribute.name,
                                                                type: attribute.type,
                                                                standard: attribute.standard,
                                                                index: attribute.index ?? '',
                                                                assembly: {
                                                                    standard: {active: !!attribute.assembly.standard, index: attribute.assembly.standard ?? ''},
                                                                    typed: {active: !!attribute.assembly.typed, index: attribute.assembly.typed ?? ''},
                                                                    arbitrary: {active: !!attribute.assembly.arbitrary, index: attribute.assembly.arbitrary ?? ''}
                                                                },
                                                                addition: attribute.addition
                                                                    ? {
                                                                        prefix: {value: attribute.addition.prefix.value ?? '', space: Boolean(attribute.addition.prefix.space)},
                                                                        postfix: {value: attribute.addition.postfix.value ?? '', space: Boolean(attribute.addition.postfix.space)}
                                                                    }
                                                                    : {
                                                                        prefix: {value: '', space: false},
                                                                        postfix: {value: '', space: false}
                                                                    },
                                                                search: !!attribute.search,
                                                                priority: !!attribute.priority,
                                                                exceptions: attribute.exceptions,
                                                                required: !!attribute.required,
                                                                dictionary: !!attribute.dictionary,
                                                                multiple: !!attribute.multiple,
                                                                options: attribute.options,
                                                                value: attribute.value,
                                                                disabled: !!attribute.disabled,
                                                                space: !!attribute.space,
                                                                params: attribute.params ? Object.entries(attribute.params).reduce((result, [key, value]) => {
                                                                    result[`${key}`] = {
                                                                        active: !!value,
                                                                        index: value ?? ''
                                                                    }

                                                                    return result
                                                                }, {}) : null
                                                            }
                                                        })
                                                    } : {
                                                        category: '',
                                                        attributes: []
                                                    })
                                                })
                                            }
                                        }}
                                        inputProps={{
                                            renderValue: (value) => value.name
                                        }}
                                    >
                                        { getCategoriesTree(categories).map(el => el) }
                                    </Field>
                                </Grid>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <FormControlLabel
                                        control={
                                            <Field
                                                name="active"
                                                color="primary"
                                                label="Активная"
                                                component={ Switch }
                                                disabled={(values.category ? (!values.category.active) : false) || (isSubmitting || deleteCategory)}
                                            />
                                        }
                                        label="Активная"
                                    />
                                </Grid>
                                <FieldArray
                                    name="attributes"
                                    render={ arrayHelpers => (
                                        <Grid item sm={10} className={classes.fullWidth}>
                                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                                {(values.attributes && !!values.attributes.length) && (
                                                    values.attributes.map((attribute, index) => (
                                                        <Attribute
                                                            key={index}
                                                            attribute={attribute}
                                                            index={index}
                                                            remove={(index) => arrayHelpers.remove(index)}
                                                            isSubmitting={isSubmitting || deleteCategory}
                                                            setFieldValue={setFieldValue}
                                                            errors={{
                                                                option: !!(errors.hasOwnProperty('attributes') && (errors.attributes.hasOwnProperty(`${index}`) && errors.attributes[`${index}`] !== undefined) && errors.attributes[`${index}`].option),
                                                                params: !!(errors.hasOwnProperty('attributes') && (errors.attributes.hasOwnProperty(`${index}`) && errors.attributes[`${index}`] !== undefined) && errors.attributes[`${index}`].params)
                                                            }}
                                                        />
                                                    ))
                                                )}
                                                <Grid item className={classes.fullWidth}>
                                                    <Button
                                                        onClick={() => arrayHelpers.push({ name: '', type: '', index: '', addition: {prefix: {value: '', space: false}, postfix: {value: '', space: false}}, standard: false, assembly: {standard: {active: false, index: ''}, typed: {active: false, index: ''}, arbitrary: {active: false, index: ''}}, search: false, priority: false, exceptions: [], required: false, dictionary: false, multiple: false, value: '', space: false, params: {}})}
                                                        aria-label={`Добавить`}
                                                        color="primary"
                                                        endIcon={<PlaylistAdd />}
                                                        disabled={(isSubmitting || deleteCategory)}
                                                    >
                                                        Добавить атрибут
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                />
                            </Grid>
                        </DialogContent>
                        {
                            category
                                ? (
                                    <DialogActions>
                                        {
                                            deleteCategory
                                                ?
                                                    confirmation
                                                        ?
                                                            category
                                                                ? <FormControl className={classes.fullWidth} error>
                                                                    <Select fullWidth value={0} error
                                                                        onChange={event => {
                                                                            setConfirmation(false)
                                                                            setCategory(false)
                                                                            handleDelete(category.id, { type: 'category', category: event.target.value}).then(
                                                                                () => {
                                                                                    handleClose()
                                                                                }
                                                                            )
                                                                        }}
                                                                    >
                                                                        { getCategoriesTree(categories, false).map(el => el) }
                                                                    </Select>
                                                                    <FormHelperText>Выберите категорию для переноса эталонов</FormHelperText>
                                                                </FormControl>
                                                                : <FormControl className={classes.fullWidth} error>
                                                                    <Select
                                                                        fullWidth
                                                                        value=""
                                                                        error
                                                                        onChange={event => {
                                                                            const type = event.target.value

                                                                            if (type === 'category') {
                                                                                setCategory(true)
                                                                            } else {
                                                                                setConfirmation(false)
                                                                                handleDelete(category.id, { type: type }).then(
                                                                                    () => {
                                                                                        handleClose()
                                                                                    }
                                                                                )
                                                                            }
                                                                        }}
                                                                    >
                                                                        <MenuItem value="category">Выбрать категорию для переноса эталонов</MenuItem>
                                                                        <MenuItem value="connect">Оставить связь эталонов с удалённой категорией</MenuItem>
                                                                        <MenuItem value="empty">Оставить эталоны без категории</MenuItem>
                                                                    </Select>
                                                                    <FormHelperText>В категории присутствуют эталоны, необходимо выбрать тип удаления</FormHelperText>
                                                                </FormControl>
                                                        :
                                                        <React.Fragment>
                                                            <Button
                                                                disabled={ isSubmitting && deleteCategory }
                                                                onClick={() => {
                                                                    setDeleteCategory(false)
                                                                }}
                                                                color="secondary"
                                                                type="submit"
                                                            >
                                                                Отмена
                                                            </Button>
                                                            <Button
                                                                disabled={ isSubmitting && deleteCategory }
                                                                onClick={() => {
                                                                    setSubmitting(true)
                                                                    handleDelete(category.id).then(
                                                                        val => {
                                                                            if (val) {
                                                                                setConfirmation(true)
                                                                                setSubmitting(false)
                                                                            } else {
                                                                                handleClose()
                                                                                setSubmitting(false)
                                                                            }
                                                                        }
                                                                    )
                                                                }}
                                                                color="primary"
                                                                type="submit"
                                                            >
                                                                {isSubmitting ? <CircularProgress size={24}/> : 'Подтвердить'}
                                                            </Button>
                                                        </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <Button
                                                        disabled={isSubmitting || deleteCategory}
                                                        onClick={() => {
                                                            setDeleteCategory(true)
                                                        }}
                                                        color="secondary"
                                                        type="submit"
                                                    >
                                                        {
                                                            deleteCategory
                                                                ? <CircularProgress size={24}/>
                                                                : 'Удалить'
                                                        }
                                                    </Button>
                                                    <Button
                                                        disabled={isSubmitting || deleteCategory}
                                                        onClick={handleSubmit}
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        {isSubmitting ? <CircularProgress size={24}/> : 'Сохранить'}
                                                    </Button>
                                                </React.Fragment>
                                        }
                                    </DialogActions>
                                )
                                : (
                                    <DialogActions>
                                        <Button
                                            disabled={ isSubmitting }
                                            onClick={ handleSubmit }
                                            color="primary"
                                            type="submit"
                                        >
                                            { isSubmitting ? <CircularProgress size={24} /> : 'Добавить' }
                                        </Button>
                                    </DialogActions>
                                )
                        }
                    </Dialog>
                </Form>
            )}
        </Formik>
    )
}
