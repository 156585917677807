import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
    Grid,
    TextField,
    makeStyles, withStyles, Button, Breadcrumbs, IconButton, Typography,
} from '@material-ui/core'
import {blue} from "@material-ui/core/colors"
import {
    PagingState, CustomPaging, SelectionState, IntegratedSelection
} from '@devexpress/dx-react-grid'
import {
    DragDropProvider, Table, TableHeaderRow, TableColumnResizing, PagingPanel, TableColumnReordering, TableSelection
} from '@devexpress/dx-react-grid-material-ui'

import {useDebouncedCallback} from 'use-debounce'
import {StickyTable} from "../../../../App/components/Table/StikyTable"
import {Grid as GridTable} from "../../../../App/components/Table/Grid"
import {Pager} from "../../../../App/components/Table/Paging/Pager"
import {SynonymActions} from "../../../actions/category/synonym"
import {Link, useParams} from "react-router-dom"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import Alert from "@material-ui/lab/Alert"
import {endings} from "../../../../App/helpers/endings"
import {DeleteForm} from "./DeleteForm"
import {ValueForm} from "./ValueForm"
import {Check, GetApp, Home, NavigateNext} from "@material-ui/icons";
import useMousetrap from "react-hook-mousetrap";
import {TableHeaderContent} from "./Value/Table/TableHeaderContent";
import {DownloadActions} from "../../../../Download/actions/download";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    data: {
        'height': 'calc(100% - 72px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '52px'
    },
    active: {
        'height': '41px',
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    body: {
        'height': '100%'
    }
}))

const StyledAlert = withStyles((theme) => ({
    message: {
        'padding': '0'
    },
}))(Alert)

export const Values = (props) => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const classes = useStyles()

    const {category, values, filter} = useSelector(state => state.synonym)
    const [search, setSearch] = useState('')

    const debounced = useDebouncedCallback(
        (value) => {
            setLoading(value)
        }, 700
    )

    const columns = [
        { name: 'value', title: 'Эталон'},
        { name: 'option', title: 'Вариация' },
        { name: 'active', title: 'Статус' },
        { name: 'source', title: 'Источник' },
        { name: 'created', title: 'Дата создания' },
        { name: 'updated', title: 'Дата обновления' }
    ]

    const [tableRef, setTableRef] = useState(null)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [loading, setLoading] = useState(false)
    const [initialize, setInitialize] = useState(false)
    const [selection, setSelection] = useState([])
    const [select, setSelect] = useState(null)
    const [deleted, setDeleted] = useState(false)
    const [change, setChange] = useState(false)
    const [selectionPage, setSelectionPage] = useState({})
    const [columnWidths, setColumnWidths] = useState([
        { columnName: 'value', width: 300 },
        { columnName: 'option', width: 300 },
        { columnName: 'active', width: 135 },
        { columnName: 'source', width: 215 },
        { columnName: 'created', width: 200 },
        { columnName: 'updated', width: 200 }
    ])
    const [columnOrder, setColumnOrder] = useState(['value', 'option', 'active', 'source', 'created', 'updated'])

    useEffect(() => {
        const getData = async () => {
            return await dispatch(SynonymActions.category(id))
        }

        if (!initialize && id) {
            getData().then(() => {
                setInitialize(true)
            })
        }

        if (initialize) {
            return () => {
                dispatch({type: 'SYNONYM_CATEGORY_CLEAR'})
                dispatch({type: 'SYNONYM_CATEGORY_VALUES_CLEAR'})
            }
        }
    }, [initialize, id])

    useEffect(() => {
        const getData = async () => {
            return await dispatch(SynonymActions.values(id, {
                page: page,
                limit: rowsPerPage,
                ...(search ? {search: search} : {}),
                ...((filter.sort.name && filter.sort.direction) ? {order: [filter.sort.name, filter.sort.direction]} : []),
                ...((filter.hasOwnProperty('value') && filter.value.length) ? {standard: filter.value} : []),
                ...((filter.hasOwnProperty('option') && filter.option.length) ? {value: filter.option} : []),
                ...((filter.hasOwnProperty('active') && filter.active.length) ? {status: filter.active} : []),
                ...((filter.hasOwnProperty('source') && filter.source.length) ? {source: filter.source} : [])
            }))
        }

        if (category && !loading) {
            getData().then(values => {
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setLoading(true)

                let numbers = []

                if (selectionPage.hasOwnProperty(page)) {
                    values.forEach((item, index) => {
                        if (selectionPage[page].find(id => (id === item.id))) {
                            numbers.push(index)
                        }
                    })
                }

                setSelection(numbers)
            })
        }
    }, [category, loading, page, rowsPerPage, search])

    useEffect(() => {
        setPage(1)
        setLoading(false)
    }, [filter])

    const handleDelete = (id, params) => {
        return dispatch(SynonymActions.remove(id, params)).then(
            () => {
                setSelect(null)
                setSelectionPage({})
                setSelection([])
                setLoading(false)
            }
        )
    }

    const handleSave = (id, params) => {
        return dispatch(SynonymActions.save(id, params)).then(
            () => {
                setSelect(null)
                setSelectionPage({})
                setSelection([])
                setLoading(false)
            }
        )
    }

    useMousetrap(["up", 'down'], (event) => {
        if (select) {
            const index = values.data.findIndex(item => (item.id === select))

            switch (event.code) {
                case 'ArrowUp':
                    if (index > 0) {
                        setSelect(values.data[index - 1].id)
                        setChange(false)
                    }
                    break
                case 'ArrowDown':
                    if (index + 1 < values.data.length) {
                        setSelect(values.data[index + 1].id)
                        setChange(false)
                    }
                    break
            }
        }
    }, 'keydown')

    return category ? (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={ classes.body } spacing={2} onClick={() => { setSelect(null) }}>
            <Grid item className={classes.element}>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Grid item sm={3}>
                        <TextField
                            fullWidth
                            label='Поиск'
                            value={search}
                            onChange={event => {
                                setPage(1)
                                setSearch(event.target.value)
                                debounced.callback(false)
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.breadcrumb}>
                <Grid container direction="row" justify="space-between" alignItems="center" style={{ height: '38px' }}>
                    <Grid item>
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
                            <IconButton
                                type="button"
                                size="small"
                                component={Link}
                                to="/dictionary/synonym/categories"
                            >
                                <Home />
                            </IconButton>
                            <Typography variant="subtitle2" color="textPrimary">{category.name}</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item>
                                <Button
                                    disabled={ false }
                                    onClick={e => {
                                        e.stopPropagation()
                                        setSelect(null)
                                        setChange(true)
                                    }}
                                    color="primary"
                                    type="button"
                                >
                                    Добавить
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={ !select  }
                                    onClick={e => {
                                        e.stopPropagation()
                                        setChange(true)
                                    }}
                                    color="primary"
                                    type="button"
                                >
                                    Редактировать
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={ !Object.keys(selectionPage).length && !select }
                                    onClick={e => {
                                        e.stopPropagation()
                                        setDeleted(true)
                                    }}
                                    color="secondary"
                                    type="button"
                                >
                                    Удалить
                                </Button>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    type="button"
                                    size="small"
                                    onClick={() => {
                                        dispatch(DownloadActions.create({
                                            type: 'synonym_category_dictionaries',
                                            columns: columnOrder,
                                            params: {
                                                category: category.id,
                                                ...((filter.sort.name && filter.sort.direction) ? {order: `${filter.sort.name}, ${filter.sort.direction}`} : []),
                                                ...(search ? {search: search} : {}),
                                                ...((filter.hasOwnProperty('value') && filter.value.length) ? {standard: filter.value.join('')} : []),
                                                ...((filter.hasOwnProperty('option') && filter.option.length) ? {value: filter.option.join('')} : []),
                                                ...((filter.hasOwnProperty('active') && filter.active.length) ? {status: filter.active.join('')} : []),
                                                ...((filter.hasOwnProperty('source') && filter.source.length) ? {source: filter.source.join('')} : [])
                                            }
                                        }))
                                    }}
                                >
                                    <GetApp />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.data}>
                <GridTable
                    rows={values.data.map(value => ({
                        'id': value.id,
                        'value': value.item.name,
                        'option': value.option,
                        'active': value.active ? <StyledAlert icon={false} color="success">Активный</StyledAlert> : <StyledAlert icon={false} color="error">Неактивный</StyledAlert>,
                        'source': value.source ? value.source.name : null,
                        'created': format(new Date(value.created), 'H:mm PP',  {locale: ru}),
                        'updated': format(new Date(value.updated), 'H:mm PP',  {locale: ru})
                    }))}
                    columns={columns}
                >
                    <PagingState
                        currentPage={page}
                        onCurrentPageChange={newPage => {
                            setPage(newPage)
                            setLoading(false)
                        }}
                        pageSize={rowsPerPage}
                        onPageSizeChange={newRowsPerPage => {
                            setPage(1)
                            setRowsPerPage(newRowsPerPage)
                            setLoading(false)
                        }}
                    />
                    <CustomPaging
                        totalCount={values.data.length ? values.meta.total : 0}
                    />
                    <SelectionState
                        selection={selection}
                        onSelectionChange={(numbers) => {
                            setSelection(numbers)
                            const data = values.data.filter((value, index) => (numbers.find(key => (key === index)) !== undefined)).map(value => value.id)
                            setSelectionPage(!!data.length ? {...selectionPage, [page]: data} : Object.fromEntries(
                                Object.entries(selectionPage).filter(key => (parseInt(key) !== parseInt(page))).map(([key, value]) => [key, value])
                            ))
                        }}
                    />
                    <IntegratedSelection />
                    <DragDropProvider />
                    <Table
                        noDataCellComponent={props => {
                            return null
                        }}
                        tableComponent={props => <StickyTable onClick={e => e.stopPropagation()} {...props} setTableRef={setTableRef} />}
                        rowComponent={({ row, tableRow, children }) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.default}
                                row={row}
                            />
                        )}
                    />
                    <TableColumnReordering
                        order={columnOrder}
                        onOrderChange={setColumnOrder}
                    />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                    />
                    <TableHeaderRow
                        contentComponent={(props) => <TableHeaderContent {...props} category={category} />}
                    />
                    <TableSelection
                        selectByRowClick
                        showSelectAll
                        rowComponent={(props) => {
                            const { tableRow, children, highlighted } = props
                            const { row } = tableRow
                            return (
                                <Table.Row
                                    tableRow={tableRow}
                                    children={children}
                                    onClick={() => {
                                        if (select === row.id) {
                                            setChange(true)
                                        }
                                        setSelect(row.id)
                                    }}
                                    className={(highlighted || (select === row.id)) ? classes.active : classes.default}
                                    row={row}
                                />
                            )
                        }}
                    />
                    <PagingPanel
                        containerComponent={Pager}
                        messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from}-{to} из {count}'}}
                        pageSizes={[50, 100, 200]}
                    />
                </GridTable>
            </Grid>
            {change && (
                function () {
                    return <ValueForm
                        category={category}
                        value={values.data.find(value => (value.id === select))}
                        open={change}
                        handleSave={(id, params) => {
                            return handleSave(id, params)
                        }}
                        handleInitialize={() => {
                            setSelect(null)
                        }}
                        handleClose={() => {
                            setChange(false)
                        }}
                    />
                }()
            )}
            {deleted && (
                function () {
                    const items = Object.entries(selectionPage).map(([key, value]) => value).flat()

                    return <DeleteForm
                        label="Удаление позиций"
                        text={`Вы действительно хотите удалить ${endings(!!items.length ? items.length : 1, ['позицию', 'позиции', 'позиций'])}? `}
                        open={deleted}
                        handleDelete={() => {
                            return handleDelete(category.id, {ids: !!items.length ? items.join(',') : select})
                        }}
                        handleClose={() => {setDeleted(false)}}
                    />
                }()
            )}
        </Grid>
    ) : null
}
