import React from 'react'

import {TableHeaderRow} from "@devexpress/dx-react-grid-material-ui"
import {Filter} from "../Filter"

export const TableHeaderContent = (props) => {
    const {column, children, ...restProps} = props

    let button = null

    switch (column.name) {
        case 'value':
        case 'status':
        case 'categories':
        case 'subcategories':
        case 'standard':
        case 'category':
        case 'user':
            button = <Filter name={column.name}/>
            break
        default:
            break
    }

    return (
        <TableHeaderRow.Content
            column={column}
            {...restProps}
            style={{
                justifyContent: 'space-between'
            }}
        >
            {children}
            {button}
        </TableHeaderRow.Content>
    )
}
