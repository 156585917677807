import React, {useEffect, useState} from 'react'
import {useDispatch} from "react-redux"
import {getIn, Field} from 'formik'

import {
    Grid,
    IconButton,
    makeStyles, Typography, Tooltip, TextField
} from '@material-ui/core'
import {red} from "@material-ui/core/colors"
import {Help} from '@material-ui/icons'
import Autocomplete from "@material-ui/lab/Autocomplete"
import {ItemActions} from "../../Item/actions/item"

const useStyles = makeStyles(theme => ({
    tooltip: {
        "background-color": "#000",
        "font-size": "15px",
        "color": "#fff",
        "padding": "15px 15px",
    },
    option: {
        'padding': '0 !important'
    },
    default: {
        'width': '100%',
        'padding': '6px 24px 6px 16px',
    },
    confirmation: {
        'width': '100%',
        'background-color': red[50],
        'padding': '6px 24px 6px 16px',
        '&:hover': {
            'background-color': `rgba(0, 0, 0, 0.01)`
        }
    }
}))

export const Standard = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {handleSubmit, touched, errors, setFieldValue, setFieldTouched, isSubmitting, values, item, dirty, setItem} = props

    const [standard, setStandard] = useState('')
    const [items, setItems] = useState([])

    useEffect(() => {
        let type = null

        if (standard.length || (item.item.standard && item.item.standard.deleted && item.item.standard.log && (item.item.standard.log.type === 'duplicate'))) {
            type = 'standard'
        }

        dispatch(ItemActions.analysis({...(type ? {type: type} : {}), ...{search: (standard.length ? standard : ((item.item.standard && item.item.standard.deleted && item.item.standard.log && (item.item.standard.log.type === 'duplicate')) ? item.item.standard.log.description.standard.name : item.value)), values: true, confirmed: false}})).then(data => setItems(data))
    }, [standard])

    const offer = (
        <Grid container justify='space-between' alignItems="center" style={{ cursor: "pointer" }} onClick={(e) => { e.stopPropagation(); setItem(item) }}>
            <Grid item><Typography>Предложить эталон</Typography></Grid>
            <Grid item>
                <Tooltip
                    title="Если эталон отсутствует в списке, вы можете предложить свой эталон"
                    placement="right"
                    classes={{tooltip: classes.tooltip}}
                >
                    <IconButton
                        style={{
                            "width": "24px",
                            "height": "24px",
                            "padding": "0"
                        }}
                    >
                        <Help style={{"color": "#485868"}}/>
                    </IconButton>
                </Tooltip>
            </Grid>
        </Grid>
    )

    return (
        <Autocomplete
            name="standard"
            options={!!items.length ? items : ['offer']}
            filterOptions={(options, state) => {
                return options
            }}
            classes={{
                option: classes.option
            }}
            value={values.standard}
            getOptionLabel={item => item ? ((item instanceof Object) ? item.name : item) : ''}
            noOptionsText='Введите название эталона'
            disabled={isSubmitting}
            onChange={async (e, item) => {
                await setFieldValue('standard', item)
                handleSubmit()
            }}
            groupBy={(option) => {
                return offer
            }}
            renderOption={option => (
                (option instanceof Object) ? <Typography className={option.confirmed ? classes.default : classes.confirmation}>{option.name}</Typography> : null
            )}
            renderInput={params => {
                const error = getIn(touched, `standard`) && getIn(errors, `standard`)

                return <Field
                    fullWidth
                    component={TextField}
                    required
                    {...params}
                    onChange={(e) => {
                        const val = e.target.value
                        setFieldValue(`standard`, val)
                        setStandard(val)
                    }}
                    error={!!error}
                    helperText={error}
                    name="standard"
                />
            }}
            onFocus ={(e, value) => {
                setFieldTouched(`standard`, true, true)
            }}
        />
    )
}
