import { NotificationService } from '../services/notification'

export const NotificationActions = {
    events,
    read,
    count
}

function events (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'NOTIFICATION_EVENTS_REQUEST' })

        NotificationService.events(params)
            .then(
                response => {
                    dispatch({ type: 'NOTIFICATION_EVENTS_SUCCESS', payload: { data: response.data, meta: response.meta, search: params.search, limit: params.limit ? params.limit : 10, page: params.page ? params.page : 1 } })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'NOTIFICATION_EVENTS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function read (id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'NOTIFICATION_READ_REQUEST' })

        NotificationService.read(id)
            .then(
                response => {
                    dispatch({ type: 'NOTIFICATION_READ_SUCCESS' })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'NOTIFICATION_READ_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function count () {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'NOTIFICATION_COUNT_REQUEST' })

        NotificationService.count()
            .then(
                response => {
                    dispatch({ type: 'NOTIFICATION_COUNT_SUCCESS', payload: response.count })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'NOTIFICATION_COUNT_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}
