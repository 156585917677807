import { SynonymService } from '../../services/category/synonym'

export const SynonymActions = {
    categories,
    category,
    values,
    remove,
    save,
    filter
}

function categories (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'SYNONYM_CATEGORIES_REQUEST' })

        SynonymService.categories(params)
            .then(
                categories => {
                    dispatch({ type: 'SYNONYM_CATEGORIES_SUCCESS', payload: categories })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(categories)
                },
                error => {
                    dispatch({ type: 'SYNONYM_CATEGORIES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function category (id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'SYNONYM_CATEGORY_REQUEST' })

        SynonymService.category(id)
            .then(
                category => {
                    dispatch({ type: 'SYNONYM_CATEGORY_SUCCESS', payload: category })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(category)
                },
                error => {
                    dispatch({ type: 'SYNONYM_CATEGORY_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function values (id, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'SYNONYM_CATEGORY_VALUES_REQUEST' })

        SynonymService.values(id, params)
            .then(
                values => {
                    dispatch({ type: 'SYNONYM_CATEGORY_VALUES_SUCCESS', payload: values })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(values.data)
                },
                error => {
                    dispatch({ type: 'SYNONYM_CATEGORY_VALUES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function remove (id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'SYNONYM_DELETE_CATEGORY_VALUE_REQUEST' })

        SynonymService.remove(id, params)
            .then(
                () => {
                    dispatch({ type: 'SYNONYM_DELETE_CATEGORY_VALUE_SUCCESS' })
                    resolve()
                },
                error => {
                    dispatch({ type: 'SYNONYM_DELETE_CATEGORY_VALUE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function save (id, params) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'SYNONYM_SAVE_CATEGORY_VALUES_REQUEST' })

        SynonymService.save(id, params)
            .then(
                () => {
                    dispatch({ type: 'SYNONYM_SAVE_CATEGORY_VALUES_SUCCESS' })
                    resolve()
                },
                error => {
                    dispatch({ type: 'SYNONYM_SAVE_CATEGORY_VALUES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function filter (id = null, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        SynonymService.filter(id, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}
