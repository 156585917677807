import { history } from '../helpers/history'
import * as AppStorage from '../helpers/storage';

export const HttpService = {
    http
}

function http(path, options = {}, authorization = false, formData = false) {
    options.headers = Object.assign({
        'Accept': 'application/json',
    }, options.headers ? options.headers : {})

    if (!formData) {
        options.headers["Content-Type"] = "application/json"
    }

    if (authorization) {
        options.headers.Authorization = AppStorage.get('token')
    }

    if (options.params && Object.keys(options.params).length) {
        path = `${path}?`

        let paramPath = false
        for (let name in options.params) {
            if (paramPath) {
                path = `${path}&`
            }

            if (options.params.hasOwnProperty(name)) {
                path = `${path}${name}=${(name === 'search') ? options.params[name].replace('+', '%2B') : options.params[name]}`
            }

            paramPath = true
        }
    }

    return fetch(`${process.env.REACT_APP_HOST_API}${path}`, options)
        .then(handleResponse)
}

function handleResponse(response) {
    let data
    let headers = response.headers

    if (headers.has('Authorization')) {
        AppStorage.remove('token')
        AppStorage.set('token', headers.get('Authorization'))
    }

    switch (response.status) {
        case 204:
            return response
        case 304:
            return response
        case 401:
            AppStorage.remove('account')
            AppStorage.remove('token')
            history.push('/logout')
            break
        default:
            data = response.json()
    }

    if (!response.ok) {
        return data.then(Promise.reject.bind(Promise))
    }

    return data
}
