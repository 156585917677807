import { combineReducers } from 'redux'

import application from './application'
import alert from './alert'
import message from './message'
import system from './system'
import dictionary from '../../Dictionary/reducers/dictionary'
import converter from '../../Converter/reducers/converter'
import authentication from  '../../Auth/reducers/authentication'
import account from '../../Account/reducers/account'
import settings from '../../Settings/reducers/settings'
import item from '../../Item/reducers/item'
import history from '../../Item/reducers/history'
import attribute from '../../Item/reducers/attribute'
import category from '../../Category/reducers/category'
import synonym from '../../Dictionary/reducers/synonym'
import synonym_term from '../../Dictionary/reducers/term'
import attribute_type from '../../Category/reducers/Attribute/type'
import unit from '../../Dictionary/reducers/unit'
import role from '../../Access/reducers/role'
import permission from '../../Access/reducers/permission'
import user from '../../Access/reducers/user'
import markup_file from "../../Markup/reducers/file"
import markup_item from "../../Markup/reducers/item"
import download from "../../Download/reducers/download"
import notification from "../../Notification/reducers/notification"

const AppReducers = combineReducers({
    application,
    alert,
    message,
    system,
    settings,
    dictionary,
    converter,
    authentication,
    account,
    item,
    history,
    attribute,
    category,
    synonym,
    synonym_term,
    attribute_type,
    unit,
    role,
    permission,
    user,
    markup_file,
    markup_item,
    download,
    notification
})

export default AppReducers
