import { HttpService } from '../../../App/services/http'

export const SynonymService = {
    attributes,
    attribute,
    values,
    remove,
    save,
    filter
}

function attributes (params) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/dictionary/synonym/attributes`, options, true)
        .then(response => {
            return response
        })
}

function attribute (id) {
    const options = {
        method: 'GET'
    }

    return HttpService.http(`/dictionary/synonym/attribute/${id}`, options, true)
        .then(response => {
            return response.attribute
        })
}

function values (id, params) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/dictionary/synonym/attribute/${id}/values`, options, true)
        .then(response => {
            return response
        })
}

function remove (id, params) {
    const options = {
        method: 'DELETE',
        body: JSON.stringify(params)
    }

    return HttpService.http(`/dictionary/synonym/attribute/${id}/values`, options, true)
        .then(response => {
            return response
        })
}

function save (id, params) {
    const options = {
        method: 'PUT',
        body: JSON.stringify(params)
    }

    return HttpService.http(`/dictionary/synonym/attribute/${id}/values`, options, true)
        .then(response => {
            return response
        })
}

function filter (id = null, params = {}) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(id ? `/dictionary/synonym/attribute/${id}/values/filter` : `/dictionary/synonym/attributes/filter`, options, true)
        .then(response => {
            return response
        })
}
