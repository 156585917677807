import React from 'react'
import {Formik, Field, Form} from 'formik'

import {
    Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Grid,
    IconButton, makeStyles, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, Checkbox
} from '@material-ui/core'
import {
    TextField
} from 'formik-material-ui'
import {Close} from "@material-ui/icons"
import {useDispatch} from "react-redux"
import {standard} from "../../App/helpers/standard";
import * as Yup from "yup";
import {ItemActions} from "../actions/item";

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    },
    primary: {
        'color': 'primary'
    },
    error: {
        'color': '#f44336'
    }
}))

const columns = [
    {id: 0, label: 'Главный'},
    {id: 1, label: 'Эталон'},
    {id: 2, label: ''},
]

export const UnionForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { handleClose, handleDelete, open, items, categories } = props

    return (
        <Formik
            enableReinitialize={ true }
            initialValues = {{
                general: null,
                comment: '',
                items: items.map(item => ({
                    id: item.id,
                    name: standard(categories.find(cat => item.category.id === cat.id), categories, item.values)
                }))
            }}
            validationSchema={Yup.object().shape({
                general: Yup.number().required("Выберите главный эталон!"),
                comment: Yup.string(),
                items: Yup.array().of(Yup.object().shape({
                    id: Yup.number().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    name: Yup.string()
                }))
            })}
            onSubmit = {(values, { setSubmitting }) => {
                return dispatch(ItemActions.union({
                    ...values,
                    ...{items: values.items.filter(item => (values.general !== item.id)).map(item => item.id)}
                })).then(
                    () => {
                        setSubmitting(false)
                        handleClose(true)
                    },
                    errors => {
                        if (errors) { }
                        setSubmitting(false)
                    }
                )
            }}
        >
            {({
                setFieldValue,
                values,
                errors,
                handleSubmit,
                isSubmitting
            }) => (
                <Form>
                    <Dialog
                        fullWidth={ true }
                        maxWidth="md"
                        open={ open }
                        onClose={ handleClose }
                        aria-labelledby="Объединение эталонов"
                        classes={{
                            paper: classes.dialog
                        }}
                    >
                        <DialogTitle>Объединение эталонов</DialogTitle>
                        <DialogContent>
                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <TableContainer>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map(column => (
                                                        <TableCell
                                                            key={column.id}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {values.items.map(item => (
                                                    <TableRow key={item.id} >
                                                        <TableCell align="left">
                                                            <Checkbox
                                                                name={'general'}
                                                                color='primary'
                                                                classes={{root: errors.hasOwnProperty('general') ? classes.error : classes.primary }}
                                                                onClick={() => { setFieldValue('general', (values.general === item.id) ? null : item.id) }}
                                                                checked={(values.general === item.id)}
                                                                disabled={ isSubmitting }
                                                            />
                                                        </TableCell>
                                                        <TableCell align="left" style={{ whiteSpace: 'normal' }}>
                                                            {item.name}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton
                                                                aria-label="Удалить"
                                                                disabled={ isSubmitting }
                                                                onClick={() => handleDelete(item.id) }
                                                            >
                                                                <Close />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <Field
                                        fullWidth
                                        name={`comment`}
                                        type="text"
                                        label="Комментарий"
                                        multiline
                                        rows={4}
                                        component={ TextField }
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <DialogActions>
                                <Button
                                    disabled={ isSubmitting }
                                    onClick={ handleClose }
                                    color="secondary"
                                    type="submit"
                                >
                                    Отмена
                                </Button>
                                <Button
                                    disabled={ isSubmitting || (items.length < 2) }
                                    onClick={ handleSubmit }
                                    color="primary"
                                    type="submit"
                                >
                                    { isSubmitting ? <CircularProgress size={24} /> : 'Сохранить' }
                                </Button>
                            </DialogActions>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    )
}
