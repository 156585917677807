export default function dictionary (state = {
    dictionary: null,
    items: {data: [], meta: {}, limit: 50, page: 1},
    attribute: {
        items: {data: [], meta: {}, limit: 50, page: 1}
    },
    categories:[],
    dictionaries: {data: [], meta: {}, limit: 50, page: 1},
    filter: {sort: {name: null, direction: null}, conditions: {condition: null, expression: null}}
}, action) {
    switch (action.type) {
        case 'DICTIONARY_REQUEST':
            return state
        case 'DICTIONARY_SUCCESS':
            return {
                ...state,
                ...{dictionary: action.payload}
            }
        case 'DICTIONARY_FAILURE':
            return {
                ...state,
                ...{dictionary: null}
            }
        case 'DICTIONARY_CLEAR':
            return {
                ...state,
                ...{dictionary: null}
            }
        case 'DICTIONARY_ITEMS_REQUEST':
            return state
        case 'DICTIONARY_ITEMS_SUCCESS':
            return {
                ...state,
                ...{items: action.payload}
            }
        case 'DICTIONARY_ITEMS_FAILURE':
            return {
                ...state,
                ...{items: {data: [], meta: {}, limit: 50, page: 1}}
            }
        case 'DICTIONARY_ITEMS_CLEAR':
            return {
                ...state,
                ...{items: {data: [], meta: {}, limit: 50, page: 1}}
            }
        case 'DICTIONARY_ATTRIBUTE_ITEMS_REQUEST':
            return state
        case 'DICTIONARY_ATTRIBUTE_ITEMS_SUCCESS':
            return {
                ...state,
                ...{attribute: {items: action.payload}}
            }
        case 'DICTIONARY_ATTRIBUTE_ITEMS_FAILURE':
            return {
                ...state,
                ...{attribute: {items: {data: [], meta: {}, limit: 50, page: 1}}}
            }
        case 'DICTIONARY_ATTRIBUTE_ITEMS_CLEAR':
            return {
                ...state,
                ...{attribute: {items: {data: [], meta: {}, limit: 50, page: 1}}}
            }
        case 'DICTIONARY_CATEGORIES_REQUEST':
            return state
        case 'DICTIONARY_CATEGORIES_SUCCESS':
            return {
                ...state,
                ...{categories: action.payload}
            }
        case 'DICTIONARY_CATEGORIES_FAILURE':
            return {
                ...state,
                ...{categories: []}
            }
        case 'DICTIONARY_CATEGORIES_CLEAR':
            return {
                ...state,
                ...{categories: []}
            }
        case 'DICTIONARIES_REQUEST':
            return state
        case 'DICTIONARIES_SUCCESS':
            return {
                ...state,
                ...{dictionaries: action.payload}
            }
        case 'DICTIONARIES_FAILURE':
            return {
                ...state,
                ...{dictionaries: {data: [], meta: {}, limit: 50, page: 1}}
            }
        case 'DICTIONARIES_CLEAR':
            return {
                ...state,
                ...{dictionaries: {data: [], meta: {}, limit: 50, page: 1}}
            }
        case 'DICTIONARY_DESCRIPTION_SUCCESS':
            return {
                ...state,
                ...{
                    dictionaries: {
                        ...state.dictionaries,
                        ...{
                            data: state.dictionaries.data.map(dictionary => {
                                return (action.payload.id === dictionary.id) ? {
                                    ...dictionary,
                                    ...action.payload.values,
                                } : dictionary
                            })
                        },
                    }
                }
            }
        case 'DICTIONARY_FILTER':
            return {
                ...state,
                ...{filter: {
                    ...state.filter,
                    ...{sort: action.payload.sort},
                    ...{conditions: action.payload.conditions},
                    ...action.payload
                }}
            }
        default:
            return state
    }
}
