import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"

import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    makeStyles
} from '@material-ui/core'
import {Add, Clear, Close} from '@material-ui/icons'
import {Field, FieldArray, Form, Formik} from "formik"
import {TextField} from "formik-material-ui"
import * as Yup from "yup"
import {AttributeActions} from "../../../../Item/actions/attribute"
import Autocomplete from "@material-ui/lab/Autocomplete"

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    }
}))

export const ValueForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {items} = useSelector(state => {return {items: state.attribute.terms}})

    const [initialize, setInitialize] = useState(false)
    const [value] = useState(props.value)

    const {handleInitialize, handleSave, handleClose, open, attribute} = props

    useEffect(() => {
        const getData = async () => {
            return await dispatch(AttributeActions.terms(attribute.id, {page: 1, limit: 50}))
        }

        if (!initialize) {
            handleInitialize()
            getData().then(() => {
                setInitialize(true)
            })
        }

        if (initialize) {
            return () => {
                dispatch({type: 'ATTRIBUTE_VALUES_CLEAR'})
            }
        }
    }, [initialize, attribute])

    return (
        <Formik
            initialValues = {{
                values: value ? [value] : [{value: '', option: ''}]
            }}
            validationSchema={Yup.object().shape({
                values: Yup.array().of(Yup.object().shape({
                    value: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                    option: Yup.string().required("Поле не может быть пустым или состоять из одних пробелов!")
                }))
            })}
            onSubmit = {(values, { setSubmitting }) => {
                handleSave(attribute.id, values).then(
                    () => {
                        setSubmitting(false)
                        dispatch(handleClose)
                    },
                    () => {
                        setSubmitting(false)
                    }
                )
            }}
        >
            {({
                  values,
                  errors,
                  handleSubmit,
                  isSubmitting,
                  setSubmitting,
                  setValues,
                  setTouched,
                  setFieldValue
              }) => (
                <Form>
                    <Dialog
                        fullWidth={true}
                        maxWidth="md"
                        open={open}
                        onClose={handleClose}
                        classes={{
                            paper: classes.dialog
                        }}
                    >
                        <DialogTitle>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item>
                                    {value ? 'Редактировать' : 'Добавить'}
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label="Закрыть"
                                        onClick={() => handleClose()}
                                    >
                                        <Close/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <FieldArray
                                        name="values"
                                        render={ arrayOptions => (
                                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                                {values.values.map((option, key) => (
                                                    <Grid item key={key} className={classes.fullWidth}>
                                                        <Grid container direction='row' justify='space-between' alignItems='flex-end' spacing={2}>
                                                            <Grid item sm={5} className={classes.fullWidth}>
                                                                <Autocomplete
                                                                    filterOptions={(options, state) => options}
                                                                    options={items.data}
                                                                    required
                                                                    disabled={ isSubmitting }
                                                                    getOptionLabel={item => item ? item.value : null}
                                                                    defaultValue={value ? value : null}
                                                                    noOptionsText='Данные не найдены'
                                                                    onChange={(e, value) => {
                                                                        setFieldValue(`values.${key}.value`, (value ? value.value : null))
                                                                    }}
                                                                    onBlur={() => {
                                                                        setTouched({[`values.${key}.value`]: true})
                                                                    }}
                                                                    name={`values.${key}.value`}
                                                                    renderInput={params => (
                                                                        <Field
                                                                            component={TextField}
                                                                            name={`values.${key}.value`}
                                                                            label={attribute.name}
                                                                            {...params}
                                                                            required={true}
                                                                            InputProps={{
                                                                                ...params.InputProps,
                                                                                onChange: (e) => {
                                                                                    dispatch(AttributeActions.terms(attribute.id, {page: 1, limit: 50, search: e.target.value}))
                                                                                }
                                                                            }}
                                                                            fullWidth
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item sm={5} className={classes.fullWidth}>
                                                                <Field
                                                                    fullWidth
                                                                    name={`values.${key}.option`}
                                                                    type="text"
                                                                    label="Синоним"
                                                                    required
                                                                    component={ TextField }
                                                                />
                                                            </Grid>
                                                            {!value &&
                                                                <Grid item>
                                                                    <IconButton
                                                                        onClick={() => arrayOptions.remove(key)}
                                                                        color="primary"
                                                                        aria-label="Удалить"
                                                                        component="span"
                                                                    >
                                                                        <Clear/>
                                                                    </IconButton>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                {!value &&
                                                    <Grid item className={classes.fullWidth}>
                                                        <IconButton
                                                            onClick={() => arrayOptions.push({value: '', option: ''})}
                                                            color={'primary'}
                                                            aria-label="Добавить"
                                                            component="span"
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </Grid>
                                                }
                                            </Grid>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={ isSubmitting || !values.values.length }
                                onClick={ handleSubmit }
                                color="primary"
                                type="submit"
                            >
                                {isSubmitting ? <CircularProgress size={24}/> : (value ? 'Сохранить' : 'Добавить')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    )
}
