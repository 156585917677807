import React, {useState} from "react"
import { Field } from "formik"

import { Select } from "formik-material-ui"
import {FormControl, FormHelperText, InputLabel, makeStyles, MenuItem} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    }
}))

export const FieldMultiselect = (props) => {
    const classes = useStyles()

    const { id, label, items, errors, isSubmitting, disabled } = props
    const [name] = useState(props.hasOwnProperty('name') ? props.name : `attributes.${id}`)

    return (
        <FormControl className={classes.fullWidth} error={ !!(errors.attributes && errors.attributes.hasOwnProperty(`${id}`)) }>
            <InputLabel shrink={ true } htmlFor="roles">
                { label }
            </InputLabel>
            <Field
                fullWidth
                type="text"
                name={`${name}`}
                disabled={ isSubmitting || disabled }
                label={ label }
                component={ Select }
                multiple={ true }
            >
                {items.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Field>
            {!!(errors.attributes && errors.attributes.hasOwnProperty(`${id}`)) &&
                <FormHelperText>{ errors.attributes[`${id}`] }</FormHelperText>
            }
        </FormControl>
    )
}
