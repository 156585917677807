import React, {useEffect, useState} from 'react'

import {
    Grid,
    TextField,
    makeStyles, withStyles, Breadcrumbs, IconButton, Typography, Button
} from '@material-ui/core'
import {blue, red} from "@material-ui/core/colors"

import {useDebouncedCallback} from 'use-debounce'
import {useDispatch} from "react-redux";
import {DictionaryActions} from "../../actions/dictionary";
import {Grid as GridTable} from "../../../App/components/Table/Grid";
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import {CustomPaging, IntegratedSelection, PagingState, SelectionState} from "@devexpress/dx-react-grid";
import {
    DragDropProvider, PagingPanel,
    Table,
    TableColumnReordering,
    TableColumnResizing, TableColumnVisibility, TableHeaderRow, TableSelection
} from "@devexpress/dx-react-grid-material-ui";
import {StickyTable} from "../../../App/components/Table/StikyTable";
import {TableHeaderContent} from "./Values/Table/TableHeaderContent";
import {Pager} from "../../../App/components/Table/Paging/Pager";
import {ColumnChooser} from "../../../Item/components/Table/ColumnChooser";
import {Link, useParams} from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import {GetApp, Home, NavigateNext} from "@material-ui/icons";
import {ValueForm} from "./Values/ValueForm";
import {stringFormat} from "../../../App/helpers/string";
import {DownloadActions} from "../../../Download/actions/download";
import {UnitActions} from "../../actions/unit";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    data: {
        'height': 'calc(100% - 72px)',
        'width': '100%'
    },
    dialog: {
        'height': 'calc(100% - 100px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '52px'
    },
    active: {
        'height': '41px',
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    confirmation: {
        'height': '41px',
        'background-color': red[50],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    body: {
        'height': '100%'
    }
}))

const StyledAlert = withStyles((theme) => ({
    message: {
        'padding': '0'
    },
}))(Alert)

export const Values = (props) => {
    const dispatch = useDispatch()
    const params = useParams()
    const classes = useStyles()

    const [dictionary, setDictionary] = useState(null)
    const [items, setItems] = useState({data: [], meta: {}, limit: 50, page: 1})
    const [filter, setFilter] = useState({sort: {name: null, direction: null}, conditions: {condition: null, expression: null}})

    const { type, callback } = props

    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [initialize, setInitialize] = useState(false)
    const [loading, setLoading] = useState(false)
    const [id] = useState((type) ? props.id : params.id)

    const [value, setValue] = useState(null)
    const [dialog, setDialog] = useState(false)

    const [tableRef, setTableRef] = useState(null)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [select, setSelect] = useState(null)
    const [selection, setSelection] = useState([])
    const [columns, setColumns] = useState([
        { name: 'value', title: 'Значение' },
        { name: 'attribute', title: 'Наименование атрибута' },
        { name: 'category', title: 'Категория' },
        { name: 'active', title: 'Статус' },
        { name: 'pipeline', title: 'Источник' },
        { name: 'source.type', title: 'Источник предложения' },
        { name: 'source.module', title: 'Модуль' },
        { name: 'source.user', title: 'ФИО пользователя' },
        { name: 'source.company', title: 'Наименование компании' },
        { name: 'source.contacts', title: 'Контактная информация' },
        { name: 'created', title: 'Дата создания' },
        { name: 'updated', title: 'Дата обновления' }
    ])
    const [columnWidths, setColumnWidths] = useState([
        { columnName: 'value', width: 450 },
        { columnName: 'attribute', width: 250 },
        { columnName: 'category', width: 250 },
        { columnName: 'active', width: 200 },
        { columnName: 'pipeline', width: 250 },
        { columnName: 'source.type', width: 250 },
        { columnName: 'source.module', width: 250 },
        { columnName: 'source.user', width: 250 },
        { columnName: 'source.company', width: 250 },
        { columnName: 'source.contacts', width: 250 },
        { columnName: 'created', width: 200 },
        { columnName: 'updated', width: 200 }
    ])
    const [columnOrder, setColumnOrder] = useState(['value', 'attribute', 'category', 'active', 'created', 'updated', 'source', 'pipeline', 'source.type', 'source.module', 'source.user', 'source.company', 'source.contacts'])
    const [hiddenColumnNames, setHiddenColumnNames] = useState(['attribute', 'source.module', 'category'])

    const debounced = useDebouncedCallback(
        (value) => {
            setLoading(value)
        }, 700
    )

    useEffect(() => {
        const getData = async () => {
            dispatch(DictionaryActions.categories())
            dispatch(UnitActions.units())
            return await dispatch(DictionaryActions.dictionary(id))
        }

        if (!initialize && id) {
            getData().then(dictionary => {
                setDictionary(dictionary)
                if (dictionary.attributes.length) {
                    setColumns([
                        { name: 'value', title: dictionary.value ?? (dictionary.attribute ? dictionary.attribute.name : 'Значение') },
                        ...dictionary.attributes.map(attribute => ({
                            name: `attributes.${attribute.id}`,
                            title: attribute.name
                        })),
                        { name: 'attribute', title: 'Наименование атрибута' },
                        { name: 'category', title: 'Категория' },
                        { name: 'active', title: 'Статус' },
                        { name: 'pipeline', title: 'Источник' },
                        { name: 'source.type', title: 'Источник предложения' },
                        { name: 'source.module', title: 'Модуль' },
                        { name: 'source.user', title: 'ФИО пользователя' },
                        { name: 'source.company', title: 'Наименование компании' },
                        { name: 'source.contacts', title: 'Контактная информация' },
                        { name: 'created', title: 'Дата создания' },
                        { name: 'updated', title: 'Дата обновления' }
                    ])
                    setColumnWidths([
                        { columnName: 'value', width: 450 },
                        ...dictionary.attributes.map(attribute => ({
                            columnName: `attributes.${attribute.id}`,
                            width: 250
                        })),
                        { columnName: 'attribute', width: 250 },
                        { columnName: 'category', width: 250 },
                        { columnName: 'active', width: 200 },
                        { columnName: 'pipeline', width: 250 },
                        { columnName: 'source.type', width: 250 },
                        { columnName: 'source.module', width: 250 },
                        { columnName: 'source.user', width: 250 },
                        { columnName: 'source.company', width: 250 },
                        { columnName: 'source.contacts', width: 250 },
                        { columnName: 'created', width: 200 },
                        { columnName: 'updated', width: 200 }
                    ])
                    setColumnOrder(['value', ...dictionary.attributes.map(attribute => `attributes.${attribute.id}`), 'attribute', 'category', 'active', 'created', 'updated', 'source', 'pipeline', 'source.type', 'source.module', 'source.user', 'source.company', 'source.contacts'])
                } else {
                    setColumns([
                        ...columns.map(column => {
                            if (column.name === 'value') {
                                column.title = dictionary.value ?? (dictionary.attribute ? dictionary.attribute.name : 'Значение')
                            }

                            return column
                        })
                    ])
                }

                if (dictionary.key === 'black_lists') {
                    setHiddenColumnNames(['source', 'pipeline', 'source.type', 'source.module', 'source.user', 'source.company', 'source.contacts'])
                } else if (!dictionary.attribute) {
                    setHiddenColumnNames([...['attribute', 'source', 'pipeline', 'source.type', 'source.module', 'source.user', 'source.company', 'source.contacts'], ...((dictionary.key === 'units' || dictionary.key === 'sources') ? ['category'] : [])])
                }
                setInitialize(true)
            })
        }

        if (initialize) {
            return () => {
                dispatch({type: 'DICTIONARY_CLEAR'})
                dispatch({type: 'DICTIONARY_ITEMS_CLEAR'})
            }
        }
    }, [initialize, id])

    useEffect(() => {
        const getData = async () => {
            return await dispatch(DictionaryActions.items(id, {
                page: page,
                limit: rowsPerPage,
                dictionary: id,
                ...(search ? {search: search} : {}),
                ...((filter.sort.name && filter.sort.direction) ? {order: [filter.sort.name, filter.sort.direction]} : []),
                ...((filter.hasOwnProperty('value') && filter.value.length) ? {value: filter.value} : []),
                ...((filter.hasOwnProperty('category') && filter.category.length) ? {categories: filter.category} : []),
                ...((filter.hasOwnProperty('active') && filter.active.length) ? {status: filter.active} : []),
                ...((!!filter.conditions.condition && !!filter.conditions.expression) ? {'conditions[condition]': filter.conditions.condition, 'conditions[expression]': filter.conditions.expression} : {})
            }))
        }

        if (dictionary && !loading) {
            getData().then(items => {
                setItems(items)
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setLoading(true)
                setSelection([])
            })
        }
    }, [dictionary, loading, id, page, rowsPerPage, search])

    useEffect(() => {
        setPage(1)
        setLoading(false)
    }, [filter])

    const getStatus = item => {
        if (item.active) {
            return <StyledAlert icon={false} color="success">Активный</StyledAlert>
        } else if (item.confirmed) {
            return <StyledAlert icon={false} color="warning">Неактивный</StyledAlert>
        }
        return <StyledAlert icon={false} color="error">Не проверено</StyledAlert>
    }

    const handleSave = (values, id = null) => {
        if (id) {
            return new Promise((resolve, reject) => {
                dispatch(DictionaryActions.editItem(dictionary.id, id, values)).then(
                    () => {
                        setLoading(false)
                        resolve()
                    },
                    errors => {
                        reject(errors)
                    }
                )
            })
        } else {
            return new Promise((resolve, reject) => {
                dispatch(DictionaryActions.addItem(dictionary.id, values)).then(
                    () => {
                        setLoading(false)
                        resolve()
                    },
                    errors => {
                        reject(errors)
                    }
                )
            })
        }
    }

    const handleReject = (values, id) => {
        return new Promise((resolve, reject) => {
            dispatch(DictionaryActions.rejectItem(dictionary.id, id, values)).then(
                () => {
                    setLoading(false)
                    resolve()
                },
                errors => {
                    reject(errors)
                }
            )
        })
    }

    const getValue = (value) => {
        if (dictionary.attribute) {
            // eslint-disable-next-line default-case
            switch (dictionary.attribute.name) {
                case 'Дозировка':
                case 'Объем, вес и дозы':
                case 'Вес, объем':
                case 'Фасовка':
                case 'Количество':
                case 'Фасовка/Количество':
                case 'Концентрация':
                case 'Игла':
                case 'Плотность/жесткость':
                    value = stringFormat(String(value), false)
            }
        }

        return value
    }

    return initialize ? (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={ classes.body } spacing={2}>
            <Grid item className={classes.element}>
                <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                    <Grid item sm={3}>
                        <TextField
                            fullWidth
                            label='Поиск'
                            value={search}
                            onChange={event => {
                                setPage(1)
                                setSearch(event.target.value)
                                debounced.callback(false)
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.breadcrumb}>
                <Grid container direction="row" justify="space-between" alignItems="center" style={{ height: '38px' }}>
                    <Grid item>
                        <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
                            <IconButton
                                type="button"
                                size="small"
                                component={Link}
                                to="/dictionaries"
                            >
                                <Home />
                            </IconButton>
                            <Typography variant="subtitle2" color="textPrimary">{dictionary.name}</Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                            {(type === 'dialog') &&
                                <Grid item>
                                    <Button
                                        disabled={!select}
                                        onClick={() => {
                                            if (callback) {
                                                callback(items.data.find(item => (item.id === select)))
                                            }
                                        }}
                                        color="primary"
                                        type="button"
                                    >
                                        Выбрать
                                    </Button>
                                </Grid>
                            }
                            {((dictionary.key !== 'black_lists') && (dictionary.key !== 'sources')) &&
                                <Grid item>
                                    <Button
                                        disabled={!select || !!items.data.find(item => (item.id === select)).confirmed}
                                        onClick={() => {
                                            setValue(items.data.find(item => (item.id === select)))
                                            setDialog(true)
                                        }}
                                        color="primary"
                                        type="button"
                                    >
                                        Подтвердить
                                    </Button>
                                </Grid>
                            }
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        setDialog(true)
                                    }}
                                    color="primary"
                                    type="button"
                                >
                                    Добавить
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={!select || ((dictionary.key !== 'black_lists') && !items.data.find(item => (item.id === select)).confirmed)}
                                    onClick={() => {
                                        setValue(items.data.find(item => (item.id === select)))
                                        setDialog(true)
                                    }}
                                    color="primary"
                                    type="button"
                                >
                                    Редактировать
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={!select || ((dictionary.key !== 'black_lists') && (!items.data.find(item => (item.id === select)).confirmed || items.data.find(item => (item.id === select)).active))}
                                    onClick={() => {
                                        dispatch(DictionaryActions.deleteItem(dictionary.id, select)).then(
                                            () => {
                                                setSelect(null)
                                                setLoading(false)
                                            }
                                        )
                                    }}
                                    color="secondary"
                                    type="button"
                                >
                                    Удалить
                                </Button>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    type="button"
                                    size="small"
                                    onClick={() => {
                                        dispatch(DownloadActions.create({
                                            type: 'attribute_dictionaries',
                                            columns: columnOrder.filter(name => (hiddenColumnNames.indexOf(name) < 0)),
                                            params: {
                                                dictionary: dictionary.id,
                                                ...(search ? {search: search} : {}),
                                                ...((filter.sort.name && filter.sort.direction) ? {order: `${filter.sort.name}, ${filter.sort.direction}`} : []),
                                                ...((filter.hasOwnProperty('value') && filter.value.length) ? {value: filter.value.join(',')} : []),
                                                ...((filter.hasOwnProperty('category') && filter.category.length) ? {categories: filter.category.join(',')} : []),
                                                ...((filter.hasOwnProperty('active') && filter.active.length) ? {status: filter.active.join(',')} : []),
                                                ...((!!filter.conditions.condition && !!filter.conditions.expression) ? {'conditions[condition]': filter.conditions.condition, 'conditions[expression]': filter.conditions.expression} : {})
                                            }
                                        }))
                                    }}
                                >
                                    <GetApp />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <ColumnChooser columns={columns} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={(type === 'dialog') ? classes.dialog : classes.data}>
                <GridTable
                    rows={items.data.map(item => ({
                        'id': item.id,
                        'value': getValue(item.value),
                        ...dictionary.attributes.reduce((result, attribute) => {
                            switch (attribute.type) {
                                case 'one':
                                    result[`attributes.${attribute.id}`] = item.items.find(value => (value.attribute.id === attribute.id)) ? item.items.find(value => value.attribute.id === attribute.id).value : null
                                    break
                                case 'many':
                                    result[`attributes.${attribute.id}`] = item.items.filter(value => { return value.attribute.id === attribute.id }).reduce((result, value) => {
                                        return result ? `${result}, ${value.value}` : value.value
                                    }, null)
                                    break
                            }
                            return result
                        }, {}),
                        'confirmed': item.confirmed,
                        'attribute': item.dictionary.attribute ? item.dictionary.attribute.name : null,
                        'category': item.dictionary.attribute ? item.dictionary.attribute.category.name : (item.categories ? item.categories.join(', ') : null),
                        'active': getStatus(item),
                        'pipeline': item.pipeline ? item.pipeline.value : null,
                        'source.type': item.source ? function () {
                            switch (item.source.type) {
                                case 'customer':
                                    return 'Заказчик'
                                case 'supplier':
                                    return 'Поставщик'
                                default:
                                    return 'Система'
                            }
                        }() : 'Система',
                        'source.module': item.source ? item.source.module : null,
                        'source.user': item.source ? item.source.user : null,
                        'source.company': item.source ? item.source.company : null,
                        'source.contacts': item.source ? function () {
                            if (item.source.contacts) {
                                let contacts = ''

                                Object.keys(item.source.contacts).forEach(type => {
                                    if (!!item.source.contacts[type]) {
                                        switch (type) {
                                            case 'phones':
                                                contacts = `${contacts.length ? `${contacts}; ` : ''}тел.: ${item.source.contacts[type].map(phone => { return `${phone.number} ${phone.extra ? `доб. ${phone.extra}` : ''}` }).join(', ')}`
                                                break
                                            case 'emails':
                                                contacts = `${contacts.length ? `${contacts}; ` : ''}эл. почта: ${item.source.contacts[type].join(', ')}`
                                                break
                                        }
                                    }
                                })

                                return contacts
                            }

                            return null
                        }() : null,
                        'created': item.created ? format(new Date(item.created), 'H:mm PP',  {locale: ru}) : null,
                        'updated': item.updated ? format(new Date(item.updated), 'H:mm PP',  {locale: ru}) : null
                    }))}
                    columns={columns}
                >
                    <PagingState
                        currentPage={page}
                        onCurrentPageChange={newPage => {
                            setPage(newPage)
                            setSelect(null)
                            setLoading(false)
                        }}
                        pageSize={rowsPerPage}
                        onPageSizeChange={newRowsPerPage => {
                            setPage(1)
                            setSelect(null)
                            setRowsPerPage(newRowsPerPage)
                            setLoading(false)
                        }}
                    />
                    <CustomPaging
                        totalCount={items.data.length ? items.meta.total : 0}
                    />
                    <SelectionState
                        selection={selection}
                        onSelectionChange={(numbers) => {
                            const current = numbers.filter(x => !selection.includes(x))

                            if (!current.length) {
                                setValue(items.data[selection[0]])
                                setDialog(true)
                            } else {
                                setSelection(numbers.filter(x => !selection.includes(x)))
                            }
                        }}
                    />
                    <IntegratedSelection />
                    <DragDropProvider />
                    <Table
                        noDataCellComponent={props => {
                            return null
                        }}
                        tableComponent={props => <StickyTable onClick={e => e.stopPropagation()} {...props} setTableRef={setTableRef} />}
                        rowComponent={({ row, tableRow, children }) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.default}
                                row={row}
                            />
                        )}
                    />
                    <TableColumnReordering
                        order={columnOrder}
                        onOrderChange={setColumnOrder}
                    />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                    />
                    <TableHeaderRow
                        contentComponent={(props) => <TableHeaderContent {...props} dictionary={dictionary} filter={filter} setFilter={setFilter} />}
                    />
                    <TableColumnVisibility
                        hiddenColumnNames={hiddenColumnNames}
                        onHiddenColumnNamesChange={setHiddenColumnNames}
                    />
                    <TableSelection
                        selectByRowClick
                        highlightRow
                        showSelectionColumn={false}
                        rowComponent={(props) => {
                            const { tableRow, children, highlighted, onToggle } = props
                            const { row } = tableRow
                            return (
                                <Table.Row
                                    tableRow={tableRow}
                                    children={children}
                                    onClick={() => {
                                        setSelect(row.id)
                                        onToggle()
                                    }}
                                    className={(highlighted || (select === row.id)) ? classes.active : (row.confirmed ? classes.default : classes.confirmation)}
                                    row={row}
                                />
                            )
                        }}
                    />
                    <PagingPanel
                        containerComponent={Pager}
                        messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from}-{to} из {count}'}}
                        pageSizes={[50, 100, 200]}
                    />
                </GridTable>
            </Grid>
            {dialog &&
                <ValueForm
                    open={dialog}
                    value={value}
                    handleClose={() => {
                        setSelect(null)
                        setSelection([])
                        setValue(null)
                        setDialog(false)
                    }}
                    handleSave={handleSave}
                    handleReject={handleReject}
                    dictionary={dictionary}
                />
            }
        </Grid>
    ) : null
}
