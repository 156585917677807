export default function file (state = { positions: { data: [], meta: {}, limit: 100, page: 1 } }, action) {
    switch (action.type) {
        case 'CONVERTER_POSITIONS_REQUEST':
            return state
        case 'CONVERTER_POSITIONS_SUCCESS':
            return {
                ...state,
                ...{positions: action.payload}
            }
        case 'CONVERTER_POSITIONS_FAILURE':
            return {
                ...state,
                ...{positions: { data: [], meta: {}, limit: state.positions.limit, page: state.positions.page }}
            }
        case 'CONVERTER_POSITIONS_CLEAR':
            return {
                ...state,
                ...{positions: { data: [], meta: {}, limit: 10, page: 1 }}
            }
        default:
            return state
    }
}
