export default function item (
    state = {
        items: { data: [], meta: {}, category: null, limit: 100, page: 1 },
        offers: {offers: {count: 0, categories: {}}, fresh: {count: 0, categories: {}}},
        filter: {category: null, order: [], selected: {}, conditions: {}, attributes: {}, sort: {name: null, direction: null}, status: [], source: [], company: [], module: [], standard: []}
    },
    action
) {
    switch (action.type) {
        case 'ITEMS_REQUEST':
            return state
        case 'ITEMS_SUCCESS':
            return {
                ...state,
                ...{items: action.payload}
            }
        case 'ITEMS_FAILURE':
            return {
                ...state,
                ...{items: { data: [], meta: {}, category: state.items.category, limit: state.items.limit, page: state.items.page }}
            }
        case 'ITEMS_CLEAR':
            return {
                ...state,
                ...{items: { data: [], meta: {}, category: null, limit: 10, page: 1 }}
            }
        case 'ITEM_DELETE_REQUEST':
            return state
        case 'ITEM_DELETE_SUCCESS':
            return {
                ...state,
                ...{items: { data: state.items.data.filter(item => item.id !== action.payload), meta: state.items.meta }}
            }
        case 'ITEM_DELETE_FAILURE':
            return state
        case 'ITEM_ADD_REQUEST':
            return state
        case 'ITEM_ADD_SUCCESS':
            return state
        case 'ITEM_ADD_FAILURE':
            return state
        case 'ITEM_SAVE_REQUEST':
            return state
        case 'ITEM_SAVE_SUCCESS':
            return {
                ...state,
                ...{items: {
                    ...state.items,
                    ...{data: state.items.data.map((item, key) => {
                        if (item.id === action.payload.id) {
                            item = action.payload
                        }
                        return item
                    })}
                }}
            }
        case 'ITEM_SAVE_FAILURE':
            return state
        case 'ITEMS_UNION_REQUEST':
            return state
        case 'ITEMS_UNION_SUCCESS':
            return state
        case 'ITEMS_UNION_FAILURE':
            return state
        case 'ITEM_MATCHING_REQUEST':
            return state
        case 'ITEM_MATCHING_SUCCESS':
            return state
        case 'ITEM_MATCHING_FAILURE':
            return state
        case 'ITEM_OFFERS_REQUEST':
            return state
        case 'ITEM_OFFERS_SUCCESS':
            return {
                ...state,
                ...{offers: action.payload}
            }
        case 'ITEM_OFFERS_FAILURE':
            return {
                ...state,
                ...{offers: {offers: {count: 0, categories: {}}, fresh: {count: 0, categories: {}}}}
            }
        case 'ITEM_FILTER_CATEGORY':
            return {
                ...state,
                ...{filter: {
                    ...state.filter,
                    ...{category: action.payload}
                }}
            }
        case 'ITEM_FILTER':
            return {
                ...state,
                ...{filter: {
                    ...state.filter,
                    ...{order: action.payload.order},
                    ...{sort: action.payload.sort},
                    ...{selected: action.payload.selected},
                    ...{conditions: action.payload.conditions},
                    ...{attributes: action.payload.attributes},
                    ...{status: action.payload.status},
                    ...{source: action.payload.source},
                    ...{company: action.payload.company},
                    ...{module: action.payload.module},
                    ...{standard: action.payload.standard}
                }}
            }
        default:
            return state
    }
}
