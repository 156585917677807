export default function file (state = { files: { data: [], meta: {}, limit: 100, page: 1 } }, action) {
    switch (action.type) {
        case 'DOWNLOAD_FILES_REQUEST':
            return state
        case 'DOWNLOAD_FILES_SUCCESS':
            return {
                ...state,
                ...{files: action.payload}
            }
        case 'DOWNLOAD_FILES_FAILURE':
            return {
                ...state,
                ...{files: { data: [], meta: {}, limit: state.files.limit, page: state.files.page }}
            }
        case 'DOWNLOAD_FILES_CLEAR':
            return {
                ...state,
                ...{files: { data: [], meta: {}, limit: 10, page: 1 }}
            }
        case 'DOWNLOAD_FILE_CREATE_REQUEST':
            return state
        case 'DOWNLOAD_FILE_CREATE_SUCCESS':
            return state
        case 'DOWNLOAD_FILE_CREATE_FAILURE':
            return state
        default:
            return state
    }
}
