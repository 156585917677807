import { DictionaryService } from '../services/dictionary'

export const DictionaryAttributeActions = {
    items,
}

function items (id, params = { }, data = false) {
    return dispatch => new Promise((resolve, reject) => {
        if (!data) {
            dispatch({type: 'FILLING', payload: true})
            dispatch({type: 'DICTIONARY_ATTRIBUTE_ITEMS_REQUEST'})
        }

        DictionaryService.items(id, params)
            .then(
                items => {
                    if (data) {
                        resolve(items.data)
                    } else {
                        dispatch({ type: 'DICTIONARY_ATTRIBUTE_ITEMS_SUCCESS', payload: items })
                        dispatch({ type: 'FILLING', payload: false })
                        resolve(items)
                    }
                },
                error => {
                    if (!data) {
                        dispatch({type: 'DICTIONARY_ATTRIBUTE_ITEMS_FAILURE'})
                        dispatch({type: 'ALERT_ERROR', payload: error.message})
                        dispatch({type: 'FILLING', payload: false})
                    }
                    reject()
                }
            )
    })
}
