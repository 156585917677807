import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Formik, Field, Form} from 'formik'
import {
    Button,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    makeStyles,
    Typography,
    ListItemIcon,
    ListItemText,
    FormControlLabel,
    LinearProgress,
} from '@material-ui/core'
import {
    Switch,
    TextField
} from 'formik-material-ui'
import { FieldString } from "../../Category/components/Attribute/FieldString"
import { FieldInteger } from "../../Category/components/Attribute/FieldInteger"
import { FieldDouble } from "../../Category/components/Attribute/FieldDouble"
import { FieldBoolean } from "../../Category/components/Attribute/FieldBoolean"
import { FieldSelect } from "../../Category/components/Attribute/FieldSelect"
import { FieldMultiselect } from "../../Category/components/Attribute/FieldMultiselect"
import { FieldDictionary } from "../../Category/components/Attribute/FieldDictionary"
import { FieldUnit } from "../../Category/components/Attribute/FieldUnit"
import { FieldIntegerUnit } from "../../Category/components/Attribute/FieldIntegerUnit"
import { FieldDoubleUnit } from "../../Category/components/Attribute/FieldDoubleUnit"
import { FieldNumber } from "../../Category/components/Attribute/FieldNumber"
import { FieldSize } from "../../Category/components/Attribute/FieldSize"
import { FieldCompositeSize } from "../../Category/components/Attribute/FieldCompositeSize"
import { FieldVolume } from "../../Category/components/Attribute/FieldVolume"
import { FieldCompositeVolume } from "../../Category/components/Attribute/FieldCompositeVolume"
import { FieldDosage } from "../../Category/components/Attribute/FieldDosage"
import { FieldNumberDefinitions } from "../../Category/components/Attribute/FieldNumberDefinitions"
import { FieldNeedle } from "../../Category/components/Attribute/FieldNeedle"
import { ItemActions } from "../actions/item"
import { MatchingForm } from "./MatchingForm"
import {Close, ExpandLess, ExpandMore} from "@material-ui/icons"
import { stringFormat } from '../../App/helpers/string'
import {AttributeActions} from "../actions/attribute"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {Offers} from "./Offers"
import {FieldRangeUnit} from "../../Category/components/Attribute/FieldRangeUnit";
import {Images} from "./Images";
import {useDebounce} from "use-debounce";
import {DictionaryAttributeActions} from "../../Dictionary/actions/attribute";
import {getValue} from "../../Category/components/Attribute/helpers/value";
import {Changes} from "./Changes";

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    },
    disabled: {
        'color': 'grey'
    },
    default: {
        'color': 'inherit'
    },
    items: {
        'width': '100%',
        'overflow': 'auto'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    }
}))

export const ItemForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {categories, dictionaries, units} = useSelector(state => {
        const { categories } = state.system
        const { units } = state.unit

        return {
            categories, dictionaries: state.dictionary, units: units.data
        }
    })

    const { handleClose, handleDelete, handleSave, open, category, offer } = props

    const [standardRef, setStandardRef] = useState(null)
    const [initialize, setInitialize] = useState(false)
    const [item, setItem] = useState(props.item)
    const [agreement, setAgreement] = useState(props.agreement ?? false)
    const [reject, setReject] = useState(false)
    const [standards, setStandards] = useState([])
    const [copy] = useState(props.copy ?? false)
    const [search, setSearch] = useState({search: null, type: null, attribute: null, item: null, listeners: {}})
    const [searchRequest] = useDebounce(search, 700)
    const [attributes, setAttributes] = useState([])
    const [valuesAttribute, setValuesAttribute] = useState({})
    const [matching, setMatching] = useState(false)
    const [changes, setChanges] = useState(false)
    const [openCategory, setOpenCategory] = useState({})

    useEffect(() => {
        if (search.type) {
            if (search.search) {
                let listeners = Object.entries(search.listeners).reduce((result, [id, value]) => {
                    result[`listeners[${id}]`] = value

                    return result
                }, {})

                dispatch(DictionaryAttributeActions.items(search.type, {...{search: search.search, status: ['active']}, ...(!!Object.keys(listeners).length ? listeners : {}), ...{limit: 20}}))
            }
        } else if (search.attribute) {
            if (search.search) {
                dispatch(AttributeActions.items(search.attribute, {...{search: search.search}, ...{limit: 20}}))
            }
        }
    }, [searchRequest])

    useEffect(() => {
        if (search.type) {
            if (!search.search) {
                dispatch({type: 'DICTIONARY_ATTRIBUTE_ITEMS_CLEAR'})
            }
        } else if (search.attribute) {
            if (!search.search) {
                dispatch({type: 'ATTRIBUTE_ITEMS_CLEAR'})
            }
        }
    }, [search])

    useEffect(() => {
        if (reject) {
            dispatch(ItemActions.analysis({search: item.name, values: true})).then(data => setStandards(data))
        }
    }, [reject])

    const getAttributeWithValue = (category, attributes, values) => {
        category.attributes.forEach(attribute => {
            switch(attribute.type.key) {
                case 'string':
                    attributes.push({ Field: FieldString, attribute: attribute })
                    values[`${attribute.id}`] = attribute.multiple ? [] : ''
                    break
                case 'integer':
                    attributes.push({ Field: FieldInteger, attribute: attribute })
                    values[`${attribute.id}`] = 0
                    break
                case 'double':
                    attributes.push({ Field: FieldDouble, attribute: attribute })
                    values[`${attribute.id}`] = 0.00000
                    break
                case 'boolean':
                    attributes.push({ Field: FieldBoolean, attribute: attribute })
                    values[`${attribute.id}`] = false
                    break
                case 'select':
                    attributes.push({ Field: FieldSelect, attribute: attribute })
                    values[`${attribute.id}`] = 0
                    break
                case 'multiselect':
                    attributes.push({ Field: FieldMultiselect, attribute: attribute })
                    values[`${attribute.id}`] = []
                    break
                case 'dictionary':
                    attributes.push({ Field: FieldDictionary, attribute: attribute })
                    values[`${attribute.id}`] = attribute.multiple ? [] : ''
                    break
                case 'unit':
                    attributes.push({ Field: FieldUnit, attribute: attribute })
                    values[`${attribute.id}`] = ''
                    break
                case 'integer_unit':
                    attributes.push({ Field: FieldIntegerUnit, attribute: attribute })
                    values[`${attribute.id}`] = {
                        value: '',
                        unit: ''
                    }
                    break
                case 'double_unit':
                    attributes.push({ Field: FieldDoubleUnit, attribute: attribute })
                    values[`${attribute.id}`] = {
                        value: '',
                        unit: ''
                    }
                    break
                case 'range_unit':
                    attributes.push({ Field: FieldRangeUnit, attribute: attribute })
                    values[`${attribute.id}`] = {
                        value: '',
                        max: '',
                        unit: ''
                    }
                    break
                case 'number':
                    attributes.push({ Field: FieldNumber, attribute: attribute })
                    values[`${attribute.id}`] = {
                        number: '',
                        form: ''
                    }
                    break
                case 'size':
                    if (attribute.dictionary) {
                        attributes.push({ Field: FieldString, attribute: attribute })
                        values[`${attribute.id}`] = ''
                    } else {
                        attributes.push({Field: FieldSize, attribute: attribute})
                        values[`${attribute.id}`] = {
                            value: '',
                            width: '',
                            height: '',
                            depth: {
                                value: '',
                                max: ''
                            },
                            unit: '',
                            delimiter: '',
                            symbol: ''
                        }
                    }
                    break
                case 'composite_size':
                    if (attribute.dictionary) {
                        attributes.push({ Field: FieldString, attribute: attribute })
                        values[`${attribute.id}`] = ''
                    } else {
                        attributes.push({Field: FieldCompositeSize, attribute: attribute})
                        values[`${attribute.id}`] = {
                            value: '',
                            prefix: '',
                            amount: '',
                            size: {
                                range: '',
                                charrier: ''
                            },
                            space: '',
                            diameter: {
                                outer: {
                                    value: '',
                                    unit: '',
                                    delimiter: ''
                                },
                                inner: {
                                    value: '',
                                    unit: '',
                                    delimiter: ''
                                }
                            },
                            lengths: [
                                {
                                    value: '',
                                    unit: '',
                                    delimiter: ''
                                }
                            ],
                            width: {
                                value: '',
                                unit: '',
                                delimiter: ''
                            },
                            metric: '',
                            height: '',
                            thickness: '',
                            unit: '',
                            gauge: '',
                            postfix: ''
                        }
                    }
                    break
                case 'volume':
                    if (attribute.dictionary) {
                        attributes.push({ Field: FieldString, attribute: attribute })
                        values[`${attribute.id}`] = ''
                    } else {
                        attributes.push({Field: FieldVolume, attribute: attribute})
                        values[`${attribute.id}`] = {
                            value: '',
                            volume: '',
                            weight: '',
                            dose: {
                                value: '',
                                max: ''
                            },
                            unit: '',
                            delimiter: '',
                            symbol: ''
                        }
                    }
                    break
                case 'composite_volume':
                    if (attribute.dictionary) {
                        attributes.push({ Field: FieldString, attribute: attribute })
                        values[`${attribute.id}`] = ''
                    } else {
                        attributes.push({Field: FieldCompositeVolume, attribute: attribute})
                        values[`${attribute.id}`] = {
                            value: '',
                            prefix: '',
                            values: [
                                {
                                    amount: '',
                                    delimiter: '',
                                    volume: '',
                                    weight: '',
                                    dose: '',
                                    unit: '',
                                    space: ''
                                }
                            ],
                            postfix: ''
                        }
                    }
                    break
                case 'dosage':
                    if (attribute.dictionary) {
                        attributes.push({ Field: FieldString, attribute: attribute })
                        values[`${attribute.id}`] = ''
                    } else {
                        attributes.push({Field: FieldDosage, attribute: attribute})
                        values[`${attribute.id}`] = {
                            value: '',
                            prefix: '',
                            values: [
                                {
                                    amount: '',
                                    unit: '',
                                    delimiter: ''
                                }
                            ],
                            volume: {
                                value: '',
                                unit: '',
                                delimiter: ''
                            },
                            description: '',
                            initial: ''
                        }
                    }
                    break
                case 'number_definitions':
                    if (attribute.dictionary) {
                        attributes.push({ Field: FieldString, attribute: attribute })
                        values[`${attribute.id}`] = ''
                    } else {
                        attributes.push({Field: FieldNumberDefinitions, attribute: attribute})
                        values[`${attribute.id}`] = {
                            value: '',
                            values: [
                                {
                                    amount: '',
                                    definitions: '',
                                    unit: '',
                                    delimiter: ''
                                }
                            ]
                        }
                    }
                    break
                case 'needle':
                    if (attribute.dictionary) {
                        attributes.push({ Field: FieldString, attribute: attribute })
                        values[`${attribute.id}`] = ''
                    } else {
                        attributes.push({Field: FieldNeedle, attribute: attribute})
                        values[`${attribute.id}`] = {
                            value: '',
                            information: '',
                            amount: '',
                            gauge: {
                                value: '',
                                additional: ''
                            },
                            diameter: {
                                value: '',
                                delimiter: ''
                            },
                            length: {
                                value: '',
                                unit: '',
                                delimiter: ''
                            },
                            bend: '',
                            description: ''
                        }
                    }
                    break
                default:
                    break
            }
        })

        return {
            attributes: attributes,
            values: values
        }
    }

    useEffect(() => {
        if (!initialize) {
            let attributesData = []
            let valuesData = {}

            if (!!category && !!Object.keys(category).length) {
                let parent = category

                while (parent) {
                    const {attributes, values} = getAttributeWithValue(parent, [], valuesData)

                    attributesData = attributes.concat(attributesData)
                    valuesData = values

                    parent = parent.category ? categories.find(cat => (cat.id === parent.category.id)) : null
                }

                if (item) {
                    item.values.forEach(value => {
                        switch(value.attribute.type.key) {
                            case 'integer_unit':
                            case 'double_unit':
                            case 'range_unit':
                            case 'number':
                                valuesData[`${value.attribute.id}`] = JSON.parse(value.value?.replace(new RegExp('"null"|null', 'g'), '""'))
                                break
                            case 'size':
                            case 'composite_size':
                            case 'dosage':
                            case 'number_definitions':
                            case 'volume':
                            case 'composite_volume':
                            case 'needle':
                                let data = JSON.parse(value.value?.replace(new RegExp('"null"|null', 'g'), '""'))

                                valuesData[`${value.attribute.id}`] = value.attribute.dictionary ? data.value : data
                                break
                            case 'multiselect':
                                valuesData[`${value.attribute.id}`] = JSON.parse(value.value)
                                break
                            case 'boolean':
                                valuesData[`${value.attribute.id}`] = !!value.value
                                break
                            case 'double':
                                valuesData[`${value.attribute.id}`] = value.value?.replace(',', '.')
                                break
                            case 'dictionary':
                                valuesData[`${value.attribute.id}`] = value.attribute.multiple ? JSON.parse(value.value) : value.value
                                break
                            case 'string':
                                valuesData[`${value.attribute.id}`] = value.attribute.multiple ? JSON.parse(value.value) : value.value
                                break
                            default:
                                valuesData[`${value.attribute.id}`] = value.value
                        }
                    })
                }
            }

            setAttributes(attributesData.sort((first, second) => {
                return (!first.attribute.index || (second.attribute.index && (first.attribute.index > second.attribute.index))) ? 1 : ((!second.attribute.index || first.attribute.index < second.attribute.index) ? -1 : 0)
            }))
            setValuesAttribute(valuesData)
            setInitialize(true)
        }

        if (copy) {
            setItem(null)
        }
    }, [initialize])

    const attribute = (item, items= [], value = [], values= [], errors= [], setFieldValue, setFieldTouched, setSearch = () => {}, isSubmitting= false, disabled = false) => {
        const { Field, attribute } = item

        return <Field
            id={ attribute.id }
            label={ attribute.name }
            addition={ attribute.addition }
            attribute={{
                ...attribute,
                ...(attribute.dictionary ? {
                    dictionary: {
                        ...attribute.dictionary,
                        attribute: attribute
                    }
                } : {}),
            }}
            attributes={ attributes.map(item => item.attribute) }
            items={ items }
            value={ value }
            values={ values }
            errors={ errors }
            setFieldValue={ setFieldValue }
            setFieldTouched={ setFieldTouched }
            setSearch={ setSearch }
            isSubmitting={ isSubmitting }
            disabled={disabled}
        />
    }

    const getItems = (attribute) => {
        switch (attribute.type.key) {
            case 'dictionary':
                if (attribute.value) {
                    return dictionaries.items
                }
                break
            case 'select':
                return attribute.options.map(option => { return { id: option.id, name: option.option } })
            case 'multiselect':
                return attribute.options.map(option => { return { id: option.id, name: option.option } })
            case 'integer_unit':
            case 'double_unit':
            case 'range_unit':
            case 'unit':
            case 'size':
            case 'composite_size':
            case 'volume':
            case 'composite_volume':
            case 'number_definitions':
            case 'dosage':
            case 'needle':
                return units
            default:
                return []
        }
    }

    const standard = (values) => {
        let name = ''
        attributes.filter(item => (item.attribute.index && item.attribute.standard)).sort((first, second) => {
            return (first.attribute.index > second.attribute.index) ? 1 : ((first.attribute.index < second.attribute.index) ? -1 : 0)
        }).map((item) => {
            const attribute = item.attribute

            let val = getValue(attribute, values[attribute.id], attribute.dictionary)

            switch (attribute.name) {
                case 'Дозировка':
                case 'Объем, вес и дозы':
                case 'Вес, объем':
                case 'Фасовка':
                case 'Количество':
                case 'Фасовка/Количество':
                case 'Концентрация':
                case 'Игла':
                case 'Плотность/жесткость':
                    val = stringFormat(String(val), false)
            }

            if (val) {
                name = `${name} ${val}`
            }
        })

        return name ?? null
    }

    const assembly = (options, parent = 0, level = 0) => {
        let result = []

        if (options.hasOwnProperty(parent)) {
            options[parent].sort((first, second) => {
                return (first.name > second.name) ? 1 : (second.name > first.name) ? -1 : 0
            }).forEach(category => {
                result.push(
                    <MenuItem key={ category.id } value={ category } style={{ paddingLeft: `${16 * (level + 1)}px`}}>
                        {options.hasOwnProperty(category.id) &&
                            <ListItemIcon className={ classes.listItemIcon }>
                                <IconButton
                                    size="small"
                                    onClick={e => {
                                        e.stopPropagation()
                                        if (options.hasOwnProperty(category.id)) {
                                            setOpenCategory({...openCategory, ...{[category.id]: openCategory.hasOwnProperty(category.id) ? !openCategory[category.id] : true}})
                                        }
                                    }}
                                >
                                    {(openCategory.hasOwnProperty(category.id) && openCategory[category.id]) ? <ExpandLess/> : <ExpandMore/>}
                                </IconButton>
                            </ListItemIcon>
                        }
                        <ListItemText className={ options.hasOwnProperty(category.id) ? classes.listItemTextWithIcon : classes.listItemText } primary={ !level ? <Typography variant="body1" className={!category.terminal ? classes.disabled : classes.default}>{category.name}</Typography> : <Typography variant="body2" className={!category.terminal ? classes.disabled : classes.default}>{category.name}</Typography> } />
                    </MenuItem>
                )

                const childes = assembly(options, category.id, level + 1)

                if (!!childes.length && openCategory.hasOwnProperty(category.id) && openCategory[category.id]) {
                    result = result.concat([
                        childes.map(row => row)
                    ])
                }
            })
        }

        return result
    }

    const getCategoriesTree = options => {
        let tmp = {}
        options.forEach(category => {
            if (!tmp.hasOwnProperty((category.category !== null) ? category.category.id : 0)) {
                tmp[(category.category !== null) ? category.category.id : 0] = []
            }

            tmp[(category.category !== null) ? category.category.id : 0].push(category)
        })

        return assembly(tmp)
    }

    switch (true) {
        case !!matching:
            return <MatchingForm matching={matching} id={item.id} open={!!open} handleClose={handleClose} />
        case !!changes:
            return <Changes id={item.id} open={!!open} handleClose={handleClose} />
        case !!initialize:
            return <Formik
                enableReinitialize={ true }
                validateOnChange={false}
                initialValues = {{...{
                        active: item ? item.active : !offer,
                        category: (!!category && !!Object.keys(category).length) ? category : ''
                    }, ...{ attributes: valuesAttribute }, ...{reject: {type: '', description: '', standard: null}}}}
                validate = {values => {
                    const errors = {}

                    if (!values.category) {
                        errors.category = 'Выберите категорию'
                    } else if (!values.category.terminal) {
                        errors.category = 'Выберите терминальную категорию'
                    }

                    errors.attributes = {}

                    if (!!category && !!Object.keys(category).length) {
                        attributes.map(item => item.attribute).forEach(attribute => {
                            if ((attribute.type.key !== 'boolean') && !values.attributes[`${attribute.id}`] && !!attribute.required) {
                                errors.attributes[`${attribute.id}`] = `Введите ${attribute.name.toLowerCase()}`
                            }

                            if (attribute.type.key === 'multiselect') {
                                if (!!attribute.required && !values.attributes[`${attribute.id}`].length) {
                                    errors.attributes[`${attribute.id}`] = `Выберите ${attribute.name.toLowerCase()}`
                                }
                            }

                            if (attribute.type.key === 'select') {
                                if (!!attribute.required && !values.attributes.hasOwnProperty(`${attribute.id}`)) {
                                    errors.attributes[`${attribute.id}`] = `Выберите ${attribute.name.toLowerCase()}`
                                }
                            }

                            if (attribute.type.key === 'range_unit') {
                                if (!!attribute.required && values.attributes.hasOwnProperty(`${attribute.id}`)) {
                                    if (!values.attributes[attribute.id].width) {
                                        if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                            errors.attributes[`${attribute.id}`] = {}
                                        }

                                        errors.attributes[`${attribute.id}`].value = `Введите значение`
                                    }

                                    if (!values.attributes[attribute.id].unit || !values.attributes[attribute.id].unit.length) {
                                        if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                            errors.attributes[`${attribute.id}`] = {}
                                        }

                                        errors.attributes[`${attribute.id}`].unit = `Укажите ед. изм.`
                                    }
                                }
                            }

                            if (attribute.type.key === 'size') {
                                if (!!attribute.required && values.attributes.hasOwnProperty(`${attribute.id}`)) {
                                    if (!values.attributes[attribute.id].width) {
                                        if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                            errors.attributes[`${attribute.id}`] = {}
                                        }

                                        errors.attributes[`${attribute.id}`]['width'] = `Введите ширину`
                                    }

                                    if (!values.attributes[attribute.id].height) {
                                        if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                            errors.attributes[`${attribute.id}`] = {}
                                        }

                                        errors.attributes[`${attribute.id}`]['height'] = `Введите высоту`
                                    }

                                    if (!values.attributes[attribute.id].depth.value) {
                                        if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                            errors.attributes[`${attribute.id}`] = {}
                                        }

                                        errors.attributes[`${attribute.id}`]['depth'] = {value: `Введите глубину`}
                                    }

                                    if (!values.attributes[attribute.id].unit || !values.attributes[attribute.id].unit.length) {
                                        if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                            errors.attributes[`${attribute.id}`] = {}
                                        }

                                        errors.attributes[`${attribute.id}`]['unit'] = `Укажите ед. изм.`
                                    }
                                }
                            }

                            if (attribute.type.key === 'volume') {
                                if (!!attribute.required && values.attributes.hasOwnProperty(`${attribute.id}`)) {
                                    if (!values.attributes[attribute.id].volume) {
                                        if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                            errors.attributes[`${attribute.id}`] = {}
                                        }

                                        errors.attributes[`${attribute.id}`]['volume'] = `Введите объём`
                                    }

                                    if (!values.attributes[attribute.id].weight) {
                                        if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                            errors.attributes[`${attribute.id}`] = {}
                                        }

                                        errors.attributes[`${attribute.id}`]['weight'] = `Введите вес`
                                    }

                                    if (!values.attributes[attribute.id].dose.value) {
                                        if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                            errors.attributes[`${attribute.id}`] = {}
                                        }

                                        errors.attributes[`${attribute.id}`]['dose'] = {value: `Введите дозу`}
                                    }

                                    if (!values.attributes[attribute.id].unit || !values.attributes[attribute.id].unit.length) {
                                        if (!errors.attributes.hasOwnProperty(`${attribute.id}`)) {
                                            errors.attributes[`${attribute.id}`] = {}
                                        }

                                        errors.attributes[`${attribute.id}`]['unit'] = `Укажите ед. изм.`
                                    }
                                }
                            }
                        })
                    }

                    if (!Object.keys(errors.attributes).length) {
                        delete errors.attributes
                    }

                    if (reject) {
                        if (!values.reject.type.length) {
                            if (!errors.hasOwnProperty('reject')) {
                                errors.reject = {}
                            }

                            errors.reject.type = 'Выберите причину.'
                        } else {
                            switch (values.reject.type) {
                                case 'duplicate':
                                    if (!values.reject.standard) {
                                        if (!errors.hasOwnProperty('reject')) {
                                            errors.reject = {}
                                        }

                                        errors.reject.standard = 'Выберите эталон.'
                                    }
                                    break
                                case 'other':
                                    if (!values.reject.description.length) {
                                        if (!errors.hasOwnProperty('reject')) {
                                            errors.reject = {}
                                        }

                                        errors.reject.description = 'Укажите причину.'
                                    }
                                    break
                            }
                        }

                        delete errors.category
                    }

                    return errors
                }}

                onSubmit = {(values, { setSubmitting, setErrors }) => {
                    setSubmitting(true)

                    handleSave(values, item ? item.id : null).then(
                        () => {
                            setSubmitting(false)
                            handleClose()
                        },
                        (error) => {
                            if (error.hasOwnProperty('errors')) {
                                setErrors({
                                    attributes: error.errors
                                })
                            }
                            setSubmitting(false)
                        }
                    )
                }}
            >
                {({
                      values,
                      errors,
                      handleSubmit,
                      setFieldValue,
                      setFieldTouched,
                      setSubmitting,
                      setValues,
                      setTouched,
                      isSubmitting,
                      validateForm
                  }) => (
                    <Form>
                        <Dialog
                            fullWidth={ true }
                            maxWidth={(!item || item.confirmed || (!item.confirmed && offer) || reject || agreement) ? 'sm' : 'lg'}
                            open={!!open}
                            onClose={ handleClose }
                            aria-labelledby="Эталон"
                            classes={{
                                paper: classes.dialog
                            }}
                        >
                            {isSubmitting && <LinearProgress />}
                            <DialogTitle style={isSubmitting ? {paddingTop: '12px'} : {}}>
                                <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                    <Grid item sm={(!item || item.confirmed || reject || agreement) ? 11 : 6}>
                                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                            <Grid item xs={6}>
                                                { item ? (reject ? 'Отклонить' : (agreement ? 'Удалить' : item.confirmed ? 'Редактировать' : (offer ? 'Предложить' : 'Подтвердить'))) : (offer ? 'Предложить эталон' : 'Добавить') }
                                            </Grid>
                                            {(!reject && !offer) && <Grid item>
                                                <FormControlLabel
                                                    disabled={isSubmitting || (item && (!item.confirmed || !!item.item))}
                                                    control={
                                                        <Field
                                                            name="active"
                                                            label="Активный"
                                                            component={ Switch }
                                                        />
                                                    }
                                                    label="Активный"
                                                />
                                            </Grid>}
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={(!item || item.confirmed || reject || agreement) ? 1 : 6}>
                                        <Grid container direction='row' justify='flex-end' alignItems='center' spacing={2}>
                                            <Grid item>
                                                <IconButton
                                                    aria-label="Закрыть"
                                                    onClick={() => handleClose()}
                                                >
                                                    <Close />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                {reject ?
                                    <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                        <Grid item sm={10} className={classes.fullWidth}>
                                            Вы действительно хотите отклонить эталон?
                                        </Grid>
                                        <Grid item xs={10} className={classes.fullWidth}>
                                            <Typography variant="caption" style={{ color: "rgba(0, 0, 0, 0.54)" }}>Эталон</Typography>
                                            <Typography>{(item && (item.category.index !== values.category.index)) ? item.name : standard(values.attributes)}</Typography>
                                        </Grid>
                                        <Grid item sm={10} className={classes.fullWidth}>
                                            <Field
                                                fullWidth
                                                type="text"
                                                name="reject.type"
                                                disabled={ isSubmitting }
                                                label="Причина"
                                                select
                                                required={ true }
                                                variant="standard"
                                                component={ TextField }
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                            >
                                                {
                                                    [
                                                        {
                                                            name: 'Запись уже есть в номенклаторе',
                                                            key: 'duplicate'
                                                        },
                                                        {
                                                            name: 'Некорректное торговое наименование',
                                                            key: 'incorrect'
                                                        },
                                                        {
                                                            name: 'Другое',
                                                            key: 'other'
                                                        }
                                                    ].map(type => {
                                                        return <MenuItem key={type.key} value={ type.key}>
                                                            {type.name}
                                                        </MenuItem>
                                                    })
                                                }
                                            </Field>
                                        </Grid>
                                        {(values.reject.type === 'duplicate') &&
                                            <Grid item sm={10} className={classes.fullWidth}>
                                                <Autocomplete
                                                    filterOptions={(options, state) => options}
                                                    options={standards}
                                                    required={true}
                                                    disabled={ isSubmitting }
                                                    getOptionLabel={option => option ? option.name : null}
                                                    defaultValue={null}
                                                    noOptionsText='Эталон не найден'
                                                    onChange={(e, value) => {
                                                        setFieldValue('reject.standard', (value ? value : null))
                                                        setStandards([])
                                                    }}
                                                    onBlur={() => {
                                                        setTouched({'reject.standard': true})
                                                    }}
                                                    renderInput={params => (
                                                        <Field
                                                            component={TextField}
                                                            name={'reject.standard'}
                                                            label='Эталон'
                                                            {...params}
                                                            required={true}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                onChange: (e) => {
                                                                    dispatch(ItemActions.search({search: e.target.value, type: 'standard', status: 'active'})).then(response => setStandards(response.data))
                                                                }
                                                            }}
                                                            multiline
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        }
                                        <Grid item sm={10} className={classes.fullWidth}>
                                            <Field
                                                component={ TextField }
                                                disabled={ isSubmitting }
                                                required={ (values.reject.type === 'other') }
                                                name={`reject.description`}
                                                label="Комментарий"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid> :
                                    <Grid container direction='row' justify='space-between' alignItems='stretch' spacing={2}>
                                        <Grid item sm={(!item || item.confirmed || (!item.confirmed && offer) || reject || agreement) ? 12 : 6}>
                                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                                <Grid item ref={el => setStandardRef(el)} xs={10} className={classes.fullWidth}>
                                                    <Typography variant="caption" style={{ color: "rgba(0, 0, 0, 0.54)" }}>Эталон</Typography>
                                                    <Typography>{(item && (item.category.index !== values.category.index)) ? item.name : standard(values.attributes)}</Typography>
                                                </Grid>
                                                <Grid item xs={10} style={{ maxHeight: `${685 - ((standardRef && standardRef.clientHeight) ? standardRef.clientHeight : 0)}px`, width: '100%', overflow: 'auto' }}>
                                                    <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                                        <Grid item xs={12} className={classes.fullWidth}>
                                                            <Field
                                                                fullWidth
                                                                type="text"
                                                                name="category"
                                                                label="Категория"
                                                                select
                                                                variant="standard"
                                                                disabled={ isSubmitting || (item && (item.confirmed && !item.active)) || agreement }
                                                                component={ TextField }
                                                                InputLabelProps={{
                                                                    shrink: true
                                                                }}
                                                                InputProps={{
                                                                    onChange: (e) => {
                                                                        let current = e.target.value
                                                                        let attrbts = []
                                                                        let items = {}

                                                                        let parent = current

                                                                        while (parent) {
                                                                            const data = getAttributeWithValue(parent, [], items)

                                                                            attrbts = data.attributes.concat(attrbts)
                                                                            items = data.values

                                                                            parent = parent.category ? categories.find(cat => (cat.id === parent.category.id)) : null
                                                                        }

                                                                        Object.keys(values.attributes).forEach(id => {
                                                                            if (items.hasOwnProperty(`${parseInt(id)}`)) {
                                                                                items[`${parseInt(id)}`] = values.attributes[parseInt(id)]
                                                                            }
                                                                        })

                                                                        setAttributes(attrbts.sort((first, second) => {
                                                                            return (!first.attribute.index || (second.attribute.index && (first.attribute.index > second.attribute.index))) ? 1 : ((!second.attribute.index || first.attribute.index < second.attribute.index) ? -1 : 0)
                                                                        }))
                                                                        setValues({
                                                                            ...values,
                                                                            ...{
                                                                                category: current,
                                                                                attributes: items
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                inputProps={{
                                                                    renderValue: (value) => value.name
                                                                }}
                                                            >
                                                                {
                                                                    getCategoriesTree(categories)
                                                                }
                                                            </Field>
                                                        </Grid>
                                                        {
                                                            attributes.map((attr, index) => (
                                                                <Grid item xs={12} key={index} className={classes.fullWidth}>
                                                                    {attribute(
                                                                        attr,
                                                                        getItems(attr.attribute),
                                                                        values.attributes[attr.attribute.id],
                                                                        values.attributes,
                                                                        errors,
                                                                        setFieldValue,
                                                                        setFieldTouched,
                                                                        setSearch,
                                                                        isSubmitting,
                                                                        (!!item ? ((!item.active && item.confirmed) || agreement) : false)
                                                                    )}
                                                                </Grid>
                                                            ))
                                                        }
                                                        {(item && !!item.images.length && !item.confirmed && !offer) &&
                                                            <Grid item xs={12} className={classes.fullWidth}>
                                                                <Images images={item.images} />
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {(item && !item.confirmed && !offer) &&
                                            <Grid item sm={6}>
                                                <Offers item={item} />
                                            </Grid>
                                        }
                                    </Grid>
                                }
                            </DialogContent>
                            <DialogActions>
                                <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                    <Grid item>
                                        {(!!item && (!agreement && !reject) && !offer) &&
                                            <DialogActions>
                                                <Button
                                                    disabled={ isSubmitting }
                                                    onClick={() => {
                                                        return dispatch(ItemActions.matching(item.id)).then((val) => {
                                                            setMatching(val)
                                                        })
                                                    }}
                                                    color="primary"
                                                    type="button"
                                                >
                                                    Вариации
                                                </Button>
                                                <Button
                                                    disabled={ isSubmitting }
                                                    onClick={() => {
                                                        setChanges(true)
                                                    }}
                                                    color="primary"
                                                    type="button"
                                                >
                                                    История
                                                </Button>
                                            </DialogActions>
                                        }
                                    </Grid>
                                    <Grid item>
                                        {
                                            item ? (
                                                (agreement || reject) ?
                                                    <DialogActions>
                                                        <Button
                                                            disabled={ isSubmitting }
                                                            onClick={() => {
                                                                if (reject) {
                                                                    setValues({
                                                                        ...values,
                                                                        ...{
                                                                            reject: {type: '', description: '', standard: null}
                                                                        }
                                                                    })
                                                                    setTouched({
                                                                        reject: {
                                                                            type: false,
                                                                            description: false,
                                                                            standard: false
                                                                        }
                                                                    })
                                                                }
                                                                setReject(false)
                                                                setAgreement(false)
                                                            }}
                                                            color="secondary"
                                                            type="submit"
                                                        >
                                                            Отмена
                                                        </Button>
                                                        <Button
                                                            disabled={ isSubmitting }
                                                            onClick={() => {
                                                                setAgreement(true)
                                                                setSubmitting(true)

                                                                validateForm().then(errs => {
                                                                    if (!!Object.keys(errs).length && errs.hasOwnProperty('reject')) {
                                                                        setTouched({
                                                                            ...{reject: {
                                                                                    type: errs.reject.hasOwnProperty('type'),
                                                                                    description: errs.reject.hasOwnProperty('description'),
                                                                                    standard: errs.reject.hasOwnProperty('standard')
                                                                                }}
                                                                        })
                                                                        setSubmitting(false)
                                                                    } else {
                                                                        handleDelete(item, (reject ? {reject: values.reject} : {})).then(
                                                                            () => {
                                                                                setSubmitting(false)
                                                                                handleClose()
                                                                            },
                                                                            () => {
                                                                                if (reject) {
                                                                                    setSubmitting(false)
                                                                                    setAgreement(false)
                                                                                }
                                                                            }
                                                                        )
                                                                    }
                                                                })
                                                            }}
                                                            color="primary"
                                                            type="submit"
                                                        >
                                                            Подтвердить
                                                        </Button>
                                                    </DialogActions>
                                                    :
                                                    <DialogActions>
                                                        {(item.confirmed || offer) ?
                                                            <Button
                                                                disabled={ isSubmitting || (item.active || !item.confirmed || !!item.item) }
                                                                onClick={() => {
                                                                    setAgreement(true)
                                                                }}
                                                                color="secondary"
                                                                type="submit"
                                                            >
                                                                Удалить
                                                            </Button> :
                                                            <Button
                                                                disabled={isSubmitting || item.active}
                                                                onClick={() => {
                                                                    setReject(true)
                                                                }}
                                                                color="secondary"
                                                                type="submit"
                                                            >
                                                                Отклонить
                                                            </Button>
                                                        }
                                                        <Button
                                                            disabled={ isSubmitting }
                                                            onClick={ handleSubmit }
                                                            color="primary"
                                                            type="submit"
                                                        >
                                                            {offer ? 'Предложить' : 'Сохранить'}
                                                        </Button>
                                                        {item.confirmed ?
                                                            <Button
                                                                disabled={ isSubmitting }
                                                                onClick={() => {
                                                                    setItem(null)
                                                                }}
                                                                color="primary"
                                                                type="button"
                                                            >
                                                                Копировать
                                                            </Button> :
                                                            (!offer ? <Button
                                                                disabled={ isSubmitting }
                                                                onClick={async () => {
                                                                    await setFieldValue('confirmed', true)
                                                                    handleSubmit()
                                                                }}
                                                                color="default"
                                                                type="submit"
                                                            >
                                                                Подтвердить
                                                            </Button> : null)
                                                        }
                                                    </DialogActions>
                                            ) : (
                                                <DialogActions>
                                                    <Button
                                                        disabled={ isSubmitting }
                                                        onClick={ handleSubmit }
                                                        color="primary"
                                                        type="submit"
                                                    >
                                                        {offer ? 'Предложить' : 'Добавить'}
                                                    </Button>
                                                </DialogActions>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )}
            </Formik>
        default:
            return null;
    }
}
