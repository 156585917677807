import React, {useState} from 'react'
import {useDispatch} from "react-redux"

import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    makeStyles, Typography
} from '@material-ui/core'
import {Close} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    }
}))

export const DeleteForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [isSubmitting, setIsSubmitting] = useState(false)

    const {handleDelete, handleClose, open, label, text} = props

    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={open}
            onClose={handleClose}
            aria-labelledby={label}
            classes={{
                paper: classes.dialog
            }}
        >
            <DialogTitle>
                <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                    <Grid item>
                        {label}
                    </Grid>
                    <Grid item>
                        <IconButton
                            aria-label="Закрыть"
                            onClick={() => handleClose()}
                        >
                            <Close/>
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                    <Grid item sm={10} className={classes.fullWidth}>
                        <Typography>{text}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isSubmitting}
                    onClick={() => {
                        setIsSubmitting(true)
                        handleDelete().then(
                            () => {
                                setIsSubmitting(true)
                                dispatch(handleClose)
                            },
                            () => {
                                setIsSubmitting(true)
                            }
                        )
                    }}
                    color="primary"
                    type="submit"
                >
                    {isSubmitting ? <CircularProgress size={24}/> : 'Подтвердить'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
