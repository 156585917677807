export default function file (state = { files: { data: [], meta: {}, limit: 100, page: 1 }, file: null }, action) {
    switch (action.type) {
        case 'MARKUP_FILES_REQUEST':
            return state
        case 'MARKUP_FILES_SUCCESS':
            return {
                ...state,
                ...{files: action.payload}
            }
        case 'MARKUP_FILES_FAILURE':
            return {
                ...state,
                ...{files: { data: [], meta: {}, limit: state.files.limit, page: state.files.page }}
            }
        case 'MARKUP_FILES_CLEAR':
            return {
                ...state,
                ...{files: { data: [], meta: {}, limit: 10, page: 1 }}
            }
        case 'MARKUP_UNLOADING_FILE_REQUEST':
            return state
        case 'MARKUP_UNLOADING_FILE_SUCCESS':
            return state
        case 'MARKUP_UNLOADING_FILE_FAILURE':
            return state
        case 'MARKUP_DELETE_FILE_REQUEST':
            return state
        case 'MARKUP_DELETE_FILE_SUCCESS':
            return {
                ...state,
                ...{files: { data: state.files.data.filter(file => file.id !== action.payload), meta: state.files.meta }}
            }
        case 'MARKUP_DELETE_FILE_FAILURE':
            return state
        case 'MARKUP_FILE_REQUEST':
            return state
        case 'MARKUP_FILE_SUCCESS':
            return {
                ...state,
                ...{file: action.payload}
            }
        case 'MARKUP_FILE_FAILURE':
            return state
        case 'MARKUP_FILE_CLEAR':
            return {
                ...state,
                ...{file: null}
            }
        default:
            return state
    }
}
