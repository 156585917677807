import React, {useState} from 'react'
import {useDispatch} from "react-redux"

import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    makeStyles, FormControlLabel
} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {Field, Form, Formik} from "formik"
import {Switch, TextField} from "formik-material-ui"
import * as Yup from "yup"

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    }
}))

export const UnitForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [unit] = useState(props.unit)

    const {handleSave, handleClose, open} = props

    return (
        <Formik
            initialValues = {unit ?? {name: '', active: true}}
            validationSchema={Yup.object().shape({
                name: Yup.mixed().required("Поле не может быть пустым или состоять из одних пробелов!"),
                active: Yup.bool()
            })}
            onSubmit = {(values, { setSubmitting }) => {
                handleSave(unit.id, values).then(
                    () => {
                        setSubmitting(false)
                        dispatch(handleClose)
                    },
                    () => {
                        setSubmitting(false)
                    }
                )
            }}
        >
            {({
                  values,
                  errors,
                  handleSubmit,
                  isSubmitting,
                  setSubmitting,
                  setValues,
                  setTouched,
                  setFieldValue
              }) => (
                <Form>
                    <Dialog
                        fullWidth={true}
                        maxWidth="md"
                        open={open}
                        onClose={handleClose}
                        classes={{
                            paper: classes.dialog
                        }}
                    >
                        <DialogTitle>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item>
                                    {unit ? 'Редактировать' : 'Добавить'}
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label="Закрыть"
                                        onClick={() => handleClose()}
                                    >
                                        <Close/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                        <Grid item className={classes.fullWidth}>
                                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                                <Grid item sm={10} className={classes.fullWidth}>
                                                    <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                                        <Grid item className={classes.fullWidth}>
                                                            <Grid container direction='row' justify='space-between' alignItems='flex-end' spacing={2}>
                                                                <Grid item sm={6} className={classes.fullWidth}>
                                                                    <Field
                                                                        fullWidth
                                                                        name={`name`}
                                                                        type="text"
                                                                        label="Наименование"
                                                                        required
                                                                        component={ TextField }
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={3} className={classes.fullWidth}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Field
                                                                                name={`active`}
                                                                                label="Активный"
                                                                                component={ Switch }
                                                                            />
                                                                        }
                                                                        label="Активный"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={ isSubmitting }
                                onClick={ handleSubmit }
                                color="primary"
                                type="submit"
                            >
                                {isSubmitting ? <CircularProgress size={24}/> : (unit ? 'Сохранить' : 'Добавить')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    )
}
