import React from 'react'
import {useDispatch} from "react-redux"
import { Formik, Field, Form } from 'formik'
import { TextField } from 'formik-material-ui'

import {
    Grid,
    Paper,
    Button,
    CircularProgress,
    makeStyles
} from '@material-ui/core'

import { AuthActions } from './actions/authentication'

const useStyles = makeStyles(theme => ({
    container: {
        'border-radius': 0,
        'height': '100%'
    },
    content: {
        'border-radius': 0,
        'padding': theme.spacing(3, 2),
    },
    fullWidth: {
        'width': '100%'
    }
}))

export const Login = () => {
    const dispatch = useDispatch()
    const classes = useStyles()

    return (
        <Grid container direction='row' justify='center' alignItems='center' className={classes.container}>
            <Grid item xs={9} sm={6} md={3}>
                <Paper className={classes.content}>
                    <Formik
                        initialValues = {{ login: '', password: '' }}
                        validate = {values => {
                            const errors = {}

                            if (!values.login) {
                                errors.login = 'Введите имя пользователя'
                            }

                            if (!values.password) {
                                errors.password = 'Ввведите пароль'
                            }

                          return errors
                        }}
                        onSubmit = {(values, { setSubmitting }) => {
                            return dispatch(AuthActions.login(values)).then(
                                () => {
                                    setSubmitting(false)
                                },
                                errors => {
                                    if (errors) { }
                                    setSubmitting(false)
                                }
                            )
                        }}
                    >
                        {({
                            handleSubmit,
                            isSubmitting
                        }) => (
                          <Form>
                              <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                  <Grid item xs={10} className={classes.fullWidth}>
                                      <Grid container direction='column' justify='center' alignItems='stretch' spacing={2}>
                                          <Grid item className={classes.fullWidth}>
                                              <Field
                                                  fullWidth
                                                  name="login"
                                                  type="text"
                                                  label="Имя пользователя"
                                                  component={ TextField }
                                              />
                                          </Grid>
                                          <Grid item className={classes.fullWidth}>
                                              <Field
                                                  fullWidth
                                                  type="password"
                                                  name="password"
                                                  label="Пароль"
                                                  component={ TextField }
                                              />
                                          </Grid>
                                          <Grid item className={classes.fullWidth}>
                                              <Grid container direction='row' justify='flex-end' alignItems='center'>
                                                  <Grid item>
                                                      <Button
                                                          variant="contained"
                                                          color="primary"
                                                          disabled={ isSubmitting }
                                                          onClick={ handleSubmit }
                                                          type="submit"
                                                      >
                                                          { isSubmitting ? <CircularProgress size={24} /> : 'Войти' }
                                                      </Button>
                                                  </Grid>
                                              </Grid>
                                          </Grid>
                                      </Grid>
                                  </Grid>
                              </Grid>
                          </Form>
                        )}
                    </Formik>
                </Paper>
            </Grid>
        </Grid>
    )
}
