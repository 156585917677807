import React, {useState} from "react"
import { Field } from "formik"
import { TextField } from "formik-material-ui"
import {Grid, makeStyles} from "@material-ui/core"
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    }
}))

export const FieldRangeUnit = (props) => {
    const classes = useStyles()

    const { id, label, items, setFieldValue, isSubmitting, disabled } = props
    const [name] = useState(props.hasOwnProperty('name') ? props.name : `attributes.${id}`)
    const [value, setValue] = useState(props.value)

    return (
        <Grid container direction='row' justify='space-between' alignItems='flex-start' spacing={2}>
            <Grid item sm={4} className={classes.fullWidth}>
                <Field
                    fullWidth
                    name={ `${name}.value` }
                    disabled={ isSubmitting || disabled }
                    type="number"
                    label={ `${label}` }
                    inputProps={{
                        step: 0.0001,
                        onWheel: e => {
                            e.target.blur()
                            e.stopPropagation()
                            setTimeout(() => {
                                e.target.focus()
                            }, 0)
                        }
                    }}
                    component={ TextField }
                />
            </Grid>
            <Grid item sm={4} className={classes.fullWidth}>
                <Field
                    fullWidth
                    name={ `${name}.max` }
                    disabled={ isSubmitting || disabled || !props.value}
                    type="number"
                    label={ `${label}` }
                    helperText="Предельная"
                    inputProps={{
                        step: 0.0001,
                        onWheel: e => {
                            e.target.blur()
                            e.stopPropagation()
                            setTimeout(() => {
                                e.target.focus()
                            }, 0)
                        }
                    }}
                    component={ TextField }
                />
            </Grid>
            <Grid item sm={4} className={classes.fullWidth}>
                <Autocomplete
                    filterOptions={createFilterOptions({
                        matchFrom: 'start',
                        stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
                    })}
                    options={ items }
                    name={`${name}.unit`}
                    disabled={ isSubmitting || disabled }
                    getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
                    onChange={(e, value) => {
                        setFieldValue(`${name}.unit`, ((value instanceof Object) ? value.short : value))
                    }}
                    defaultValue={value.unit}
                    renderInput={params => (
                        <Field
                            fullWidth
                            {...params}
                            name={`${name}.unit`}
                            InputProps={{
                                ...params.InputProps,
                                onChange: (e) => {
                                    setValue({
                                        ...value,
                                        ...{
                                            unit: e.target.value
                                        }
                                    })
                                }
                            }}
                            type="text"
                            label="Ед. измерения"
                            component={ TextField }
                        />
                    )}
                />
            </Grid>
        </Grid>
    )
}
