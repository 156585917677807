import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import {
    AppBar,
    Container,
    Toolbar,
    Grid,
    Collapse,
    Paper,
    List,
    ListItem,
    ListItemText,
    Typography,
    Button,
    LinearProgress,
    IconButton,
    Menu,
    makeStyles, withStyles, Badge
} from '@material-ui/core'
import {useDispatch, useSelector} from "react-redux"
import {ExpandLess, ExpandMore, Notifications} from '@material-ui/icons'
import { Menu as MenuIcon } from '@material-ui/icons'

import { AuthorizationService } from '../Auth/services/authorization'
import {ItemActions} from "../Item/actions/item";
import {MessageActions} from "./actions/message";
import {NotificationActions} from "../Notification/actions/notification";

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    body: {
        'height': '100%'
    },
    item: {
        'height': '100%',
        'padding': theme.spacing(1, 1)
    },
    container: {
        'height': 'calc(100% - 64px)',
        'margin-top': '64px',
        [theme.breakpoints.down('xl')]: {
            'max-width': '1850px',
        },
    },
    content: {
        'height': '100%'
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    title: {
        'flex-grow': 1
    },
    paper: {
        'height': 'calc(100% - 112px)',
        'border-radius': 0,
        'padding': theme.spacing(3, 2),
        'margin': theme.spacing(3, 2)
    }
}))

const StyledBadge = withStyles((theme) => ({
    badge: {
        'transform': 'none',
        'top': 4
    },
}))(Badge)

export const App = ({Content, ...props}) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { location } = props

    const [open, setOpen] = useState({access: (location.pathname.indexOf('access') >= 0), dictionary: ((location.pathname.indexOf('dictionary') >= 0) || (location.pathname === '/dictionaries')), items: (location.pathname.indexOf('items') >= 0)})
    const [anchor, setAnchor] = useState(null)
    const {application, offers} = useSelector(state => { return {...state, ...{offers: state.item.offers}} })
    const {account} = useSelector(state => state.account)
    const {count} = useSelector(state => state.notification)
    const [initialize, setInitialize] = useState(false)
    const [reload, setReload] = useState(false)
    const access = AuthorizationService.permissions(account, ['role', 'user'])

    useEffect(() => {
        if (!initialize || reload) {
            dispatch(NotificationActions.count()).then(() => {
                setInitialize(true)
                setReload(false)
            })
        }
    }, [reload])

    useEffect(() => {
        let interval = setInterval(() => setReload(true), 60000)

        return () => clearInterval(interval)
    })

    useEffect(() => {
        setOpen({access: (location.pathname.indexOf('access') >= 0), dictionary: ((location.pathname.indexOf('dictionary') >= 0) || (location.pathname === '/dictionaries')), items: (location.pathname.indexOf('items') >= 0)})
    }, [location.pathname])

    const title = (name) => {
        switch (location.pathname) {
            case '/items':
                name = <Typography variant="h5" className={classes.title}>
                    {name} / <Typography component={'span'} variant="subtitle1" className={classes.title}>Эталоны</Typography>
                </Typography>
                break
            case '/items/union':
                name = <Typography variant="h5" className={classes.title}>
                    {name} / <Typography component={'span'} variant="subtitle1" className={classes.title}>Объединённые эталоны</Typography>
                </Typography>
                break
            case '/markup':
            case '/markup/file':
                name = <Typography variant="h5" className={classes.title}>
                    {name} / <Typography component={'span'} variant="subtitle1" className={classes.title}>Разметка данных</Typography>
                </Typography>
                break
            case '/categories':
                name = <Typography variant="h5" className={classes.title}>
                    {name} / <Typography component={'span'} variant="subtitle1" className={classes.title}>Категории</Typography>
                </Typography>
                break
            case '/dictionary':
                name = <Typography variant="h5" className={classes.title}>
                    {name} / <Typography component={'span'} variant="subtitle1" className={classes.title}>Справочники</Typography>
                </Typography>
                break
            case '/dictionary/synonym':
                name = <Typography variant="h5" className={classes.title}>
                    {name} / <Typography component={'span'} variant="subtitle1" className={classes.title}>Справочники</Typography> / <Typography component={'span'} variant="subtitle1" className={classes.title}>Справочники для сопоставления</Typography>
                </Typography>
                break
            case '/dictionaries':
                name = <Typography variant="h5" className={classes.title}>
                    {name} / <Typography component={'span'} variant="subtitle1" className={classes.title}>Справочники</Typography> / <Typography component={'span'} variant="subtitle1" className={classes.title}>Справочники атрибутов</Typography>
                </Typography>
                break
            case '/downloads':
                name = <Typography variant="h5" className={classes.title}>
                    {name} / <Typography component={'span'} variant="subtitle1" className={classes.title}>Загрузки</Typography>
                </Typography>
                break
            case '/access/users':
            case '/access/roles':
                name = <Typography variant="h5" className={classes.title}>
                    {name} / <Typography component={'span'} variant="subtitle1" className={classes.title}>Управление ролями</Typography>
                </Typography>
                break
            default:
                if (location.pathname.indexOf('dictionary') >= 0) {
                    if (location.pathname.indexOf('dictionary/synonym') >= 0) {
                        name = <Typography variant="h5" className={classes.title}>
                            {name} / <Typography component={'span'} variant="subtitle1" className={classes.title}>Справочники</Typography> / <Typography component={'span'} variant="subtitle1" className={classes.title}>Справочники для сопоставления</Typography>
                            </Typography>
                    } else {
                        name = <Typography variant="h5" className={classes.title}>
                            {name} / <Typography component={'span'} variant="subtitle1" className={classes.title}>Справочники</Typography>
                        </Typography>
                    }
                } else {
                    name = <Typography variant="h5" className={classes.title}>{name}</Typography>
                }
        }

        return name
    }

    return (
        <Grid container direction='column' justify='flex-start' alignItems='stretch' className={classes.content}>
            <AppBar>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={(event) => {
                            setAnchor(event.currentTarget)
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchor}
                        keepMounted
                        open={Boolean(anchor)}
                        onClose={() => {
                            setAnchor(null)
                            setOpen({access: false, dictionary: false})
                        }}
                    >
                        {AuthorizationService.permissions(account, 'standard_view') &&
                            <ListItem
                                button
                                onClick={() => { setOpen({...open, ...{items: !open.items}}) }}
                            >
                                <ListItemText primary={'Эталоны'}/>
                                {open.items ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                        }
                        {AuthorizationService.permissions(account, 'standard_view') &&
                            <Collapse in={ open.items } timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem
                                        selected={(location.pathname === '/items')}
                                        button
                                        component={Link}
                                        to={'/items'}
                                        className={classes.nested}
                                        onClick={() => {
                                            setAnchor(null)
                                        }}
                                    >
                                        <ListItemText primary={'Эталоны'}/>
                                    </ListItem>
                                    <ListItem
                                        selected={(location.pathname === '/items/union')}
                                        button
                                        component={Link}
                                        to={'/items/union'}
                                        className={classes.nested}
                                        onClick={() => {
                                            setAnchor(null)
                                        }}
                                    >
                                        <ListItemText primary={'Объединённые эталоны'}/>
                                    </ListItem>
                                </List>
                            </Collapse>
                        }
                        {AuthorizationService.permissions(account, 'category') &&
                            <ListItem
                                selected={location.pathname === '/categories'}
                                button
                                component={Link}
                                to={'/categories'}
                                onClick={() => {
                                    setAnchor(null)
                                }}
                            >
                                <ListItemText primary={'Категории'}/>
                            </ListItem>
                        }
                        {AuthorizationService.permissions(account, 'dictionary') &&
                            <ListItem
                                button
                                onClick={() => { setOpen({...open, ...{dictionary: !open.dictionary}}) }}
                            >
                                <ListItemText primary={'Справочники'}/>
                                {open.dictionary ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                        }
                        {AuthorizationService.permissions(account, 'dictionary') &&
                            <Collapse in={ open.dictionary } timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem
                                        selected={(location.pathname === '/dictionaries') || (location.pathname.indexOf('dictionaries') >= 0)}
                                        button
                                        component={Link}
                                        to={'/dictionaries'}
                                        className={classes.nested}
                                        onClick={() => {
                                            setAnchor(null)
                                        }}
                                    >
                                        <ListItemText primary={'Справочники атрибутов'}/>
                                    </ListItem>
                                    <ListItem
                                        selected={(location.pathname === '/dictionary/synonym') || (location.pathname.indexOf('dictionary/synonym') >= 0)}
                                        button
                                        component={Link}
                                        to={'/dictionary/synonym/attributes'}
                                        className={classes.nested}
                                        onClick={() => {
                                            setAnchor(null)
                                        }}
                                    >
                                        <ListItemText primary={'Справочники для сопоставления'}/>
                                    </ListItem>
                                </List>
                            </Collapse>
                        }
                        <ListItem
                            selected={location.pathname === '/converter'}
                            button
                            component={Link}
                            to={'/converter'}
                            onClick={() => {
                                setAnchor(null)
                            }}
                        >
                            <ListItemText primary={'Конвертер'}/>
                        </ListItem>
                        {AuthorizationService.permissions(account, 'data_markup') &&
                            <ListItem
                                selected={location.pathname === '/markup'}
                                button
                                component={Link}
                                to={'/markup'}
                                onClick={() => {
                                    setAnchor(null)
                                }}
                            >
                                <ListItemText primary={'Разметка данных'}/>
                            </ListItem>
                        }
                        <ListItem
                            selected={location.pathname === '/downloads'}
                            button
                            component={Link}
                            to={'/downloads'}
                            onClick={() => {
                                setAnchor(null)
                            }}
                        >
                            <ListItemText primary={'Загрузки'}/>
                        </ListItem>
                        {access &&
                            <ListItem
                                button
                                onClick={() => { setOpen({...open, ...{access: !open.access}}) }}
                            >
                                <ListItemText primary={'Управление доступом'}/>
                                {open.access ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                        }
                        {access &&
                            <Collapse in={ open.access } timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    { AuthorizationService.permissions(account, 'user') &&
                                    <ListItem
                                        selected={location.pathname === '/access/users'}
                                        button
                                        component={Link}
                                        to={'/access/users'}
                                        className={classes.nested}
                                        onClick={() => {
                                            setAnchor(null)
                                        }}
                                    >
                                        <ListItemText primary={'Пользователи'}/>
                                    </ListItem>
                                    }
                                    { AuthorizationService.permissions(account, 'role') &&
                                    <ListItem
                                        selected={location.pathname === '/access/roles'}
                                        button
                                        component={Link}
                                        to={'/access/roles'}
                                        className={classes.nested}
                                        onClick={() => {
                                            setAnchor(null)
                                        }}
                                    >
                                        <ListItemText primary={'Роли'}/>
                                    </ListItem>
                                    }
                                </List>
                            </Collapse>
                        }
                    </Menu>
                    {title('Эталонный номенклатор')}
                    <IconButton
                        edge="start"
                        color="inherit"
                        component={ Link }
                        to='/notifications'
                    >
                        <Badge badgeContent={count} color="primary"><Notifications/></Badge>
                    </IconButton>
                    <Button
                        color="inherit"
                        component={ Link }
                        to='/logout'
                    >
                        Выйти
                    </Button>
                </Toolbar>
                { application.filling && <LinearProgress /> }
            </AppBar>
            <Container maxWidth="xl" fixed className={ classes.container }>
                <Paper className={ classes.paper }>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={ classes.body }>
                        <Grid item xs={12} className={ classes.item }>
                            <Content {...props} />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Grid>
    )
}
