import * as AppStorage from "../../App/helpers/storage";

export default function account(state = { account: JSON.parse(AppStorage.get('account')), load: false, loading: false }, action) {
    switch (action.type) {
        case 'ACCOUNT_REQUEST':
            return {
                ...state,
                ...{
                    account: state.account,
                    loading: true
                }
            }
        case 'ACCOUNT':
            return {
                account: action.payload,
                load: true,
                loading: false
            }
        case 'ACCOUNT_FAILURE':
            return {
                ...state,
                ...{
                    account: state.account,
                    loading: false
                }
            }
        default:
            return state
    }
}
