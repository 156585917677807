import React, {useState} from "react"
import { Field } from "formik"
import { TextField } from "formik-material-ui"
import { InputAdornment } from "@material-ui/core"

export const FieldDouble = (props) => {
    const { id, label, addition, isSubmitting, disabled } = props
    const [name] = useState(props.hasOwnProperty('name') ? props.name : `attributes.${id}`)

    return (
        <Field
            fullWidth
            name={ `${name}` }
            type="number"
            disabled={ isSubmitting || disabled }
            label={ `${label}` }
            component={ TextField }
            inputProps={{
                step: 0.0001,
                onWheel: e => {
                    e.target.blur()
                    e.stopPropagation()
                    setTimeout(() => {
                        e.target.focus()
                    }, 0)
                }
            }}
            InputProps={{
                ...((addition && addition.prefix.value) ? {startAdornment: <InputAdornment position="start">{addition.prefix.value}</InputAdornment>} : {}),
                ...((addition && addition.postfix.value) ? {endAdornment: <InputAdornment position="end">{addition.postfix.value}</InputAdornment>} : {})
            }}
        />
    )
}
