import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux"

import {
    Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, makeStyles
} from '@material-ui/core'
import {NotificationActions} from "./actions/notification";
import format from "date-fns/format";
import {ru} from "date-fns/locale";

const useStyles = makeStyles(theme => ({
    field: {
        'height': '100%'
    },
    data: {
        'height': 'calc(100% - 52px)',
        'width': '100%'
    },
    item: {
        'width': '100%'
    },
    table: {
        'height': '100%'
    },
    fab: {
        'margin': '0',
        'top': 'auto',
        'right': '90px',
        'bottom': '25px',
        'left': 'auto',
        'position': 'fixed'
    }
}))

const columns = [
    {
        id: 'message',
        label: 'Событие',
        align: 'left',
        format: value => value.toLocaleString()
    },
    {
        id: 'created',
        label: 'Дата',
        align: 'left',
        format: value => value.toLocaleString()
    }
]

export const Notification = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {events} = useSelector(state => state.notification)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [loading, setLoading] = useState(false)
    const [initialize, setInitialize] = useState(false)

    useEffect(() => {
        const getEvents = async () => {
            return await dispatch(NotificationActions.events({page: page + 1, limit: rowsPerPage}))
        }

        if (!loading) {
            getEvents().then(_ => {
                setLoading(true)
                setInitialize(true)
            })
        }
    }, [dispatch, initialize, loading, page, rowsPerPage])

    const handleRead = (id) => {
        return dispatch(NotificationActions.read(id)).then(
            () => {
                setLoading(false)
            }
        )
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        setLoading(false)
    }

    const handleChangeRowsPerPage = (event) => {
        setPage(0)
        setRowsPerPage(+event.target.value)
        setLoading(false)
    }

    return (
        <Grid container direction="column" justify="space-between" alignItems="center" className={classes.field}>
            <Grid item className={classes.data}>
                <TableContainer className={classes.table}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map(column => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {events.data.map(item => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        key={item.id}
                                        onClick={() => {
                                            if (!item.read) {
                                                handleRead(item.id)
                                            }
                                        }}
                                    >
                                        <TableCell align="left">
                                            { item.message }
                                        </TableCell>
                                        <TableCell align="left">
                                            { format(new Date(item.created), 'H:mm PP',  {locale: ru}) }
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item className={classes.item}>
                <TablePagination
                    rowsPerPageOptions={ [10, 25, 100] }
                    component="div"
                    count={ events.data.length ? events.meta.total : 0 }
                    rowsPerPage={ rowsPerPage }
                    page={ page }
                    onChangePage={ handleChangePage }
                    onChangeRowsPerPage={ handleChangeRowsPerPage }
                />
            </Grid>
        </Grid>
    )
}
