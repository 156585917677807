import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {
    Grid,
    TextField,
    makeStyles,
    Button, FormControlLabel, Switch, Typography
} from '@material-ui/core'
import {blue, green} from "@material-ui/core/colors"
import {
    SelectionState, PagingState, CustomPaging
} from '@devexpress/dx-react-grid'
import {
    DragDropProvider, Table, TableSelection, TableHeaderRow , TableColumnResizing, PagingPanel, TableColumnReordering
} from '@devexpress/dx-react-grid-material-ui'

import {FileActions} from "./actions/file"
import {useDebouncedCallback} from 'use-debounce'
import {StickyTable} from "../App/components/Table/StikyTable"
import {Grid as GridTable} from "../App/components/Table/Grid"
import {Pager} from "../App/components/Table/Paging/Pager"
import format from "date-fns/format"
import {ru} from "date-fns/locale"
import {FileForm} from "./components/FileForm"
import {DeleteForm} from "./components/DeleteForm"
import {history} from "../App/helpers/history"

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    data: {
        'height': 'calc(100% - 72px)',
        'width': '100%'
    },
    element: {
        'width': '100%'
    },
    breadcrumb: {
        'width': '100%',
        'height': '52px'
    },
    active: {
        'height': '41px',
        'background-color': blue[100],
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    completed: {
        'height': '41px',
        'background-color': green[100],
        '&:hover': {
            'background-color': `${green[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    default: {
        'height': '41px',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    loading: {
        'height': '41px',
        'background-color': 'rgba(244, 244, 244, 1)',
        '&:hover': {
            'background-color': `${blue[50]} !important`
        },
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    },
    body: {
        'height': '100%'
    }
}))

export const Files = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {files} = useSelector(state => state.markup_file)
    const [search, setSearch] = useState('')
    const [unverified, setUnverified] = useState(false)
    const [deleted, setDeleted] = useState(false)
    const [tableRef, setTableRef] = useState(null)

    const [unloading, setUnloading] = useState(false)

    const debounced = useDebouncedCallback(
        (value) => {
            setLoading(value)
        }, 700
    )

    const columns = [
        { name: 'name', title: 'Имя файла'},
        { name: 'created', title: 'Дата загрузки' },
        { name: 'updated', title: 'Дата обновления' },
        { name: 'user.login', title: 'Логин пользователя'},
        { name: 'loaded', title: 'Статус'},
        { name: 'total', title: 'Всего'},
        { name: 'unverified', title: 'Не проверено'},
        { name: 'matched.category', title: 'Назначена категория'},
        { name: 'matched.standard', title: 'Назначен эталон'},
        { name: 'deleted', title: 'Удалено'},
        { name: 'proposed', title: 'Предложено'},
        { name: 'rejected', title: 'Отклонено предложений'}
    ]

    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [loading, setLoading] = useState(false)
    const [selection, setSelection] = useState([])
    const [columnWidths, setColumnWidths] = useState([
        { columnName: 'name', width: 250 },
        { columnName: 'created', width: 175 },
        { columnName: 'updated', width: 175 },
        { columnName: 'user.login', width: 200 },
        { columnName: 'loaded', width: 125 },
        { columnName: 'total', width: 125 },
        { columnName: 'unverified', width: 125 },
        { columnName: 'matched.category', width: 150 },
        { columnName: 'matched.standard', width: 150 },
        { columnName: 'deleted', width: 125 },
        { columnName: 'proposed', width: 125 },
        { columnName: 'rejected', width: 150 }
    ])
    const [columnOrder, setColumnOrder] = useState(['name', 'created', 'updated', 'user.login', 'loaded', 'total', 'unverified', 'matched.category', 'matched.standard', 'deleted', 'proposed', 'rejected'])

    useEffect(() => {
        const getData = async () => {
            return await dispatch(FileActions.files({
                page: page,
                limit: rowsPerPage,
                ...(unverified ? {unverified: true} : {}),
                ...(search ? {search: search} : {}),
            }))
        }

        if (!loading) {
            getData().then(props => {
                tableRef && tableRef.current && tableRef.current.scrollIntoView()
                setLoading(true)
                setUnloading(false)
                setSelection([])
            })
        }
    }, [loading, page, rowsPerPage, search, unverified])

    const handleUnloading = (values) => {
        return new Promise((resolve, reject) => {
            dispatch(FileActions.unloading(values)).then(
                () => {
                    setLoading(false)
                    resolve()
                },
                errors => {
                    reject(errors)
                }
            )
        })
    }

    const handleDelete = (id) => {
        return dispatch(FileActions.remove(id)).then(
            () => {
                setLoading(false)
            }
        )
    }

    return (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={ classes.body } spacing={2}>
            <Grid item className={classes.element}>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    <Grid item sm={3}>
                        <TextField
                            fullWidth
                            id="category"
                            label='Поиск'
                            value={search}
                            onChange={event => {
                                setPage(1)
                                setSearch(event.target.value)
                                debounced.callback(false)
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.breadcrumb}>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={unverified}
                                    onChange={(event) => {
                                        setUnverified(event.target.checked)
                                        setLoading(false)
                                    }}
                                    name="unverified"
                                    color="primary"
                                />
                            }
                            label="Только непроверенные"
                        />
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item>
                                <Button
                                    onClick={() => {
                                        setUnloading(true)
                                    }}
                                    color="primary"
                                    type="button"
                                >
                                    Загрузить
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={ !selection.length || (files.data[selection[0]] && !files.data[selection[0]].loaded) }
                                    onClick={() => {
                                        history.push(`/markup/file/${files.data[selection[0]].id}`)
                                    }}
                                    color="primary"
                                    type="button"
                                >
                                    Разметка данных
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    disabled={ !selection.length }
                                    onClick={() => {
                                        setDeleted(true)
                                    }}
                                    color="secondary"
                                    type="button"
                                >
                                    Удалить
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classes.data}>
                <GridTable
                    rows={files.data.map(file => ({
                        'name': file.name,
                        'created': format(new Date(file.created), 'H:mm PP',  {locale: ru}),
                        'updated': format(new Date(file.updated), 'H:mm PP',  {locale: ru}),
                        'user.login': file.user.login,
                        'loaded': file.loaded ? 'Загружен' : 'Загружается',
                        'total': file.total ?? 0,
                        'unverified': file.unverified ?? 0,
                        'matched.category': file.matched.category ?? 0,
                        'matched.standard': file.matched.standard ?? 0,
                        'deleted': file.deleted ?? 0,
                        'proposed': file.proposed ?? 0,
                        'rejected': file.rejected ? <Typography style={{color: 'red'}}>{file.rejected}</Typography> : 0
                    }))}
                    columns={columns}
                >
                    <PagingState
                        currentPage={page}
                        onCurrentPageChange={newPage => {
                            setPage(newPage)
                            setLoading(false)
                        }}
                        pageSize={rowsPerPage}
                        onPageSizeChange={newRowsPerPage => {
                            setPage(1)
                            setRowsPerPage(newRowsPerPage)
                            setLoading(false)
                        }}
                    />
                    <CustomPaging
                        totalCount={files.data.length ? files.meta.total : 0}
                    />
                    <SelectionState
                        selection={selection}
                        onSelectionChange={(numbers) => {
                            const current = numbers.filter(x => !selection.includes(x))

                            if (!current.length && files.data[selection[0]].loaded) {
                                history.push(`/markup/file/${files.data[selection[0]].id}`)
                            } else {
                                setSelection(numbers.filter(x => !selection.includes(x)))
                            }
                        }}
                    />
                    <DragDropProvider />
                    <Table
                        noDataCellComponent={props => {
                            return null
                        }}
                        tableComponent={props => <StickyTable {...props} setTableRef={setTableRef} />}
                        rowComponent={({ row, tableRow, children }) => (
                            <Table.Row
                                tableRow={tableRow}
                                children={children}
                                className={classes.default}
                                row={row}
                            />
                        )}
                    />
                    <TableColumnReordering
                        order={columnOrder}
                        onOrderChange={setColumnOrder}
                    />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                    />
                    <TableHeaderRow />
                    <TableSelection
                        selectByRowClick
                        highlightRow
                        showSelectionColumn={false}
                        rowComponent={(props) => {
                            const { tableRow, children, highlighted, onToggle } = props
                            const { row } = tableRow

                            return (
                                <Table.Row
                                    tableRow={tableRow}
                                    children={children}
                                    onClick={onToggle}
                                    className={highlighted ? classes.active : ((row.loaded === 'Загружен') ? (!row.unverified ? classes.completed : classes.default): classes.loading)}
                                    row={row}
                                />
                            )
                        }}
                    />
                    <PagingPanel
                        containerComponent={Pager}
                        messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from}-{to} из {count}'}}
                        pageSizes={[50, 100, 200]}
                    />
                </GridTable>
            </Grid>
            {unloading && <FileForm open={unloading} handleUnloading={handleUnloading} handleClose={() => {setUnloading(false)}} />}
            {deleted && <DeleteForm label="Удаление файла" text="Вы действительно хотите удалить файл? " open={deleted} handleDelete={() => { return handleDelete(files.data[selection[0]].id) }}  handleClose={() => {setDeleted(false)}} />}
        </Grid>
    )
}
