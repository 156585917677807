import { ConverterService } from '../services/converter'

export const ConverterActions = {
    positions,
    add,
    edit,
    remove
}

function positions (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'CONVERTER_POSITIONS_REQUEST' })

        ConverterService.positions(params)
            .then(
                response => {
                    dispatch({ type: 'CONVERTER_POSITIONS_SUCCESS', payload: { data: response.data, meta: response.meta, limit: params.limit ? params.limit : 10, page: params.page ? params.page : 1 } })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(response.data)
                },
                error => {
                    dispatch({ type: 'CONVERTER_POSITIONS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}


function add (values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'CONVERTER_POSITION_ADD_REQUEST' })

        ConverterService.add(values)
            .then(
                () => {
                    dispatch({ type: 'CONVERTER_POSITION_ADD_SUCCESS' })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'CONVERTER_POSITION_ADD_FAILURE' })
                    if (!error.type) {
                        dispatch({type: 'ALERT_ERROR', payload: error.message})
                    }
                    dispatch({ type: 'FILLING', payload: false })
                    reject(error)
                }
            )
    })
}

function edit (id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'CONVERTER_POSITION_EDIT_REQUEST' })

        ConverterService.edit(id, values)
            .then(
                () => {
                    dispatch({ type: 'CONVERTER_POSITION_EDIT_SUCCESS' })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'CONVERTER_POSITION_EDIT_FAILURE' })
                    if (!error.type) {
                        dispatch({type: 'ALERT_ERROR', payload: error.message})
                    }
                    dispatch({ type: 'FILLING', payload: false })
                    reject(error)
                }
            )
    })
}

function remove (id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'CONVERTER_POSITION_DELETE_REQUEST' })

        ConverterService.remove(id)
            .then(
                () => {
                    dispatch({ type: 'CONVERTER_POSITION_DELETE_SUCCESS' })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'CONVERTER_POSITION_DELETE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}
