export default function message(state = {type: null, title: null, message: null}, action) {
    switch (action.type) {
        case 'MESSAGE_SUCCESS':
            return {
                type: 'success',
                title: action.payload.title,
                message: action.payload.message
            }
        case 'MESSAGE_INFO':
            return {
                type: 'info',
                title: action.payload.title,
                message: action.payload.message
            }
        case 'MESSAGE_ERROR':
            return {
                type: 'error',
                title: action.payload.title,
                message: action.payload.message
            }
        case 'MESSAGE_CLEAR':
            return {}
        default:
            return state
    }
}
