import React from 'react'
import {useDispatch, useSelector} from "react-redux"

import { Snackbar, Fade, makeStyles } from '@material-ui/core'

import { MessageActions } from './actions/message'
import {Alert, AlertTitle} from "@material-ui/lab"

const useStyles = makeStyles(theme => ({
    root: {
        'max-width': '500px'
    }
}))

export const Message = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const message = useSelector(state => state.message)

    const close = () => {
        dispatch(MessageActions.clear())
    }

    if (message.message) {
        return (
            <Snackbar
                classes={{
                    root: classes.root
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={ true }
                TransitionComponent={ Fade }
            >
                <Alert onClose={ close } severity={message.type}>
                    <AlertTitle>{message.title}</AlertTitle>{message.message}
                </Alert>
            </Snackbar>
        )
    } else {
        return null
    }
}
