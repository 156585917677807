import React from 'react'

import {TableHeaderRow} from "@devexpress/dx-react-grid-material-ui"
import {Filter} from "../Filter"

export const TableHeaderContent = (props) => {
    const {column, children, ...restProps} = props

    let button = null

    switch (column.name) {
        case 'id':
        case 'status':
        case 'value.keyword':
        case 'created':
            button = <Filter name={column.name}/>
            break
        case 'source.type':
            button = <Filter name="source"/>
            break
        case 'source.module':
            button = <Filter name="module"/>
            break
        case 'source.company':
            button = <Filter name="company"/>
            break
        case 'source.user':
        case 'source.contacts':
        case 'category':
        case 'section':
        case 'group':
            break
        default:
            button = <Filter attribute={column.attribute} name={column.name}/>
    }

    return (
        <TableHeaderRow.Content
            column={column}
            {...restProps}
            style={{
                justifyContent: 'space-between'
            }}
        >
            {children}
            {button}
        </TableHeaderRow.Content>
    )
}
