export default function category (state = { categories: { data: [], meta: {}, search: null, limit: 100, page: 1 } }, action) {
    switch (action.type) {
        case 'CATEGORIES_REQUEST':
            return state
        case 'CATEGORIES_SUCCESS':
            return {
                categories: action.payload
            }
        case 'CATEGORIES_FAILURE':
            return {
                categories: { data: [], meta: {}, search: state.categories.search, limit: state.categories.limit, page: state.categories.page }
            }
        case 'CATEGORIES_CLEAR':
            return {
                categories: { data: [], meta: {}, search: null, limit: 10, page: 1 }
            }
        case 'CATEGORY_DELETE_REQUEST':
            return state
        case 'CATEGORY_DELETE_SUCCESS':
            return {
                categories: { data: state.categories.data.filter(category => category.id !== action.payload), meta: state.categories.meta }
            }
        case 'CATEGORY_DELETE_FAILURE':
            return state
        case 'CATEGORY_ADD_REQUEST':
            return state
        case 'CATEGORY_ADD_SUCCESS':
            return state
        case 'CATEGORY_ADD_FAILURE':
            return state
        case 'CATEGORY_SAVE_REQUEST':
            return state
        case 'CATEGORY_SAVE_SUCCESS':
            state.categories.data.forEach(function (category, key) {
                if (category.category && (category.category.id === action.payload.id)) {
                    state.categories.data[key] = {
                        ...state.categories.data[key],
                        ...{category: action.payload}
                    }
                }

                if (category.id === action.payload.id) {
                    state.categories.data[key] = action.payload
                }
            })

            state.categories.data.sort((first, second) => { if (first.id < second.id) { return -1 } if (first.id > second.id) { return 1 } return 0 })

            return state
        case 'CATEGORY_SAVE_FAILURE':
            return {
                categories: state.categories
            }
        default:
            return state
    }
}
