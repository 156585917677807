import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux"
import { Formik, Field, Form } from 'formik'

import {
    Button, CircularProgress, MenuItem,
    Dialog, DialogTitle, DialogContent, DialogActions, Grid,
    FormControl, InputLabel, makeStyles
} from '@material-ui/core'
import {
    TextField, Select,
} from 'formik-material-ui'

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    }
}))

export const UserForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const [deleteUser, setDeleteUser] = useState(false)
    const {roles} = useSelector(state => state.role)

    const { handleClose, handleDelete, handleSave, open, user } = props

    return (
        <Formik
            initialValues = {{
                login: user ? user.login : '',
                password: '',
                roles: user ? user.roles.map(role => { return role.id }) : []
            }}
            validate = {values => {
                const errors = {}

                if (!values.login) {
                    errors.login = 'Введите имя пользователя'
                }

                if (!values.roles) {
                    errors.roles = 'Выберит роли'
                }

                return errors
            }}
            onSubmit = {(values, { setSubmitting }) => {
                handleSave(values, user ? user.id : null).then(
                    () => {
                        setSubmitting(false)
                        dispatch(handleClose)
                    },
                    () => {
                        setSubmitting(false)
                    }
                )
            }}
        >
            {({
                  handleSubmit,
                  isSubmitting
              }) => (
                <Form>
                    <Dialog
                        fullWidth={ true }
                        maxWidth="sm"
                        open={ open }
                        onClose={ handleClose }
                        aria-labelledby="Пользователь"
                        classes={{
                            paper: classes.dialog
                        }}
                    >
                        <DialogTitle>{ user ? 'Редактировать' : 'Добавить' }</DialogTitle>
                        <DialogContent>
                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                <Grid item sm={8} className={classes.fullWidth}>
                                    <Field
                                        fullWidth
                                        name="login"
                                        type="text"
                                        label="Имя пользователя"
                                        component={ TextField }
                                    />
                                </Grid>
                                { !user &&
                                    <Grid item sm={8} className={classes.fullWidth}>
                                        <Field
                                            fullWidth
                                            type="password"
                                            name="password"
                                            label="Пароль"
                                            component={ TextField }
                                        />
                                    </Grid>
                                }
                                <Grid item sm={8} className={classes.fullWidth}>
                                    <FormControl className={classes.fullWidth}>
                                        <InputLabel shrink={ true } htmlFor="roles">
                                            Роли
                                        </InputLabel>
                                        <Field
                                            fullWidth
                                            type="text"
                                            name="roles"
                                            label="Роли"
                                            component={ Select }
                                            multiple={ true }
                                        >
                                            {roles.data.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.description}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            {
                                user
                                    ? (
                                        <DialogActions>
                                            <Button
                                                disabled={ isSubmitting || deleteUser }
                                                onClick={() => {
                                                    setDeleteUser(true)
                                                    handleDelete(user.id).then(
                                                        () => {
                                                            handleClose()
                                                        }
                                                    )
                                                }}
                                                color="secondary"
                                                type="submit"
                                            >
                                                { deleteUser ? <CircularProgress size={24} /> : 'Удалить' }
                                            </Button>
                                            < Button
                                                disabled={ isSubmitting || deleteUser }
                                                onClick={ handleSubmit }
                                                color="primary"
                                                type="submit"
                                            >
                                                { isSubmitting ? <CircularProgress size={24} /> : 'Сохранить' }
                                            </Button>
                                        </DialogActions>
                                    )
                                    : (
                                        <DialogActions>
                                            < Button
                                                disabled={ isSubmitting }
                                                onClick={ handleSubmit }
                                                color="primary"
                                                type="submit"
                                            >
                                                { isSubmitting ? <CircularProgress size={24} /> : 'Добавить' }
                                            </Button>
                                        </DialogActions>
                                    )
                            }
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    )
}
