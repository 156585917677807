import { ItemService } from '../services/item'
import {FileService} from "../../Markup/services/file";

export const ItemActions = {
    search,
    analysis,
    items,
    count,
    offers,
    add,
    save,
    union,
    matching,
    cache,
    remove,
    sources,
    changes,
    upload
}

function search (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        ItemService.items(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    reject()
                }
            )
    })
}

function analysis (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        ItemService.analysis(params)
            .then(
                response => {
                    resolve(response.standards)
                },
                error => {
                    reject()
                }
            )
    })
}

function items (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'ITEMS_REQUEST' })

        ItemService.items(params)
            .then(
                response => {
                    dispatch({ type: 'ITEMS_SUCCESS', payload: { data: response.data, meta: response.meta, category: params.category, limit: params.limit ? params.limit : 10, page: params.page ? params.page : 1 } })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(response.data)
                },
                error => {
                    dispatch({ type: 'ITEMS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function count (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })

        ItemService.count(params)
            .then(
                response => {
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(response.count)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function offers (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'ITEM_OFFERS_REQUEST' })
        dispatch({ type: 'FILLING', payload: true })

        ItemService.offers(params)
            .then(
                response => {
                    dispatch({ type: 'ITEM_OFFERS_SUCCESS', payload: response })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ITEM_OFFERS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function add (values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'ITEM_ADD_REQUEST' })

        ItemService.add(values)
            .then(
                item => {
                    setTimeout(() => {
                        dispatch({ type: 'ITEM_ADD_SUCCESS', payload: item })
                        dispatch({ type: 'ALERT_SUCCESS', payload: 'Эталон добавлен.' })
                        resolve(item)
                    }, 1000)
                },
                error => {
                    dispatch({ type: 'ITEM_ADD_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject(error)
                }
            )
    })
}

function save (id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'ITEM_SAVE_REQUEST' })

        ItemService.save(id, values)
            .then(
                item => {
                    setTimeout(() => {
                        dispatch({ type: 'ITEM_SAVE_SUCCESS', payload: item })
                        dispatch({ type: 'ALERT_SUCCESS', payload: values.hasOwnProperty('confirmed') ? 'Эталон подтверждён.' : 'Эталон изменён.' })
                        resolve(item)
                    }, 1000)
                },
                error => {
                    dispatch({ type: 'ITEM_SAVE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject(error)
                }
            )
    })
}

function union (values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'ITEMS_UNION_REQUEST' })

        ItemService.union(values)
            .then(
                () => {
                    dispatch({ type: 'ITEMS_UNION_SUCCESS' })
                    dispatch({ type: 'ALERT_SUCCESS', payload: 'Эталоны объединены.' })
                    resolve()
                },
                error => {
                    dispatch({ type: 'ITEMS_UNION_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject(error)
                }
            )
    })
}

function matching (id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'ITEM_MATCHING_REQUEST' })

        ItemService.matching(id)
            .then(
                response => {
                    dispatch({ type: 'ITEM_MATCHING_SUCCESS', payload: { id: id, matching: response.matching } })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(response.matching)
                },
                error => {
                    dispatch({ type: 'ITEM_MATCHING_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function cache (values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'ITEM_MATCHING_CACHE_REQUEST' })

        ItemService.cache(values)
            .then(
                response => {
                    dispatch({ type: 'ITEM_MATCHING_CACHE_SUCCESS' })
                    resolve()
                },
                error => {
                    dispatch({ type: 'ITEM_MATCHING_CACHE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function remove (id, params = {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'ITEM_DELETE_REQUEST' })

        ItemService.remove(id, params)
            .then(
                () => {
                    setTimeout(() => {
                        dispatch({ type: 'ITEM_DELETE_SUCCESS', payload: id })
                        dispatch({ type: 'ALERT_SUCCESS', payload: params.hasOwnProperty('reject') ? 'Эталон отклонён.' : 'Эталон удалён.' })
                        resolve()
                    }, 1000)
                },
                error => {
                    dispatch({ type: 'ITEM_DELETE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function sources (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        ItemService.sources(params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function changes (id, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        ItemService.changes(id, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function upload (id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })

        ItemService.upload(id, values)
            .then(
                file => {
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject(error.hasOwnProperty('errors') ? error.errors : [])
                }
            )
    })
}

