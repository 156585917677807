import { DictionaryService } from '../services/dictionary'

export const DictionaryActions = {
    items,
    sources,
    dictionary,
    dictionaries,
    categories,
    description,
    filter,
    addItem,
    editItem,
    rejectItem,
    deleteItem
}

function items (id, params = { }, data = false) {
    return dispatch => new Promise((resolve, reject) => {
        if (!data) {
            dispatch({type: 'FILLING', payload: true})
            dispatch({type: 'DICTIONARY_ITEMS_REQUEST'})
        }

        DictionaryService.items(id, params)
            .then(
                items => {
                    if (data) {
                        resolve(items.data)
                    } else {
                        dispatch({ type: 'DICTIONARY_ITEMS_SUCCESS', payload: items })
                        dispatch({ type: 'FILLING', payload: false })
                        resolve(items)
                    }
                },
                error => {
                    if (!data) {
                        dispatch({type: 'DICTIONARY_ITEMS_FAILURE'})
                        dispatch({type: 'ALERT_ERROR', payload: error.message})
                        dispatch({type: 'FILLING', payload: false})
                    }
                    reject()
                }
            )
    })
}

function sources () {
    return () => new Promise((resolve, reject) => {
        DictionaryService.sources()
            .then(
                items => {
                    resolve(items.data)
                },
                error => {
                    reject()
                }
            )
    })
}

function dictionary (id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'DICTIONARY_REQUEST' })

        DictionaryService.dictionary(id)
            .then(
                dictionary => {
                    dispatch({ type: 'DICTIONARY_SUCCESS', payload: dictionary })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(dictionary)
                },
                error => {
                    dispatch({ type: 'DICTIONARY_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function dictionaries (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'DICTIONARIES_REQUEST' })

        DictionaryService.dictionaries(params)
            .then(
                dictionaries => {
                    dispatch({ type: 'DICTIONARIES_SUCCESS', payload: dictionaries })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(dictionaries.data)
                },
                error => {
                    dispatch({ type: 'DICTIONARIES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function description (id, values) {
    return dispatch => new Promise((resolve, reject) => {
        DictionaryService.description(id, values)
            .then(
                () => {
                    dispatch({ type: 'DICTIONARY_DESCRIPTION_SUCCESS', payload: {id: id, values: values} })
                    resolve()
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function categories () {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'DICTIONARY_CATEGORIES_REQUEST' })

        DictionaryService.categories()
            .then(
                categories => {
                    dispatch({ type: 'DICTIONARY_CATEGORIES_SUCCESS', payload: categories })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(categories)
                },
                error => {
                    dispatch({ type: 'DICTIONARY_CATEGORIES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function filter (type, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        DictionaryService.filter(type, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function addItem (dictionary, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'DICTIONARY_ITEM_ADD_REQUEST' })

        DictionaryService.addItem(dictionary, values)
            .then(
                (item) => {
                    dispatch({ type: 'DICTIONARY_ITEM_ADD_SUCCESS' })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(item)
                },
                error => {
                    dispatch({ type: 'DICTIONARY_ITEM_ADD_FAILURE' })
                    if (!error.type) {
                        dispatch({type: 'ALERT_ERROR', payload: error.message})
                    }
                    dispatch({ type: 'FILLING', payload: false })
                    reject(error)
                }
            )
    })
}

function editItem (dictionary, id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'DICTIONARY_ITEM_EDIT_REQUEST' })

        DictionaryService.editItem(dictionary, id, values)
            .then(
                () => {
                    dispatch({ type: 'DICTIONARY_ITEM_EDIT_SUCCESS' })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'DICTIONARY_ITEM_EDIT_FAILURE' })
                    if (!error.type) {
                        dispatch({type: 'ALERT_ERROR', payload: error.message})
                    }
                    dispatch({ type: 'FILLING', payload: false })
                    reject(error)
                }
            )
    })
}

function rejectItem (dictionary, id, values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'DICTIONARY_ITEM_REJECT_REQUEST' })

        DictionaryService.rejectItem(dictionary, id, values)
            .then(
                () => {
                    dispatch({ type: 'DICTIONARY_ITEM_REJECT_SUCCESS' })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'DICTIONARY_ITEM_REJECT_FAILURE' })
                    dispatch({type: 'ALERT_ERROR', payload: error.message})
                    dispatch({ type: 'FILLING', payload: false })
                    reject(error)
                }
            )
    })
}

function deleteItem (dictionary, id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'DICTIONARY_ITEM_DELETE_REQUEST' })

        DictionaryService.deleteItem(dictionary, id)
            .then(
                () => {
                    dispatch({ type: 'DICTIONARY_ITEM_DELETE_SUCCESS' })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'DICTIONARY_ITEM_DELETE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}
