import React from 'react'

import {TableHeaderRow} from "@devexpress/dx-react-grid-material-ui"
import {Filter} from "../Filter"

export const TableHeaderContent = (props) => {
    const {column, attribute, children, ...restProps} = props

    let button = null

    switch (column.name) {
        case 'value':
        case 'option':
        case 'active':
        case 'display':
        case 'created':
        case 'updated':
            button = <Filter name={column.name} attribute={attribute}/>
            break
        default:
            break
    }

    return (
        <TableHeaderRow.Content
            column={column}
            {...restProps}
            style={{
                justifyContent: 'space-between'
            }}
        >
            {children}
            {button}
        </TableHeaderRow.Content>
    )
}
