import { FileService } from '../services/file'
import {history} from "../../App/helpers/history"

export const FileActions = {
    files,
    file,
    unloading,
    remove,
    standards,
    categories,
    users
}

function files (params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'MARKUP_FILES_REQUEST' })

        FileService.files(params)
            .then(
                response => {
                    dispatch({ type: 'MARKUP_FILES_SUCCESS', payload: { data: response.data, meta: response.meta, limit: params.limit ? params.limit : 10, page: params.page ? params.page : 1 } })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve(response.data)
                },
                error => {
                    dispatch({ type: 'MARKUP_FILES_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject()
                }
            )
    })
}

function file (id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'LOADING', payload: true })
        dispatch({ type: 'MARKUP_FILE_REQUEST' })

        FileService.file(id)
            .then(
                file => {
                    dispatch({ type: 'MARKUP_FILE_SUCCESS', payload: file})
                    dispatch({ type: 'LOADING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'MARKUP_FILE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'LOADING', payload: false })
                    history.push('/markup')
                    reject()
                }
            )
    })
}

function unloading (values) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'FILLING', payload: true })
        dispatch({ type: 'MARKUP_UNLOADING_FILE_REQUEST' })

        FileService.unloading(values)
            .then(
                file => {
                    dispatch({ type: 'MARKUP_UNLOADING_FILE_SUCCESS', payload: file })
                    dispatch({ type: 'FILLING', payload: false })
                    resolve()
                },
                error => {
                    dispatch({ type: 'MARKUP_UNLOADING_FILE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    dispatch({ type: 'FILLING', payload: false })
                    reject(error.hasOwnProperty('errors') ? error.errors : [])
                }
            )
    })
}

function remove (id) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'MARKUP_DELETE_FILE_REQUEST' })

        FileService.remove(id)
            .then(
                () => {
                    dispatch({ type: 'MARKUP_DELETE_FILE_SUCCESS', payload: id })
                    resolve()
                },
                error => {
                    dispatch({ type: 'MARKUP_DELETE_FILE_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function standards (id, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        FileService.standards(id, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function categories (id, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        FileService.categories(id, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function users (id, params = { }) {
    return dispatch => new Promise((resolve, reject) => {
        FileService.users(id, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}
