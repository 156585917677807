import { HttpService } from '../../App/services/http'

export const AttributeService = {
    values,
    terms,
    items
}

function values (id, params = {}) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/attribute/${id}/values`, options, true)
        .then(response => {
            return response
        })
}

function terms (id, params = {}) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/attribute/${id}/terms`, options, true)
        .then(response => {
            return response
        })
}

function items (id, params = {}) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/attribute/${id}/items`, options, true)
        .then(response => {
            return response.data
        })
}
