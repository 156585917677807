import React from 'react'

import {useDispatch} from "react-redux";
import {DictionaryActions} from "../../actions/dictionary";

import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {TextField as MUITextField} from "formik-material-ui";

export const Description = (props) => {
    const dispatch = useDispatch()

    const {select, setSelect, dictionary} = props

    const handleDescription = (id, values) => {
        return dispatch(DictionaryActions.description(id, values))
    }

    return (select === dictionary.id) ? (
    <Formik
        enableReinitialize={ true }
        initialValues={{
            description: dictionary.description ?? ''
        }}
        validationSchema={Yup.object().shape({
            description: Yup.string()
        })}
        onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true)

            handleDescription(dictionary.id, {description: values.description}).then(
                () => {
                    setSubmitting(false)
                    setSelect(null)
                },
                () => {
                    setSubmitting(false)
                }
            )
        }}
    >
        {({
              handleSubmit
          }) => (
            <Form>
                <Field
                    fullWidth
                    name="description"
                    type="text"
                    component={ MUITextField }
                    onClick={e => e.stopPropagation()}
                    onBlur={() => {
                        handleSubmit()
                    }}
                />
            </Form>
        )}
    </Formik>) : dictionary.description
}
