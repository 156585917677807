import React, {useEffect, useState} from 'react'

import {
    Dialog, DialogTitle, DialogContent, Grid, makeStyles
} from '@material-ui/core'
import {useDispatch, useSelector} from "react-redux"
import {ItemActions} from "../actions/item";
import format from "date-fns/format";
import {ru} from "date-fns/locale";
import {CustomPaging, PagingState} from "@devexpress/dx-react-grid";
import {
    DragDropProvider, PagingPanel,
    Table, TableColumnVisibility,
    TableHeaderRow
} from "@devexpress/dx-react-grid-material-ui";
import {StickyTable} from "../../App/components/Table/StikyTable";
import {Pager} from "../../App/components/Table/Paging/Pager";
import {Grid as GridTable} from "../../App/components/Table/Grid";
import {getValue} from "../../Category/components/Attribute/helpers/value";
import {ColumnChooser} from "./Table/ColumnChooser";
import {TableHeaderContent} from "./History/TableHeaderContent";
const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    }
}))

const columns = [
    { name: 'attribute', title: 'Параметр' },
    { name: 'value', title: 'Значение' },
    { name: 'created', title: 'Дата изменения' },
    { name: 'user', title: 'Пользователь' }
]

export const Changes = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {filter} = useSelector(state => state.history)

    const [tableRef, setTableRef] = useState(null)
    const [page, setPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(50)
    const [items, setItems] = useState({data: [], meta: {}, limit: 50, page: 1})
    const [hiddenColumnNames, setHiddenColumnNames] = useState([])

    const { id, handleClose, open } = props

    useEffect(() => {
        const getData = async () => {
            return dispatch(ItemActions.changes(id, {
                page: page,
                limit: rowsPerPage,
                ...((filter.sort.name && filter.sort.direction) ? {order: [filter.sort.name, filter.sort.direction]} : []),
                ...((filter.hasOwnProperty('attribute') && filter.attribute.length) ? {attribute: filter.attribute} : [])
            }));
        }

        getData().then((data) => {
            setItems(data)
            tableRef && tableRef.current && tableRef.current.scrollIntoView()
        })
    }, [filter, page, rowsPerPage]);

    return (
        <Dialog
            fullWidth={ true }
            maxWidth="md"
            open={ open }
            onClose={() => {
                dispatch({type: 'ITEM_CHANGES_FILTER', payload: {sort: {name: null, direction: null}, attribute: []}})
                handleClose()
            }}
            classes={{
                paper: classes.dialog
            }}
        >
            <DialogTitle>
                <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
                    <Grid item>
                        История изменений
                    </Grid>
                    <Grid item>
                        <ColumnChooser columns={columns.filter(column => ['created', 'user'].includes(column.name))} hiddenColumnNames={hiddenColumnNames} setHiddenColumnNames={setHiddenColumnNames} />
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                    <GridTable
                        rows={items.data.map(item => ({
                            id: item.id,
                            attribute: item.attribute?.name ?? 'Категория',
                            value: item.attribute ? getValue(item.attribute, item, item.attribute.dictionary, true) : item.value,
                            user: item.user?.login,
                            created: item.created ? format(new Date(item.created), 'H:mm PP',  {locale: ru}) : null
                        }))}
                        columns={columns}
                    >
                        <PagingState
                            currentPage={page}
                            onCurrentPageChange={newPage => {
                                setPage(newPage)
                            }}
                            pageSize={rowsPerPage}
                            onPageSizeChange={newRowsPerPage => {
                                setPage(1)
                                setRowsPerPage(newRowsPerPage)
                            }}
                        />
                        <CustomPaging
                            totalCount={items.data.length ? items.meta.total : 0}
                        />
                        <DragDropProvider />
                        <Table
                            noDataCellComponent={props => {
                                return null
                            }}
                            tableComponent={props => <StickyTable onClick={e => e.stopPropagation()} {...props} setTableRef={setTableRef} />}
                            rowComponent={({ row, tableRow, children }) => (
                                <Table.Row
                                    tableRow={tableRow}
                                    children={children}
                                    className={classes.default}
                                    row={row}
                                />
                            )}
                        />
                        <TableHeaderRow
                            contentComponent={(props) => <TableHeaderContent {...props} id={id} />}
                        />
                        <TableColumnVisibility
                            hiddenColumnNames={hiddenColumnNames}
                            onHiddenColumnNamesChange={setHiddenColumnNames}
                        />
                        <PagingPanel
                            containerComponent={Pager}
                            messages={{showAll: 'Все', rowsPerPage: 'Записей на странице:', info: '{from}-{to} из {count}'}}
                            pageSizes={[50, 100, 200]}
                        />
                    </GridTable>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}
