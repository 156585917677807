import { AttributeService } from '../services/attribute'

export const AttributeActions = {
    values,
    terms,
    items
}

function values (id, params = {}) {
    return dispatch => new Promise((resolve, reject) => {

        AttributeService.values(id, params)
            .then(
                response => {
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function terms (id, params = {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'ATTRIBUTE_TERMS_REQUEST' })

        AttributeService.terms(id, params)
            .then(
                response => {
                    dispatch({ type: 'ATTRIBUTE_TERMS_SUCCESS', payload: { data: response.data, meta: response.meta, limit: params.limit ? params.limit : 10, page: params.page ? params.page : 1} })
                    resolve(response)
                },
                error => {
                    dispatch({ type: 'ATTRIBUTE_TERMS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}

function items (id, params = {}) {
    return dispatch => new Promise((resolve, reject) => {
        dispatch({ type: 'ATTRIBUTE_ITEMS_REQUEST' })

        AttributeService.items(id, params)
            .then(
                items => {
                    dispatch({ type: 'ATTRIBUTE_ITEMS_SUCCESS', payload: items })
                    resolve()
                },
                error => {
                    dispatch({ type: 'ATTRIBUTE_ITEMS_FAILURE' })
                    dispatch({ type: 'ALERT_ERROR', payload: error.message })
                    reject()
                }
            )
    })
}
