import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {
    Grid,
    makeStyles,
    Typography,
    TextField,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableRow, TableCell, TableBody, TableContainer, Tooltip, Fade
} from '@material-ui/core'

import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import {ItemActions} from "../actions/item"
import {stringFormat} from "../../App/helpers/string"

const useStyles = makeStyles(theme => ({
    fullWidth: {
        'width': '100%'
    },
    items: {
        'width': '100%',
        'max-height': '635px',
        'overflow': 'auto'
    },
    accordion: {
        'box-shadow': 'none !important',
        'border-radius': '0 !important',
    },
    accordionSummary: {
        '& .MuiAccordionSummary-content' : {
            'max-width': 'calc(100% - 32px)'
        }
    },
    row: {
        '& .MuiTableCell-root': {
            'white-space': 'break-spaces',
        }
    }
}))

export const Offers = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {item} = props

    const [search, setSearch] = useState('')
    const [expanded, setExpanded] = useState(false)
    const [items, setItems] = useState([])

    const handleChange = (item) => (event, isExpanded) => {
        if (isExpanded) {
            setExpanded(item.id)
        } else {
            setExpanded(false)
        }
    }

    const getRow = (value) => {
        let val
        let data = null

        switch (value.attribute.type.key) {
            case 'select':
                val = value.attribute.options.filter(el => parseInt(el.id) === parseInt(value.value)).map(el => { return el.option }).join(', ')
                break
            case 'integer_unit':
            case 'double_unit':
            case 'range_unit':
                data = JSON.parse(value.value)

                val = (data.value || data.unit) ? `${data.value ?? ''}${value.attribute.space ? ' ' : ''}${data.unit ?? ''}` : ''
                break
            case 'size':
            case 'composite_size':
            case 'dosage':
            case 'number_definitions':
            case 'volume':
            case 'composite_volume':
            case 'needle':
                data = JSON.parse(value.value)

                val = data.value
                break
            case 'number':
                data = JSON.parse(value.value)

                val = `${(value.attribute.addition && value.attribute.addition.prefix.value) ? `${value.attribute.addition.prefix.value}${value.attribute.addition.prefix.space ? ' ' : ''}` : ''}${data.number ? `${data.number}${data.form ? `x${data.form}` : ''}` : ''}${(value.attribute.addition && value.attribute.addition.postfix.value) ? `${value.attribute.addition.postfix.space ? ' ' : ''}${value.attribute.addition.postfix.value}` : ''}`
                break
            case 'boolean':
                val = !!value.value
                break
            case 'multiselect':
                val = value.attribute.options.filter(el => JSON.parse(value.value).includes(el.id)).map(el => { return el.option }).join(', ')
                break
            case 'string':
                data = value.attribute.multiple ? JSON.parse(value.value) : value.value
                val = `${(value.attribute.addition && value.attribute.addition.prefix.value) ? `${value.attribute.addition.prefix.value}${value.attribute.addition.prefix.space ? ' ' : ''}` : ''}${data}${(value.attribute.addition && value.attribute.addition.postfix.value) ? `${value.attribute.addition.postfix.space ? ' ' : ''}${value.attribute.addition.postfix.value}` : ''}`
                break;
            case 'dictionary':
                val = value.attribute.multiple ? JSON.parse(value.value) : value.value
                break;
            default:
                val = `${(value.attribute.addition && value.attribute.addition.prefix.value) ? `${value.attribute.addition.prefix.value}${value.attribute.addition.prefix.space ? ' ' : ''}` : ''}${value.value}${(value.attribute.addition && value.attribute.addition.postfix.value) ? `${value.attribute.addition.postfix.space ? ' ' : ''}${value.attribute.addition.postfix.value}` : ''}`
        }

        switch (value.attribute.name) {
            case 'Дозировка':
            case 'Объем, вес и дозы':
            case 'Вес, объем':
            case 'Фасовка':
            case 'Количество':
            case 'Фасовка/Количество':
            case 'Концентрация':
            case 'Игла':
            case 'Плотность/жесткость':
                val = stringFormat(String(val), false)
        }

        return (!!val && !!val.length) ? (
            <TableRow key={value.id} className={classes.row}>
                <TableCell align="left">
                    {value.attribute.name}
                </TableCell>
                <TableCell align="left">
                    {val}
                </TableCell>
            </TableRow>
        ) : null
    }

    useEffect(() => {
        dispatch(ItemActions.analysis({values: true, search: (search.length ? search : item.name)})).then(data => setItems(data))
    }, [search])

    return (
        <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
            <Grid item xs={10} className={classes.fullWidth}>
                <TextField
                    fullWidth
                    id="category"
                    name="search"
                    label='Поиск по эталонам'
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                />
            </Grid>
            <Grid item xs={10} className={classes.items}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableBody>
                            {items.map(item => (
                                <TableRow key={item.id}>
                                    <TableCell align="left">
                                        <Accordion expanded={expanded === item.id} className={classes.accordion} onChange={handleChange(item)}>
                                            <AccordionSummary className={classes.accordionSummary} expandIcon={<ExpandMoreIcon/>}>
                                                <Tooltip TransitionComponent={Fade} title={item.name} placement="bottom-end">
                                                    <Grid container direction="row" justify="center" alignItems="center">
                                                        <Grid item className={classes.fullWidth}>
                                                            <Typography noWrap={(expanded !== item.id)} style={{whiteSpace: (expanded !== item.id) ? 'nowrap' : 'normal'}}>{item.name}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Tooltip>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <TableContainer>
                                                    <Table stickyHeader aria-label="sticky table">
                                                        <TableBody>
                                                            <TableRow className={classes.row}>
                                                                <TableCell align="left">
                                                                    Категория
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    {item.category?.name}
                                                                </TableCell>
                                                            </TableRow>
                                                            {item.values.sort((first, second) => {
                                                                return !first.attribute.index ? 1 : (!second.attribute.index ? -1 : (first.attribute.index - second.attribute.index))
                                                            }).map(value => getRow(value))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </AccordionDetails>
                                        </Accordion>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}
