import { HttpService } from '../../App/services/http'

export const NotificationService = {
    events,
    read,
    count
}

function events (params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/notification/events`, options, true)
        .then(response => {
            return response
        })
}

function read (id) {
    const options = {
        method: 'PUT'
    }

    return HttpService.http(`/notification/event/${id}/read`, options, true)
        .then(response => {
            return response
        })
}

function count () {
    const options = {
        method: 'GET'
    }

    return HttpService.http(`/notification/count`, options, true)
        .then(response => {
            return response
        })
}
