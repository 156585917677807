import React, {useState} from "react"
import { Field } from "formik"
import { TextField } from "formik-material-ui"
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'

export const FieldUnit = (props) => {
    const { id, label, items, setFieldValue, isSubmitting, disabled } = props
    const [name] = useState(props.hasOwnProperty('name') ? props.name : `attributes.${id}`)
    const [value, setValue] = useState(props.value)

    return (
        <Autocomplete
            filterOptions={createFilterOptions({
                matchFrom: 'start',
                stringify: option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')
            })}
            options={ items }
            name={`${name}`}
            disabled={ isSubmitting || disabled }
            getOptionLabel={option => (option instanceof Object) ? (option.short ?? '') : (option ?? '')}
            onChange={(e, value) => {
                setFieldValue(`${name}`, ((value instanceof Object) ? value.short : value))
            }}
            defaultValue={value}
            renderInput={params => (
                <Field
                    fullWidth
                    {...params}
                    name={`${name}`}
                    InputProps={{
                        ...params.InputProps,
                        onChange: (e) => {
                            setValue(e.target.value)
                        }
                    }}
                    type="text"
                    label={ label }
                    component={ TextField }
                />
            )}
        />
    )
}
