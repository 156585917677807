import React from 'react'
import {useDispatch} from "react-redux"
import {Formik, Form} from 'formik'

import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    IconButton,
    makeStyles, Typography
} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {endings} from "../../App/helpers/endings"
import {FieldCategory} from "../../Category/components/Attribute/FieldCategory"
import * as Yup from "yup"

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    },
    listItemIcon: {
        'min-width': '39px'
    },
    listItemText: {
        'padding-left': '55px'
    },
    listItemTextWithIcon: {
        'padding-left': '16px'
    }
}))

export const CategoryForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const {handleCategory, handleClose, open, items} = props

    return (
        <Formik
            enableReinitialize={ true }
            initialValues={{
                category: {}
            }}
            validationSchema={Yup.object().shape({
                category: Yup.object().required("Выберите категорию!")
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                handleCategory(values.category).then(
                    () => {
                        setSubmitting(false)
                        handleClose()
                    },
                    () => {
                        setSubmitting(false)
                    }
                )
            }}
        >
            {({
                handleSubmit,
                isSubmitting,
                values,
                errors
            }) => (
                <Form>
                    <Dialog
                        fullWidth={true}
                        maxWidth="sm"
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="Назначение категории"
                        classes={{
                            paper: classes.dialog
                        }}
                    >
                        <DialogTitle>
                            <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                <Grid item>
                                    Назначение категории
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label="Закрыть"
                                        onClick={() => handleClose()}
                                    >
                                        <Close/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <Typography>Выберите категорию для {endings(items.length, ['позиции', 'позиций', 'позиций'])}.</Typography>
                                </Grid>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <FieldCategory label={"Категория"} disabled={isSubmitting} isSubmitting={isSubmitting} />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                                color="primary"
                                type="submit"
                            >
                                {isSubmitting ? <CircularProgress size={24}/> : 'Сохранить'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    )
}
