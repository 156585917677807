import {stringFormat} from "../../../../App/helpers/string";

const getValue = (attribute, values, dictionary = false, json = false) => {
    let result = ''
    let data = null

    const val = values ? (values.hasOwnProperty('value') ? values.value : values) : null

    if (val || (!json && values)) {
        switch (attribute.type.key) {
            case 'select':
                result = attribute.options.filter(el => parseInt(el.id) === parseInt(val)).map(el => { return el.option }).join(', ')
                break
            case 'multiselect':
                result = attribute.options.filter(el => (json ? JSON.parse(val) : val).includes(el.id)).map(el => { return el.option }).join(', ')
                break
            case 'integer_unit':
            case 'double_unit':
                data = json ? JSON.parse(val) : values
                result = `${data.value ?? ''}${attribute.space ? ' ' : ''}${data.unit ?? ''}`
                break
            case 'range_unit':
                data = json ? JSON.parse(val) : values
                result = `${data.value ?? ''}${(data.value && data.max) ? ` - ${data.max}` : ''}${attribute.space ? ' ' : ''}${data.unit ?? ''}`
                break
            case 'number':
                data = json ? JSON.parse(val) : values
                result = `${(attribute.addition && attribute.addition.prefix.value) ? `${attribute.addition.prefix.value}${attribute.addition.prefix.space ? ' ' : ''}` : ''}${data.number ? `${data.number}${data.form ? `x${data.form}` : ''}` : ''}${(attribute.addition && attribute.addition.postfix.value) ? `${attribute.addition.postfix.space ? ' ' : ''}${attribute.addition.postfix.value}` : ''}`
                break
            case 'size':
                data = json ? JSON.parse(val) : values
                result = dictionary ? (data.hasOwnProperty('value') ? data.value : data) : `${stringFormat(String(data.width ?? ''), false)}${((data.width && (data.height || data.depth.value)) ? (data.delimiter ? data.delimiter : 'x') : '')}${stringFormat(String(data.height ?? ''), false)}${((data.height && data.depth.value) ? (data.delimiter ? data.delimiter : 'x') : '')}${stringFormat(String(data.depth.value ?? ''), false)}${((data.depth.value && data.depth.max) ? `-${stringFormat(String(data.depth.max), false)}` : '')}${(data.unit && attribute.space) ? ' ' : ''}${data.unit ?? ''} ${data.symbol ?? ''}`
                break
            case 'composite_size':
                data = json ? JSON.parse(val) : values
                result = dictionary ? (data.hasOwnProperty('value') ? data.value : data) : function () {
                    let result = ''

                    Object.entries(attribute.params).reduce((result, [key, index]) => {
                        if (index) {
                            result[index] = key
                        }

                        return result
                    }, []).forEach(name => {
                        switch (name) {
                            case 'prefix':
                            case 'postfix':
                            case 'space':
                            case 'metric':
                            case 'unit':
                            case 'gauge':
                                result += `${data[name] ?? ''}`
                                break
                            case 'amount':
                            case 'height':
                            case 'thickness':
                                result += `${stringFormat(String(data[name] ?? ''), false)}`
                                break
                            case 'size.charrier':
                                result += `${data.size.charrier ?? ''}`
                                break
                            case 'size.range':
                                result += `${data.size.range ?? ''}`
                                break
                            case 'diameter.outer':
                                result += `${stringFormat(String(data.diameter.outer.value ?? ''), false)}${data.diameter.outer.unit ?? ''}${data.diameter.outer.delimiter ?? ''}`
                                break
                            case 'diameter.inner':
                                result += `${stringFormat(String(data.diameter.inner.value ?? ''), false)}${data.diameter.inner.unit ?? ''}${data.diameter.inner.delimiter ?? ''}`
                                break
                            case 'width':
                                result += `${stringFormat(String(data.width.value ?? ''), false)}${data.width.unit ?? ''}${data.width.delimiter ?? ''}`
                            case 'lengths':
                                result += `${data['lengths'].filter((_, index) => ((name === 'width') ? !!index : !index) ).reduce((str, length) => { return `${str}${stringFormat(String(length.value ?? ''), false)}${length.unit ?? ''}${length.delimiter ?? ''}` }, '')}`
                                break
                        }
                    })

                    return result
                } ()
                break
            case 'volume':
                data = json ? JSON.parse(val) : values
                result = dictionary ? (data.hasOwnProperty('value') ? data.value : data) : `${stringFormat(String(data.volume ?? ''), false)}${((data.volume && (data.weight || data.dose.value)) ? (data.delimiter ? data.delimiter : 'x') : '')}${stringFormat(String(data.weight ?? ''), false)}${((data.weight && data.dose.value) ? (data.delimiter ? data.delimiter : 'x') : '')}${stringFormat(String(data.dose.value), false) ?? ''}${((data.dose.value && data.dose.max) ? `-${stringFormat(String(data.dose.max), false)}` : '')}${(data.unit && attribute.space) ? ' ' : ''}${data.unit ?? ''} ${data.symbol ?? ''}`
                break
            case 'composite_volume':
                data = json ? JSON.parse(val) : values
                result = dictionary ? (data.hasOwnProperty('value') ? data.value : data) : function () {
                    let result = ''

                    Object.entries(attribute.params).reduce((result, [key, index]) => {
                        if (index) {
                            result[index] = key
                        }

                        return result
                    }, []).forEach(name => {
                        switch (name) {
                            case 'prefix':
                            case 'postfix':
                                result += `${data[name] ?? ''}`
                                break
                            case 'values':
                                result += `${data[name].reduce((str, value) => {return `${str}${stringFormat(String(value.amount ?? ''), false)}${value.delimiter ?? ''}${stringFormat(String(value.volume ?? ''), false)}${stringFormat(String(value.weight ?? ''), false)}${stringFormat(String(value.dose ?? ''), false)}${value.unit ?? ''}${value.space ?? ''}` }, '')}`
                                break
                        }
                    })

                    return result
                } ()

                break
            case 'dosage':
                data = json ? JSON.parse(val) : values
                result = dictionary ? (data.hasOwnProperty('value') ? data.value : data) : `${data.prefix ?? ''}${data.values.reduce((str, value) => { return `${str}${stringFormat(String(value.amount ?? ''), false)}${value.unit ?? ''}${value.delimiter ?? ''}` }, '')}${data.volume.delimiter ?? ''}${stringFormat(String(data.volume.value ?? ''), false)}${data.volume.unit ?? ''}${data.description ?? ''}${data.initial ?? ''}`
                break
            case 'number_definitions':
                data = json ? JSON.parse(val) : values
                result = dictionary ? (data.hasOwnProperty('value') ? data.value : data) : `${data.values.reduce((str, value) => { return `${str}${stringFormat(String(value.amount ?? ''), false)}${value.definitions ?? ''}${value.unit ?? ''}${value.delimiter ?? ''}` }, '')}`
                break
            case 'needle':
                data = json ? JSON.parse(val) : values
                result = dictionary ? (data.hasOwnProperty('value') ? data.value : data) : `${data.information ?? ''}${data.gauge.value ?? ''}${stringFormat(String(data.diameter.value ?? ''), false)}${data.diameter.delimiter ?? ''}${stringFormat(String(data.length.value ?? ''), false)}${data.length.unit ?? ''}${data.length.delimiter ?? ''}${stringFormat(String(data.amount ?? ''), false)} ${data.gauge.additional ?? ''}${data.bend ?? ''}${data.description ?? ''}`
                break
            case 'boolean':
                result = (!!val ? attribute.name : false).toString()
                break
            case 'string':
                data = (json && attribute.multiple) ? JSON.parse(val).join(', ') : val
                result = `${(attribute.addition && attribute.addition.prefix.value) ? `${attribute.addition.prefix.value}${attribute.addition.prefix.space ? ' ' : ''}` : ''}${((values.keyword && values.keyword.option) ? values.keyword.option.value : data)}${(attribute.addition && attribute.addition.postfix.value) ? `${attribute.addition.postfix.space ? ' ' : ''}${attribute.addition.postfix.value}` : ''}`
                break;
            case 'dictionary':
                result = attribute.multiple ? (json ? JSON.parse(val).join(', ') : val.join(', ')) : val
                break;
            default:
                result = `${(attribute.addition && attribute.addition.prefix.value) ? `${attribute.addition.prefix.value}${attribute.addition.prefix.space ? ' ' : ''}` : ''}${((values.keyword && values.keyword.option) ? values.keyword.option.value : val)}${(attribute.addition && attribute.addition.postfix.value) ? `${attribute.addition.postfix.space ? ' ' : ''}${attribute.addition.postfix.value}` : ''}`
        }
    }

    return result?.replace(/\t+|\s+/g, ' ')?.trim()
}

export { getValue }
