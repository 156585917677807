import { HttpService } from '../../App/services/http'

export const ConverterService = {
    positions,
    add,
    edit,
    remove
}

function positions (params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/converter/positions`, options, true)
        .then(response => {
            return response
        })
}

function add (values) {
    const options = {
        method: 'POST',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/converter/position`, options, true)
        .then(response => {
            return response
        })
}

function edit (id, values) {
    const options = {
        method: 'PUT',
        body: JSON.stringify(values)
    }

    return HttpService.http(`/converter/position/${id}`, options, true)
        .then(response => {
            return response
        })
}

function remove (id) {
    const options = {
        method: 'DELETE'
    }

    return HttpService.http(`/converter/position/${id}`, options, true)
        .then(response => {
            return response
        })
}
