import { HttpService } from '../../App/services/http'

export const FileService = {
    files,
    file,
    unloading,
    remove,
    standards,
    categories,
    users
}

function files (params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/markup/files`, options, true)
        .then(response => {
            return response
        })
}

function file (id) {
    const options = {
        method: 'GET'
    }

    return HttpService.http(`/markup/file/${id}`, options, true)
        .then(response => {
            return response.file
        })
}

function unloading(formData) {
    const options = {
        method: "POST",
        body: formData
    }

    return HttpService.http(`/markup/file`, options, true, true)
        .then(response => {
            return response.file
        })
}

function remove (id) {
    const options = {
        method: 'DELETE'
    }

    return HttpService.http(`/markup/file/${id}`, options, true)
        .then(response => {
            return response
        })
}

function standards (id, params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/markup/file/${id}/standards`, options, true)
        .then(response => {
            return response
        })
}

function categories (id, params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/markup/file/${id}/categories`, options, true)
        .then(response => {
            return response
        })
}

function users (id, params = null) {
    const options = {
        method: 'GET',
        params: params
    }

    return HttpService.http(`/markup/file/${id}/users`, options, true)
        .then(response => {
            return response
        })
}
