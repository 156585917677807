import React from 'react'
import classNames from 'clsx'

import {makeStyles} from "@material-ui/core"

import {PageSizeSelector} from './PageSizeSelector'
import {Pagination} from './Pagination'
import {PageSelector} from "./PageSelector"

const useStyles = makeStyles(theme => ({
    pager: {
        overflow: 'hidden',
        padding: theme.spacing(1.5),
        display: 'flex',
        flex: 'none',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}))

export const Pager = ({
    currentPage,
    pageSizes,
    totalPages,
    pageSize,
    onCurrentPageChange,
    onPageSizeChange,
    totalCount,
    getMessage,
    className,
    ...restProps
}) => {
    const classes = useStyles()

    return (
        <div
            className={classNames(classes.pager, className)}
            {...restProps}
        >
            {!!pageSizes.length && (
                <PageSizeSelector
                    pageSize={pageSize}
                    onPageSizeChange={onPageSizeChange}
                    pageSizes={pageSizes}
                    getMessage={getMessage}
                />
            )}
            <Pagination
                totalPages={totalPages}
                totalCount={totalCount}
                currentPage={currentPage}
                onCurrentPageChange={page => onCurrentPageChange(page)}
                pageSize={pageSize}
                getMessage={getMessage}
            />
            <PageSelector
                totalPages={totalPages}
                totalCount={totalCount}
                currentPage={currentPage}
                onCurrentPageChange={page => onCurrentPageChange(page)}
            />
        </div>
    )
}
