import React, {useState} from "react"
import { FormControlLabel } from "@material-ui/core"
import { Field } from "formik"
import { Switch } from "formik-material-ui"

export const FieldBoolean = (props) => {
    const { id, label, isSubmitting, disabled } = props
    const [name] = useState(props.hasOwnProperty('name') ? props.name : `attributes.${id}`)

    return (
        <FormControlLabel
            control={
                <Field
                    name={ `${name}` }
                    label={ label }
                    disabled={ isSubmitting || disabled }
                    component={ Switch }
                />
            }
            label={ label }
        />
    )
}
