import {HttpService} from "../../App/services/http"

export const SettingsService = {
    table,
    update,
}

function table(name) {
    const options = {
        method: "GET"
    }

    return HttpService.http(`/settings/table/${name}`, options, true)
        .then(response => {
            return response.settings
        })
}

function update(values) {
    const options = {
        method: "PUT",
        body: JSON.stringify(values)
    }

    return HttpService.http("/settings/table", options, true)
        .then(response => {
            return response
        })
}
