const numberFormat = (number, separator = ' ') => {
    number = number.replaceAll(',', '.')
    number += ''
    const batch = number.split('.')
    let numerator = parseInt(batch[0]).toString()
    const denominator = (batch.length > 1) ? ',' + batch[1] : ''
    const rgx = /(\d+)(\d{3})/

    if (separator) {
        while (rgx.test(numerator)) {
            numerator = numerator.replace(rgx, '$1' + separator + '$2')
        }
    }

    return numerator + denominator
}

const stringFormat = (str, separate = true) => {
    let result = ''

    if (str) {
        let number = ''

        for (let i = 0; i < str.length; i++) {
            if (!isNaN(str[i]) || (!!number.length && ((str[i] === '.') || (str[i] === ',')))) {
                switch (str[i]) {
                    case ' ':
                        if (number.length) {
                            if (((number.split(".").length + number.split(",").length) - 2) > 1) {
                                result += number
                            } else {
                                result += numberFormat(number, separate ? ' ' : null)
                            }
                            number = ''
                        }
                        result += str[i]
                        break;
                    default:
                        number += str[i]
                        if (str.length === (i + 1)) {
                            if (((number.split(".").length + number.split(",").length) - 2) > 1) {
                                result += number
                            } else {
                                result += numberFormat(number, separate ? ' ' : null)
                            }
                            number = ''
                        }
                }
            } else {
                switch (str[i]) {
                    case '(':
                    case '-':
                    case ')':
                        if (number.length) {
                            result += number
                            number = ''
                        }
                        break;
                    default:
                        if (number.length) {
                            if (((number.split(".").length + number.split(",").length) - 2) > 1) {
                                result += number
                            } else {
                                result += numberFormat(number, separate ? ' ' : null)
                            }
                            number = ''
                        }
                }
                result += str[i]
            }
        }
    }
    return result
}

export { stringFormat }
