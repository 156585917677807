import React from 'react'
import {Formik, Field, Form, FieldArray} from 'formik'

import {
    Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Grid, IconButton, makeStyles
} from '@material-ui/core'
import {Add, Clear} from "@material-ui/icons"
import {
    TextField
} from 'formik-material-ui'
import {useDispatch} from "react-redux"
import {ItemActions} from "../actions/item"

const useStyles = makeStyles(theme => ({
    dialog: {
        'border-radius': 0
    },
    fullWidth: {
        'width': '100%'
    }
}))

export const MatchingForm = (props) => {
    const dispatch = useDispatch()
    const classes = useStyles()

    const { matching, id, handleClose, open } = props

    return (
        <Formik
            enableReinitialize={ true }
            initialValues = {{
                items: matching.length ? matching : [{value: ''}]
            }}
            onSubmit = {(values, { setSubmitting }) => {
                let items = values.items.map((value) => {
                    return {
                        search: value.value,
                        standard_id: id
                    }
                })

                matching.forEach(item => {
                    if (!items.find(value => (value.search === item.value))) {
                        items.push({
                            search: item.value,
                            standard_id: null
                        })
                    }
                })

                return dispatch(ItemActions.cache({items: items}))
            }}
        >
            {({
                  values,
                  handleSubmit,
                  isSubmitting
              }) => (
                <Form>
                    <Dialog
                        fullWidth={ true }
                        maxWidth="sm"
                        open={ open }
                        onClose={ handleClose }
                        aria-labelledby="Вариации"
                        classes={{
                            paper: classes.dialog
                        }}
                    >
                        <DialogTitle>Вариации</DialogTitle>
                        <DialogContent>
                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                <Grid item sm={10} className={classes.fullWidth}>
                                    <FieldArray
                                        name="items"
                                        render={ arrayOptions => (
                                            <Grid container direction='column' justify='center' alignItems='center' spacing={2}>
                                                {values.items.map((option, key) => (
                                                    <Grid item key={key} className={classes.fullWidth}>
                                                        <Grid container direction='row' justify='space-between' alignItems='center' spacing={2}>
                                                            <Grid item sm={9} className={classes.fullWidth}>
                                                                <Field
                                                                    fullWidth
                                                                    name={`items.${key}.value`}
                                                                    type="text"
                                                                    component={ TextField }
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    onClick={() => arrayOptions.remove(key) }
                                                                    color="primary"
                                                                    aria-label="Удалить"
                                                                    component="span"
                                                                >
                                                                    <Clear />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                <Grid item className={classes.fullWidth}>
                                                    <IconButton
                                                        onClick={() => arrayOptions.push({ value: '' })}
                                                        color={'primary'}
                                                        aria-label="Добавить"
                                                        component="span"
                                                    >
                                                        <Add />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <DialogActions>
                                <Button
                                    disabled={ isSubmitting }
                                    onClick={ handleSubmit }
                                    color="primary"
                                    type="submit"
                                >
                                    { isSubmitting ? <CircularProgress size={24} /> : 'Сохранить' }
                                </Button>
                            </DialogActions>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    )
}
